<template>
    <btn-floating-action :width="width" :order="order" :color="color" :background="background">
      <c-icon>{{prependIcon}}</c-icon>
      <slot>primary button</slot>
      <c-icon>{{postpendIcon}}</c-icon>
    </btn-floating-action>
  </template>
  
  <script>
  import {floatingActionButton} from '../atoms/button_styles'
  import {iconStyle} from '../atoms/icon_styles'
  export default {
      name: 'buttonFloatingAction',
      props:{
        prependIcon: String,
        postpendIcon: String,
        width: {
          type: Number
        },
        order: {
            type: Number
        },
        color:{
          type: String
        },
        background: {
            type: String
        },
      },
      components:{
          'btn-floating-action': floatingActionButton,
          'c-icon': iconStyle
      }
  }
  </script>