<template>
    <c-secondary-hyperlink :color="color" :width="width" :order="order" :height="height">
      <c-icon>{{prependIcon}}</c-icon>
      <slot >NavLink</slot>
      <c-icon>{{postpendIcon}}</c-icon>
    </c-secondary-hyperlink>
</template>
  
  <script>
  import {secondaryHypherLinkButton} from '../atoms/button_styles'
  import {iconStyle} from '../atoms/icon_styles'
  export default {
      name: 'secondary-hyperlink',
      props:{
        prependIcon: String,
        postpendIcon: String,
        width: {
          type: Number
        },
        height: {
          type: String
        },
        color: {
          type: String
        },
        order: {
          type: Number
        }
      },
      components:{
          'c-secondary-hyperlink': secondaryHypherLinkButton,
          'c-icon': iconStyle
      }
  }
  </script>