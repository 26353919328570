let theme = {
    'Secondary-Gray0000': '#F8FFFF',
    'Secondary-Gray0100': '#F0FFFF',
    'Secondary-Gray0200': '#D2E7E7',
    'Secondary-Gray0300': '#C5E5E5',
    'Secondary-Gray0400': '#C5E6E6',
    'Secondary-Gray0500': '#93ABAB',
    'Secondary-Gray0600': '#6D8080',
    'Secondary-Gray0700': '#586666',
    'Secondary-Gray0800': '#424D4D',
    'Primary-Cyan0300': '#00CED1',
    'Primary-Cyan0400': '#00B0B2',
    'PrimaryCyan0400-Default': '#00B0B3',
    'Primary-Cyan0500': '#009799',
    'status-success': '#009900',
    'status-error': '#CF0000',
    'Accent-Magenta0000': '#F22594',
    'Accent-Magenta0200': '#E6238C',
    'Accent-Magenta0300' : '#CC1F7C',
    'Accent-Magenta0400' : '#A61965',
    'Primary-white': '#FFFFFF;'
}

export {theme}