<template>
    <node-view-wrapper as="span" inline>
      <div v-if="!viewMode">
        <c-basic-info-form-input width="100px">
            <c-form-input-field v-model="inputText" @input.native="renderedInputText"></c-form-input-field>
        </c-basic-info-form-input>
        <c-btn-secondary @click.native="saveInputTextFn">save</c-btn-secondary>
        <c-btn-secondary @click.native="cancelInputTextFn">cancel</c-btn-secondary>
      </div>
      <p v-else :id="'viewModeInputBoxRenderID_' + comp_uuid" style="width:fit-content;border: 1px solid #93ABAB;background: #F0FFFF;border-radius: 4px;"></p>
    </node-view-wrapper>
</template>

<script>

let comp_uuid = 0;

//https://tiptap.dev/guide/node-views/vue#update-node-attributes
//https://karlomikus.com/blog/create-latex-math-node-and-mark-for-tiptap-2
// mergeAttributes: https://tiptap.dev/guide/custom-extensions
import { NodeViewWrapper } from '@tiptap/vue-2'
import AppConfig from '../../config.js';
import axios from 'axios';
import buttonSecondary from '../molecules/button_secondary.vue'
import {formInputField, basicInfoFormInputDiv} from '../atoms/create_course_div_style'

export default {
  components: {
    'node-view-wrapper': NodeViewWrapper,
    'c-btn-secondary': buttonSecondary,
    'c-basic-info-form-input': basicInfoFormInputDiv,
    'c-form-input-field': formInputField
  },
  props:{
    editor: {
      type: Object,
    },
    node: {
      type: Object,
      required: true,
    },
    // update attributes of the current node
    updateAttributes: {
      type: Function,
    },
    parent: {
      type: String,
      //required: true,
    }
  },
  data() {
    return {
        //UI_baseURL: AppConfig.UI_Base_URL,
        //auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
        viewMode: false,
        inputText: "",
        previewText: "",
        comp_uuid: comp_uuid,
        UI_baseURL: AppConfig.UI_Base_URL,
        auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
        chal_id: ""
    }
  },
  beforeCreate() {
    comp_uuid += 1 
  },
  async mounted(){
    this.chal_id = this.node.attrs.chal_id
    // in case value is null req api
    if(this.$store.state.files_manifest[this.node.attrs.uniqkey].value === null){
      this.inputText = await this.getEditorKeyContentFn(this.node.attrs.uniqkey)
    }
    else{
      this.inputText = this.$store.state.files_manifest[this.node.attrs.uniqkey].value
    }
  },
  watch:{
    async inputText(newVal, val) {
      if (newVal == val) {
        return;
      }
      console.log("textInput formula updated for key", this.node.attrs.uniqkey);
      console.log("state of files manifest before update", this.$store.state.files_manifest);
      if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === 'add'){
        this.$store.commit('updateKeyValue', {key: this.node.attrs.uniqkey, value: newVal})
      }
      else if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === 'edit'){
        this.$store.commit('updateKeyValue', {key: this.node.attrs.uniqkey, value: newVal})
      }
      else if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === 'del'){
        console.log("not updating the files_manifest");
      }
      else if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === ''){
        this.$store.commit('updateKeyValue', {key: this.node.attrs.uniqkey, value: newVal})
        this.$store.commit('updateKeyOpr', {key: this.node.attrs.uniqkey, opr: 'edit'})
      }
      console.log("state of files manifest after update", this.$store.state.files_manifest);
    }
  },
  methods: {
    sleep(ms) {
        // add some sleep using this function , can be used when we want to wait some time to let page load the html
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    renderedInputText(){
      console.log("inputText", this.inputText);
    },
    async getEditorKeyContentFn(storedUniqKey){
      // request api if value of key is not in the stored manifest
      let storeApiResp = await axios.get(this.auth_api_Base_URL + "/v2_1/chalEditors/" + this.chal_id + "/?chal_editor=" + storedUniqKey)
      console.log("storeApiResp is", storeApiResp)
      return storeApiResp.data.actualContent
      // get rawFormula value from here, if apiResp return error means key not stored yet so new key and hence rawFormula will be just a empty string.
    },
    async saveInputTextFn(){
      this.viewMode = true
      this.previewText = this.inputText
      console.log("previewText", this.previewText);
      await this.sleep(2)
      console.log("id is", 'viewModeInputBoxRenderID_' + this.comp_uuid);
      let inputElement = document.getElementById('viewModeInputBoxRenderID_' + this.comp_uuid)
      console.log("inputElement",inputElement);
      inputElement.innerHTML = this.previewText
      if(this.previewText === ''){
        inputElement.style.width = '50px'
        inputElement.style.height = '26px'
      }
    },
    async cancelInputTextFn(){
      this.inputText = this.previewText
      this.viewMode = true
      await this.sleep(2)
      if (!this.inputText) {
        return '';
      }
      let originalElement = document.getElementById('viewModeInputBoxRenderID_' + this.comp_uuid)
      console.log("originalElement",originalElement);
    }
  }
}
</script>