import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import authentication from "@/plugins/kc_auth.js"
import store from './store/store.js'

Vue.use(authentication);

/**
 * Maps the slot category to a user-friendly string.
 * @param {string} slot_category - The slot category to map.
 * @returns {string} An object containing the mapped slot category string and the color.
*/
Vue.prototype.$get_slot_category=((slot_category)=> {
  const slotCategoryMapping = {
      auto_create_type_slot: {
          text: "AUTO CREATE SLOT",
          color: "Accent-Magenta0000"  
      },
      adhoc_slot_by_tutor: {
          text: "Adhoc Slot by Tutor",
          color: "Accent-Magenta1000"  
      },
      self_study_slot: {
          text: "Self Study Slot",
          color: "Accent-Magenta0200"  
      },
      sponsor_created_slot: {
          text: "Sponsor Created Slot",
          color: "Accent-Magenta0300"  
      },
      meeting_slot: {
          text: "Meeting Slot",
          color: "Accent-Magenta0400"  
      }
  }
      return slotCategoryMapping[slot_category] || {
          text: "Unknown Slot Category",
          color: "status-error"  // status-error key
      };
})

let keycloakInitOption = {}

// TODO: add proper if else condition and also add it for refresh and id token too
if(localStorage.getItem('access-token')===null || localStorage.getItem('access-token')==="null" || localStorage.getItem('access-token')==="undefined" || localStorage.getItem('access-token') === undefined){
  keycloakInitOption = { 
    checkLoginIframe: false
  }
}
else{
  keycloakInitOption = { 
    checkLoginIframe: false, 
    token: localStorage.getItem('access-token'), 
    refreshToken: localStorage.getItem('refresh-token'), 
    idToken: localStorage.getItem('id-token')
  }
}

Vue.config.productionTip = false

Vue.$keycloak
  .init(keycloakInitOption)
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }).catch(error =>{
    console.log("error in keycloak init", error);
    localStorage.removeItem('access-token')
    localStorage.removeItem('id-token')
    localStorage.removeItem('refresh-token')
    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })

