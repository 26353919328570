<template>
  <div class="my-orgs">
    <c-H4-22 :color="colorTheme['Secondary-Gray0700']">My orgs</c-H4-22>
    <div v-if="orgsData.length>0" class="orgCard">
      <div class="grid-org-item-list-div" v-for="(org, id) in orgsData" :key="org">
        <a class="org-title" :id="'inputOrg'+ id" :href="UI_base_url + '/orgs/' + org.id">
          <c-H5-18 style="color: #00B0B2 !important;">{{ org.org_name }}/{{ org.org_role }}</c-H5-18>
        </a>
        <c-body-14 :color="colorTheme['Secondary-Gray0700']">{{ org.about }}</c-body-14>
        <c-body-14 :color="colorTheme['Secondary-Gray0700']">{{ convertDateFormat(org.establish_since) }}</c-body-14>
      </div>
    </div>
    <div v-else style="width: 100%;">
      <c-row textalign="left" text_transform="none">
        You don't have any org
      </c-row>
    </div>
  </div>
</template>
  
<script>
//import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22, body_14, H5_18} from '../atoms/text_styles';
import { theme } from '../assets/theme';
import {rowDiv} from '../atoms/div_styles'
  export default {
    name: 'MyOrgs',
    data() {
      return {
        colorTheme:theme,
        currentView: 'MyOrgs', // Default view
        UI_base_url: AppConfig.UI_Base_URL,
        auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
        orgsData:[]
      };
    },
    props:{
      orgsListdata:{
        type: Array
      }
    },
    components:{
      'c-H4-22': H4_22,
      'c-body-14': body_14,
      'c-H5-18': H5_18,
      'c-row': rowDiv
    },
    async mounted(){
      console.log("orgsListdata", this.orgsListdata);
      this.orgsData = this.orgsListdata
    },
    methods: {
      /*async getUsersOrgFn(){
          try{
              let getUsersOrgResp = await axios.get(this.auth_api_Base_URL + "/v1_0/user_orgs")
              console.log("resp of getUsersOrg", getUsersOrgResp);
              this.org_details = getUsersOrgResp.data
          }catch(error){
              console.log("error in response of get getUsersOrg", error);
          }
      },*/
      gotodashboardfn(org) {
        window.location.href = this.UI_base_url + '/org/' + org.org_id
      },
      convertDateFormat(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      }
    }
  };
    
  </script>
  
  <style>
  .my-orgs {
    /* Add your styles here */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    gap: 8px;
    align-items: flex-start;
    border-radius: 8px;
    background: #F0FFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .org-title {
    color: #00B0B2 !important ;
  }
  .grid-org-item-list-div{
    text-align: left;
    display: flex;
    width: 50%;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Abhyas-Color-Base-Secondary-Gray0000, #F8FFFF);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }

  .orgCard{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  </style>
  