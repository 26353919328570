<template>
  <node-view-wrapper>
      <div v-if="!viewMode" class="image-component" :class="{'is-selected': selected}">
          <div class="image-component__title">
            <h3>Image</h3>
          </div>
          <img :src="rawImgUrl" style="max-width:500px; max-height:500px; width: auto; height: auto;">
          <c-math-keyboard-opr-btn>
              <c-btn-secondary @click.native="viewMode=true">Done</c-btn-secondary>
              <c-btn-secondary @click.native="removeImageFn()">Remove</c-btn-secondary>
          </c-math-keyboard-opr-btn>
      </div>
      <img v-else :src="rawImgUrl" style="max-width:250px; max-height:250px; width: auto; height: auto;" @click="viewMode=false">
  </node-view-wrapper>
</template>

<script>
//https://tiptap.dev/guide/node-views/vue#update-node-attributes
//https://karlomikus.com/blog/create-latex-math-node-and-mark-for-tiptap-2
// mergeAttributes: https://tiptap.dev/guide/custom-extensions
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import AppConfig from '../../config.js';
import buttonSecondary from '../molecules/button_secondary.vue'
import {mathKeyboardOprBtnDiv} from '../atoms/create_challenge_div_styles';

export default {
  components: {
    'node-view-wrapper': NodeViewWrapper,
    'c-btn-secondary': buttonSecondary,
    'c-math-keyboard-opr-btn': mathKeyboardOprBtnDiv,
  },
  props: nodeViewProps, //access node attrs using this.node.attrs.<name>
  data() {
    return {
        rawImgUrl: '', 
        UI_baseURL: AppConfig.UI_Base_URL,
        auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
        viewMode: false
    }
  },
  mounted() {
    // console.log("========this.node.attrs===========", this.node.attrs);
    
    // TODO: req api if value of key is null
    let createdImgUrl = ''
    if(this.$store.state.files_manifest[this.node.attrs.uniqkey].value === null){
       createdImgUrl = this.auth_api_Base_URL + "/v2_1/chalEditors/" + this.node.attrs.chal_id + "/?chal_editor=" + this.node.attrs.uniqkey
    }
    else{
      createdImgUrl = URL.createObjectURL(this.$store.state.files_manifest[this.node.attrs.uniqkey].value)
    }
    this.rawImgUrl = createdImgUrl //https://tiptap.dev/guide/node-views/vue#access-node-attributes
  },
  methods: {
    removeImageFn(){
      // remove the node
      this.$store.commit('updateKeyOpr', {key: this.node.attrs.uniqkey, opr: 'del'})
      this.deleteNode()
    }
  },
}
</script>
<style lang="css">
.image-component {
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #F0FFFF;
  margin: 5px 0;
}
.image-component.is-selected {
  border-color: rgb(33, 104, 211);
}
.image-component h3 {
  margin: 0;
}
.image-component__title {
  display: flex;
  margin-bottom: 20px;
}
.image-component__title a {
  margin-left: auto;
}
.image-component__formula {
  margin-top: 10px;
  background: rgba(0,0,0,.1);
  padding: 10px;
}
.image-component textarea {
  padding: 10px;
  font-size: 1.2rem;
}
</style>
