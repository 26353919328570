import styled from "vue-styled-components"
import {theme} from '../assets/theme.js'
import config from '../assets/config.js'


const rowDivProps = {
    width: Number, 
    textalign: String,
    padding: Number,
    gap: Number,
    display: String,
    order:Number,
    text_transform: String
};
export const rowDiv = styled("div", rowDivProps)`
    display: ${(props) => ((props.display) ? (props.display):config['divs']['rowDiv']['display'])};
    align-items: ${config['divs']['rowDiv']['align-items']};
    overflow: hidden;
    text-transform: ${(props) => ((props.text_transform) ? (props.text_transform): config['divs']['rowDiv']['text-transform'])};
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : config['divs']['rowDiv']['text-align'])};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['rowDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['rowDiv']['gap'])}
    flex:${config['divs']['rowDiv']['flex']}
    order:${(props) => ((props.order) ? (props.order) : config['divs']['rowDiv']['order'])}
    flex-grow:${config['divs']['rowDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['rowDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' :config['divs']['rowDiv']['height'])};
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }     
`;
const createChallengePageContentDivProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number
};
export const pageContentDiv = styled("div", createChallengePageContentDivProps)`
    display: ${config['divs']['pageContentDiv']['display']};
    flex-direction: ${config['divs']['pageContentDiv']['flex-direction']};
    align-items: ${config['divs']['pageContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['pageContentDiv']['padding'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['pageContentDiv']['width'])};
    height: ${config['divs']['pageContentDiv']['height']};
    overflow-y: ${config['divs']['pageContentDiv']['overflow-y']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
    }
`;
export const rightRailContentDiv = styled("div", createChallengePageContentDivProps)`
    display: ${config['divs']['rightRailContentDiv']['display']};
    flex-direction: ${config['divs']['rightRailContentDiv']['flex-direction']};
    align-items: ${config['divs']['rightRailContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['rightRailContentDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['rightRailContentDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['rightRailContentDiv']['width'])};
    height: ${config['divs']['rightRailContentDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    flex: ${config['divs']['rightRailContentDiv']['flex']};
    order: ${config['divs']['rightRailContentDiv']['order']};
    align-self: ${config['divs']['rightRailContentDiv']['align-self']};
    flex-grow: ${config['divs']['rightRailContentDiv']['flex-grow']};
`;
const createChallengePageFormSectionDivProps = {
    width: String,
    background_color: String,
    padding: Number,
    gap: Number
};
export const pageFormContentDiv = styled("div", createChallengePageFormSectionDivProps)`
    display: ${config['divs']['pageFormContentDiv']['display']};
    flex-direction: ${config['divs']['pageFormContentDiv']['flex-direction']};
    align-items: ${config['divs']['pageFormContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['pageFormContentDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['pageFormContentDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['pageFormContentDiv']['width'])};
    height: ${config['divs']['pageFormContentDiv']['height']};
    overflow-y: ${config['divs']['pageFormContentDiv']['overflow-y']};
    background: ${theme['Secondary-Gray0100']};
    flex: ${config['divs']['pageFormContentDiv']['flex']};
    order: ${config['divs']['pageFormContentDiv']['order']};
    align-self: ${config['divs']['pageFormContentDiv']['align-self']};
    flex-grow: ${config['divs']['pageFormContentDiv']['flex-grow']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
    }
`;
export const pageFormSectionDiv = styled("div", createChallengePageFormSectionDivProps)`
    display: ${config['divs']['pageFormSectionDiv']['display']};
    flex-direction: ${config['divs']['pageFormSectionDiv']['flex-direction']};
    align-items: ${config['divs']['pageFormSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['pageFormSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['pageFormSectionDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['pageFormSectionDiv']['width'])};
    height: ${config['divs']['pageFormSectionDiv']['height']};
    flex: ${config['divs']['pageFormSectionDiv']['flex']};
    order: ${config['divs']['pageFormSectionDiv']['order']};
    flex-grow: ${config['divs']['pageFormSectionDiv']['flex-grow']};
`;
const formSectionChallengeBasicInfoDivProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number,
    display : String
};
export const formSectionChallengeBasicInfoDiv = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['formSectionChallengeBasicInfoDiv']['display']};
    flex-direction: ${config['divs']['formSectionChallengeBasicInfoDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionChallengeBasicInfoDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['formSectionChallengeBasicInfoDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['formSectionChallengeBasicInfoDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionChallengeBasicInfoDiv']['width'])};
    height: ${config['divs']['formSectionChallengeBasicInfoDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['formSectionChallengeBasicInfoDiv']['box-shadow']};
    border-radius: ${config['divs']['formSectionChallengeBasicInfoDiv']['border-radius']};
    flex: ${config['divs']['formSectionChallengeBasicInfoDiv']['flex']};
    order: ${config['divs']['formSectionChallengeBasicInfoDiv']['order']};
    align-self: ${config['divs']['formSectionChallengeBasicInfoDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionChallengeBasicInfoDiv']['flex-grow']};
`;
export const headingBarDiv = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['headingBarDiv']['display']};
    flex-direction: ${config['divs']['headingBarDiv']['flex-direction']};
    justify-content: ${config['divs']['headingBarDiv']['justify-content']};
    align-items: ${config['divs']['headingBarDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['headingBarDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['headingBarDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['headingBarDiv']['width'])};
    height: ${config['divs']['headingBarDiv']['height']};
    flex: ${config['divs']['headingBarDiv']['flex']};
    order: ${config['divs']['headingBarDiv']['order']};
    align-self: ${config['divs']['headingBarDiv']['align-self']};
    flex-grow: ${config['divs']['headingBarDiv']['flex-grow']};
`;
export const heading = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['heading']['display']};
    flex-direction: ${config['divs']['heading']['flex-direction']};
    align-items: ${config['divs']['heading']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['heading']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['heading']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['heading']['width'])};
    height: ${config['divs']['heading']['height']};
    flex: ${config['divs']['heading']['flex']};
    order: ${config['divs']['heading']['order']};
    flex-grow: ${config['divs']['heading']['flex-grow']};
`;
export const basicInfoDiv = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['basicInfoDiv']['display']};
    flex-direction: ${config['divs']['basicInfoDiv']['flex-direction']};
    align-items: ${config['divs']['basicInfoDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['basicInfoDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['basicInfoDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['basicInfoDiv']['width'])};
    height: ${config['divs']['basicInfoDiv']['height']};
    flex: ${config['divs']['basicInfoDiv']['flex']};
    order: ${config['divs']['basicInfoDiv']['order']};
    align-self: ${config['divs']['basicInfoDiv']['align-self']};
    flex-grow: ${config['divs']['basicInfoDiv']['flex-grow']};
`;
export const formDiv = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['formDiv']['display'])};
    flex-direction: ${config['divs']['formDiv']['flex-direction']};
    align-items: ${config['divs']['formDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['formDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['formDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formDiv']['width'])};
    height: ${config['divs']['formDiv']['height']};
    flex: ${config['divs']['formDiv']['flex']};
    order: ${config['divs']['formDiv']['order']};
    align-self: ${config['divs']['formDiv']['align-self']};
    flex-grow: ${config['divs']['formDiv']['flex-grow']};
`;
export const inputDropDownSection = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['inputDropDownSection']['display']};
    flex-direction: ${config['divs']['inputDropDownSection']['flex-direction']};
    align-items: ${config['divs']['inputDropDownSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['inputDropDownSection']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['inputDropDownSection']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['inputDropDownSection']['width'])};
    height: ${config['divs']['inputDropDownSection']['height']};
    flex: ${config['divs']['inputDropDownSection']['flex']};
    order: ${config['divs']['inputDropDownSection']['order']};
    flex-grow: ${config['divs']['inputDropDownSection']['flex-grow']};
`;
export const inputConceptsSection = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['inputConceptsSection']['display']};
    flex-direction: ${config['divs']['inputConceptsSection']['flex-direction']};
    align-items: ${config['divs']['inputConceptsSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['inputConceptsSection']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['inputConceptsSection']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['inputConceptsSection']['width'])};
    height: ${config['divs']['inputConceptsSection']['height']};
    flex: ${config['divs']['inputConceptsSection']['flex']};
    order: ${config['divs']['inputConceptsSection']['order']};
    flex-grow: ${config['divs']['inputConceptsSection']['flex-grow']};
`;
export const selectItemDiv = styled("div", formSectionChallengeBasicInfoDivProps)`
    display: ${config['divs']['selectItemDiv']['display']};
    align-items: ${config['divs']['selectItemDiv']['align-items']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['selectItemDiv']['width'])};
    height: ${config['divs']['selectItemDiv']['height']};
    flex: ${config['divs']['selectItemDiv']['flex']};
    order: ${config['divs']['selectItemDiv']['order']};
    align-self: ${config['divs']['selectItemDiv']['align-self']};
    flex-grow: ${config['divs']['selectItemDiv']['flex-grow']};
`;
export const inputDropDownDiv = styled("input", formSectionChallengeBasicInfoDivProps)`
    box-sizing: ${config['divs']['frames']['inputDropDownDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['frames']['inputDropDownDiv']['display'])};
    flex-direction: ${config['divs']['frames']['inputDropDownDiv']['flex-direction']};
    justify-content: ${config['divs']['frames']['inputDropDownDiv']['justify-content']};
    align-items: ${config['divs']['frames']['inputDropDownDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['frames']['inputDropDownDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['frames']['inputDropDownDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['inputDropDownDiv']['width'])};
    height: ${config['divs']['frames']['inputDropDownDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius:${config['divs']['frames']['inputDropDownDiv']['border-radius']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    flex: ${config['divs']['frames']['inputDropDownDiv']['flex']};
    order: ${config['divs']['frames']['inputDropDownDiv']['order']};
    align-self: ${config['divs']['frames']['inputDropDownDiv']['align-self']};
    flex-grow: ${config['divs']['frames']['inputDropDownDiv']['flex-grow']};
`;
const helperTextDivProps = {
    width: Number,
    padding: Number,
    gap: Number
};
export const helperTextDiv = styled("div", helperTextDivProps)`
    display: ${config['divs']['helperTextDiv']['display']};
    align-items: ${config['divs']['helperTextDiv']['align-items']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['helperTextDiv']['width'])};
    height: ${config['divs']['helperTextDiv']['height']};
    flex:${config['divs']['helperTextDiv']['flex']};
    order:${config['divs']['helperTextDiv']['order']};
    align-self: ${config['divs']['helperTextDiv']['align-self']};
    flex-grow:${config['divs']['helperTextDiv']['flex-grow']};
`;
const buttonsDivProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: String
};
export const buttonsDiv = styled("div", buttonsDivProps)`
    display: ${config['divs']['buttonsDiv']['display']};
    flex-direction: ${config['divs']['buttonsDiv']['flex-direction']};
    align-items: ${config['divs']['buttonsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['buttonsDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['buttonsDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['buttonsDiv']['width'])};
    height: ${config['divs']['buttonsDiv']['height']};
    flex: ${config['divs']['buttonsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['buttonsDiv']['order'])};
    flex-grow: ${config['divs']['buttonsDiv']['flex-grow']};
`;
const formSectionChallengeTypeDivProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String
}
export const formSectionChallengeTypeDiv = styled("div", formSectionChallengeTypeDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['formSectionChallengeTypeDiv']['display'])};
    flex-direction: ${config['divs']['formSectionChallengeTypeDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionChallengeTypeDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['formSectionChallengeTypeDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['formSectionChallengeTypeDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionChallengeTypeDiv']['width'])};
    height: ${config['divs']['formSectionChallengeTypeDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['formSectionChallengeTypeDiv']['box-shadow']};
    border-radius:${config['divs']['formSectionChallengeTypeDiv']['border-radius']};
    flex: ${config['divs']['formSectionChallengeTypeDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['formSectionChallengeTypeDiv']['order'])};
    align-self: ${config['divs']['formSectionChallengeTypeDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionChallengeTypeDiv']['flex-grow']};
`;
const checkBoxGroupDivProps = {
    width: Number,
    padding: String,
    gap: Number,
    height: String,
    alignItems: String
};
export const checkBoxGroupDiv = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxGroupDiv']['display']};
    flex-direction: ${config['divs']['checkBoxGroupDiv']['flex-direction']};
    align-items: ${config['divs']['checkBoxGroupDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['checkBoxGroupDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['checkBoxGroupDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupDiv']['width'])};
    height: ${config['divs']['checkBoxGroupDiv']['height']};
    flex:${config['divs']['checkBoxGroupDiv']['flex']};
    order:${config['divs']['checkBoxGroupDiv']['order']};
    align-self: ${config['divs']['checkBoxGroupDiv']['align-self']};
    flex-grow:${config['divs']['checkBoxGroupDiv']['flex-grow']};

`;
export const checkBoxGroupTitle = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxGroupTitle']['display']};
    align-items: ${config['divs']['checkBoxGroupTitle']['align-items']};
    flex:${config['divs']['checkBoxGroupTitle']['flex']};
    order:${config['divs']['checkBoxGroupTitle']['order']};
    align-self:${config['divs']['checkBoxGroupTitle']['align-self']};
    flex-grow:${config['divs']['checkBoxGroupTitle']['flex-grow']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupTitle']['width'])};
    height: ${config['divs']['checkBoxGroupTitle']['height']};
`;
export const checkBoxDiv = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxDiv']['display']};
    flex-direction: ${config['divs']['checkBoxDiv']['flex-direction']};
    align-items: ${(props) => ((props.alignItems) ? (props.alignItems) : config['divs']['checkBoxDiv']['align-items'])};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['checkBoxDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['checkBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['checkBoxDiv']['height'])};
    flex:${config['divs']['checkBoxDiv']['flex']};
    order:${config['divs']['checkBoxDiv']['order']};
    flex-grow:${config['divs']['checkBoxDiv']['flex-grow']};
`;

const checkBoxGroupInstructionsDivProps = {
    width : Number,
    order: Number
}
export const checkBoxGroupInstructionsDiv = styled("div", checkBoxGroupInstructionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['checkBoxGroupInstructionsDiv']['display'])};
    align-items: ${config['divs']['checkBoxGroupInstructionsDiv']['align-items']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupInstructionsDiv']['width'])};
    height: ${config['divs']['checkBoxGroupInstructionsDiv']['height']};
    flex: ${config['divs']['checkBoxGroupInstructionsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['checkBoxGroupInstructionsDiv']['order'])};
    align-self: ${config['divs']['checkBoxGroupInstructionsDiv']['align-self']};
    flex-grow: ${config['divs']['checkBoxGroupInstructionsDiv']['flex-grow']};
`;
const MCQSectionDivProps = {
    width : String,
    order: Number,
    height: String,
    gap : String,
    padding : String
}
export const MCQSectionDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['MCQSectionDiv']['display'])};
    flex-direction: ${config['divs']['MCQSectionDiv']['flex-direction']};
    align-items: ${config['divs']['MCQSectionDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['MCQSectionDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['MCQSectionDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['MCQSectionDiv']['width'])};
    height: ${config['divs']['MCQSectionDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['MCQSectionDiv']['box-shadow']};
    border-radius:${config['divs']['MCQSectionDiv']['border-radius']};
    flex: ${config['divs']['MCQSectionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['MCQSectionDiv']['order'])};
    align-self: ${config['divs']['MCQSectionDiv']['align-self']};
    flex-grow: ${config['divs']['MCQSectionDiv']['flex-grow']};
`;
export const inputFieldMarkerDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['inputFieldMarkerDiv']['display'])};
    flex-direction: ${config['divs']['inputFieldMarkerDiv']['flex-direction']};
    align-items: ${config['divs']['inputFieldMarkerDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['inputFieldMarkerDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['inputFieldMarkerDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['inputFieldMarkerDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['inputFieldMarkerDiv']['height'])};
    flex: ${config['divs']['inputFieldMarkerDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['inputFieldMarkerDiv']['order'])};
    align-self: ${config['divs']['inputFieldMarkerDiv']['align-self']};
    flex-grow: ${config['divs']['inputFieldMarkerDiv']['flex-grow']};
`;

export const leftOutsideToolbarDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['leftOutsideToolbarDiv']['display'])};
    flex-direction: ${config['divs']['leftOutsideToolbarDiv']['flex-direction']};
    align-items: ${config['divs']['leftOutsideToolbarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['leftOutsideToolbarDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['leftOutsideToolbarDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['leftOutsideToolbarDiv']['width'])};
    height: ${config['divs']['leftOutsideToolbarDiv']['height']};
    flex: ${config['divs']['leftOutsideToolbarDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['leftOutsideToolbarDiv']['order'])};
    align-self: ${config['divs']['leftOutsideToolbarDiv']['align-self']};
    flex-grow: ${config['divs']['leftOutsideToolbarDiv']['flex-grow']};
`;

const mainVerticalInputProps = {
    width : Number,
    order: Number,
    background: String
}

export const mainVerticalInputDiv = styled("div", mainVerticalInputProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['mainVerticalInputDiv']['display'])};
    flex-direction: ${config['divs']['mainVerticalInputDiv']['flex-direction']};
    align-items: ${config['divs']['mainVerticalInputDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['mainVerticalInputDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['mainVerticalInputDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['mainVerticalInputDiv']['width'])};
    height: ${config['divs']['mainVerticalInputDiv']['height']};
    flex: ${config['divs']['mainVerticalInputDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['mainVerticalInputDiv']['order'])};
    align-self: ${config['divs']['mainVerticalInputDiv']['align-self']};
    flex-grow: ${config['divs']['mainVerticalInputDiv']['flex-grow']};
    background: ${(props) => ((props.background) ? (props.background) : '')}; // TODO
`;
const inputEntryBoxDivProps = {
    display : String,
    width: String,
    height : String,
    padding : Number,
    order: Number,
    gap : Number,
    alignItems: String
}
export const inputEntryBoxDiv = styled("div", inputEntryBoxDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['inputEntryBoxDiv']['display'])};
    flex-direction: ${config['divs']['inputEntryBoxDiv']['flex-direction']};
    justify-content: ${config['divs']['inputEntryBoxDiv']['justify-content']};
    align-items: ${(props) => ((props.alignItems) ? (props.alignItems) : config['divs']['inputEntryBoxDiv']['align-items'])};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['inputEntryBoxDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['inputEntryBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['inputEntryBoxDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['inputEntryBoxDiv']['height'])};
    background: ${theme['Primary-white']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius:${config['divs']['inputEntryBoxDiv']['border-radius']};
    flex: ${config['divs']['inputEntryBoxDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['inputEntryBoxDiv']['order'])};
    align-self: ${config['divs']['inputEntryBoxDiv']['align-self']};
    flex-grow: ${config['divs']['inputEntryBoxDiv']['flex-grow']};
`;
export const previewBoxDiv = styled("div", inputEntryBoxDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['previewBoxDiv']['display'])};
    flex-direction: ${config['divs']['previewBoxDiv']['flex-direction']};
    justify-content: ${config['divs']['previewBoxDiv']['justify-content']};
    align-items: ${(props) => ((props.alignItems) ? (props.alignItems) : config['divs']['previewBoxDiv']['align-items'])};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['previewBoxDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['previewBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['previewBoxDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['previewBoxDiv']['height'])};
    overflow-y: ${config['divs']['previewBoxDiv']['overflow-y']};
    background: ${theme['Primary-white']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius:${config['divs']['previewBoxDiv']['border-radius']};
    flex: ${config['divs']['previewBoxDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['previewBoxDiv']['order'])};
    align-self: ${config['divs']['previewBoxDiv']['align-self']};
    flex-grow: ${config['divs']['previewBoxDiv']['flex-grow']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
`;
export const topInsideToolbarDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['topInsideToolbarDiv']['display'])};
    flex-direction: ${config['divs']['topInsideToolbarDiv']['flex-direction']};
    justify-content: ${config['divs']['topInsideToolbarDiv']['justify-content']};
    align-items: ${config['divs']['topInsideToolbarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['topInsideToolbarDiv']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['topInsideToolbarDiv']['width'])};
    height: ${config['divs']['topInsideToolbarDiv']['height']};
    flex: ${config['divs']['topInsideToolbarDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['topInsideToolbarDiv']['order'])};
    align-self: ${config['divs']['topInsideToolbarDiv']['align-self']};
    flex-grow: ${config['divs']['topInsideToolbarDiv']['flex-grow']};
`;
export const topOutsideToolbarDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['topOutsideToolbarDiv']['display'])};
    flex-direction: ${config['divs']['topOutsideToolbarDiv']['flex-direction']};
    justify-content: ${config['divs']['topOutsideToolbarDiv']['justify-content']};
    align-items: ${config['divs']['topOutsideToolbarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['topOutsideToolbarDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)  :config['divs']['topOutsideToolbarDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['topOutsideToolbarDiv']['width'])};
    height: ${config['divs']['topOutsideToolbarDiv']['height']};
    flex: ${config['divs']['topOutsideToolbarDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['topOutsideToolbarDiv']['order'])};
    align-self: ${config['divs']['topOutsideToolbarDiv']['align-self']};
    flex-grow: ${config['divs']['topOutsideToolbarDiv']['flex-grow']};
`;
export const toolbarUndoRedoDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['toolbarUndoRedoDiv']['display'])};
    flex-direction: ${config['divs']['toolbarUndoRedoDiv']['flex-direction']};
    align-items: ${config['divs']['toolbarUndoRedoDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['toolbarUndoRedoDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['toolbarUndoRedoDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['toolbarUndoRedoDiv']['width'])};
    height: ${config['divs']['toolbarUndoRedoDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    box-shadow:${config['divs']['toolbarUndoRedoDiv']['box-shadow']};
    border-radius:${config['divs']['toolbarUndoRedoDiv']['border-radius']};
    flex: ${config['divs']['toolbarUndoRedoDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['toolbarUndoRedoDiv']['order'])};
    flex-grow: ${config['divs']['toolbarUndoRedoDiv']['flex-grow']};
`;
export const toolbarWindowControlDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['toolbarWindowControlDiv']['display'])};
    flex-direction: ${config['divs']['toolbarWindowControlDiv']['flex-direction']};
    align-items: ${config['divs']['toolbarWindowControlDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['toolbarWindowControlDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['toolbarWindowControlDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['toolbarWindowControlDiv']['width'])};
    height: ${config['divs']['toolbarWindowControlDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    box-shadow:${config['divs']['toolbarWindowControlDiv']['box-shadow']};
    border-radius:${config['divs']['toolbarWindowControlDiv']['border-radius']};
    flex: ${config['divs']['toolbarWindowControlDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['toolbarWindowControlDiv']['order'])};
    flex-grow: ${config['divs']['toolbarWindowControlDiv']['flex-grow']};
`;
export const toolbarGrabHandleDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['toolbarGrabHandleDiv']['display'])};
    flex-direction: ${config['divs']['toolbarGrabHandleDiv']['flex-direction']};
    justify-content: ${config['divs']['toolbarGrabHandleDiv']['justify-content']};
    align-items: ${config['divs']['toolbarGrabHandleDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['toolbarGrabHandleDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['toolbarGrabHandleDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['toolbarGrabHandleDiv']['width'])};
    height: ${config['divs']['toolbarGrabHandleDiv']['height']};
    flex: ${config['divs']['toolbarGrabHandleDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['toolbarGrabHandleDiv']['order'])};
    flex-grow: ${config['divs']['toolbarGrabHandleDiv']['flex-grow']};
`;
export const buttonIconDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['buttonIconDiv']['display'])};
    flex-direction: ${config['divs']['buttonIconDiv']['flex-direction']};
    align-items: ${config['divs']['buttonIconDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['buttonIconDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['buttonIconDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['buttonIconDiv']['width'])};
    height: ${config['divs']['buttonIconDiv']['height']};
    border-radius: ${config['divs']['buttonIconDiv']['border-radius']};
    flex: ${config['divs']['buttonIconDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['buttonIconDiv']['order'])};
    flex-grow: ${config['divs']['buttonIconDiv']['flex-grow']};
`;
export const formattingToolbarDiv = styled("div", MCQSectionDivProps)`
    box-sizing: ${config['divs']['formattingToolbarDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['formattingToolbarDiv']['display'])};
    flex-direction: ${config['divs']['formattingToolbarDiv']['flex-direction']};
    align-items: ${config['divs']['formattingToolbarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['formattingToolbarDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['formattingToolbarDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formattingToolbarDiv']['width'])};
    height: ${config['divs']['formattingToolbarDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius:${config['divs']['formattingToolbarDiv']['border-radius']};
    flex: ${config['divs']['formattingToolbarDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['formattingToolbarDiv']['order'])};
    align-self: ${config['divs']['formattingToolbarDiv']['align-self']};
    flex-grow: ${config['divs']['formattingToolbarDiv']['flex-grow']};
`;

export const formattingToolbarMathDiv = styled("div", MCQSectionDivProps)`
    box-sizing: ${config['divs']['formattingToolbarMathDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['formattingToolbarMathDiv']['display'])};
    flex-direction: ${config['divs']['formattingToolbarMathDiv']['flex-direction']};
    align-items: ${config['divs']['formattingToolbarMathDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['formattingToolbarMathDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['formattingToolbarMathDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formattingToolbarMathDiv']['width'])};
    height: ${config['divs']['formattingToolbarMathDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius:${config['divs']['formattingToolbarMathDiv']['border-radius']};
    flex: ${config['divs']['formattingToolbarMathDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['formattingToolbarMathDiv']['order'])};
    align-self: ${config['divs']['formattingToolbarMathDiv']['align-self']};
    flex-grow: ${config['divs']['formattingToolbarMathDiv']['flex-grow']};
`;

export const outerMathDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['outerMathDiv']['display'])};
    flex-direction: ${config['divs']['outerMathDiv']['flex-direction']};
    align-items: ${config['divs']['outerMathDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['outerMathDiv']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['outerMathDiv']['width'])};
    height: ${config['divs']['outerMathDiv']['height']};
`;

export const toolbarWhiteBarDiv = styled("div", MCQSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['toolbarWhiteBarDiv']['display'])};
    flex-direction: ${config['divs']['toolbarWhiteBarDiv']['flex-direction']};
    justify-content: ${config['divs']['toolbarWhiteBarDiv']['justify-content']};
    align-items: ${config['divs']['toolbarWhiteBarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['toolbarWhiteBarDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['toolbarWhiteBarDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['toolbarWhiteBarDiv']['width'])};
    height: ${config['divs']['toolbarWhiteBarDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    box-shadow: ${config['divs']['toolbarWhiteBarDiv']['box-shadow']};
    border-radius:${config['divs']['toolbarWhiteBarDiv']['border-radius']};
    flex: ${config['divs']['toolbarWhiteBarDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['toolbarWhiteBarDiv']['order'])};
    flex-grow: ${config['divs']['toolbarWhiteBarDiv']['flex-grow']};
`;
const MCQOptionsDivProps = {
    width : Number,
    order: Number,
    borderColor: String,
    padding : String
}
export const MCQOptionsDiv = styled("div", MCQOptionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['MCQOptionsDiv']['display'])};
    flex-direction: ${config['divs']['MCQOptionsDiv']['flex-direction']};
    align-items: ${config['divs']['MCQOptionsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['MCQOptionsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['MCQOptionsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['MCQOptionsDiv']['width'])};
    height: ${config['divs']['MCQOptionsDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['MCQOptionsDiv']['box-shadow']};
    border-radius:${config['divs']['MCQOptionsDiv']['border-radius']};
    flex: ${config['divs']['MCQOptionsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['MCQOptionsDiv']['order'])};
    align-self: ${config['divs']['MCQOptionsDiv']['align-self']};
    flex-grow: ${config['divs']['MCQOptionsDiv']['flex-grow']};
`;
export const adminMCQOptionsDiv = styled("div", MCQOptionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['adminMCQOptionsDiv']['display'])};
    flex-direction: ${config['divs']['adminMCQOptionsDiv']['flex-direction']};
    justify-content: ${config['divs']['adminMCQOptionsDiv']['justify-content']};
    align-items: ${config['divs']['adminMCQOptionsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['adminMCQOptionsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['adminMCQOptionsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['adminMCQOptionsDiv']['width'])};
    height: ${config['divs']['adminMCQOptionsDiv']['height']};
    flex: ${config['divs']['adminMCQOptionsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['adminMCQOptionsDiv']['order'])};
    align-self: ${config['divs']['adminMCQOptionsDiv']['align-self']};
    flex-grow: ${config['divs']['adminMCQOptionsDiv']['flex-grow']};
`;
export const MCQOptionBarDiv = styled("div", MCQOptionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['frames']['MCQOptionBarDiv']['display'])};
    flex-direction: ${config['divs']['frames']['MCQOptionBarDiv']['flex-direction']};
    justify-content: ${config['divs']['frames']['MCQOptionBarDiv']['justify-content']};
    align-items: ${config['divs']['frames']['MCQOptionBarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['frames']['MCQOptionBarDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['frames']['MCQOptionBarDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['MCQOptionBarDiv']['width'])};
    height: ${config['divs']['frames']['MCQOptionBarDiv']['height']};
    flex: ${config['divs']['frames']['MCQOptionBarDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['frames']['MCQOptionBarDiv']['order'])};
    align-self: ${config['divs']['frames']['MCQOptionBarDiv']['align-self']};
    flex-grow: ${config['divs']['frames']['MCQOptionBarDiv']['flex-grow']};
`;
export const MCQOptionDiv = styled("div", MCQOptionsDivProps)`
    box-sizing: ${config['divs']['MCQOptionDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['MCQOptionDiv']['display'])};
    flex-direction: ${config['divs']['MCQOptionDiv']['flex-direction']};
    align-items: ${config['divs']['MCQOptionDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['MCQOptionDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['MCQOptionDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['MCQOptionDiv']['width'])};
    height: ${config['divs']['MCQOptionDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border: 1px solid ;
    border-color : ${(props) => ((props.borderColor) ? (props.borderColor) : theme['status-success'])}
    border-radius:${config['divs']['MCQOptionDiv']['border-radius']};
    flex: ${config['divs']['MCQOptionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['MCQOptionDiv']['order'])};
    flex-grow: ${config['divs']['MCQOptionDiv']['flex-grow']};
`;
export const keyboardShortCutDiv = styled("div", MCQOptionsDivProps)`
    box-sizing: ${config['divs']['keyboardShortCutDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['keyboardShortCutDiv']['display'])};
    flex-direction: ${config['divs']['keyboardShortCutDiv']['flex-direction']};
    align-items: ${config['divs']['keyboardShortCutDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['keyboardShortCutDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['keyboardShortCutDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['keyboardShortCutDiv']['width'])};
    height: ${config['divs']['keyboardShortCutDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border: 1px solid ${theme['Secondary-Gray0700']};
    box-shadow:${config['divs']['keyboardShortCutDiv']['box-shadow']};
    border-radius:${config['divs']['keyboardShortCutDiv']['border-radius']};
    flex: ${config['divs']['keyboardShortCutDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['keyboardShortCutDiv']['order'])};
    flex-grow: ${config['divs']['keyboardShortCutDiv']['flex-grow']};
`;
const MCQOptionTextFieldDivProps = {
    width: String,
    order: String
}
export const MCQOptionTextFieldDiv = styled("div", MCQOptionTextFieldDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['frames']['MCQOptionTextFieldDiv']['display'])};
    flex-direction: ${config['divs']['frames']['MCQOptionTextFieldDiv']['flex-direction']};
    align-items: ${config['divs']['frames']['MCQOptionTextFieldDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['frames']['MCQOptionTextFieldDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['frames']['MCQOptionTextFieldDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['MCQOptionTextFieldDiv']['width'])};
    height: ${config['divs']['frames']['MCQOptionTextFieldDiv']['height']};
    flex: ${config['divs']['frames']['MCQOptionTextFieldDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['frames']['MCQOptionTextFieldDiv']['order'])};
    flex-grow: ${config['divs']['frames']['MCQOptionTextFieldDiv']['flex-grow']};
`;
export const adminActionsDiv = styled("div", MCQOptionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['adminActionsDiv']['display'])};
    flex-direction: ${config['divs']['adminActionsDiv']['flex-direction']};
    align-items: ${config['divs']['adminActionsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['adminActionsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['adminActionsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['adminActionsDiv']['width'])};
    height: ${config['divs']['adminActionsDiv']['height']};
    flex: ${config['divs']['adminActionsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['adminActionsDiv']['order'])};
    flex-grow: ${config['divs']['adminActionsDiv']['flex-grow']};
`;
const formSectionChallengeHintDivProps = {
    width : String,
    order: Number,
    alignItems : String
}
export const formSectionChallengeHintDiv = styled("div", formSectionChallengeHintDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['formSectionChallengeHintDiv']['display'])};
    flex-direction: ${config['divs']['formSectionChallengeHintDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionChallengeHintDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['formSectionChallengeHintDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['formSectionChallengeHintDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionChallengeHintDiv']['width'])};
    height: ${config['divs']['formSectionChallengeHintDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['formSectionChallengeHintDiv']['box-shadow']};
    border-radius:${config['divs']['formSectionChallengeHintDiv']['border-radius']};
    flex: ${config['divs']['formSectionChallengeHintDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['formSectionChallengeHintDiv']['order'])};
    align-self: ${config['divs']['formSectionChallengeHintDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionChallengeHintDiv']['flex-grow']};
`;
export const HintDiv = styled("div", formSectionChallengeHintDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['HintDiv']['display'])};
    flex-direction: ${config['divs']['HintDiv']['flex-direction']};
    align-items: ${(props) => ((props.alignItems) ? (props.alignItems) :config['divs']['HintDiv']['align-items'])};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['HintDiv']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['HintDiv']['width'])};
    height: ${config['divs']['HintDiv']['height']};
    flex: ${config['divs']['HintDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['HintDiv']['order'])};
    align-self: ${config['divs']['HintDiv']['align-self']};
    flex-grow: ${config['divs']['HintDiv']['flex-grow']};
`;
export const contentBlockDiv = styled("div", formSectionChallengeHintDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['contentBlockDiv']['display'])};
    flex-direction: ${config['divs']['contentBlockDiv']['flex-direction']};
    align-items: ${config['divs']['contentBlockDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['contentBlockDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['contentBlockDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['contentBlockDiv']['width'])};
    height: ${config['divs']['contentBlockDiv']['height']};
    flex: ${config['divs']['contentBlockDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['contentBlockDiv']['order'])};
    flex-grow: ${config['divs']['contentBlockDiv']['flex-grow']};
`;
const contentTextDivProps = {
    width : String,
    order: Number,
    alignItems : String,
    borderColor : String
}
export const contentTextDiv = styled("div", contentTextDivProps)`
    box-sizing: ${config['divs']['contentTextDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['contentTextDiv']['display'])};
    flex-direction: ${config['divs']['contentTextDiv']['flex-direction']};
    align-items: ${config['divs']['contentTextDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['contentTextDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['contentTextDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['contentTextDiv']['width'])};
    height: ${config['divs']['contentTextDiv']['height']};
    background: ${theme['Primary-white']};
    border:1px solid ${(props) => ((props.borderColor) ? (props.borderColor) : theme['Accent-Magenta0300'])};
    border-radius:${config['divs']['contentTextDiv']['border-radius']};
`;
const contentBadgeDivProps = {
    width : String,
    order: Number,
    alignItems : String,
    background_color : String
}
export const contentBadgeDiv = styled("div", contentBadgeDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['contentBadgeDiv']['display'])};
    flex-direction: ${config['divs']['contentBadgeDiv']['flex-direction']};
    align-items: ${config['divs']['contentBadgeDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['contentBadgeDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['contentBadgeDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['contentBadgeDiv']['width'])};
    height: ${config['divs']['contentBadgeDiv']['height']};
    background: ${(props) => ((props.background_color) ? (props.background_color) :theme['Accent-Magenta0300'])};
    border-radius:${config['divs']['contentBadgeDiv']['border-radius']};
`;

const mathKeyboardProps = {
    width : String,
    height : String,
    gap : String,
    padding : String,
    display: String
}

export const mathKeyboard = styled("div", mathKeyboardProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['mathKeyboardDiv']['display'])};
    flex-direction: ${config['divs']['mathKeyboardDiv']['flex-direction']};
    flex-wrap: ${config['divs']['mathKeyboardDiv']['flex-wrap']};
    align-items: ${config['divs']['mathKeyboardDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['mathKeyboardDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap):config['divs']['mathKeyboardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width): config['divs']['mathKeyboardDiv']['width'])};
    height: ${config['divs']['mathKeyboardDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border:${config['divs']['mathKeyboardDiv']['border'] + theme['Secondary-Gray0500']};
`;

const keyboardKeyProps = {
    width : String,
    height : String,
    gap : String,
    padding : String,
    display: String
}

export const keyboardKey = styled("div", keyboardKeyProps)`
    box-sizing: ${config['divs']['keyboardKeyBtn']['box-sizing']};
    display: ${config['divs']['keyboardKeyBtn']['display']};
    flex-direction: ${config['divs']['keyboardKeyBtn']['flex-direction']};
    justify-content: ${config['divs']['keyboardKeyBtn']['justify-content']};
    align-items: ${config['divs']['keyboardKeyBtn']['align-items']};
    padding: ${config['divs']['keyboardKeyBtn']['padding']};
    gap: ${config['divs']['keyboardKeyBtn']['gap']};
    width: ${config['divs']['keyboardKeyBtn']['width']};
    height: ${config['divs']['keyboardKeyBtn']['height']};
    background: ${theme['Secondary-Gray0100']};
    border: ${config['divs']['keyboardKeyBtn']['border'] + theme['Secondary-Gray0500']};
    box-shadow: ${config['divs']['keyboardKeyBtn']['box-shadow']};
    border-radius: ${config['divs']['keyboardKeyBtn']['border-radius']};
`;

const mathPreviewAreaProps = {
    width : String,
    height : String,
    gap : String,
    padding : String,
    display: String
}

export const mathPreviewArea = styled("div", mathPreviewAreaProps)`
    box-sizing: ${config['divs']['mathPreviewAreaDiv']['box-sizing']};
    display: ${config['divs']['mathPreviewAreaDiv']['display']};
    flex-direction: ${config['divs']['mathPreviewAreaDiv']['flex-direction']};
    align-items: ${config['divs']['mathPreviewAreaDiv']['align-items']};
    padding: ${config['divs']['mathPreviewAreaDiv']['padding']};
    gap: ${config['divs']['mathPreviewAreaDiv']['gap']};
    width: ${config['divs']['mathPreviewAreaDiv']['width']};
    height: ${config['divs']['mathPreviewAreaDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: ${config['divs']['mathPreviewAreaDiv']['border'] + theme['Secondary-Gray0500']};
    border-radius: ${config['divs']['mathPreviewAreaDiv']['border-radius']};
`;

const mathKeyboardOprBtnProps = {
    width: String,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const mathKeyboardOprBtnDiv = styled("div", mathKeyboardOprBtnProps)`
    display: ${config['divs']['mathKeyboardOprBtnDiv']['display']};
    flex-direction: ${config['divs']['mathKeyboardOprBtnDiv']['flex-direction']};
    flex-wrap: ${config['divs']['mathKeyboardOprBtnDiv']['flex-wrap']};
    align-items: ${config['divs']['mathKeyboardOprBtnDiv']['align-items']};
    justify-content: ${config['divs']['mathKeyboardOprBtnDiv']['justify-content']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['mathKeyboardOprBtnDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['mathKeyboardOprBtnDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['mathKeyboardOprBtnDiv']['width'])};
    height: ${config['divs']['mathKeyboardOprBtnDiv']['height']};
    flex: ${config['divs']['mathKeyboardOprBtnDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['mathKeyboardOprBtnDiv']['order'])};
    align-self: ${config['divs']['mathKeyboardOprBtnDiv']['align-self']};
    flex-grow: ${config['divs']['mathKeyboardOprBtnDiv']['flex-grow']};
`;

const inputFieldViewProps = {
    width: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const inputFieldViewDiv = styled("div", inputFieldViewProps)`
    display: ${config['divs']['inputFieldViewDiv']['display']};
    flex-direction: ${config['divs']['inputFieldViewDiv']['flex-direction']};
    align-items: ${config['divs']['inputFieldViewDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['inputFieldViewDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['inputFieldViewDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['inputFieldViewDiv']['width'])};
    height: ${config['divs']['inputFieldViewDiv']['height']};
    overflow: ${config['divs']['inputFieldViewDiv']['overflow']};
    background: '#FFFFFF';
    border: ${config['divs']['inputFieldViewDiv']['border'] + theme['Secondary-Gray0500']};
    border-radius: ${config['divs']['inputFieldViewDiv']['border-radius']};
    flex: ${config['divs']['inputFieldViewDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['inputFieldViewDiv']['order'])};
    align-self: ${config['divs']['inputFieldViewDiv']['align-self']};
    flex-grow: ${config['divs']['inputFieldViewDiv']['flex-grow']};
`;
const selectedItemsOuterDivProps = {
    width: Number
}
export const selectedItemsOuterDiv = styled("div", selectedItemsOuterDivProps)`
    display: ${config['divs']['selectedItemsOuterDiv']['display']};
    flex-direction: ${config['divs']['selectedItemsOuterDiv']['flex-direction']};
    align-items: ${config['divs']['selectedItemsOuterDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['selectedItemsOuterDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['selectedItemsOuterDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['selectedItemsOuterDiv']['width'])};
    height: ${config['divs']['selectedItemsOuterDiv']['height']};
`;
const frame_35_Props = {
    width: Number
}
export const frame_35 = styled("div", frame_35_Props)`
    display: ${config['divs']['frames']['frame_35']['display']};
    flex-direction: ${config['divs']['frames']['frame_35']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_35']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['frame_35']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['frame_35']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_35']['width'])};
    height: ${config['divs']['frames']['frame_35']['height']};
    flex: ${config['divs']['frames']['frame_35']['flex']};
    order: ${config['divs']['frames']['frame_35']['order']};
    flex-grow: ${config['divs']['frames']['frame_35']['flex-grow']};
`;
const frame_18_Props = {
    width: Number,
    borderColor: String
}
export const frame_18 = styled("div", frame_18_Props)`
    box-sizing: ${config['divs']['frames']['frame_18']['box-sizing']};
    display: ${config['divs']['frames']['frame_18']['display']};
    flex-direction: ${config['divs']['frames']['frame_18']['flex-direction']};
    justify-content: ${config['divs']['frames']['frame_18']['justify-content']};
    align-items: ${config['divs']['frames']['frame_18']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['frame_18']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['frame_18']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_18']['width'])};
    height: ${config['divs']['frames']['frame_18']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: ${config['divs']['frames']['frame_18']['border']}${(props) => ((props.borderColor) ? (props.borderColor) : theme['Secondary-Gray0000'])};
    border-radius: ${config['divs']['frames']['frame_18']['border-radius']};
    flex: ${config['divs']['frames']['frame_18']['flex']};
    order: ${config['divs']['frames']['frame_18']['order']};
    align-self: ${config['divs']['frames']['frame_18']['align-self']};
    flex-grow: ${config['divs']['frames']['frame_18']['flex-grow']};
`;
const frame_36_Props = {
    width: Number
}
export const frame_36 = styled("div", frame_36_Props)`
    display: ${config['divs']['frames']['frame_36']['display']};
    flex-direction: ${config['divs']['frames']['frame_36']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_36']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['frames']['frame_36']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['frames']['frame_36']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_36']['width'])};
    height: ${config['divs']['frames']['frame_36']['height']};
    max-width: ${config['divs']['frames']['frame_36']['max-width']};
    flex-wrap: ${config['divs']['frames']['frame_36']['flex-wrap']};
    flex: ${config['divs']['frames']['frame_36']['flex']};
    order: ${config['divs']['frames']['frame_36']['order']};
    flex-grow: ${config['divs']['frames']['frame_36']['flex-grow']};
`;
const multiSelectOptionOuterDivProps = {
    width: Number
}
export const multiSelectOptionOuterDiv = styled("div", multiSelectOptionOuterDivProps)`
    box-sizing: ${config['divs']['multiSelectOptionOuterDiv']['box-sizing']};
    display: ${config['divs']['multiSelectOptionOuterDiv']['display']};
    flex-direction: ${config['divs']['multiSelectOptionOuterDiv']['flex-direction']};
    justify-content: ${config['divs']['multiSelectOptionOuterDiv']['justify-content']};
    align-items: ${config['divs']['multiSelectOptionOuterDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['multiSelectOptionOuterDiv']['padding'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['multiSelectOptionOuterDiv']['width'])};
    height: ${config['divs']['multiSelectOptionOuterDiv']['height']};
    border: ${config['divs']['multiSelectOptionOuterDiv']['border']}${theme['PrimaryCyan0400-Default']};
    border-radius: ${config['divs']['multiSelectOptionOuterDiv']['border-radius']};
    flex: ${config['divs']['multiSelectOptionOuterDiv']['flex']};
    order: ${config['divs']['multiSelectOptionOuterDiv']['order']};
    align-self: ${config['divs']['multiSelectOptionOuterDiv']['align-self']};
    flex-grow: ${config['divs']['multiSelectOptionOuterDiv']['flex-grow']};
`;
const multiSelectDropdownOptionItemsDivProps = {
    width: Number
}
export const multiSelectDropdownOptionItemsDiv = styled("div", multiSelectDropdownOptionItemsDivProps)`
    box-sizing: ${config['divs']['multiSelectDropdownOptionItemsDiv']['box-sizing']};
    display: ${config['divs']['multiSelectDropdownOptionItemsDiv']['display']};
    flex-direction: ${config['divs']['multiSelectDropdownOptionItemsDiv']['flex-direction']};
    justify-content: ${config['divs']['multiSelectDropdownOptionItemsDiv']['justify-content']};
    align-items: ${config['divs']['multiSelectDropdownOptionItemsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['multiSelectDropdownOptionItemsDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['multiSelectDropdownOptionItemsDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['multiSelectDropdownOptionItemsDiv']['width'])};
    height: ${config['divs']['multiSelectDropdownOptionItemsDiv']['height']};
    background: ${theme['PrimaryCyan0400-Default']};
    flex: ${config['divs']['multiSelectDropdownOptionItemsDiv']['flex']};
    order: ${config['divs']['multiSelectDropdownOptionItemsDiv']['order']};
    align-self: ${config['divs']['multiSelectDropdownOptionItemsDiv']['align-self']};
    flex-grow: ${config['divs']['multiSelectDropdownOptionItemsDiv']['flex-grow']};
    &:hover{
        background: ${theme['Secondary-Gray0100']}!important;
        color:${theme['Secondary-Gray0800']}!important;
    }
    &:focus{
        background-color: ${theme['Secondary-Gray0100']};
        color: ${theme['Primary-Cyan0400']};
    }
`;
const basicInfoFormProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const basicInfoFormDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['basicInfoFormDiv']['display'])};
    flex-direction: ${config['divs']['basicInfoFormDiv']['flex-direction']};
    align-items: ${config['divs']['basicInfoFormDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['basicInfoFormDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['basicInfoFormDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['basicInfoFormDiv']['width'])};
    height: ${config['divs']['basicInfoFormDiv']['height']};
    flex: ${config['divs']['basicInfoFormDiv']['flex']};
    order: ${config['divs']['basicInfoFormDiv']['order']};
    align-self: ${config['divs']['basicInfoFormDiv']['align-self']};
    flex-grow: ${config['divs']['basicInfoFormDiv']['flex-grow']};
`;
export const challengeTypeformDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeTypeformDiv']['display'])};
    flex-direction: ${config['divs']['challengeTypeformDiv']['flex-direction']};
    align-items: ${config['divs']['challengeTypeformDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['challengeTypeformDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['challengeTypeformDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengeTypeformDiv']['width'])};
    height: ${config['divs']['challengeTypeformDiv']['height']};
    flex: ${config['divs']['challengeTypeformDiv']['flex']};
    order: ${config['divs']['challengeTypeformDiv']['order']};
    align-self: ${config['divs']['challengeTypeformDiv']['align-self']};
    flex-grow: ${config['divs']['challengeTypeformDiv']['flex-grow']};
`;
export const MCQformDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['MCQformDiv']['display'])};
    flex-direction: ${config['divs']['MCQformDiv']['flex-direction']};
    align-items: ${config['divs']['MCQformDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['MCQformDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['MCQformDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['MCQformDiv']['width'])};
    height: ${config['divs']['MCQformDiv']['height']};
    flex: ${config['divs']['MCQformDiv']['flex']};
    order: ${config['divs']['MCQformDiv']['order']};
    align-self: ${config['divs']['MCQformDiv']['align-self']};
    flex-grow: ${config['divs']['MCQformDiv']['flex-grow']};
`;
export const MCQOptionformDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['MCQOptionformDiv']['display'])};
    flex-direction: ${config['divs']['MCQOptionformDiv']['flex-direction']};
    align-items: ${config['divs']['MCQOptionformDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['MCQOptionformDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['MCQOptionformDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['MCQOptionformDiv']['width'])};
    height: ${config['divs']['MCQOptionformDiv']['height']};
    flex: ${config['divs']['MCQOptionformDiv']['flex']};
    order: ${config['divs']['MCQOptionformDiv']['order']};
    align-self: ${config['divs']['MCQOptionformDiv']['align-self']};
    flex-grow: ${config['divs']['MCQOptionformDiv']['flex-grow']};
`;
export const questionStatementDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['questionStatementDiv']['display'])};
    flex-direction: ${config['divs']['questionStatementDiv']['flex-direction']};
    align-items: ${config['divs']['questionStatementDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['questionStatementDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['questionStatementDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['questionStatementDiv']['width'])};
    height: ${config['divs']['questionStatementDiv']['height']};
    flex: ${config['divs']['questionStatementDiv']['flex']};
    order: ${config['divs']['questionStatementDiv']['order']};
    align-self: ${config['divs']['questionStatementDiv']['align-self']};
    flex-grow: ${config['divs']['questionStatementDiv']['flex-grow']};
`;
export const instructionsStatementDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['instructionsStatementDiv']['display'])};
    flex-direction: ${config['divs']['instructionsStatementDiv']['flex-direction']};
    align-items: ${config['divs']['instructionsStatementDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['instructionsStatementDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['instructionsStatementDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['instructionsStatementDiv']['width'])};
    height: ${config['divs']['instructionsStatementDiv']['height']};
    flex: ${config['divs']['instructionsStatementDiv']['flex']};
    order: ${config['divs']['instructionsStatementDiv']['order']};
    align-self: ${config['divs']['instructionsStatementDiv']['align-self']};
    flex-grow: ${config['divs']['instructionsStatementDiv']['flex-grow']};
`;
const FRQFormProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const FRQformDiv = styled("div", FRQFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['FRQformDiv']['display'])};
    flex-direction: ${config['divs']['FRQformDiv']['flex-direction']};
    align-items: ${config['divs']['FRQformDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['FRQformDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['FRQformDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['FRQformDiv']['width'])};
    height: ${config['divs']['FRQformDiv']['height']};
    flex: ${config['divs']['FRQformDiv']['flex']};
    order: ${config['divs']['FRQformDiv']['order']};
    align-self: ${config['divs']['FRQformDiv']['align-self']};
    flex-grow: ${config['divs']['FRQformDiv']['flex-grow']};
`;
export const FRQAdvanceformDiv = styled("div", FRQFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['FRQAdvanceformDiv']['display'])};
    flex-direction: ${config['divs']['FRQAdvanceformDiv']['flex-direction']};
    align-items: ${config['divs']['FRQAdvanceformDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['FRQAdvanceformDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['FRQAdvanceformDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['FRQAdvanceformDiv']['width'])};
    height: ${config['divs']['FRQAdvanceformDiv']['height']};
    flex: ${config['divs']['FRQAdvanceformDiv']['flex']};
    order: ${config['divs']['FRQAdvanceformDiv']['order']};
    align-self: ${config['divs']['FRQAdvanceformDiv']['align-self']};
    flex-grow: ${config['divs']['FRQAdvanceformDiv']['flex-grow']};
`;
const hintFormProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const hintformDiv = styled("div", hintFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['hintformDiv']['display'])};
    flex-direction: ${config['divs']['hintformDiv']['flex-direction']};
    align-items: ${config['divs']['hintformDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['hintformDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['hintformDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['hintformDiv']['width'])};
    height: ${config['divs']['hintformDiv']['height']};
    flex: ${config['divs']['hintformDiv']['flex']};
    order: ${config['divs']['hintformDiv']['order']};
    align-self: ${config['divs']['hintformDiv']['align-self']};
    flex-grow: ${config['divs']['hintformDiv']['flex-grow']};
`;
const statusSectionProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number,
    gap: Number,
    order: Number, 
    background: String,
}
export const statusSection = styled("div", statusSectionProps)`
    box-sizing: ${config['divs']['statusSection']['box-sizing']};
    display: ${config['divs']['statusSection']['display']};
    flex-direction: ${config['divs']['statusSection']['flex-direction']};
    align-items: ${config['divs']['statusSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['statusSection']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['statusSection']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['statusSection']['width'])};
    height: ${config['divs']['statusSection']['height']};
    flex: ${config['divs']['statusSection']['flex']};
    order: ${config['divs']['statusSection']['order']};
    align-self: ${config['divs']['statusSection']['align-self']};
    flex-grow: ${config['divs']['statusSection']['flex-grow']};
    background: ${theme['Primary-white']};
    border-radius: ${config['divs']['statusSection']['border-radius']};
    border: ${config['divs']['statusSection']['border']} ${theme['Accent-Magenta0300']};
`;
const frame_54_props = {
    width: Number, 
    padding: Number,
    gap: Number,
    order: Number
}
export const frame_54 = styled("div", frame_54_props)`
    display: ${config['divs']['frames']['frame_54']['display']};
    flex-direction: ${config['divs']['frames']['frame_54']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_54']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['frame_54']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['frame_54']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['frame_54']['width'])};
    height: ${config['divs']['frames']['frame_54']['height']};
    flex: ${config['divs']['frames']['frame_54']['flex']};
    order: ${config['divs']['frames']['frame_54']['order']};
    flex-grow: ${config['divs']['frames']['frame_54']['flex-grow']};
`;
const challengeCardDivProps = {
    width : String,
    order: Number,
    alignItems : String
}
export const challengeCardDiv = styled("div", challengeCardDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeCardDiv']['display'])};
    flex-direction: ${config['divs']['challengeCardDiv']['flex-direction']};
    align-items: ${config['divs']['challengeCardDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['challengeCardDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)  :config['divs']['challengeCardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['challengeCardDiv']['width'])};
    height: ${config['divs']['challengeCardDiv']['height']};
    flex: ${config['divs']['challengeCardDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengeCardDiv']['order'])};
    align-self: ${config['divs']['challengeCardDiv']['align-self']};
    flex-grow: ${config['divs']['challengeCardDiv']['flex-grow']};
`;
export const challengeDetailsDiv = styled("div", challengeCardDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeDetailsDiv']['display'])};
    flex-direction: ${config['divs']['challengeDetailsDiv']['flex-direction']};
    align-items: ${config['divs']['challengeDetailsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['challengeDetailsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)  :config['divs']['challengeDetailsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['challengeDetailsDiv']['width'])};
    height: ${config['divs']['challengeDetailsDiv']['height']};
    flex: ${config['divs']['challengeDetailsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengeDetailsDiv']['order'])};
    align-self: ${config['divs']['challengeDetailsDiv']['align-self']};
    flex-grow: ${config['divs']['challengeDetailsDiv']['flex-grow']};
`;
const challengeQuestionStatementDivProps = {
    width : String,
    order: Number,
    alignItems : String
}
export const challengeQuestionStatementDiv = styled("div", challengeQuestionStatementDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeQuestionStatementDiv']['display'])};
    flex-direction: ${config['divs']['challengeQuestionStatementDiv']['flex-direction']};
    justify-content: ${config['divs']['challengeQuestionStatementDiv']['justify-content']};
    align-items: ${config['divs']['challengeQuestionStatementDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['challengeQuestionStatementDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)  :config['divs']['challengeQuestionStatementDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['challengeQuestionStatementDiv']['width'])};
    height: ${config['divs']['challengeQuestionStatementDiv']['height']};
    flex: ${config['divs']['challengeQuestionStatementDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengeQuestionStatementDiv']['order'])};
    align-self: ${config['divs']['challengeQuestionStatementDiv']['align-self']};
    flex-grow: ${config['divs']['challengeQuestionStatementDiv']['flex-grow']};
`;

const mtfOptionOuterDivProps = {
    width : String,
    order: Number,
    alignItems : String
}
export const mtfOptionOuterDiv = styled("div", mtfOptionOuterDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['mtfOptionOuterDiv']['display'])};
    flex-direction: ${config['divs']['mtfOptionOuterDiv']['flex-direction']};
    align-items: ${config['divs']['mtfOptionOuterDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['mtfOptionOuterDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)  :config['divs']['mtfOptionOuterDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['mtfOptionOuterDiv']['width'])};
    height: ${config['divs']['mtfOptionOuterDiv']['height']};
    flex: ${config['divs']['mtfOptionOuterDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['mtfOptionOuterDiv']['order'])};
    flex-grow: ${config['divs']['mtfOptionOuterDiv']['flex-grow']};
    background: ${theme['Primary-white']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius:${config['divs']['mtfOptionOuterDiv']['border-radius']};
`;
const cellColumnDivProps = {
    width : String,
    order: Number,
    alignItems : String
}
export const cellColumnDiv = styled("div", cellColumnDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['cellColumnDiv']['display'])};
    flex-direction: ${config['divs']['cellColumnDiv']['flex-direction']};
    align-items: ${config['divs']['cellColumnDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['cellColumnDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)  :config['divs']['cellColumnDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['cellColumnDiv']['width'])};
    height: ${config['divs']['cellColumnDiv']['height']};
    flex: ${config['divs']['cellColumnDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['cellColumnDiv']['order'])};
    flex-grow: ${config['divs']['cellColumnDiv']['flex-grow']};
`;