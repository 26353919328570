import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Component from './ImageComponent.vue'
import { Plugin } from 'prosemirror-state';

export default Node.create({
  name: 'imageComponent',

  // Extensions group
  group: 'block',

  // Don't allow selection
  selectable: false,

  addOptions() { // passed when configuring the extension in editor
    return {
      vueInstance: null, // Add vueInstance as an option
    };
  },

  // Add "url" attribute to our element
  // We are going to use this attribute as a
  // container for our image url
  addAttributes() {
    return {
      uniqkey: {
        default: '',
      },
      chal_id: {
        default: '',
      }
    }
  },

  //https://tiptap.dev/guide/node-views
  // Parse all <c-image> tags
  // restoring the content, Attributes are automagically restored, if you registered them through addAttributes
  parseHTML() {
    return [
      {
        tag: 'c-image',
      }
    ]
  },

  // Render our <c-image> tag and merge it with our "uniqkey" attribute
  renderHTML({ HTMLAttributes }) {
    return ['c-image', mergeAttributes(HTMLAttributes)] // this 'c-image' tag will be displayed
  },

  // Use our Vue component as node view
  addNodeView() {
    return VueNodeViewRenderer(Component)
  },

  // Insert image block at the specific cursor position
  addCommands() {
    return {
      addImage: (attrs) => ({state, dispatch}) => {
        const { selection } = state
        const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
        const node = this.type.create(attrs)
        const transaction = state.tr.insert(position, node);
        dispatch(transaction);
        // update the url attributes to the given attrs.chal_id value
        //this.editor.commands.updateAttributes('c-image', {uniqkey: attrs.uniqkey});
      }
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event) => {
            // console.log("event and view is", view, event);
            
            const clipboardData = event.clipboardData || window.clipboardData;
            const items = clipboardData.items;
            // handle paste of text
            let handled = false;
  
            for (const item of items) {
              // console.log("-items are----------", item);
              
              if (/image/i.test(item.type)) {
                // need to transfer this data to store
                // Access the Vue store from the vuejs instance we passed in options
                let vueInstance = this.options.vueInstance
                const fileObj = item.getAsFile();
                let uniq_key = "$" + fileObj.name + "_" + Date.now() + "$"
                // console.log("===uniq_key++++", uniq_key, vueInstance.$store, URL.createObjectURL(fileObj));
                
                if(uniq_key in vueInstance.$store.state.files_manifest){
                    console.log('error, key already exist');
                    return
                }
                vueInstance.$store.commit('addKeyInFilesManifest', {
                    key: uniq_key, type: "image", value: fileObj, opr: "add"
                })
                const node = view.state.schema.nodes.imageComponent.create({
                  uniqkey: uniq_key,
                  chal_id: vueInstance.chal_id,
                });
                const transaction = view.state.tr.replaceSelectionWith(node);
                view.dispatch(transaction);
                handled = true // means the image was copied
                // return true;
              }
            }
            // copy text (if its not image)
            if (!handled) {
              // Let ProseMirror handle text pasting by returning false
              return false;
            }
            return true;
          },
          handleDOMEvents: {
            drop: (view, event) => {
              // on drop check if any file is uploaded ?
              const hasFiles = event.dataTransfer.files && event.dataTransfer.files.length;
              if (!hasFiles) {
                return false;
              }
              // now check if its an image or not
              const images = Array.from(event.dataTransfer.files).filter(file => /image/i.test(file.type));
              if (images.length === 0) {
                return false;
              }
              else{
                // console.log("prevet default called ========================================");
                
                event.preventDefault();// prevent default behavior of the drop event (which naviagte browser to new url)
              }

              const { schema } = view.state;

              images.forEach(image => {
                // let dataUrl = URL.createObjectURL(image)
                // need to transfer this data to store
                // Access the Vue store from the vuejs instance we passed in options
                let vueInstance = this.options.vueInstance
                let uniq_key = "$" + image.name + "_" + Date.now() + "$"
                console.log("===uniq_key++++", uniq_key, vueInstance.$store);
                
                if(uniq_key in vueInstance.$store.state.files_manifest){
                    console.log('error, key already exist');
                    return
                }
                vueInstance.$store.commit('addKeyInFilesManifest', {
                    key: uniq_key, type: "image", value: image, opr: "add"
                })
                const node = schema.nodes.imageComponent.create({
                  uniqkey: uniq_key,
                  chal_id: vueInstance.chal_id,
                });
                const transaction = view.state.tr.replaceSelectionWith(node);
                view.dispatch(transaction);
              });

              return true;
            },
          },
        },
      }),
    ];
  },
})