<template>
    <c-key>
        <slot>NavLink</slot>
    </c-key>
</template>
  
<script>
import {keyboardKey} from '../atoms/create_challenge_div_styles.js'
export default {
    name: 'custom-keyboard-key',
    components:{
        'c-key': keyboardKey,
    }
}
</script>