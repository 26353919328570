let mathKeyboard = {
    "basic": [
        {
            "name": "plus",
            "opr": "+",
            "unicode": "43"
        },
        {
            "name": "minus",
            "opr": "-",
            "unicode": "45"
        },
        {
            "name": "multiply",
            "opr": "*",
            "unicode": "42"
        }
    ],
    "symbols": [
        {
            "name": "alpha",
            "opr": "\\alpha",
            "unicode": "945"
        },
        {
            "name": "beta",
            "opr": "\\beta",
            "unicode": "946"
        },
        {
            "name": "gamma",
            "opr": "\\gamma",
            "unicode": "947"
        }
    ],
    "calculus": [
        {
            "name": "Summation",
            "opr": "\\Sigma",
            "unicode": "8721"
        },
        {
            "name": "theta",
            "opr": "\\theta",
            "unicode": "920"
        },
        {
            "name": "pi",
            "opr": "\\pi",
            "unicode": "928"
        }
    ]
}

export default mathKeyboard