import styled from "vue-styled-components"
import {theme} from '../assets/theme.js'
import config from '../assets/config.js'


const rowDivProps = {
    width: Number, 
    textalign: String,
    padding: Number,
    gap: Number,
    display: String,
    order:Number,
    text_transform: String
};
export const rowDiv = styled("div", rowDivProps)`
    display: ${(props) => ((props.display) ? (props.display):config['divs']['rowDiv']['display'])};
    align-items: ${config['divs']['rowDiv']['align-items']};
    overflow: hidden;
    text-transform: ${(props) => ((props.text_transform) ? (props.text_transform): config['divs']['rowDiv']['text-transform'])};
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : config['divs']['rowDiv']['text-align'])};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['rowDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['rowDiv']['gap'])}
    flex:${config['divs']['rowDiv']['flex']}
    order:${(props) => ((props.order) ? (props.order) : config['divs']['rowDiv']['order'])}
    flex-grow:${config['divs']['rowDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['rowDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' :config['divs']['rowDiv']['height'])};
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }     
`;
const createCoursePageContentDivProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number
};
export const pageContentDiv = styled("div", createCoursePageContentDivProps)`
    display: ${config['divs']['pageContentDiv']['display']};
    flex-direction: ${config['divs']['pageContentDiv']['flex-direction']};
    align-items: ${config['divs']['pageContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['pageContentDiv']['padding'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['pageContentDiv']['width'])};
    height: ${config['divs']['pageContentDiv']['height']};
    overflow-y: ${config['divs']['pageContentDiv']['overflow-y']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
    }
`;
export const rightRailContentDiv = styled("div", createCoursePageContentDivProps)`
    display: ${config['divs']['rightRailContentDiv']['display']};
    flex-direction: ${config['divs']['rightRailContentDiv']['flex-direction']};
    align-items: ${config['divs']['rightRailContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['rightRailContentDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['rightRailContentDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['rightRailContentDiv']['width'])};
    height: ${config['divs']['rightRailContentDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    flex: ${config['divs']['rightRailContentDiv']['flex']};
    order: ${config['divs']['rightRailContentDiv']['order']};
    align-self: ${config['divs']['rightRailContentDiv']['align-self']};
    flex-grow: ${config['divs']['rightRailContentDiv']['flex-grow']};
`;
const createCoursePageFormSectionDivProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number
};
export const pageFormContentDiv = styled("div", createCoursePageFormSectionDivProps)`
    display: ${config['divs']['pageFormContentDiv']['display']};
    flex-direction: ${config['divs']['pageFormContentDiv']['flex-direction']};
    align-items: ${config['divs']['pageFormContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['pageFormContentDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['pageFormContentDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['pageFormContentDiv']['width'])};
    height: ${config['divs']['pageFormContentDiv']['height']};
    overflow-y: ${config['divs']['pageFormContentDiv']['overflow-y']};
    background: ${theme['Secondary-Gray0100']};
    flex: ${config['divs']['pageFormContentDiv']['flex']};
    order: ${config['divs']['pageFormContentDiv']['order']};
    align-self: ${config['divs']['pageFormContentDiv']['align-self']};
    flex-grow: ${config['divs']['pageFormContentDiv']['flex-grow']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
    }
`;

export const pageFormSectionDiv = styled("div", createCoursePageFormSectionDivProps)`
    display: ${config['divs']['pageFormSectionDiv']['display']};
    flex-direction: ${config['divs']['pageFormSectionDiv']['flex-direction']};
    align-items: ${config['divs']['pageFormSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['pageFormSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['pageFormSectionDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['pageFormSectionDiv']['width'])};
    height: ${config['divs']['pageFormSectionDiv']['height']};
    flex: ${config['divs']['pageFormSectionDiv']['flex']};
    order: ${config['divs']['pageFormSectionDiv']['order']};
    flex-grow: ${config['divs']['pageFormSectionDiv']['flex-grow']};
`;
export const formSectionCourseBasicInfoDiv = styled("div", createCoursePageFormSectionDivProps)`
    display: ${config['divs']['formSectionCourseBasicInfoDiv']['display']};
    flex-direction: ${config['divs']['formSectionCourseBasicInfoDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionCourseBasicInfoDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['formSectionCourseBasicInfoDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['formSectionCourseBasicInfoDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionCourseBasicInfoDiv']['width'])};
    height: ${config['divs']['formSectionCourseBasicInfoDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['formSectionCourseBasicInfoDiv']['box-shadow']};
    border-radius: ${config['divs']['formSectionCourseBasicInfoDiv']['border-radius']};
    flex: ${config['divs']['formSectionCourseBasicInfoDiv']['flex']};
    order: ${config['divs']['formSectionCourseBasicInfoDiv']['order']};
    align-self: ${config['divs']['formSectionCourseBasicInfoDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionCourseBasicInfoDiv']['flex-grow']};
`;
export const headingBarDiv = styled("div", createCoursePageFormSectionDivProps)`
    display: ${config['divs']['headingBarDiv']['display']};
    flex-direction: ${config['divs']['headingBarDiv']['flex-direction']};
    justify-content: ${config['divs']['headingBarDiv']['justify-content']};
    align-items: ${config['divs']['headingBarDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['headingBarDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['headingBarDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['headingBarDiv']['width'])};
    height: ${config['divs']['headingBarDiv']['height']};
    flex: ${config['divs']['headingBarDiv']['flex']};
    order: ${config['divs']['headingBarDiv']['order']};
    align-self: ${config['divs']['headingBarDiv']['align-self']};
    flex-grow: ${config['divs']['headingBarDiv']['flex-grow']};
`;
export const heading = styled("div", createCoursePageFormSectionDivProps)`
    display: ${config['divs']['heading']['display']};
    flex-direction: ${config['divs']['heading']['flex-direction']};
    align-items: ${config['divs']['heading']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['heading']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['heading']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['heading']['width'])};
    height: ${config['divs']['heading']['height']};
    flex: ${config['divs']['heading']['flex']};
    order: ${config['divs']['heading']['order']};
    flex-grow: ${config['divs']['heading']['flex-grow']};
`;

const sectionSubTitleProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const sectionSubTitle = styled("div", sectionSubTitleProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['sectionSubTitle']['display'])};
    align-items: ${config['divs']['sectionSubTitle']['align-items']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sectionSubTitle']['width'])};
    height: ${config['divs']['sectionSubTitle']['height']};
    flex: ${config['divs']['sectionSubTitle']['flex']};
    order: ${config['divs']['sectionSubTitle']['order']};
    align-self: ${config['divs']['sectionSubTitle']['align-self']};
    flex-grow: ${config['divs']['sectionSubTitle']['flex-grow']};
`;
const basicInfoFormProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const basicInfoFormDiv = styled("div", basicInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['basicInfoFormDiv']['display'])};
    flex-direction: ${config['divs']['basicInfoFormDiv']['flex-direction']};
    align-items: ${config['divs']['basicInfoFormDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['basicInfoFormDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['basicInfoFormDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['basicInfoFormDiv']['width'])};
    height: ${config['divs']['basicInfoFormDiv']['height']};
    flex: ${config['divs']['basicInfoFormDiv']['flex']};
    order: ${config['divs']['basicInfoFormDiv']['order']};
    align-self: ${config['divs']['basicInfoFormDiv']['align-self']};
    flex-grow: ${config['divs']['basicInfoFormDiv']['flex-grow']};
`;
const scheduleInfoFormProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const scheduleInfoForm = styled("div", scheduleInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['scheduleInfoForm']['display'])};
    flex-direction: ${config['divs']['scheduleInfoForm']['flex-direction']};
    align-items: ${config['divs']['scheduleInfoForm']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['scheduleInfoForm']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['scheduleInfoForm']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['scheduleInfoForm']['width'])};
    height: ${config['divs']['scheduleInfoForm']['height']};
    flex: ${config['divs']['scheduleInfoForm']['flex']};
    order: ${config['divs']['scheduleInfoForm']['order']};
    align-self: ${config['divs']['scheduleInfoForm']['align-self']};
    flex-grow: ${config['divs']['scheduleInfoForm']['flex-grow']};
`;
const priceInfoFormProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const priceInfoForm = styled("div", priceInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['priceInfoForm']['display'])};
    flex-direction: ${config['divs']['priceInfoForm']['flex-direction']};
    align-items: ${config['divs']['priceInfoForm']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['priceInfoForm']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['priceInfoForm']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['priceInfoForm']['width'])};
    height: ${config['divs']['priceInfoForm']['height']};
    flex: ${config['divs']['priceInfoForm']['flex']};
    order: ${config['divs']['priceInfoForm']['order']};
    align-self: ${config['divs']['priceInfoForm']['align-self']};
    flex-grow: ${config['divs']['priceInfoForm']['flex-grow']};
`;
const additionalInfoFormProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const additionalInfoForm = styled("div", additionalInfoFormProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['additionalInfoForm']['display'])};
    flex-direction: ${config['divs']['additionalInfoForm']['flex-direction']};
    align-items: ${config['divs']['additionalInfoForm']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['additionalInfoForm']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['additionalInfoForm']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['additionalInfoForm']['width'])};
    height: ${config['divs']['additionalInfoForm']['height']};
    flex: ${config['divs']['additionalInfoForm']['flex']};
    order: ${config['divs']['additionalInfoForm']['order']};
    align-self: ${config['divs']['additionalInfoForm']['align-self']};
    flex-grow: ${config['divs']['additionalInfoForm']['flex-grow']};
`;

const basicInfoFormInputProps = {
    width: String,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number,
    display: String
};
export const basicInfoFormInputDiv = styled("div", basicInfoFormInputProps)`
    display: ${config['divs']['basicInfoFormInputDiv']['display']};
    flex-direction: ${config['divs']['basicInfoFormInputDiv']['flex-direction']};
    align-items: ${config['divs']['basicInfoFormInputDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['basicInfoFormInputDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['basicInfoFormInputDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['basicInfoFormInputDiv']['width'])};
    height: ${config['divs']['basicInfoFormInputDiv']['height']};
    flex: ${config['divs']['basicInfoFormInputDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['basicInfoFormInputDiv']['order'])};
    align-self: ${config['divs']['basicInfoFormInputDiv']['align-self']};
    flex-grow: ${config['divs']['basicInfoFormInputDiv']['flex-grow']};
`;
export const textBoxLabelField = styled("div", basicInfoFormProps)`
    display: ${config['divs']['textBoxLabelField']['display']};
    align-items: ${config['divs']['textBoxLabelField']['align-items']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['textBoxLabelField']['width'])};
    height: ${config['divs']['textBoxLabelField']['height']};
    flex: ${config['divs']['textBoxLabelField']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['textBoxLabelField']['order'])};
    align-self: ${config['divs']['textBoxLabelField']['align-self']};
    flex-grow: ${config['divs']['textBoxLabelField']['flex-grow']};
`;
export const formInputField = styled("input", basicInfoFormProps)`
    box-sizing: ${config['divs']['frames']['formInputField']['box-sizing']};
    display: ${config['divs']['frames']['formInputField']['display']};
    flex-direction: ${config['divs']['frames']['formInputField']['flex-direction']};
    align-items: ${config['divs']['frames']['formInputField']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['formInputField']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['formInputField']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['formInputField']['width'])};
    height: ${config['divs']['frames']['formInputField']['height']};
    background: ${theme['Secondary-Gray0000']}
    border-radius: ${config['divs']['frames']['formInputField']['border-radius']};
    border: 1px solid #93ABAB;
    flex: ${config['divs']['frames']['formInputField']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['frames']['formInputField']['order'])};
    align-self: ${config['divs']['frames']['formInputField']['align-self']};
    flex-grow: ${config['divs']['frames']['formInputField']['flex-grow']};
    font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
    font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
    font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    color: ${theme['Secondary-Gray0800']};
    ::placeholder {
        color: ${(props) => (props.placeholderColor ?props.placeholderColor:theme['Secondary-Gray0500'])};
        font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
        font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
        font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
        font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
        line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    }
    &:focus{
        outline: none;
        border: 1px solid ${theme['PrimaryCyan0400-Default']};
        ::placeholder {
            color: transparent;
        }
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
        display:none;
    }
`;
export const enrollStudentNoDiv = styled("div", basicInfoFormProps)`
    display: ${config['divs']['frames']['enrollStudentNoDiv']['display']};
    flex-direction: ${config['divs']['frames']['enrollStudentNoDiv']['flex-direction']};
    align-items: ${config['divs']['frames']['enrollStudentNoDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['enrollStudentNoDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['enrollStudentNoDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['enrollStudentNoDiv']['width'])};
    height: ${config['divs']['frames']['enrollStudentNoDiv']['height']};
    flex: ${config['divs']['frames']['enrollStudentNoDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['frames']['enrollStudentNoDiv']['order'])};
    align-self: ${config['divs']['frames']['enrollStudentNoDiv']['align-self']};
    flex-grow: ${config['divs']['frames']['enrollStudentNoDiv']['flex-grow']};
`;
const formSectionScheduleInfoDivProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number,
    order: Number
};
export const formSectionScheduleInfoDiv = styled("div", formSectionScheduleInfoDivProps)`
    display: ${config['divs']['formSectionScheduleInfoDiv']['display']};
    flex-direction: ${config['divs']['formSectionScheduleInfoDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionScheduleInfoDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['formSectionScheduleInfoDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['formSectionScheduleInfoDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionScheduleInfoDiv']['width'])};
    height: ${config['divs']['formSectionScheduleInfoDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['formSectionScheduleInfoDiv']['box-shadow']};
    border-radius: ${config['divs']['formSectionScheduleInfoDiv']['border-radius']};
    flex: ${config['divs']['formSectionScheduleInfoDiv']['flex']};
    order: ${config['divs']['formSectionScheduleInfoDiv']['order']};
    align-self: ${config['divs']['formSectionScheduleInfoDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionScheduleInfoDiv']['flex-grow']};
`;
export const inputScheduleField = styled("div", formSectionScheduleInfoDivProps)`
    display: ${config['divs']['frames']['inputScheduleField']['display']};
    flex-direction: ${config['divs']['frames']['inputScheduleField']['flex-direction']};
    align-items: ${config['divs']['frames']['inputScheduleField']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['inputScheduleField']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['inputScheduleField']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['inputScheduleField']['width'])};
    height: ${config['divs']['frames']['inputScheduleField']['height']};
    flex: ${config['divs']['frames']['inputScheduleField']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['frames']['inputScheduleField']['order'])};
    align-self: ${config['divs']['frames']['inputScheduleField']['align-self']};
    flex-grow: ${config['divs']['frames']['inputScheduleField']['flex-grow']};
`;
export const dateInputFieldDiv = styled("div", formSectionScheduleInfoDivProps)`
    display: ${config['divs']['dateInputFieldDiv']['display']};
    flex-direction: ${config['divs']['dateInputFieldDiv']['flex-direction']};
    align-items: ${config['divs']['dateInputFieldDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['dateInputFieldDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['dateInputFieldDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['dateInputFieldDiv']['width'])};
    height: ${config['divs']['dateInputFieldDiv']['height']};
    flex: ${config['divs']['dateInputFieldDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['dateInputFieldDiv']['order'])};
    align-self: ${config['divs']['dateInputFieldDiv']['align-self']};
    flex-grow: ${config['divs']['dateInputFieldDiv']['flex-grow']};
`;
const checkBoxGroupDivProps = {
    width: Number,
    padding: Number,
    gap: Number
};
export const checkBoxGroupDiv = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxGroupDiv']['display']};
    flex-direction: ${config['divs']['checkBoxGroupDiv']['flex-direction']};
    align-items: ${config['divs']['checkBoxGroupDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['checkBoxGroupDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['checkBoxGroupDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupDiv']['width'])};
    height: ${config['divs']['checkBoxGroupDiv']['height']};
    flex:${config['divs']['checkBoxGroupDiv']['flex']};
    order:${config['divs']['checkBoxGroupDiv']['order']};
    align-self: ${config['divs']['checkBoxGroupDiv']['align-self']};
    flex-grow:${config['divs']['checkBoxGroupDiv']['flex-grow']};

`;
export const checkBoxGroupTitle = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxGroupTitle']['display']};
    align-items: ${config['divs']['checkBoxGroupTitle']['align-items']};
    flex:${config['divs']['checkBoxGroupTitle']['flex']};
    order:${config['divs']['checkBoxGroupTitle']['order']};
    align-self:${config['divs']['checkBoxGroupTitle']['align-self']};
    flex-grow:${config['divs']['checkBoxGroupTitle']['flex-grow']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupTitle']['width'])};
    height: ${config['divs']['checkBoxGroupTitle']['height']};
`;
export const checkBoxDiv = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxDiv']['display']};
    flex-direction: ${config['divs']['checkBoxDiv']['flex-direction']};
    align-items: ${config['divs']['checkBoxDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['checkBoxDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['checkBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxDiv']['width'])};
    height: ${config['divs']['checkBoxDiv']['height']};
    flex:${config['divs']['checkBoxDiv']['flex']};
    order:${config['divs']['checkBoxDiv']['order']};
    flex-grow:${config['divs']['checkBoxDiv']['flex-grow']};
`;
export const unselectedCheckBox = styled("input", checkBoxGroupDivProps)`
    box-sizing: ${config['divs']['checkBox']['unselectedCheckBox']['box-sizing']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['checkBox']['unselectedCheckBox']['width'])};
    height: ${config['divs']['checkBox']['unselectedCheckBox']['height']};
    background: ${config['divs']['checkBox']['unselectedCheckBox']['background']};
    border: ${config['divs']['checkBox']['unselectedCheckBox']['border']};
    border-radius:${ config['divs']['checkBox']['unselectedCheckBox']['border-radius']}
    flex:${config['divs']['checkBox']['unselectedCheckBox']['flex']}
    order:${config['divs']['checkBox']['unselectedCheckBox']['order']}
    flex-grow:${config['divs']['checkBox']['unselectedCheckBox']['flex-grow']}
`;
const helperTextDivProps = {
    width: Number,
    padding: Number,
    gap: Number
};
export const helperTextDiv = styled("div", helperTextDivProps)`
    display: ${config['divs']['helperTextDiv']['display']};
    align-items: ${config['divs']['helperTextDiv']['align-items']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['helperTextDiv']['width'])};
    height: ${config['divs']['helperTextDiv']['height']};
    flex:${config['divs']['helperTextDiv']['flex']};
    order:${config['divs']['helperTextDiv']['order']};
    align-self: ${config['divs']['helperTextDiv']['align-self']};
    flex-grow:${config['divs']['helperTextDiv']['flex-grow']};

`;
const formSectionPriceAndEnrollSectionDivProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: Number
};
export const formSectionPriceAndEnrollSectionDiv = styled("div", formSectionPriceAndEnrollSectionDivProps)`
    display: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['display']};
    flex-direction: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['formSectionPriceAndEnrollSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['formSectionPriceAndEnrollSectionDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionPriceAndEnrollSectionDiv']['width'])};
    height: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['box-shadow']};
    border-radius: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['border-radius']};
    flex: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['formSectionPriceAndEnrollSectionDiv']['order'])};
    align-self: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionPriceAndEnrollSectionDiv']['flex-grow']};
`;
export const inputCurrencyField = styled("div", formSectionScheduleInfoDivProps)`
    display: ${config['divs']['frames']['inputCurrencyField']['display']};
    flex-direction: ${config['divs']['frames']['inputCurrencyField']['flex-direction']};
    align-items: ${config['divs']['frames']['inputCurrencyField']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['inputCurrencyField']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['inputCurrencyField']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['inputCurrencyField']['width'])};
    height: ${config['divs']['frames']['inputCurrencyField']['height']};
    flex: ${config['divs']['frames']['inputCurrencyField']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['frames']['inputCurrencyField']['order'])};
    align-self: ${config['divs']['frames']['inputCurrencyField']['align-self']};
    flex-grow: ${config['divs']['frames']['inputCurrencyField']['flex-grow']};
`;
const formSectionBackgroundDocumentsDivProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order: Number
};
export const formSectionBackgroundDocumentsDiv = styled("div", formSectionBackgroundDocumentsDivProps)`
    display: ${config['divs']['formSectionBackgroundDocumentsDiv']['display']};
    flex-direction: ${config['divs']['formSectionBackgroundDocumentsDiv']['flex-direction']};
    align-items: ${config['divs']['formSectionBackgroundDocumentsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['formSectionBackgroundDocumentsDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['formSectionBackgroundDocumentsDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['formSectionBackgroundDocumentsDiv']['width'])};
    height: ${config['divs']['formSectionBackgroundDocumentsDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['formSectionBackgroundDocumentsDiv']['box-shadow']};
    border-radius: ${config['divs']['formSectionBackgroundDocumentsDiv']['border-radius']};
    flex: ${config['divs']['formSectionBackgroundDocumentsDiv']['flex']};
    order: ${config['divs']['formSectionBackgroundDocumentsDiv']['order']};
    align-self: ${config['divs']['formSectionBackgroundDocumentsDiv']['align-self']};
    flex-grow: ${config['divs']['formSectionBackgroundDocumentsDiv']['flex-grow']};
`;
export const fileUploaderDiv = styled("div", formSectionBackgroundDocumentsDivProps)`
    display: ${config['divs']['fileUploaderDiv']['display']};
    flex-direction: ${config['divs']['fileUploaderDiv']['flex-direction']};
    align-items: ${config['divs']['fileUploaderDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['fileUploaderDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['fileUploaderDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['fileUploaderDiv']['width'])};
    height: ${config['divs']['fileUploaderDiv']['height']};
    flex: ${config['divs']['fileUploaderDiv']['flex']};
    order: ${config['divs']['fileUploaderDiv']['order']};
    align-self: ${config['divs']['fileUploaderDiv']['align-self']};
    flex-grow: ${config['divs']['fileUploaderDiv']['flex-grow']};
`;
export const fileDiv = styled("div", formSectionBackgroundDocumentsDivProps)`
    display: ${config['divs']['fileDiv']['display']};
    flex-direction: ${config['divs']['fileDiv']['flex-direction']};
    align-items: ${config['divs']['fileDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['fileDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['fileDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['fileDiv']['width'])};
    height: ${config['divs']['fileDiv']['height']};
    flex: ${config['divs']['fileDiv']['flex']};
    order: ${config['divs']['fileDiv']['order']};
    flex-grow: ${config['divs']['fileDiv']['flex-grow']};
`;
export const fileNameInputDiv = styled("input", formSectionBackgroundDocumentsDivProps)`
    box-sizing: ${config['divs']['fileNameInputDiv']['box-sizing']};
    display: ${config['divs']['fileNameInputDiv']['display']};
    flex-direction: ${config['divs']['fileNameInputDiv']['flex-direction']};
    align-items: ${config['divs']['fileNameInputDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['fileNameInputDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['fileNameInputDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['fileNameInputDiv']['width'])};
    height: ${config['divs']['fileNameInputDiv']['height']};
    background: ${theme['Secondary-Gray0000']}
    border-radius: ${config['divs']['fileNameInputDiv']['border-radius']};
    border: 1px solid #93ABAB;
    flex: ${config['divs']['fileNameInputDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['fileNameInputDiv']['order'])};
    flex-grow: ${config['divs']['fileNameInputDiv']['flex-grow']};
    ::-webkit-file-upload-button {
        display: none;
    }
    ::file-selector-button { font-size: initial; }
`;
export const fileInputTextAreaDiv = styled("textarea", formSectionBackgroundDocumentsDivProps)`
    box-sizing: ${config['divs']['fileInputTextAreaDiv']['box-sizing']};
    display: ${config['divs']['fileInputTextAreaDiv']['display']};
    flex-direction: ${config['divs']['fileInputTextAreaDiv']['flex-direction']};
    align-items: ${config['divs']['fileInputTextAreaDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['fileInputTextAreaDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['fileInputTextAreaDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['fileInputTextAreaDiv']['width'])};
    height: ${config['divs']['fileInputTextAreaDiv']['height']};
    background: ${theme['Secondary-Gray0000']}
    border-radius: ${config['divs']['fileInputTextAreaDiv']['border-radius']};
    border: 1px solid #93ABAB;
    flex: ${config['divs']['fileInputTextAreaDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['fileInputTextAreaDiv']['order'])};
    align-self: ${config['divs']['fileInputTextAreaDiv']['align-self']};
    flex-grow: ${config['divs']['fileInputTextAreaDiv']['flex-grow']};
    font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
    font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
    font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    color: ${theme['Secondary-Gray0800']};
    ::placeholder {
        color: ${(props) => (props.placeholderColor ?props.placeholderColor:theme['Secondary-Gray0500'])};
        font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
        font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
        font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
        font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
        line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    }
    &:focus{
        outline: none;
        border: 1px solid ${theme['PrimaryCyan0400-Default']};
        ::placeholder {
            color: transparent;
        }
    }
`;
export const buttonsDiv = styled("div", formSectionBackgroundDocumentsDivProps)`
    display: ${config['divs']['buttonsDiv']['display']};
    flex-direction: ${config['divs']['buttonsDiv']['flex-direction']};
    align-items: ${config['divs']['buttonsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['buttonsDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['buttonsDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['buttonsDiv']['width'])};
    height: ${config['divs']['buttonsDiv']['height']};
    flex: ${config['divs']['buttonsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) : config['divs']['buttonsDiv']['order'])};
    flex-grow: ${config['divs']['buttonsDiv']['flex-grow']};
`;
const courseSessionsDivProps = {
    width: String,
}
export const sessionsDiv = styled("div", courseSessionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['courseSessionsDiv']['display'])};
    flex-direction: ${config['divs']['courseSessionsDiv']['flex-direction']};
    align-items: ${config['divs']['courseSessionsDiv']['align-items']};
    padding: ${config['divs']['courseSessionsDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['courseSessionsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['courseSessionsDiv']['width'])};
    height: ${config['divs']['courseSessionsDiv']['height']};
    flex: ${config['divs']['courseSessionsDiv']['flex']};
    order: ${config['divs']['courseSessionsDiv']['order']};
    flex-grow: ${config['divs']['courseSessionsDiv']['flex-grow']};
`;
const courseSessionsListDivProps = {
    width: String,
}
export const sessionsListDiv = styled("div", courseSessionsListDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['courseSessionsListDiv']['display'])};
    flex-direction: ${config['divs']['courseSessionsListDiv']['flex-direction']};
    align-items: ${config['divs']['courseSessionsListDiv']['align-items']};
    padding: ${config['divs']['courseSessionsListDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['courseSessionsListDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['courseSessionsListDiv']['width'])};
    height: ${config['divs']['courseSessionsListDiv']['height']};
    flex: ${config['divs']['courseSessionsListDiv']['flex']};
    order: ${config['divs']['courseSessionsListDiv']['order']};
    align-self: ${config['divs']['courseSessionsListDiv']['align-self']};
    flex-grow: ${config['divs']['courseSessionsListDiv']['flex-grow']};
`;
const sessionSummaryCardProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number,
    gap: Number,
    order: Number, 
    background: String,

};

export const session_Summary_Card = styled("div", sessionSummaryCardProps)`
    background: ${(props) => ((props.background) ? (props.background) :theme['Secondary-Gray0000'])};
    display: ${config['divs']['sessionSummaryCard']['display']};
    flex-direction: ${config['divs']['sessionSummaryCard']['flex-direction']};
    align-items: ${config['divs']['sessionSummaryCard']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['sessionSummaryCard']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['sessionSummaryCard']['gap'])};
    flex:${config['divs']['sessionSummaryCard']['flex']}
    order:${config['divs']['sessionSummaryCard']['order']}
    flex-grow:${config['divs']['sessionSummaryCard']['flex-grow']}
    align-self: ${config['divs']['sessionSummaryCard']['align-self']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionSummaryCard']['width'])};
    height: ${config['divs']['sessionSummaryCard']['height']}px;
    border: ${config['divs']['sessionSummaryCard']['border']};
    border-radius: ${config['divs']['sessionSummaryCard']['border-radius']};
`;
export const sessionSummaryCardLeftDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['sessionSummaryCardLeft']['display']};
    flex-direction: ${config['divs']['sessionSummaryCardLeft']['flex-direction']};
    align-items: ${config['divs']['sessionSummaryCardLeft']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['sessionSummaryCardLeft']['padding'])}px
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['sessionSummaryCardLeft']['gap'])}px
    flex:${config['divs']['sessionSummaryCardLeft']['flex']}
    order:${config['divs']['sessionSummaryCardLeft']['order']}
    align-self:${config['divs']['sessionSummaryCardLeft']['align-self']}
    flex-grow:${config['divs']['sessionSummaryCardLeft']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionSummaryCardLeft']['width'])};
    height: ${config['divs']['sessionSummaryCardLeft']['height']}px;
`;
export const sessionSummaryCardRightDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['sessionSummaryCardRight']['display']};
    flex-direction: ${config['divs']['sessionSummaryCardRight']['flex-direction']};
    align-items: ${config['divs']['sessionSummaryCardRight']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['sessionSummaryCardRight']['padding'])}px
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['sessionSummaryCardRight']['gap'])}px
    flex:${config['divs']['sessionSummaryCardRight']['flex']}
    order:${config['divs']['sessionSummaryCardRight']['order']}
    align-self:${config['divs']['sessionSummaryCardRight']['align-self']}
    flex-grow:${config['divs']['sessionSummaryCardRight']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['sessionSummaryCardRight']['width'])}px;
    height: ${config['divs']['sessionSummaryCardRight']['height']}px;
`;
const statusSectionProps = {
    width: String, // width is multiple of 100 in px
    padding: Number,
    gap: Number,
    order: Number, 
    background: String,
}
export const statusSection = styled("div", statusSectionProps)`
    box-sizing: ${config['divs']['statusSection']['box-sizing']};
    display: ${config['divs']['statusSection']['display']};
    flex-direction: ${config['divs']['statusSection']['flex-direction']};
    align-items: ${config['divs']['statusSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['statusSection']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['statusSection']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['statusSection']['width'])};
    height: ${config['divs']['statusSection']['height']};
    flex: ${config['divs']['statusSection']['flex']};
    order: ${config['divs']['statusSection']['order']};
    align-self: ${config['divs']['statusSection']['align-self']};
    flex-grow: ${config['divs']['statusSection']['flex-grow']};
    background: ${theme['Primary-white']};
    border-radius: ${config['divs']['statusSection']['border-radius']};
    border: ${config['divs']['statusSection']['border']} ${theme['Accent-Magenta0300']};
`;
const frame_54_props = {
    width: Number, 
    padding: Number,
    gap: Number,
    order: Number
}
export const frame_54 = styled("div", frame_54_props)`
    display: ${config['divs']['frames']['frame_54']['display']};
    flex-direction: ${config['divs']['frames']['frame_54']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_54']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['frame_54']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['frame_54']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['frame_54']['width'])};
    height: ${config['divs']['frames']['frame_54']['height']};
    flex: ${config['divs']['frames']['frame_54']['flex']};
    order: ${config['divs']['frames']['frame_54']['order']};
    flex-grow: ${config['divs']['frames']['frame_54']['flex-grow']};
`;
const sessionbuttonsDivProps = {
    width: String, 
    padding: String,
    gap: String,
    order: Number, 
    background: String,
    display: String
}
export const sessionbuttonsDiv = styled("div", sessionbuttonsDivProps)`
    display: ${config['divs']['sessionbuttonsDiv']['display']};
    flex-direction: ${config['divs']['sessionbuttonsDiv']['flex-direction']};
    align-items: ${config['divs']['sessionbuttonsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['sessionbuttonsDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['sessionbuttonsDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['sessionbuttonsDiv']['width'])};
    height: ${config['divs']['sessionbuttonsDiv']['height']};
    flex: ${config['divs']['sessionbuttonsDiv']['flex']};
    order: ${config['divs']['sessionbuttonsDiv']['order']};
    flex-grow: ${config['divs']['sessionbuttonsDiv']['flex-grow']};
`;