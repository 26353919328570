<template>
    <c-home-page>
        <!-- <c-hero-section></c-hero-section> -->
        <div class="heroSection">
            <vueper-slides
            ref="myVueperSlides"
            autoplay
            fixed-height="400px"
            slide-image-inside
            arrows-outside
            :pause-on-hover="pauseOnHover"
            @autoplay-pause="internalAutoPlaying = false"
            @autoplay-resume="internalAutoPlaying = true">
                <vueper-slide
                v-for="(slide) in slides"
                :key="slide.id"
                :image="slide.image"
                :title="slide.title"
                :content="slide.content">
                </vueper-slide>
            </vueper-slides>
        </div>
        <c-upcoming-course-section :isLoadingCoursesListData="isLoadingCoursesListData" :upcomingCoursesListData="upcomingCoursesListData" :key="upcomingCoursesListData"></c-upcoming-course-section>
        <c-how-it-work-section ></c-how-it-work-section>
        <c-meet-team-section :meetTheTeamListData="meetTheTeamListData" :key="meetTheTeamListData"></c-meet-team-section>
        <c-footer></c-footer>
        
    </c-home-page>
</template>

<script>
import AppConfig from '../../config.js';
import axios from 'axios';
// import hero_section from '../organisms/hero_section.vue'
import {theme} from '../assets/theme.js'
import footerComp from '../organisms/footerComp.vue'
import meet_team_section from '../organisms/meet_team_section.vue'
import how_it_work_section from '../organisms/how_it_work_section.vue'
import upcoming_course_section from '../organisms/upcoming_course_section.vue'
import {homePageDiv} from '../atoms/div_styles'
import { VueperSlides, VueperSlide } from 'vueperslides'
export default {
    name:'unsignedHome',
    components:{
        'c-home-page': homePageDiv, 
        // 'c-hero-section': hero_section,
        'c-how-it-work-section':how_it_work_section,
        'c-footer': footerComp,
        'c-upcoming-course-section': upcoming_course_section,
        'c-meet-team-section': meet_team_section,
        VueperSlides,
        VueperSlide
    },
    data() {
        return {
            UI_baseURL: AppConfig.UI_Base_URL,
            auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
            colorTheme:theme,
            upcomingCoursesListData:[],
            meetTheTeamListData:[],
            isLoadingCoursesListData : true,
            pauseOnHover: false,
            autoPlaying: true,
            internalAutoPlaying: true,
            slides: [
                { image: require('../assets/images/tutor2_final.webp'), content: '<span style="font-size: 1.2em;color: black">Welcome to rasree, online learning platform</span>'},
                // { image: require('../assets/images/philanthropy2_final.webp'),  title: 'slide-2', content: 'content-2, <span style="font-size: 1.2em;color: red">Teaching</span>' },
                { image: require('../assets/images/tech4_final.webp'), content: '<span style="font-size: 1.2em;color: black">Lets read together</span>'},
                { image: require('../assets/images/parent3_final.webp'), content: '<span style="font-size: 1.2em;color: Black">Bright future for your kids</span>'},
                { image: require('../assets/images/learner4_final.webp'), content: '<span style="font-size: 1.2em;color: Black">The journey of a thousand miles begin with one step</span>'},
                { image: require('../assets/images/educationalist2_final.webp'), content: '<span style="font-size: 1.2em;color: black">Learning to success</span>'},
                { image: require('../assets/images/govt1_final.webp'), content: '<span style="font-size: 1.2em;color: black">Teaching</span>'}
            ]
        }
    },
    async beforeMount() {
        // api call for get-courses
        await this.getUnsignedHomeSupportFn()
    },
    methods: {
        async getUnsignedHomeSupportFn(){
            //api call for get UnsignedHomePageData
            try{
                this.isLoadingCoursesListData = true
                let unsignedHomePageResp = await axios.get(this.auth_api_Base_URL + "/v1_0/unsignedHomePageData")
                console.log("resp from unsignedHomePage Data", unsignedHomePageResp)
                this.upcomingCoursesListData = unsignedHomePageResp.data.courses
                this.meetTheTeamListData = unsignedHomePageResp.data.socialProofs
                console.log("unsignedHomePageResp", this.upcomingCoursesListData, typeof this.upcomingCoursesListData);
            }catch(error){
                console.log("error in resp of get unsignedHomePage Data is:", error.response)
            }
            this.isLoadingCoursesListData = false
        }
    }
}
</script>
<style>
.vueperslides__arrow {
    color: white
}

.vueperslide__content {
    width: 50% !important;
    text-align: left !important;
    font-weight: 700 !important;
}

.vueperslide__content-wrapper {
    font-size: 1.5rem; /*1rem=16px*/
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin: 16px !important;
    word-break: keep-all;
}

.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}
.vueperslides__bullet--active .default {
    background-color: #42b983;
}
.vueperslide{
    white-space: normal;
    background-size: cover;
    flex-shrink: 0;
    display: block;
    width: 100%;
    position: relative;
}
.vueperslide__image {
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    border-radius: 8px;
}
.vueperslide__title {
  font-size: 4em;

}
.heroSection{
    width: 100%;
    padding: 2% 20%;
    background: #424D4D;
}

@media (max-width: 480px) {
    .vueperslide__image {
        background-position: center !important;
    }

    .vueperslide__content {
        width: 50% !important;
        text-align: left !important;
        font-weight: 700 !important;
        font-size: 14px;
    }
}
</style>
