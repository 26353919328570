<template>
    <c-list-item v-bind="computedProps">
        <c-icon>{{prependIcon}}</c-icon>
        <slot>primary tag</slot>
        <c-icon>{{postpendIcon}}</c-icon>
    </c-list-item>
</template>
  
<script>
import { listItem } from '../atoms/div_styles';
import {iconStyle} from '../atoms/icon_styles'
export default {
    name: 'listItem',
    props:{
        prependIcon: String,
        postpendIcon: String,
        width: {
            type: Number
        },
        height :{
            type: String
        },
        order: {
            type: String
        },
        background_color: {
            type: String
        },
        gap:{
            type: String
        },
        padding:{
            type: String
        }
    },
    components:{
        'c-icon': iconStyle,
        'c-list-item': listItem,
    },
    computed:{
        computedProps(){
            let props = {
                width: this.width,
                height: this.height,
                order: this.order,
                gap: this.gap,
                background_color: this.background_color
            }
            if (this.padding !== null && this.padding !== undefined) {
                props.padding = this.padding;
            }
            return props
        }
    }
}
</script>