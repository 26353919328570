import styled from "vue-styled-components";
import {theme} from '../assets/theme.js'
import config from '../assets/config.js'

const primaryBtnProps = {
    width: Number, //multiple of default value
    display: String,
    height: Number,
    order: Number,
    color: String,
    padding: String
}

export const PrimaryButton = styled('button', primaryBtnProps)`
    background: ${theme['Primary-Cyan0400']};
    border-radius: ${config['buttons']['primary']['border-radius']};
    display: ${props => (props.display ? props.display : config['buttons']['primary']['display'])};
    flex-direction: ${config['buttons']['primary']['flex-direction']};
    justify-content: ${config['buttons']['primary']['justify-content']};
    align-items: ${config['buttons']['primary']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['buttons']['primary']['padding'])};
    width: ${props => ((props.width) ? (props.width) + 'px' : config['buttons']['primary']['width'])};
    height: ${props => (props.height ? (props.height) + 'px' : config['buttons']['primary']['height'])};
    left: ${config['buttons']['primary']['left']};
    top: ${config['buttons']['primary']['top']};
    font-family: ${config['fonts']['lexend']['H4-22']["font-family"]};
    font-style: ${config['fonts']['lexend']['H4-22']["font-style"]};
    font-weight: ${config['fonts']['lexend']['H4-22']["font-weight"]};
    font-size: ${config['fonts']['lexend']['H4-22']["font-size"]};
    line-height: ${config['fonts']['lexend']['H4-22']["font-height"]};
    color: ${props => (props.color ? props.color : theme['Secondary-Gray0000'])};
    flex: ${config['buttons']['primary']['flex']};
    order: ${props => (props.order ? props.order : config['buttons']['primary']['order'])};
    flex-grow: ${config['buttons']['primary']['flex-grow']};
    &:hover{
        background: ${theme['Primary-Cyan0300']};
    }
    &:focus{
        background: ${theme['Primary-Cyan0500']};
    }
`;

const secondaryBtnProps = {
    width: Number, //multiple of default value
    display: String,
    height: Number,
    order: Number,
    padding: String,
    border: String,
    borderColor: String

}

export const SecondaryButton = styled('button', secondaryBtnProps)`
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['buttons']['secondary']['border-radius']};
    border: ${(props) => ((props.border) ? (props.border) : config['buttons']['secondary']['border'])} ${(props) => ((props.borderColor) ? (props.borderColor) : theme['Secondary-Gray0600'])};
    display: ${props => (props.display ? props.display : config['buttons']['secondary']['display'])};
    flex-direction: ${config['buttons']['secondary']['flex-direction']};
    justify-content: ${config['buttons']['secondary']['justify-content']};
    align-items: ${config['buttons']['secondary']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) : config['buttons']['secondary']['padding'])};
    width: ${props => ((props.width) ? (props.width) + 'px' : config['buttons']['secondary']['width'])};
    height: ${props => (props.height ? (props.height) + 'px' : config['buttons']['secondary']['height'])};
    left: ${config['buttons']['secondary']['left']};
    top: ${config['buttons']['secondary']['top']};
    font-family: ${config['fonts']['lexend']['H4-22']["font-family"]};
    font-style: ${config['fonts']['lexend']['H4-22']["font-style"]};
    font-weight: ${config['fonts']['lexend']['H4-22']["font-weight"]};
    font-size: ${config['fonts']['lexend']['H4-22']["font-size"]};
    line-height: ${config['fonts']['lexend']['H4-22']["font-height"]};
    color: ${theme['Secondary-Gray0800']};
    flex: ${config['buttons']['secondary']['flex']};
    order: ${props => (props.order ? props.order : config['buttons']['secondary']['order'])};
    flex-grow: ${config['buttons']['secondary']['flex-grow']};
    &:hover{
        background: ${theme['Secondary-Gray0100']};
    }
    &:focus{
        background: ${theme['Secondary-Gray0300']};
    }
`;
const floatingActionButtonProps = {
    width: Number, 
    display: String,
    height: Number,
    order: Number,
    padding: Number,
    background: String
}

export const floatingActionButton = styled('button', floatingActionButtonProps)`
    display: ${config['buttons']['floatingActionButton']['display']};
    flex-direction: ${config['buttons']['floatingActionButton']['flex-direction']};
    justify-content: ${config['buttons']['floatingActionButton']['justify-content']};
    align-items: ${config['buttons']['floatingActionButton']['align-items']};
    padding: ${config['buttons']['floatingActionButton']['padding']};
    gap: ${config['buttons']['floatingActionButton']['gap']};
    width: ${props => (props.width ? (props.width) : config['buttons']['floatingActionButton']['width'])};
    height: ${config['buttons']['floatingActionButton']['height']};
    position: ${config['buttons']['floatingActionButton']['position']};
    right: ${config['buttons']['floatingActionButton']['right']};
    bottom: ${config['buttons']['floatingActionButton']['bottom']};
    font-family: ${config['fonts']['lexend']['H4-22']["font-family"]};
    font-style: ${config['fonts']['lexend']['H4-22']["font-style"]};
    font-weight: ${config['fonts']['lexend']['H4-22']["font-weight"]};
    font-size: ${config['fonts']['lexend']['H4-22']["font-size"]};
    line-height: ${config['fonts']['lexend']['H4-22']["font-height"]};
    background: ${props => (props.background ? props.background : theme['Primary-Cyan0400'])};
    box-shadow: ${config['buttons']['floatingActionButton']['box-shadow']};
    border-radius: ${config['buttons']['floatingActionButton']['border-radius']};
    color: ${props => (props.color ? props.color : theme['Secondary-Gray0000'])};
    flex: ${config['buttons']['floatingActionButton']['flex']};
    order:  ${props => (props.order ? props.order : config['buttons']['floatingActionButton']['order'])};
    flex-grow: ${config['buttons']['floatingActionButton']['flex-grow']};
    z-index: ${config['buttons']['floatingActionButton']['z-index']};
    &:hover{
        background: ${theme['Primary-Cyan0300']};
    }
    &:focus{
        background: ${theme['Primary-Cyan0500']};
    }
`;
export const floatingActionButtonOfAdmin = styled('button', floatingActionButtonProps)`
    display: ${config['buttons']['floatingActionButton']['display']};
    flex-direction: ${config['buttons']['floatingActionButton']['flex-direction']};
    justify-content: ${config['buttons']['floatingActionButton']['justify-content']};
    align-items: ${config['buttons']['floatingActionButton']['align-items']};
    padding: ${config['buttons']['floatingActionButton']['padding']};
    gap: ${config['buttons']['floatingActionButton']['gap']};
    width: ${props => (props.width ? (props.width) : config['buttons']['floatingActionButton']['width'])};
    height: ${config['buttons']['floatingActionButton']['height']};
    position: ${config['buttons']['floatingActionButton']['position']};
    right: ${config['buttons']['floatingActionButton']['right']};
    bottom: ${config['buttons']['floatingActionButton']['bottom']};
    font-family: ${config['fonts']['lexend']['H4-22']["font-family"]};
    font-style: ${config['fonts']['lexend']['H4-22']["font-style"]};
    font-weight: ${config['fonts']['lexend']['H4-22']["font-weight"]};
    font-size: ${config['fonts']['lexend']['H4-22']["font-size"]};
    line-height: ${config['fonts']['lexend']['H4-22']["font-height"]};
    background: ${props => (props.background ? props.background : theme['Accent-Magenta0300'])};
    box-shadow: ${config['buttons']['floatingActionButton']['box-shadow']};
    border-radius: ${config['buttons']['floatingActionButton']['border-radius']};
    color: ${props => (props.color ? props.color : theme['Secondary-Gray0000'])};
    flex: ${config['buttons']['floatingActionButton']['flex']};
    order:  ${props => (props.order ? props.order : config['buttons']['floatingActionButton']['order'])};
    flex-grow: ${config['buttons']['floatingActionButton']['flex-grow']};
    z-index: ${config['buttons']['floatingActionButton']['z-index']};
    &:hover{
        background: ${theme['Accent-Magenta0200']};
    }
    &:focus{
        background: ${theme['Accent-Magenta0400']};
    }
`;
const navLinkButtonProps = {
    width: Number,
    padding: Number,
    gap: Number,
    height: Number,
    display: String
};
export const navLinkButton = styled("button", navLinkButtonProps)`
    display: ${props => (props.display ? props.display :config['buttons']['navLink']['display'])};
    flex-direction: ${config['buttons']['navLink']['flex-direction']};
    justify-content: ${config['buttons']['navLink']['justify-content']};
    align-items: ${config['buttons']['navLink']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['buttons']['navLink']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' : config['buttons']['navLink']['gap'])};
    width: ${config['buttons']['navLink']['width']};
    height: ${config['buttons']['navLink']['height']};
    background: ${theme['Secondary-Gray0000']};
    text-decoration:none;
    border-radius: ${config['buttons']['navLink']['border-radius']};
    font-family: ${config['fonts']['lexend']['Body-UX-14']["font-family"]};
    font-style: ${config['fonts']['lexend']['Body-UX-14']["font-style"]};
    font-weight: ${config['fonts']['lexend']['Body-UX-14']["font-weight"]};
    font-size: ${config['fonts']['lexend']['Body-UX-14']["font-size"]};
    line-height: ${config['fonts']['lexend']['Body-UX-14']["line-height"]};
    color: ${theme['Secondary-Gray0700']};
    flex: ${config['buttons']['navLink']['flex']};
    order: ${props => (props.order ? props.order : config['buttons']['navLink']['order'])};
    flex-grow: ${config['buttons']['navLink']['flex-grow']};
    z-index: ${config['buttons']['navLink']['z-index']};
    &:hover{
        background: ${theme['Secondary-Gray0200']};
    }
    &:focus{
        background: ${theme['Secondary-Gray0000']};
    }
`;
const secondaryHypherLinkButtonProps = {
    width: Number,
    padding: Number,
    gap: Number,
    height: String,
    display: String,
    color: String
};
export const secondaryHypherLinkButton = styled("button", secondaryHypherLinkButtonProps)`
    display: ${config['buttons']['secondaryHypherLinkButton']['display']};
    flex-direction: ${config['buttons']['secondaryHypherLinkButton']['flex-direction']};
    align-items: ${config['buttons']['secondaryHypherLinkButton']['align-items']};
    padding: ${config['buttons']['secondaryHypherLinkButton']['padding']};
    gap: ${config['buttons']['secondaryHypherLinkButton']['gap']};
    width: ${props => (props.width ? (props.width) + 'px' : config['buttons']['secondaryHypherLinkButton']['width'])};
    height: ${props => (props.height ? (props.height) : config['buttons']['secondaryHypherLinkButton']['height'])};
    position: ${config['buttons']['secondaryHypherLinkButton']['position']};
    font-family: ${config['fonts']['lexend']['H5-18']["font-family"]};
    font-style: ${config['fonts']['lexend']['H5-18']["font-style"]};
    font-weight: ${config['fonts']['lexend']['H5-18']["font-weight"]};
    font-size: ${config['fonts']['lexend']['H5-18']["font-size"]};
    line-height: ${config['fonts']['lexend']['H5-18']["font-height"]};
    background: ${props => (props.background ? props.background : 'transparent')};
    color: ${theme['Secondary-Gray0700']};
    flex: ${config['buttons']['secondaryHypherLinkButton']['flex']};
    order:  ${props => (props.order ? props.order : config['buttons']['secondaryHypherLinkButton']['order'])};
    flex-grow: ${config['buttons']['secondaryHypherLinkButton']['flex-grow']};
    &:hover{
        color: ${theme['Secondary-Gray0600']};
    }
    &:focus{
        color: ${theme['Secondary-Gray0800']};
    }
`;
const secondaryHypherLinkCaptionButtonProps = {
    width: Number,
    padding: Number,
    gap: Number,
    height: Number,
    display: String,
    color: String
};
export const secondaryHypherLinkCaptionButton = styled("a", secondaryHypherLinkCaptionButtonProps)`
    display: ${config['buttons']['secondaryHypherLinkCaptionButton']['display']};
    flex-direction: ${config['buttons']['secondaryHypherLinkCaptionButton']['flex-direction']};
    align-items: ${config['buttons']['secondaryHypherLinkCaptionButton']['align-items']};
    padding: ${config['buttons']['secondaryHypherLinkCaptionButton']['padding']};
    gap: ${config['buttons']['secondaryHypherLinkCaptionButton']['gap']};
    width: ${props => (props.width ? (props.width) + 'px' : config['buttons']['secondaryHypherLinkCaptionButton']['width'])};
    height: ${config['buttons']['secondaryHypherLinkCaptionButton']['height']};
    position: ${config['buttons']['secondaryHypherLinkCaptionButton']['position']};
    font-family: ${config['fonts']['lexend']['Caption-9']["font-family"]};
    font-style: ${config['fonts']['lexend']['Caption-9']["font-style"]};
    font-weight: ${config['fonts']['lexend']['Caption-9']["font-weight"]};
    font-size: ${config['fonts']['lexend']['Caption-9']["font-size"]};
    line-height: ${config['fonts']['lexend']['Caption-9']["font-height"]};
    text-transform: ${config['buttons']['secondaryHypherLinkCaptionButton']['text-transform']};
    background: ${props => (props.background ? props.background : 'transparent')};
    color: ${props => (props.color ? props.color : theme['Secondary-Gray0700'])};
    flex: ${config['buttons']['secondaryHypherLinkCaptionButton']['flex']};
    order:  ${props => (props.order ? props.order : config['buttons']['secondaryHypherLinkCaptionButton']['order'])};
    flex-grow: ${config['buttons']['secondaryHypherLinkCaptionButton']['flex-grow']};
    &:hover{
        color: ${theme['Secondary-Gray0600']};
    }
    &:focus{
        color: ${theme['Secondary-Gray0800']};
    }
`;
const primaryHypherLinkButtonProps = {
    width: Number,
    padding: Number,
    gap: Number,
    height: Number,
    display: String,
    color: String
};
export const primaryHypherLinkButton = styled("button", primaryHypherLinkButtonProps)`
    display: ${config['buttons']['primaryHypherLinkButton']['display']};
    flex-direction: ${config['buttons']['primaryHypherLinkButton']['flex-direction']};
    align-items: ${config['buttons']['primaryHypherLinkButton']['align-items']};
    padding: ${config['buttons']['primaryHypherLinkButton']['padding']};
    gap: ${config['buttons']['primaryHypherLinkButton']['gap']};
    width: ${props => (props.width ? (props.width) + 'px' : config['buttons']['primaryHypherLinkButton']['width'])};
    height: ${config['buttons']['primaryHypherLinkButton']['height']};
    position: ${config['buttons']['primaryHypherLinkButton']['position']};
    font-family: ${config['fonts']['lexend']['Body-14']["font-family"]};
    font-style: ${config['fonts']['lexend']['Body-14']["font-style"]};
    font-weight: ${config['fonts']['lexend']['Body-14']["font-weight"]};
    font-size: ${config['fonts']['lexend']['Body-14']["font-size"]};
    line-height: ${config['fonts']['lexend']['Body-14']["font-height"]};
    text-transform: ${config['buttons']['primaryHypherLinkButton']['text-transform']};
    background: ${props => (props.background ? props.background : 'transparent')};
    color: ${theme['Primary-Cyan0400']};
    flex: ${config['buttons']['primaryHypherLinkButton']['flex']};
    order:  ${props => (props.order ? props.order : config['buttons']['primaryHypherLinkButton']['order'])};
    flex-grow: ${config['buttons']['primaryHypherLinkButton']['flex-grow']};
    &:hover{
        color: ${theme['Primary-Cyan0300']};
    }
    &:focus{
        color: ${theme['Primary-Cyan0500']};
    }
`;

const breadCrumbsProps = {
    width: Number
};
export const breadCrumbLeftButton = styled("button", breadCrumbsProps)`
    display:flex 
    align-items: center
    float: ${config['buttons']['breadCrumbLinkDefault']['float']};
    background: ${theme['Secondary-Gray0200']};
    padding-right: ${config['buttons']['breadCrumbLinkDefault']['padding-right']};
    height: ${config['buttons']['breadCrumbLinkDefault']['height']};
    margin-right:36px 
    position: ${config['buttons']['breadCrumbLinkDefault']['position']};
    text-decoration: ${config['buttons']['breadCrumbLinkDefault']['text-decoration']};
    &:before {
        content: '';
        position: relative;
        left: 132px;
        top: 0px;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 19px solid ${theme['Primary-white']};
        border-bottom: 15px solid transparent;
    }
    &:after {
        content: '';
        position: relative;
        left: 29px;
        top: 0px;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 19px solid ${theme['Secondary-Gray0200']};
        border-bottom: 15px solid transparent;
    }
`;
export const breadCrumbDefaultButton = styled("button", breadCrumbsProps)`
    display: ${config['buttons']['breadCrumbLinkDefault']['display']};
    float: ${config['buttons']['breadCrumbLinkDefault']['float']};
    background: ${theme['Secondary-Gray0200']};
    padding-right: ${config['buttons']['breadCrumbLinkDefault']['padding-right']};
    height: ${config['buttons']['breadCrumbLinkDefault']['height']};
    margin-right: ${config['buttons']['breadCrumbLinkDefault']['margin-right']};
    position: ${config['buttons']['breadCrumbLinkDefault']['position']};
    text-decoration: ${config['buttons']['breadCrumbLinkDefault']['text-decoration']};
    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: -30px;
        border: 15px solid transparent;
        border-color: ${theme['Secondary-Gray0200']};
        border-left-color: transparent; }
    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: -30px;
        border: 15px solid transparent;
        border-left-color: ${theme['Secondary-Gray0200']}; }
`;