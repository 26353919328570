<template>
    <!--fix the styling the icons from atoms-->
    <c-icon v-if="iconType==='fill'" style="cursor:pointer">{{styledIcon}}</c-icon>
    <c-outlined-icon v-else-if="iconType==='outlined'" style="cursor:pointer">{{styledIcon}}</c-outlined-icon>
</template>
  
<script>
import {iconStyle, outlinedIconStyle} from '../atoms/icon_styles'
export default {
    props:{
        styledIcon: String,
        iconType: {
            type: String,
            default: 'fill'
        }
    },
    components:{
        'c-icon': iconStyle,
        'c-outlined-icon': outlinedIconStyle
    }
}
</script>