<template>
  <node-view-wrapper as="span" inline>
      <c-outer-math-div v-if="!viewMode">
        <c-formatting-toolbar-math-div><!--select the type of keyboard to display-->
            <c-button-icon-div @click.native="changeMathKeyboardFn('basic')"><c-style-icon iconType='outlined' styledIcon="calculate" ></c-style-icon></c-button-icon-div>
            <c-button-icon-div @click.native="changeMathKeyboardFn('symbols')"><c-style-icon iconType='outlined' styledIcon="function" ></c-style-icon></c-button-icon-div>
            <c-button-icon-div @click.native="changeMathKeyboardFn('calculus')"><c-style-icon iconType='outlined' styledIcon="Functions" ></c-style-icon></c-button-icon-div>
        </c-formatting-toolbar-math-div>
        <!-- input area-->
        <c-row-div style="width: 100%;" :gap='8' :background="colorTheme['Secondary-Gray0000']">
          <c-col-div :width="5" textalign="left">
            <c-math-keyboard>
              <c-keyboard-key v-for="(keysObj, idx) in mathKeyboardKeysList[selectedKeyboard]" :key="idx" @click.native="mathKeyPressedFn(keysObj)"><p v-html="'&\#' + keysObj['unicode'] + ';'"></p></c-keyboard-key>
            </c-math-keyboard>
          </c-col-div>
          <c-col-div :width="5" textalign="left">
            <c-basic-info-form-input width="100%">
                <c-textbox-label-field>
                    <c-body-ux-14 :color="colorTheme['Secondary-Gray0500']"> LaTex </c-body-ux-14>
                </c-textbox-label-field>
                <c-form-input-field v-model="rawFormula" @input.native="renderedFormula"></c-form-input-field>
            </c-basic-info-form-input>
            <c-basic-info-form-input width="100%">
                <c-textbox-label-field>
                    <c-body-ux-14 :color="colorTheme['Secondary-Gray0500']"> Preview </c-body-ux-14>
                </c-textbox-label-field>
                <c-math-preview-area class="mathRenderID" :id="'mathRenderID_' + comp_uuid" style="color: #93ABAB !important;">
                </c-math-preview-area>
            </c-basic-info-form-input>
            <c-math-keyboard-opr-btn>
                <c-btn-secondary @click.native="saveKatexFn">Done</c-btn-secondary>
                <c-btn-secondary @click.native="cancelEditFn">Cancel</c-btn-secondary>
                <c-btn-secondary @click.native="deleteNodeFn">Delete</c-btn-secondary>
                <!--<c-btn-primary >Done</c-btn-primary>--> <!-- Why do we need this ?-->
            </c-math-keyboard-opr-btn>
          </c-col-div>
        </c-row-div>
    </c-outer-math-div>
    <span v-else :id="'viewModeMathRenderID_' + comp_uuid" @click="viewMode = false"></span>
  </node-view-wrapper>
</template>

<script>
//https://tiptap.dev/guide/node-views/vue#update-node-attributes
//https://karlomikus.com/blog/create-latex-math-node-and-mark-for-tiptap-2
// mergeAttributes: https://tiptap.dev/guide/custom-extensions

// create unique uuids for multiple instances of same component: https://github.com/vuejs/vue/issues/5886#issuecomment-308647738

let comp_uuid = 0;

import axios from 'axios';
//import Vue from 'vue';
import AppConfig from '../../config.js';
import { NodeViewWrapper } from '@tiptap/vue-2' //nodeViewProps
import katex from 'katex';
import {outerMathDiv, mathKeyboardOprBtnDiv, mathPreviewArea, mathKeyboard, buttonIconDiv, formattingToolbarMathDiv} from '../atoms/create_challenge_div_styles';
import {formInputField, textBoxLabelField, basicInfoFormInputDiv} from '../atoms/create_course_div_style'
import {rowDiv, col} from '../atoms/div_styles'
import style_icons from '../molecules/style_icons.vue'
import keyboardKeyComp from '../molecules/keyboard_keys.vue'
import mathKeysList from '../assets/mathKeyboards'
import { body_UX_14 } from '../atoms/text_styles';
import { theme } from '../assets/theme';
//import buttonPrimary from '../molecules/button_primary.vue'
import buttonSecondary from '../molecules/button_secondary.vue'
//import mathKeyboard from '../assets/mathKeyboards'
export default {
  components: {
    'node-view-wrapper': NodeViewWrapper,
    //'c-top-inside-toolbar-div': topInsideToolbarDiv,
    //'c-input-entry-box-div': inputEntryBoxDiv,
    'c-formatting-toolbar-math-div': formattingToolbarMathDiv,
    'c-style-icon': style_icons,
    //'c-main-vertical-input-div': mainVerticalInputDiv,
    'c-button-icon-div': buttonIconDiv,
    'c-row-div': rowDiv,
    'c-col-div': col,
    'c-math-keyboard': mathKeyboard,
    'c-keyboard-key': keyboardKeyComp,
    'c-basic-info-form-input': basicInfoFormInputDiv,
    'c-textbox-label-field': textBoxLabelField,
    'c-form-input-field': formInputField,
    'c-math-preview-area': mathPreviewArea,
    'c-body-ux-14': body_UX_14,
    //'c-btn-primary': buttonPrimary,
    'c-btn-secondary': buttonSecondary,
    'c-math-keyboard-opr-btn': mathKeyboardOprBtnDiv,
    'c-outer-math-div': outerMathDiv
  },
  //props: nodeViewProps, //access node attrs using this.node.attrs.<name>
  props:{
    editor: {
      type: Object,
    },
    node: {
      type: Object,
      required: true,
    },
    // update attributes of the current node
    updateAttributes: {
      type: Function,
    },
    parent: {
      type: String,
      //required: true,
    },
  },
  data() {
    return {
      UI_baseURL: AppConfig.UI_Base_URL,
      auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
      chal_id: "",
      rawFormula: '', //this.node.attrs.uniqkey, //https://tiptap.dev/guide/node-views/vue#access-node-attributes
      prevRawFormula: '', // store prev state for undo redo purpose
      options: {
        throwOnError: false,
        strict: false,
        displayMode: true,
        maxSize: 300
      },
      mathKeyboardKeysList: mathKeysList,
      colorTheme:theme,
      selectedKeyboard: 'basic',
      viewMode: false,
      comp_uuid: comp_uuid // check beforeCreate for more info
    }
  },
  beforeCreate() { // https://github.com/vuejs/vue/issues/5886#issuecomment-308647738
      comp_uuid += 1 // increment 1 for each instance
      //this.comp_uuid = comp_uuid // this will not work as data is not created yet ?
  },
  async mounted() {
    //console.log("route in katex is", this.$route);
    //console.log("math keyboard is", mathKeyboard);
    this.chal_id = this.node.attrs.chal_id
    // in case value is null req api
    if(this.$store.state.files_manifest[this.node.attrs.uniqkey].value === null){
      this.rawFormula = await this.getEditorKeyContentFn(this.node.attrs.uniqkey)
    }
    else{
      this.rawFormula = this.$store.state.files_manifest[this.node.attrs.uniqkey].value
    }
  },
  watch: {
    async rawFormula(newVal, val) {
      if (newVal == val) {
        return;
      }
      console.log("katex formula updated for key", this.node.attrs.uniqkey);
      console.log("state of files manifest before update", this.$store.state.files_manifest);

      // Update our <katex> content attribute
      // and notify editor of the change

      // Add API call here to store Katex content
      //let contentUrl = newVal
      /*
      let storeEditorRespBody = {}
      storeEditorRespBody["editorType"] = "katex"
      storeEditorRespBody["actualKatexContent"] = newVal
      let storeApiResp = await axios.post(this.auth_api_Base_URL + "/v1_0/chalEditors/add/" + this.chal_id, storeEditorRespBody)
      console.log("storeApiResp is", storeApiResp)
      contentUrl = storeApiResp.resp["contentLink"]
      */
      //console.log(JSON.parse(JSON.stringify(this.$parent)))
      //console.log("--parent is", this.parent);
      //console.log("--editor is", this.editor);
      if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === 'add'){
        this.$store.commit('updateKeyValue', {key: this.node.attrs.uniqkey, value: newVal})
      }
      else if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === 'edit'){
        this.$store.commit('updateKeyValue', {key: this.node.attrs.uniqkey, value: newVal})
      }
      else if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === 'del'){
        console.log("not updating the files_manifest");
      }
      else if(this.$store.state.files_manifest[this.node.attrs.uniqkey].opr === ''){
        this.$store.commit('updateKeyValue', {key: this.node.attrs.uniqkey, value: newVal})
        this.$store.commit('updateKeyOpr', {key: this.node.attrs.uniqkey, opr: 'edit'})
      }
      console.log("state of files manifest after update", this.$store.state.files_manifest);
      /*this.updateAttributes({
        uniqkey: contentUrl,
      })*/
    }
  },
  methods: {
    sleep(ms) {
        // add some sleep using this function , can be used when we want to wait some time to let page load the html
        return new Promise(resolve => setTimeout(resolve, ms));
    },
    async saveKatexFn(){
      // enter in view mode and disable the edit mode
      this.viewMode = true
      this.prevRawFormula = this.rawFormula
      await this.sleep(1)
      if (!this.rawFormula) {
        return '';
      }
      console.log("id is", 'viewModeMathRenderID_' + this.comp_uuid, "view mode element", document.getElementById('viewModeMathRenderID_' + this.comp_uuid));
      document.getElementById('viewModeMathRenderID_' + this.comp_uuid).innerHTML = katex.renderToString(this.rawFormula, this.options)
    },
    async cancelEditFn(){
      this.rawFormula = this.prevRawFormula
      this.viewMode = true
      await this.sleep(1)
      if (!this.rawFormula) {
        return '';
      }
      console.log("id is", 'viewModeMathRenderID_' + this.comp_uuid, "view mode element", document.getElementById('viewModeMathRenderID_' + this.comp_uuid));
      document.getElementById('viewModeMathRenderID_' + this.comp_uuid).innerHTML = katex.renderToString(this.rawFormula, this.options)
    },
    async getEditorKeyContentFn(storedUniqKey){
      // request api if value of key is not in the stored manifest
      let storeApiResp = await axios.get(this.auth_api_Base_URL + "/v2_1/chalEditors/" + this.chal_id + "/?chal_editor=" + storedUniqKey)
      console.log("storeApiResp is", storeApiResp)
      return storeApiResp.data.actualContent
      // get rawFormula value from here, if apiResp return error means key not stored yet so new key and hence rawFormula will be just a empty string.
    },
    deleteNodeFn(){
      this.$store.commit('updateKeyOpr', {key: this.node.attrs.uniqkey, opr: 'del'})
      this.deleteNode() // tiptap default function
    },
    renderedFormula() {
      if (!this.rawFormula) {
        return '';
      }
      document.getElementById('mathRenderID_' + this.comp_uuid).innerHTML = katex.renderToString(this.rawFormula, this.options)
      //return ''
      //return katex.renderToString(this.rawFormula, this.options);
    },
    changeMathKeyboardFn(keyboard){
      //console.log("changeMathKeyboardFn is called with key = ", keyboard);
      this.selectedKeyboard = keyboard
    },
    mathKeyPressedFn(keysObj){
      console.log("key pressed is", keysObj);
      this.rawFormula = this.rawFormula + keysObj.opr
    }
  }
}
</script>
<style lang="css">
.katex-component {
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgb(234, 241, 245);
  margin: 5px 0;
}
.katex-component.is-selected {
  border-color: rgb(33, 104, 211);
}
.katex-component h3 {
  margin: 0;
}
.katex-component__title {
  display: flex;
  margin-bottom: 20px;
}
.katex-component__title a {
  margin-left: auto;
}
.katex-component__formula {
  margin-top: 10px;
  background: rgba(0,0,0,.1);
  padding: 10px;
}
.katex-component textarea {
  padding: 10px;
  font-size: 1.2rem;
}
.katex *{
  font-family: Merriweather Sans !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 140%;
  color: #424D4D;
  text-transform: none;
}

 .mathRenderID .katex *{
  font-family: Merriweather Sans !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 140%;
  color: #93ABAB !important;
  text-transform: none;
}
</style>