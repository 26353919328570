<template>
  <btn-navlink>
  <c-icon>{{prependIcon}}</c-icon>
  <slot>NavLink</slot>
  <c-icon>{{postpendIcon}}</c-icon>
  </btn-navlink>
</template>

<script>
import {navLinkButton} from '../atoms/button_styles'
import {iconStyle} from '../atoms/icon_styles'
export default {
    name: 'button-navlink',
    props:{
      prependIcon: String,
      postpendIcon: String
    },
    components:{
        'btn-navlink': navLinkButton,
        'c-icon': iconStyle
    }
}
</script>