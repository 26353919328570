<template>
    <c-tag :width="width">
        <c-icon v-if="prependIcon!==''">{{prependIcon}}</c-icon>
        <slot>primary tag</slot>
        <c-icon v-if="prependIcon!==''">{{postpendIcon}}</c-icon>
    </c-tag>
</template>
  
<script>
import { tagButton } from '../atoms/tag_pill_style';
import {iconStyle} from '../atoms/icon_styles'
export default {
    name: 'tagPill',
    props:{
        prependIcon: {
            type: String,
            default: ""
        },
        postpendIcon: {
            type: String,
            default: ""
        },
        width: {
            type: Number
        }
    },
    components:{
        'c-icon': iconStyle,
        'c-tag': tagButton,
    }
}
</script>