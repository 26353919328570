<template>
    <div class="my-attempts"  >
        <c-upcoming-course-title>
            <c-H4-22 :color="colorTheme['Secondary-Gray0700']">My protege attempts</c-H4-22>
            <c-input-checkbox type="checkbox" :checked="checkedStatus('myProtegeAttemptState', inputCheckedMyProtegeAttemptState)"  style="margin-top: 8px;"  @change.native="updateMyProteegeCheckedAttemptFn()">
                <c-body-ux-14>Checked</c-body-ux-14>
            </c-input-checkbox>
            <c-basic-info-form-input v-if="protegeSupportData.orgs.length>0" style="width: auto;height: 36px;margin-top: 10px;">
                <c-select-field style="cursor: pointer;" id="inputOrgName" postpendIcon="expand_more" v-model="inputOrgName" @click.native="selectedOrgAttemptsFn(inputOrgName)">
                    <c-optionField value="Select org" disabled>Select org</c-optionField>
                    <c-optionField :id="'inputOrgOption_' + org.id" :value="org.id" v-for="org in protegeSupportData.orgs"  :key="org">{{org.org_name}}</c-optionField>
                </c-select-field>
            </c-basic-info-form-input>
            <c-basic-info-form-input v-if="protegeSupportData.course_tutoring.length>0" style="width: auto;height: 36px;margin-top: 10px;">
                <c-select-field style="cursor: pointer;" id="inputCourseName" postpendIcon="expand_more" v-model="inputCourseName" @click.native="selectedCourseAttemptsFn(inputCourseName)">
                    <c-optionField value="Select course" disabled>Select course</c-optionField>
                    <c-optionField :id="'inputCourseOption_' + course.id" :value="course.id" v-for="course in protegeSupportData.course_tutoring" :key="course">{{course.title}}</c-optionField>
                </c-select-field>
            </c-basic-info-form-input>
            <!--<c-basic-info-form-input style="width: auto;justify-content: center;">
                <div>
                    <span style="border: 1px solid #93ABAB;background-color: #f8ffff;border-radius: 8px;cursor: pointer;padding: 4px;" @click="showOrgsListFn()">Select org</span>
                    <div v-if="showOrgsList" style="z-index: 2;text-align: left; position: relative;border: 1px solid #93ABAB;border-radius: 8px;background-color: #f8ffff;">
                        <p style="cursor: pointer;border: 1px solid #93ABAB;border-radius: 8px;" v-for="(org, index) in protegeSupportData.orgs"  @click="selectedOrgAttemptsFn(org.id)" :key="index">
                            {{ org.org_name }}
                        </p>
                    </div>
                </div>
            </c-basic-info-form-input>-->
            <!--<c-basic-info-form-input style="width: auto;justify-content: center;">
                <div>
                    <span style="border: 1px solid #93ABAB;background-color: #f8ffff;border-radius: 8px;cursor: pointer;padding: 4px;" @click="showCourseListFn()">Select course</span>
                    <div v-if="showCourseList" style="z-index: 2;text-align: left; position: relative;border: 1px solid #93ABAB;border-radius: 8px;background-color: #f8ffff;">
                        <p style="cursor: pointer;border: 1px solid #93ABAB;border-radius: 8px;" v-for="(course, index) in protegeSupportData.course_tutoring"  @click="selectedCourseAttemptsFn(course.id)" :key="index">
                            {{ course.title }}
                        </p>
                    </div>
                </div>
            </c-basic-info-form-input>-->
            <c-btn-secondary as="a" :href="viewMoreAttemptsFn()" id="inputViewAllMyAttempts">View more</c-btn-secondary>
        </c-upcoming-course-title>
        <c-row v-if="attemptsData.length === 0" textalign="left" text_transform="none">
            No attempts found
        </c-row>
        <c-upcoming-courses class="my-attempts-list" v-else>
            <template v-for="(attempt, index) in attemptsData" >
                <c-abhyas-course-card v-if="!((attempt.challenge_types === 'Flash card deck') || (attempt.challenge_types === 'Flash card'))" style="width: 330px;justify-content: space-between;"  :key="index" position="relative">
                    <c-row style="gap: 4px;flex-direction: column;align-items: flex-start;width: 100%;">
                        <c-row v-if="!(attempt.challenge_types === 'Free response question')" text_align="left" align_items="flex-start" text_transform="none" class="attemptStatement">
                            <!-- eslint-disable -->
                            <c-H4-22 :id="'chalQuestionStatement_' + (index + 1)" v-html="attempt.question_statement.textContent"></c-H4-22>
                        </c-row>
                        <div v-if="attempt.challenge_types === 'Free response question'" style="display:flex;overflow-x:scroll;scrollbar-width: none;-ms-overflow-style: none;width: 100%;">
                            <c-input-component :userRoleInSlot="'protege'" :canvasInViewMode="true" :uniqueCompKey="'frq_' + attempt.challenge_id" :roomIdProp="'chal_qs_' + attempt.challenge_id" :use_icanvas_editor="false" :default_editor_mode="'iCanvas'" :editor_width="'auto'" :editor_height="'100px'" :chal_id="attempt.challenge_id" :showToolbar="false" :key="reRenderQuestionStatementInputComp"></c-input-component>
                        </div>
                        <c-row text_align="left" align_items="flex-start" text_transform="none">
                            <c-overline-11>Challenge type : {{attempt.challenge_types}}</c-overline-11>
                        </c-row>
                        <c-row text_align="left" text_transform="none">
                            <c-overline-11 :color="colorTheme['Secondary-Gray0800']" style="width: 100%;display: flex;gap: 4px;">
                            <c-overline-11 :color="colorTheme['Secondary-Gray0800']" :font_weight="7" >Protege name : </c-overline-11>
                            {{ attempt.protege_user_name }}<br>
                            </c-overline-11>
                        </c-row>
                        <c-row text_align="left" text_transform="none">
                            <c-overline-11>Org name : {{attempt.org_name}}</c-overline-11>
                        </c-row>
                    </c-row>
                    <c-frame-16 style="width: auto;">
                        <c-btn-secondary as="a" :id="'inputViewBrowseProtegeAttemptDetails_' + index " :href="gotoViewAttemptLandingPageFn(attempt)">View details</c-btn-secondary>
                    </c-frame-16>
                </c-abhyas-course-card>
            </template>
        </c-upcoming-courses>
    </div>
</template>
    
<script>
import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22,OVERLINE_11, body_UX_14} from '../atoms/text_styles';
import { theme } from '../assets/theme';
import { frame_16, abhyasCourseCard, rowDiv, upcomingCourses, upcomingCourseTitle} from '../atoms/div_styles'
import buttonSecondary from '../molecules/button_secondary.vue'
import selectMenu from '../molecules/selectMenu.vue';
import { optionDiv } from '../atoms/div_styles';
import { basicInfoFormInputDiv} from '../atoms/create_course_div_style'
import inputCheckBox from '../molecules/inputCheckBox.vue';
import input_component from '../organisms/input_component.vue'
import katex from 'katex'
//import tag_pill from '../molecules/tag_pill.vue'

    export default {
        name: 'myProtegeAttempts',
        data() {
            return {
                colorTheme:theme,
                currentView: 'myProtegeAttempts', // Default view
                UI_base_url: AppConfig.UI_Base_URL,
                auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
                attemptsData:[],
                protegeSupportData:[],
                checkedAttemptType: '',
                selectedCourseAttempt:'',
                selectedOrgAttempt:'',
                showOrgsList:false,
                showCourseList:false,
                inputCheckedMyProtegeAttemptState: false,
                inputOrgName:"Select org",
                inputCourseName: "Select course"
            };
        },
        props:{
            protegeAttemptsListData:{
                type: Array
            },
            attemptsSupportData:{
                type: Array
            },
            inputMyProtegeAttemptsCheckedState:{
                type: Boolean
            }
        },
        components:{
            'c-frame-16':frame_16,
            'c-row': rowDiv,
            //'c-body-14': body_14,
            //'c-span-body-14': span_body_14,
            'c-overline-11': OVERLINE_11,
            'c-H4-22': H4_22,
            'c-abhyas-course-card': abhyasCourseCard,
            'c-btn-secondary': buttonSecondary,
            'c-upcoming-courses':upcomingCourses,
            'c-upcoming-course-title':upcomingCourseTitle,
            'c-select-field':selectMenu,
            'c-optionField':optionDiv,
            'c-basic-info-form-input': basicInfoFormInputDiv,
            //'c-textbox-label-field': textBoxLabelField,
            'c-body-ux-14': body_UX_14,
            'c-input-checkbox': inputCheckBox,
            'c-input-component' : input_component
        },
        async mounted(){
            console.log("protegeAttemptsListData", this.protegeAttemptsListData);
            this.attemptsData = this.protegeAttemptsListData
            this.protegeSupportData = this.attemptsSupportData
            this.inputCheckedMyProtegeAttemptState = this.inputMyProtegeAttemptsCheckedState
            if(this.attemptsData.length > 0){
                let updatedFilesManifest = {}
                for(let idx=0; idx<this.attemptsData.length; idx++){
                    for(let key in this.attemptsData[idx]['files_manifest']){
                        // Update the value and opr for each key
                        console.log("====key=== is", key);
                        
                        this.attemptsData[idx]['files_manifest'][key]['value'] = null
                        this.attemptsData[idx]['files_manifest'][key]['opr'] = ''
                    }
                    // Accumulate the updated files_manifest for all challengesData
                    updatedFilesManifest = {
                        ...updatedFilesManifest,
                        ...this.attemptsData[idx]['files_manifest']
                    }
                }
                // Commit the accumulated files_manifest to the store once
                this.$store.commit('updateFilesManifest', { files_manifest: updatedFilesManifest })
            }
            if(this.attemptsData.length > 0){
                for(let idx=0; idx<this.attemptsData.length; idx++){
                    if(!((this.attemptsData[idx].challenge_types === 'Free response question') || (this.attemptsData[idx].challenge_types === 'Flash card deck') || (this.attemptsData[idx].challenge_types === 'Flash card'))){
                        await this.sleep(1);
                        await this.preview('chalQuestionStatement_' + (idx+1), this.attemptsData[idx]['challenge_id'])
                    }
                }
            }
        },
        methods: {
            checkedStatus(filterType, tag){
                console.log("filterType, tag", filterType, tag);
                if(filterType === 'myProtegeAttemptState'){
                    if(this.inputCheckedMyProtegeAttemptState === true){
                        return true
                    }
                    else{
                        return false
                    }
                }
                else{
                    return false
                }
            },
            showOrgsListFn(){
                this.showOrgsList = !this.showOrgsList
            },
            showCourseListFn(){
                this.showCourseList = !this.showCourseList
            },
            viewMoreAttemptsFn(){
                //this.searchedTerm = this.$route.query.term
                let viewMoreAttemptsPageURL = this.UI_base_url + '/search-attempt?role=tutor'
                return viewMoreAttemptsPageURL
            },
            gotoViewAttemptLandingPageFn(attemptDetails){
                console.log("attemptInfo",attemptDetails);
                let attemptId = attemptDetails.attempt_id
                let viewAttemptLandingPageURL = this.UI_base_url + '/view-attempt/' + attemptId + "/?role=tutor"
                return viewAttemptLandingPageURL
            },
            updateMyProteegeCheckedAttemptFn(){
                this.inputCheckedMyProtegeAttemptState = !this.inputCheckedMyProtegeAttemptState
                console.log("inputCheckedState", this.inputCheckedMyProtegeAttemptState);
                let checkedRequestedData = {
                    type : 'checkedState',
                    value : this.inputCheckedMyProtegeAttemptState
                }
                this.$emit('callProtegeAttempts', checkedRequestedData)
            },
            /*checkedTypeFn(checkedValue){
                this.checkedAttemptType = checkedValue;
                console.log("checkedValue", checkedValue);
                let checkedRequestedData = {
                    type : 'checkedState',
                    value : checkedValue
                }
                this.$emit('callProtegeAttempts', checkedRequestedData)
            },*/
            selectedCourseAttemptsFn(selectedCourse){
                //this.selectedCourseAttempt = selectedCourse;
                console.log("selectedCourse", selectedCourse);
                let checkedRequestedData = {
                    type : 'selectedCourse',
                    value : selectedCourse
                }
                this.$emit('callProtegeAttempts', checkedRequestedData)
                //this.showCourseList = !this.showCourseList
            },
            selectedOrgAttemptsFn(selectedOrg){
                //this.selectedOrgAttempt = selectedOrg;
                console.log("selectedOrg", selectedOrg);
                let checkedRequestedData = {
                    type : 'selectedOrg',
                    value : selectedOrg
                }
                this.$emit('callProtegeAttempts', checkedRequestedData)
                //this.showOrgsList = !this.showOrgsList
            },
            async getEditorKeyContentFn(storedUniqKey, chalId){
                // request api if value of key is not in the stored manifest
                let storeApiResp = await axios.get(this.auth_api_Base_URL + "/v2_1/chalEditors/" + chalId + "/?chal_editor=" + storedUniqKey)
                console.log("storeApiResp is", storeApiResp)
                return storeApiResp.data.actualContent
                // get rawFormula value from here, if apiResp return error means key not stored yet so new key and hence rawFormula will be just a empty string.
            },
            sleep(ms) {
                // add some sleep using this function , can be used when we want to wait some time to let page load the html
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async preview(eleId, chal_id) {
                // function to convert the rich text to renderable form
                let previewDiv = document.getElementById(eleId)
                console.log("preview div is", previewDiv, eleId, chal_id);
                console.log("=====>", this.$store.state);
                
                setTimeout(() => { // either add sleep or use setTimeout, the reason is DOM take time to update the richTextContent html so the queryselector was empty if no sleep is added
                    previewDiv.querySelectorAll('katex').forEach(async(el) => {
                        console.log('katex is', el);
                        //https://katex.org/docs/api.html
                        let katex_uniq_key = el.getAttribute('uniqkey')
                        let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                        if(this.$store.state.files_manifest[katex_uniq_key].value === null){
                            valueToRender = await this.getEditorKeyContentFn(katex_uniq_key, chal_id)
                        }
                        else{
                            valueToRender = this.$store.state.files_manifest[katex_uniq_key].value
                        }
                        katex.render(valueToRender, el, { //replace the katex element to renderable form using katex API
                            throwOnError: false,
                            strict: false,
                            displayMode: true,
                            maxSize: 300
                        });
                    })
                    previewDiv.querySelectorAll('c-image').forEach(el => { // render images using img tag and src as the created urls
                        if (!el.hasChildNodes()) {
                            //check if child already exist because if user click on preview again muliplte times then the appendChild function
                            //written below will add same amount of image child
                            console.log("image tag is", el);
                            let node = document.createElement("img");
                            let img_uniq_key = el.getAttribute('uniqkey')
                            let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                            if(this.$store.state.files_manifest[img_uniq_key].value === null){
                                valueToRender = this.auth_api_Base_URL + "/v2_1/chalEditors/" + chal_id + "/?chal_editor=" + img_uniq_key//await this.getEditorKeyContentFn(img_uniq_key, 'image')
                            }
                            else{
                                valueToRender = this.$store.state.files_manifest[img_uniq_key].value
                            }
                            let createdImgUrl = valueToRender //URL.createObjectURL(this.$store.state.files_manifest[el.getAttribute('uniqkey')].value)
                            node.setAttribute('src', createdImgUrl)
                            node.style.width = '500px'
                            node.style.height = '500px'
                            el.appendChild(node)
                        }
                    })
                }, 1)
            }
        }
    };
</script>

<style>
.my-attempts {
    /* Add your styles here */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    gap: 8px;
    align-items: flex-start;
    border-radius: 8px;
    background: #F0FFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
.my-attempts-list{
    display: flex;
    flex-wrap: wrap;
}
.attemptStatement {
    word-break: keep-all;
    max-height: 100px;
    overflow-y: scroll;
    scrollbar-width: none;
}
</style>
    