import styled from "vue-styled-components";
import {theme} from '../assets/theme.js'
import config from '../assets/config.js'

export const iconStyle = styled.i.attrs(() => ({ className: "material-icons" }))`
background-color: green;
    font-size: ${config['fonts']['lexend']['Body-UX-14']["font-size"]};
`;

export const outlinedIconStyle = styled.i.attrs(() => ({ className: "material-symbols-outlined" }))`
    background-color: ${theme['Secondary-Gray0700']};
    font-size: ${config['fonts']['lexend']['Body-UX-14']["font-size"]};
`;