<template>
    <div class="my-attempts"  >
        <c-upcoming-course-title>
            <c-H4-22 :color="colorTheme['Secondary-Gray0700']">My ward attempts</c-H4-22>
            <c-input-checkbox style="margin-top: 8px;cursor: pointer;" type="checkbox" :checked="checkedStatus('myWardAttemptState', inputCheckedMyWardAttemptState)"  @change.native="updateMyWardCheckedAttemptFn()">
                <c-body-ux-14>Checked</c-body-ux-14>
            </c-input-checkbox>
            <c-basic-info-form-input v-if="wardSupportData.wards.length>0" style="width: auto;height: 36px;margin-top: 10px;">
                <c-select-field style="cursor: pointer;" id="inputWardName" postpendIcon="expand_more" v-model="inputWardName" @click.native="selectedWardAttemptsFn(inputWardName)">
                    <c-optionField value="Select ward" disabled>Select ward</c-optionField>
                    <c-optionField :id="'inputWardOption_' + ward.id" :value="ward.id" v-for="ward in wardSupportData.wards" :key="ward">{{ ward.user_name }}</c-optionField>
                </c-select-field>
            </c-basic-info-form-input>
            <!--<c-basic-info-form-input style="width: 100px;">
                <c-textbox-label-field>
                    <c-body-ux-14> Select ward attempts: </c-body-ux-14>
                </c-textbox-label-field>
                <div>
                    <span style="border: 1px solid #93ABAB;background-color: #f8ffff; border-radius: 8px;cursor: pointer;padding: 4px;" @click="showWardsListFn()">Select ward</span>
                    <div v-if="showWardsList" style="z-index: 2;text-align: left; position: relative;border: 1px solid #93ABAB;border-radius: 8px;background-color: #f8ffff;">
                        <p style="cursor: pointer;border: 1px solid #93ABAB;border-radius: 8px;" v-for="(ward, index) in wardSupportData.wards"  @click="selectedWardAttemptsFn(ward.id)" :key="index">
                            {{ ward.user_name }}
                        </p>
                    </div>
                </div>
            </c-basic-info-form-input>-->
            <c-btn-secondary as="a" :href="viewMoreAttemptsFn()" id="inputViewAllMyAttempts">View more</c-btn-secondary>
        </c-upcoming-course-title>
        <c-row v-if="attemptsData.length === 0" textalign="left" text_transform="none">
            No attempts found
        </c-row>
        <c-upcoming-courses v-else class="my-attempts-list" >
            <template v-for="(attempt, index) in attemptsData" >
                <c-abhyas-course-card v-if="!((attempt.challenge_types === 'Flash card deck') || (attempt.challenge_types === 'Flash card'))" style="width: 330px;justify-content: space-between;" :key="index" position="relative">
                    <c-row style="gap: 4px;flex-direction: column;align-items: flex-start;width: 100%;">
                        <c-row v-if="!(attempt.challenge_types === 'Free response question')" text_align="left" align_items="flex-start" text_transform="none" class="attemptStatement">
                            <!-- eslint-disable -->
                            <c-H4-22 :id="'chalQuestionStatement_' + (index + 1)" v-html="attempt.question_statement.textContent"></c-H4-22>
                        </c-row>
                        <div v-if="attempt.challenge_types === 'Free response question'" style="display:flex;overflow-x:scroll;scrollbar-width: none;-ms-overflow-style: none;width: 100%;">
                            <c-input-component :userRoleInSlot="'protege'" :canvasInViewMode="true" :uniqueCompKey="'frq_' + attempt.challenge_id" :roomIdProp="'chal_qs_' + attempt.challenge_id" :use_icanvas_editor="false" :default_editor_mode="'iCanvas'" :editor_width="'auto'" :editor_height="'100px'" :chal_id="attempt.challenge_id" :showToolbar="false" :key="reRenderQuestionStatementInputComp"></c-input-component>
                        </div>
                        <c-row text_align="left" align_items="flex-start" text_transform="none">
                            <c-overline-11>Challenge type : {{attempt.challenge_types}}</c-overline-11>
                        </c-row>
                        <c-row text_align="left" text_transform="none">
                            <c-overline-11 :color="colorTheme['Secondary-Gray0800']" style="width: 100%;display: flex;gap: 4px;">
                            <c-overline-11 :color="colorTheme['Secondary-Gray0800']" :font_weight="7" >User name : </c-overline-11>
                            {{ attempt.protege_user_name }}<br>
                            </c-overline-11>
                        </c-row>
                        <c-row text_align="left" text_transform="none">
                            <c-overline-11>Org name : {{attempt.org_name}}</c-overline-11>
                        </c-row>
                    </c-row>
                    <c-frame-16 style="width: auto;">
                        <c-btn-secondary as="a" :id="'inputViewBrowseAttemptDetails_' + (index) " :href="gotoViewAttemptLandingPageFn(attempt)">View details</c-btn-secondary>
                    </c-frame-16>
                </c-abhyas-course-card>
            </template>
        </c-upcoming-courses>
    </div>
</template>
    
<script>
import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22,OVERLINE_11, body_UX_14} from '../atoms/text_styles';
import { theme } from '../assets/theme';
import { frame_16, abhyasCourseCard, rowDiv, upcomingCourses, upcomingCourseTitle} from '../atoms/div_styles'
import buttonSecondary from '../molecules/button_secondary.vue'
import selectMenu from '../molecules/selectMenu.vue';
import { optionDiv } from '../atoms/div_styles';
import { basicInfoFormInputDiv} from '../atoms/create_course_div_style'
import inputCheckBox from '../molecules/inputCheckBox.vue';
import input_component from '../organisms/input_component.vue'
import katex from 'katex'
//import tag_pill from '../molecules/tag_pill.vue'

    export default {
        name: 'myWardsAttempts',
        data() {
            return {
                colorTheme:theme,
                currentView: 'myWardsAttempts', // Default view
                UI_base_url: AppConfig.UI_Base_URL,
                auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
                attemptsData:[],
                wardSupportData:[],
                checkedAttemptType: '',
                selectedWardAttempt: null,
                showWardsList:false,
                inputCheckedMyWardAttemptState:false,
                inputWardName:'Select ward'
            };
        },
        props:{
            wardAttemptsListData:{
                type: Array
            },
            attemptsSupportData:{
                type: Array
            },
            inputMyWardAttemptsCheckedState:{
                type: Array
            }
        },
        components:{
            'c-frame-16':frame_16,
            'c-row': rowDiv,
            //'c-body-14': body_14,
            //'c-span-body-14': span_body_14,
            'c-overline-11': OVERLINE_11,
            'c-H4-22': H4_22,
            'c-abhyas-course-card': abhyasCourseCard,
            'c-btn-secondary': buttonSecondary,
            'c-upcoming-courses':upcomingCourses,
            'c-upcoming-course-title':upcomingCourseTitle,
            'c-select-field':selectMenu,
            'c-optionField':optionDiv,
            'c-basic-info-form-input': basicInfoFormInputDiv,
            //'c-textbox-label-field': textBoxLabelField,
            'c-body-ux-14': body_UX_14,
            'c-input-checkbox': inputCheckBox,
            'c-input-component' : input_component
        },
        async mounted(){
            console.log("wardAttemptsListData", this.wardAttemptsListData);
            this.attemptsData = this.wardAttemptsListData
            this.wardSupportData = this.attemptsSupportData
            this.inputCheckedMyWardAttemptState = this.inputMyWardAttemptsCheckedState
            if(this.attemptsData.length > 0){
                let updatedFilesManifest = {}
                for(let idx=0; idx<this.attemptsData.length; idx++){
                    for(let key in this.attemptsData[idx]['files_manifest']){
                        // Update the value and opr for each key
                        console.log("====key=== is", key);
                        
                        this.attemptsData[idx]['files_manifest'][key]['value'] = null
                        this.attemptsData[idx]['files_manifest'][key]['opr'] = ''
                    }
                    // Accumulate the updated files_manifest for all challengesData
                    updatedFilesManifest = {
                        ...updatedFilesManifest,
                        ...this.attemptsData[idx]['files_manifest']
                    }
                }
                // Commit the accumulated files_manifest to the store once
                this.$store.commit('updateFilesManifest', { files_manifest: updatedFilesManifest })
            }
            if(this.attemptsData.length > 0){
                for(let idx=0; idx<this.attemptsData.length; idx++){
                    if(!((this.attemptsData[idx].challenge_types === 'Free response question') || (this.attemptsData[idx].challenge_types === 'Flash card deck') || (this.attemptsData[idx].challenge_types === 'Flash card'))){
                        await this.sleep(1);
                        await this.preview('chalQuestionStatement_' + (idx+1), this.attemptsData[idx]['challenge_id'])
                    }
                }
            }
        },
        methods: {
            checkedStatus(filterType, tag){
                console.log("filterType, tag", filterType, tag);
                if(filterType === 'myWardAttemptState'){
                    if(this.inputCheckedMyWardAttemptState === true){
                        return true
                    }
                    else{
                        return false
                    }
                }
                else{
                    return false
                }
            },
            showWardsListFn(){
                this.showWardsList = ! this.showWardsList
            },
            viewMoreAttemptsFn(){
                //this.searchedTerm = this.$route.query.term
                let viewMoreAttemptsPageURL = this.UI_base_url + '/search-attempt?role=sponsor'
                return viewMoreAttemptsPageURL
            },
            gotoViewAttemptLandingPageFn(attemptDetails){
                console.log("attemptInfo",attemptDetails);
                let attemptId = attemptDetails.attempt_id
                let viewAttemptLandingPageURL = this.UI_base_url + '/view-attempt/' + attemptId + "/?role=sponsor"
                return viewAttemptLandingPageURL
            },
            updateMyWardCheckedAttemptFn(){
                this.inputCheckedMyWardAttemptState = !this.inputCheckedMyWardAttemptState
                console.log("inputCheckedState", this.inputCheckedMyWardAttemptState);
                let checkedRequestedData = {
                    type : 'checkedState',
                    value : this.inputCheckedMyWardAttemptState
                }
                this.$emit('callWardAttempts', checkedRequestedData)
            },
            /*checkedTypeFn(checkedValue){
                this.checkedAttemptType = checkedValue;
                let checkedRequestedData = {
                    type : 'checkedState',
                    value : checkedValue
                }
                console.log("checkedValue", checkedRequestedData);
                this.$emit('callWardAttempts', checkedRequestedData)
            },*/
            selectedWardAttemptsFn(selectedWardValue){
                this.selectedWardAttempt = selectedWardValue;
                console.log("selectedWardValue", selectedWardValue);
                let checkedRequestedData = {
                    type : 'wardInfo',
                    value : selectedWardValue
                }
                console.log("selectedValue", checkedRequestedData);
                this.$emit('callWardAttempts', checkedRequestedData)
                this.showWardsList = !this.showWardsList
            },
            async getEditorKeyContentFn(storedUniqKey, chalId){
                // request api if value of key is not in the stored manifest
                let storeApiResp = await axios.get(this.auth_api_Base_URL + "/v2_1/chalEditors/" + chalId + "/?chal_editor=" + storedUniqKey)
                console.log("storeApiResp is", storeApiResp)
                return storeApiResp.data.actualContent
                // get rawFormula value from here, if apiResp return error means key not stored yet so new key and hence rawFormula will be just a empty string.
            },
            sleep(ms) {
                // add some sleep using this function , can be used when we want to wait some time to let page load the html
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async preview(eleId, chal_id) {
                // function to convert the rich text to renderable form
                let previewDiv = document.getElementById(eleId)
                console.log("preview div is", previewDiv, eleId, chal_id);
                console.log("=====>", this.$store.state);
                
                setTimeout(() => { // either add sleep or use setTimeout, the reason is DOM take time to update the richTextContent html so the queryselector was empty if no sleep is added
                    previewDiv.querySelectorAll('katex').forEach(async(el) => {
                        console.log('katex is', el);
                        //https://katex.org/docs/api.html
                        let katex_uniq_key = el.getAttribute('uniqkey')
                        let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                        if(this.$store.state.files_manifest[katex_uniq_key].value === null){
                            valueToRender = await this.getEditorKeyContentFn(katex_uniq_key, chal_id)
                        }
                        else{
                            valueToRender = this.$store.state.files_manifest[katex_uniq_key].value
                        }
                        katex.render(valueToRender, el, { //replace the katex element to renderable form using katex API
                            throwOnError: false,
                            strict: false,
                            displayMode: true,
                            maxSize: 300
                        });
                    })
                    previewDiv.querySelectorAll('c-image').forEach(el => { // render images using img tag and src as the created urls
                        if (!el.hasChildNodes()) {
                            //check if child already exist because if user click on preview again muliplte times then the appendChild function
                            //written below will add same amount of image child
                            console.log("image tag is", el);
                            let node = document.createElement("img");
                            let img_uniq_key = el.getAttribute('uniqkey')
                            let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                            if(this.$store.state.files_manifest[img_uniq_key].value === null){
                                valueToRender = this.auth_api_Base_URL + "/v2_1/chalEditors/" + chal_id + "/?chal_editor=" + img_uniq_key//await this.getEditorKeyContentFn(img_uniq_key, 'image')
                            }
                            else{
                                valueToRender = this.$store.state.files_manifest[img_uniq_key].value
                            }
                            let createdImgUrl = valueToRender //URL.createObjectURL(this.$store.state.files_manifest[el.getAttribute('uniqkey')].value)
                            node.setAttribute('src', createdImgUrl)
                            node.style.width = '500px'
                            node.style.height = '500px'
                            el.appendChild(node)
                        }
                    })
                }, 1)
            }
        }
    };
</script>

<style>
.my-attempts {
    /* Add your styles here */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    gap: 8px;
    align-items: flex-start;
    border-radius: 8px;
    background: #F0FFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
.my-attempts-list{
    display: flex;
    flex-wrap: wrap;
}
.attemptStatement {
    word-break: keep-all;
    height: 100px;
    overflow-y: scroll;
    scrollbar-width: none;
}
</style>
    