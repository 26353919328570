import {theme} from './theme'
export default{ //Abhyas config
    data() {
        return {
            colorTheme:theme
        }
    },
    'fonts':{
        'lexend':{
            'H1-43': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 800,
                "font-size": "43px",
                "line-height": "140%",
                "font-display": "swap"
                
            },
            'H4-22': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 700,
                "font-size": "22px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'H2-34': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 700,
                "font-size": "34px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'H5-18': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 700,
                "font-size": "18px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'Body-14': {
                "font-family": "Lexend",
                "font-style": "normal",
                "font-weight": 400,
                "font-size": "14px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'Body-UX-14': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 700,
                "font-size": "14px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'Caption-9': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 400,
                "font-size": "9px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'OVERLINE-11': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 700,
                "font-size": "11px",
                "line-height": "140%",
                "text-transform": "uppercase",
                "font-display": "swap"
            },
            'Bread-Crumb-Text': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 400,
                "font-size": "14px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'H3-27': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 700,
                "font-size": "27px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'logo-text': {
                "font-family": "Exo 2",
                "font-style": "italic",
                "font-weight": 900,
                "font-size": "40px",
                "line-height": "140%",
                "font-display": "swap"
            },
            'large-title': {
                "font-family": "Merriweather Sans",
                "font-style": "normal",
                "font-weight": 800,
                "font-size": "53px",
                "line-height": "140%",
                "text-transform": "none",
                "font-display": "swap"
                
            }
        },
    },
    'icons':{
        'ellipse':{
            'border-radius': '50',
            'border':'12px solid #009799',
            'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
            'display': 'inline-block',
            'padding': '0px 0px',
            'width': '150px', // in px
            'height': '150px', //in px
            'left': '12px',
            'top': '12px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        }
    },
    'tags':{
        'box-sizing':'border-box',
        'display': 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'padding': '4px 8px',//px,
        'gap':8,//px
        'width': 'auto', // in px
        'height': 32,
        'background': '#F8FFFF',
        'border':'1px solid #424D4D',
        'border-radius': '24px',
        'flex': 'none',
        'order': 0,
        'flex-grow': 0
    },
    'buttons':{
        'primary':{
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '8px',
            'display': 'inline-block',
            'padding': '8px 20px',
            'width': 'auto', 
            'height': '48px',
            'left': '16px',
            'top': '16px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'secondary':{
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'border-radius': '8px',
            'border': '1px solid ',
            'display': 'inline-block',
            'padding': '8px 20px',
            'width': 'auto',
            'height': '48px',
            'left': '16px',
            'top': '16px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'floatingActionButton':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '12px 18px',
            'gap': '16px',
            'width': 'auto', 
            'height': '48px',
            'position': 'fixed',
            'right': '75px',
            'bottom': '80px',
            'box-shadow': '4px 8px 8px rgba(0, 0, 0, 0.5);',
            'border-radius': '30px',
            'z-index': 6
        },
        'secondaryHypherLinkButton':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '4px',
            'width': 'auto', 
            'height': '41px',
        },
        'secondaryHypherLinkCaptionButton':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '8px',
            'gap': '4px',
            'width': 'auto', 
            'height': '41px',
            'text-transform':'none'
        },
        'primaryHypherLinkButton':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '4px',
            'width': 'auto', 
            'height': '36px',
            'text-transform':'none'
        },
        'navLink':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'flex-end',
            'padding': '12px 20px',
            'gap': '4px',
            'width': 'auto',
            'height': '48px',
            'border-radius': '24px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'z-index': 0
        },
        'breadCrumbLinkDefault':{
            'display': 'block',
            'float':'center',
            'padding-right': '10px',
            'height': '30px',
            'margin-right': '31px',
            'position': 'relative',
            'text-decoration': 'none',
        },
    },
    'divs':{
        'inputCompareDiv': {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'0px',
            'gap': '0px',
            'width':'100%',
            'height':'auto',
        },
        'snackbarDiv':{
            'visibility': 'hidden',
            'min-width': '250px',
            'margin-left': '-125px',
            'background-color': '#333',
            'color': '#fff',
            'text-align': 'center',
            'border-radius': '2px',
            'padding': '16px',
            'position': 'fixed',
            'z-index': 1,
            'left': '50%',
            'bottom': '30px',
            'font-size': '17px',
            'transition':'visibility 0.5s, opacity 0.5s linear'
        },
        'tooltipDiv':{
            'position': 'relative',
            'display': 'inline-block',
            'visibility': 'hidden',
            'width': '120px',
            'background-color': 'black',
            'color': '#fff',
            'text-align': 'center',
            'border-radius': "6px",
            'padding': '5px 0'
        },
        'tooltiptext':{
            'visibility': 'hidden',
            'width': '120px',
            'background-color': 'black',
            'color': '#fff',
            'text-align': 'center',
            'border-radius': "6px",
            'padding': '5px 0',
            'position': 'absolute',
            'z-index': 1,
            'top':'-5px',
            'right': '105%',
            'bottom': '100%',
            'left': '50%',
            'margin-left':'-60px',
            'opacity': 0,
            'transition': 'opacity 1s'
        },
        'calendarTopBar':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'space-between',
            'align-self': 'stretch',
            'padding':'8px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 0,
            'flex-grow':0,
        },
        'calendarFrameButtons': {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'justify-content': 'flex-end',
            'padding':'10px',
            'gap': '10px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 1,
            'flex-grow':0,
        },
        'mtfOptionOuterDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'16px',
            'gap': '16px',
            'width':'auto',
            'height':'auto',
            'border-radius': '8px',
            'border': '1px solid',
            'flex':'none',
            'order': 0,
            'flex-grow':0
        },
        'cellColumnDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding':'0px',
            'gap': '16px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 0,
            'flex-grow':0
        },
        'challengeQuestionStatementDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding':'0px',
            'gap': '4px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'challengeDetailsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '16px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'challengeCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding':'0px',
            'gap': '4px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'navBarDesktop': {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'justify-content': 'space-between',
            'width': '100%', 
            'height': 'auto'
        },
        'navBarMobile': {
            'display': 'none',
            'flex-direction': 'row',
            'align-items': 'center',
            'gap': '8px',
            'justify-content': 'space-between',
            'width': '100%', 
            'height': 'auto'
        },
        'mcqOptionsListDiv': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'align-self': 'stretch'
        },
        'challengeTitleDiv': {
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'align-self': 'stretch',
            'padding': '12px',
            'gap': '0px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'border': '0px solid'
        },
        'qualificationSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'languageSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'locationSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'align-self': 'stretch',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'personalInfoSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'align-self': 'stretch',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'timeZoneSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'contentDiv': {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'headerDiv': {
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '0px',
            'width': 'auto', 
            'height': '15px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'userInfoDiv': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'align-self': 'stretch',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'userDetailSectionDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'align-self': 'stretch',
            'padding': '24px',
            'gap': '24px',
            'width': '100%', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'avatarSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '10px',
            'gap': '10px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'nameSection': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'profilePageDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '0px',
            'width': '100%', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'viewChallengePageContentDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',
            'width': 'auto', 
            'height': 'auto',
            'overflow-y': 'scroll',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0,
        },
        'challengeFrame':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'challengesListDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'exploreChallengesDiv': {
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': '100%', 
            'height': 'auto', 
            'flex':'none',
            'order': 3,
            'flex-grow':0
        },
        'challengesSummaryCardDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto', 
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'challengesSummaryCardLeftDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': '20%', 
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow':1
        },
        'challengesSummaryCardRightDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-end',
            'padding': '8px',
            'gap': '8px',
            'width': '225px',
            'height': 'auto',
            'flex':'none',
            'order': 1,
            'flex-grow':0
        },
        'frame_50_div':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '0px',
            'gap':'8px',
            'width': 'auto',
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'chatStatusDiv':{
            'box-sizing': 'border-box',
            'width': '12px',
            'height': '12px',
            'border': '1px solid',
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'loaderDiv':{
            'width': '50px',
            'height': '50px',
            'border': '8px solid',
            'border-radius' : '50%',
            'border-top': '8px solid',
            'border-right': '8px solid',
            'border-bottom': '8px solid',
            '-webkit-animation': 'spin 2s linear infinite',
            'animation': 'spin 2s linear infinite'
        },
        'inputDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap':'4px',
            'width': 'auto',
            'height': '100%',
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'navigationButtons':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '0px',
            'gap': '8px',
            'width': '100%',
            'height': 'auto',
            'flex':'none',
            'order': 1,
            'flex-grow': 0
        },
        'chalTitleDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '12px',
            'width': '100%',
            'height': 'auto',
            'border-style': 'solid',
            'border-width': '1px 1px 1px 1px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)'
        },
        'navigationDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'width': '100%',
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow': 0,
            'align-self': 'stretch'
        },
        'sessionLinkDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow': 1
        },
        'answerDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap':'8px',
            'width': '100%',
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'answerFrameDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap':'8px',
            'width': '100%',
            'height': 'auto',
            'flex':'none',
            'order': 1,
            'flex-grow': 0
        },
        'questionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap':'4px',
            'width': '100%',
            'height': 'auto',
            'flex':'none',
            'order': 1,
            'flex-grow': 0
        },
        'questionFrameDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap':'8px',
            'width': '100%',
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'worksheetChallengeDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '16px',
            'gap':'16px',
            'width': '100%',
            'height': '100%',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0,
            'z-index': 1
        },
        'challengeListDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap':'8px',
            'width': '100%',
            'height': '100%',
            'border': '1px solid ',
            'border-radius': '8px',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
            'z-index': 1
        },
        'listItemDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 20px',
            'gap':'12px',
            'width': '100%',
            'height': 'auto',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'selectedItemsOuterDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto',
            'height': 'auto',
        },
        'multiSelectOptionOuterDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'flex-end',
            'align-items': 'flex-start',
            'padding': '0px',
            'width': 'auto',
            'height': 'auto',
            'border': '1px solid ',
            'border-radius': '0px 0px 0px 0px',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'multiSelectDropdownOptionItemsDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto',
            'height': '40px',
            'border-top': '1px solid',
            'border-bottom': '1px solid',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'statusSection':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'border': '1px solid ',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'descriptionDiv':{
            'display': '-webkit-box',
            '-webkit-line-clamp': '5',
            '-webkit-box-orient': 'vertical',
            'overflow': 'none',
            'align-items': 'center',
            'text-align': 'center',
            'text-transform': 'uppercase',
            'padding': '0px',
            'gap': '0px',
            'width': 'auto', 
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'courseListDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',
            'gap': '16px',
            'width': '100%', 
            'height': 'auto', 
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'aboutClassCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': "auto",
            'height': "auto",
            'box-shadow': "0px 0px 8px rgba(0, 0, 0, 0.25)",
            'border-radius':"8px",
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'aboutCourseSessionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': "100%",
            'height': "auto",
            //'overflow-y': "scroll",
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'cardCTADiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-end',
            'padding': '8px',
            'gap': '8px',
            'width': "auto",
            'height': "auto",
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'aboutTutorAndOrgDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': "100%",
            'height': "auto",
            'flex': 'none',
            'order': 2,
            'flex-grow': 0
        },
        'tutorAndOrgDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '16px',
            'width': "100%",
            'height': "auto",
            'overflow-x':'scroll',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'oneSecondaryButtonCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': "auto",
            'height': "auto",
            'box-shadow': "0px 0px 8px rgba(0, 0, 0, 0.25)",
            'border-radius':"8px",
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'aboutTutorCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': "492px",
            'height': "auto",
            'box-shadow': "0px 0px 8px rgba(0, 0, 0, 0.25)",
            'border-radius':"8px",
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'aboutCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': "auto",
            'height': "auto",
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'actionButtonsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '8px',
            'gap': '16px',
            'width': "auto",
            'height': "auto",
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'registrationBoxDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '16px',
            'gap': '8px',
            'width': "368px",
            'height': "auto",
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25) ',
            'border-radius': '8px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
        },
        'inputFieldViewDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': "100%",
            'height': "350px",
            'overflow': 'scroll',
            'border': '1px solid ',
            'border-radius': '8px',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 1,
        },
        'keyboardKeyBtn':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '2px 4px',
            'gap': '4px',
            'width': '40px',
            'height': '40px',
            'border': '1px solid ',
            'box-shadow': '4px 8px 8px rgba(0, 0, 0, 0.5)',
            'border-radius': '4px'
        },
        'nameDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'profileModal':{
            'box-sizing': 'border-box',
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'box-shadow': '4px 4px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px'
        },
        'mathKeyboardOprBtnDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'flex-wrap': 'wrap',
            'justify-content': 'flex-end',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 4,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'mathPreviewAreaDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 16px 101px',
            'gap': '8px',
            'width': '100%',
            'height': 'auto',
            'border': '1px solid ',
            'border-radius': '8px'
        },
        'mathKeyboardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'flex-wrap': 'wrap',
            'align-items': 'flex-start',
            'padding':'16px',
            'gap': '8px',
            'width':'100%',
            'height':'298px',
            'border': '1px solid ',
        },
        'contentBadgeDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'4px 8px',
            'gap': '8px',
            'width':'auto',
            'height':'28px',
            'border-radius': '24px',
        },
        'contentTextDiv':{
            'box-sizing': 'border-box',
            'display': 'inline-block',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'16px 8px 8px',
            'gap': '8px',
            'width':'866px',
            'height':'auto',
            'border-radius': '8px',
        },
        'contentBlockDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'stretch',
            'padding':'0px',
            'gap': '8px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 1,
            'flex-grow':0
        },
        'HintDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-end',
            'padding':'8px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'formSectionChallengeHintDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding':'16px',
            'gap': '8px',
            'width':'auto',
            'height':'auto',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex':'none',
            'order': 4,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'adminActionsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'0px',
            'gap': '8px',
            'width':'auto',
            'height':'auto',
            'flex':'none',
            'order': 1,
            'flex-grow':0
        },
        'keyboardShortCutDiv':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding':'4px 16px',
            'gap': '4px',
            'width':'auto',
            'height':'auto',
            'box-shadow': '4px 4px 0px rgba(0, 0, 0, 0.5)',
            'border-radius': '4px',
            'flex':'none',
            'order': 0,
            'flex-grow':0
        },
        'MCQOptionDiv':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'16px',
            'gap': '8px',
            'width':'auto',
            'height':'auto',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'flex-grow':1,
        },
        'adminMCQOptionsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'MCQOptionsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto', 
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex':'none',
            'order': 3,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'toolbarWhiteBarDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '8px 4px',
            'gap': '12px',
            'width': 'auto', 
            'height': 'auto', 
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'flex-grow':0
        },
        'outerMathDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'width': 'auto', 
            'height': 'auto', 
        },
        'formattingToolbarMathDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '4px 276px 4px 24px',
            'gap': '12px',
            'width': 'auto', 
            'height': 'auto', 
            'border-radius': '8px 8px 0px 0px',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'formattingToolbarDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '4px 222px 4px 24px',
            'gap': '12px',
            'width': 'auto', 
            'height': 'auto', 
            'border-radius': '8px 8px 0px 0px',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'buttonIconDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': '40px', 
            'height': '40px', 
            'border-radius': '8px',
            'flex':'none',
            'order': 1,
            'flex-grow':0
        },
        'toolbarGrabHandleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'flex-start',
            'padding': '4px',
            'gap': '4px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'flex-grow':0
        },
        'toolbarWindowControlDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 4px',
            'gap': '12px',
            'width': 'auto', 
            'height': 'auto',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px', 
            'flex':'none',
            'order': 1,
            'flex-grow':0
        },
        'toolbarUndoRedoDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 4px',
            'gap': '12px',
            'width': 'auto', 
            'height': 'auto',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px', 
            'flex':'none',
            'order': 0,
            'flex-grow':0
        },
        'topOutsideToolbarDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'flex-end',
            'padding': '0px',
            'gap': '348px',
            'width': 'auto', 
            'height': '56px', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'topInsideToolbarDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            'align-items': 'flex-start',
            'padding': '0px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'inputEntryBoxDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',
            'gap': '0px',
            'width': 'auto', 
            'height': 'auto', 
            'border-radius': '8px',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':1
        },
        'previewBoxDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',
            'gap': '0px',
            'width': 'auto', 
            'height': '400px', 
            'overflow-y': 'scroll',
            'border-radius': '8px',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':1
        },
        'mainVerticalInputDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-end',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':1
        },
        'leftOutsideToolbarDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '0px',
            'width': '48px', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'inputFieldMarkerDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto', 
            'height': '400px', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'MCQSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': '100%', 
            'height': 'auto', 
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex':'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'checkBoxGroupInstructionsDiv':{
            'display': 'flex',
            'align-items': 'flex-end',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'formSectionChallengeTypeDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto', 
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'selectItemDiv':{
            'display': 'flex',
            'align-items': 'flex-end',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'inputConceptsSection':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'inputDropDownSection':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 1,
        },
        'formDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'basicInfoDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '16px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'formSectionChallengeBasicInfoDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'waitingForApprovalSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'waitingForApprovalSectionTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'myDraftsTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'myDraftsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'badgeCard':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'position': 'absolute',
            'width': 'auto', 
            'height': '18px',
            'flex': 'none',
            'order': 5,
            'flex-grow': 0,
            'z-index': 5,
            'text-align': 'center',
            'text-transform': 'uppercase'
        },
        'profileDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '4px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'border-radius': '24px',
            'flex': 'none',
            'order': 2,
            'flex-grow': 0,
        },
        'menuModal':{
            'box-sizing': 'border-box',
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'box-shadow': '4px 4px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px'
        },
        'navBarDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'flex-start',
            'padding': '8px',
            'isolation': 'isolate',
            'width': 'auto', 
            'height': 'auto',
            'border-radius': '28.5px',
        },
        'searchBoxDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': '56px',
            'border-radius': '8px',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0,
        },
        'inputSearchBox':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-end',
            'padding':'8px 16px',
            'gap': '8px',
            'width':'362px',
            'height':'40px',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':1,
        },
        'iconAndTextDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-end',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto', 
            'height': '24px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
        },
        'exploreButtonDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '0px 20px',
            'gap': '4px',
            'width': 'auto', 
            'height': '48px',
            'border-radius': '24px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
        },
        'signInButtonsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-end',
            'align-items': 'center',
            'padding': '0px',
            'gap': '24px',
            'width': 'auto', 
            'height': '64px',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'searchBar':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px 16px',
            'width': '720px', 
            'height': '56px',
            'border-radius': '28.5px',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'logoDiv':{
            'display': 'flex',
            'width': '220px', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'topNavBarDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'flex-end',
            'padding': '8px 26px',
            'gap': '25px',
            'width': '100%', 
            'height': 'auto',
            'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
            'position': 'fixed',
            'z-index': 5,
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'courseCard':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': '372px', 
            'height': 'auto',
            'overflow-x': 'scroll',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius':'8px'
        },
        'preReadingCardsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto',
            'overflow-x': 'scroll',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'preReadingDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'courseSessionsListDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'sessionsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': '-webkit-fill-available', 
            'height': 'auto',
            'flex': 'none',
            'order': 3,
            'flex-grow': 0
        },
        'aboutSlotCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'aboutSessionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': "auto",
            'height': "auto",
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'sessionDetailsRightRailDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '24px 8px',
            'gap': '8px',
            'width': '400px', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'sessionDetailsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px 0px 195px',
            'gap': '27px',
            'width': '20%', 
            'height': 'auto',
            //'overflow-y': 'scroll',
            'flex': 'none',
            'order': 0,
            'flex-grow': 1
        },
        'sessionPageContentDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',
            'width': 'auto', 
            'height': 'auto',
            'overflow-y': 'scroll',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0,
        },
        'breadCrumbs':{
            'list-style-type': 'none',
            'padding': '0'
        },
        'breadCrumbText':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '4px 4px 4px 8px',
            'gap': '8px',
            'width': 'auto', 
            'height': '28px',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'background': '#D2E7E7'
        },
        'breadCrumbsList':{
            'display': 'block',
            'margin': '0px',
            'color':'#333',
            'font-size':'unset',
            'height':'24px'
        },
        'anchorTag':{
            'color': 'white',
            'text-decoration': 'none', 
            'padding': '4px 4px 4px 18px',
            'background': 'brown', /* fallback color */
            'position': 'relative',
            'display': 'block',
            'float': 'left'
        },
        'basicInfoSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap':'8px',
            'width': '630px', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px'
        },
        'createSessionFormSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '12px',
            'gap':'12px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'createSessionLeftContentDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px 0px 195px',
            'gap':'27px',
            'width': 'auto', 
            'height': 'auto',
            'overflow-y': 'scroll',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 1
        },
        'createSessionRightContentDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '24px 8px',
            'gap':'8px',
            'width': '400px', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'createSessionContentDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',
            'width': 'auto', // in px
            'height': 'auto',
            'overflow-y': "scroll"
        },
        'listItem':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '8px 20px',
            'gap':'20px',
            'width': '100%', 
            'height': '48px',
            'border-radius':'8px',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'list':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap':8,//px
            'width': 'auto', // in px
            'height': 'auto',
            'background': '#F8FFFF',
            'border':'1px solid #D2E7E7',
            'box-shadow': '4px 4px 8px rgba(0, 0, 0, 0.25)',
            'border-radius':'8px',
        },
        'tagRowDiv':{
            //frame_22
            'display': 'flex',
            'flex-wrap': 'wrap',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'selectDiv':{
            'background': '#F8FFFF',
            'width': 'auto', 
            'height': '48px',
            'border': '1px solid #6D8080;',
            'border-radius': '8px',
            'text-align': 'center',
            'font-style': 'normal',
            'font-weight': 700,
            'font-size': '22px',
            'line-height': '140%',
            
        },
        'optionDiv':{
            'background': '#F8FFFF',
            'width': 'auto', 
            'height': '48px',
            'border': '1px solid #6D8080;',
            'border-radius': '8px',
            'text-align': 'center',
            'font-style': 'normal',
            'font-weight': 700,
            'font-size': '22px',
            'line-height': '140%',
        },
        'dialogBox':{
            'position': 'fixed',
            'display': 'none',
            'z-index': 1,
            'left': '0',
            'top': '0',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '24px',
            'padding-top': '100px', /* Location of the box */
            'gap': 24,//px;
            'width': '100%', 
            'height': '100%',
            'background-color': 'rgb(0,0,0)', /* Fallback color */
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'overlayBox':{
            'background-color': '#fefefe',
            'margin': 'auto',
            'padding': '20px',
            'gap': '16px',
            'border': '1px solid #888',
            'width': '50%'
        },
        'searchBarDiv':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 16px',//px,
            'gap':8,//px
            'width': 'auto', // in px
            'height': 'auto',
            'background': '#F8FFFF',
            'border':'1px solid #93ABAB',
            'border-radius': '8px',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'tagSelectedDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'tagSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 3,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'chalAboutDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',//px,
            'gap': 8,//px;
            'width': '400px', 
            'height': 'auto',
            'background': '#F8FFFF',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'challengePartContentAndAboutDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': '-webkit-fill-available', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'challengeInfoDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'background': '#F0FFFF',
            'width': '-webkit-fill-available', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'viewChallengeHeaderDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'justify-content': 'space-between',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': '-webkit-fill-available', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'challengeFrameDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': 16,//px;
            'width': 'auto', 
            'height': 'auto',
            'left': '0px',
            'top': '80px'
        },
        'commandPaletteDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': 8,//px;
            'width': '400px', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'previewFieldDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '24px',
            'gap': 24,//px;
            'width': 'auto', 
            'height': '400px',
            'background': '#F8FFFF',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'inputPartFieldDiv':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 16px 101px',
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'background': '#F8FFFF',
            'border-radius': '8px',
            'border': '1px solid #93ABAB',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 1
        },
        'inputFieldDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': 24,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'inputAndPreviewSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '24px',
            'gap': 24,//px;
            'width': 'auto', 
            'height': 'auto',
            'background':'#D2E7E7;',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 1
        },
        'editorBodySectionDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 1
        },
        'editorHeaderDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'viewChalDetailsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'stretch',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'editorSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'position':'absolute',
            'width': 'auto', 
            'height': 'auto',
        },
        'challengeDiv':{
            //'background':colorTheme['Primary-Cyan0400'],
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',//px,
            'gap': 16,//px;
            'position': 'absolute',
            'width': 'auto', 
            'height': 'auto',
            'left':'0px',//px
            'top': '80px',//px
        },
        'editChallengeHeaderDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'justify-content': 'space-between',
            'padding': '8px',//px,
            'gap': '8px',//px;
            'width': '-webkit-fill-available', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'editChallengeTopic':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px 117px 8px 8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'challengeOperationsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',//px,
            'gap': '24px',//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'challengePartsDiv':{
            'background':'#F0FFFF',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'challengePartsContentAndSettingDiv':{
            'background':'#F0FFFF',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 1
        },
        'chalPartsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'baseline',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': 'auto', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'chalContentDiv':{
            'background': '#F8FFFF',
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',//px,
            'gap': 8,//px;
            'text-align':'left',
            'width': 'min-content', 
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 1
        },
        'operationsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center',
            'padding': '8px',//px,
            'gap': 8,//px;
            'width': '120px', 
            'height': '48px',
            //'top':8,//px
            //'right':49,//px
            'flex': 'none',
            'order': 1,
            'flex-grow': 0
        },
        'selectPartDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-end',
            'padding': '4px',//px,
            'gap': 8,//px;
            'width': '34px', 
            'height': '32px',
            //'top':8,//px
            //'right':49,//px
            //'bottom':92,//px
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'howItWorkSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '24px 12px',
            'gap': '12px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'howItWorksDiv':{
            'border-radius': '8px',
            'box-shadow': '0px 4px 4px rgba(0, 0, 0, 0.25)',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '28px 12px',
            'gap': '28px',
            'margin-left':'10%',
            'margin-right':'10%',
            'width': '-webkit-fill-available',
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'flex-grow':0,
        },
        'howItWorksTitleDiv':{
            'display': 'flex',
            'justify-content':'center',
            'align-items': 'center',
            'text-align': 'center',
            'width': 'auto', //px;
            'height': 'auto', //px;
            'flex':'none',//px
            'order': 0,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'howItWorksStepsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-evenly',
            'align-items': 'center',
            'padding': '12px',
            'gap': '24px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'footerSectionDiv':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'flex-start',
            'padding': '24px 48px',
            'gap': '96px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 4,
            'align-self': 'stretch',
            'flex-wrap': 'wrap',
            'flex-grow': 0
        },
        'footerLinkGroupDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'flex-grow': 0
        },
        'rowDiv':{
            'display': 'flex',
            'align-items': 'center',
            'text-align': 'center',
            'text-transform': 'uppercase',
            'padding': '0px',
            'gap': '0px',
            'width': 'auto', 
            'height': 'auto',
            'word-break': 'break-all',
            'flex':'none',
            'order': 0,
            'flex-grow': 0,
            'overflow': 'none'
        },
        'boxDiv':{
            'box-sizing': 'border-box',
            'width': '24px',
            'height': '24px',
            'border-radius': '8px',
            'flex': 'none',
            'order': 0,
            'z-index': 0
        },
        'checkBox':{
            'unselectedCheckBox':{
                'box-sizing': 'border-box',
                'width': '24px',
                'height': '24px',
                'border-radius': '8px',
                'flex': 'none',
                'order': 0,
                'flex-grow': 0,
            },
            'selectedCheckBox':{
                'box-sizing': 'border-box',
                'width': 'auto',
                'height': '24px',
                'color': 'white',
                'background': '#00B0B3',
                'border': '1px solid #00B0B3',
                'border-radius': '8px',
                'flex': 'none',
                'order': 0,
                'flex-grow': 0,
                'z-index': 0
            },
            'disabledCheckBox':{
                'box-sizing': 'border-box',
                'width': '24px',
                'height': '24px',
                'background': '#F8FFFF',
                'border': '1px solid #93ABAB',
                'border-radius': '8px',
                'flex': 'none',
                'order': 0,
                'flex-grow': 0,
            },
            'intermediateCheckBox':{
                'box-sizing': 'border-box',
                'width': '24px',
                'height': '24px',
                'background': '#00B0B3',
                'border': '1px solid #00B0B3',
                'border-radius': '8px',
                'flex': 'none',
                'order': 0,
                'flex-grow': 0,
                'z-index': 0
            }
        },
        'card':{
            'border-radius': 8,
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'align-self': 'stretch',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0,
            'height':100
        },
        'sessionSummaryCard':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto', 
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'sessionSummaryCardLeft':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': '20%', 
            'height': 'auto',
            'flex':'none',
            'order': 0,
            'flex-grow':1
        },
        'sessionSummaryCardRight':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-end',
            'padding': '8px',
            'gap': '8px',
            'width': '225px',
            'height': 'auto',
            'flex':'none',
            'order': 1,
            'flex-grow':0
        },
        'topSessionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'stretch',
            'padding': 8,//px,
            'gap': 8,//px;
            'width': 'auto', //px;
            'height': 191, //px;
            'flex':'none',//px
            'order': 1,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'myCourseSectionDiv':{
            'background': '#FFFFFF',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',//px,
            'gap': 16,//px;
            'width': 'auto', //px;
            'height': 'auto', //px;
            'flex':'none',//px
            'order': 2,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'mySessionTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': 0,//px,
            'left': '16px',
            'top': '8px',
            //'gap': 874,//px;
            'width': 'auto', //px;
            'height': 48, //px;
            'flex':'none',//px
            'order': 0,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'recommendedCourseSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',//px,
            'gap': 16,//px;
            'width': 'auto', //px;
            'height': 'auto', //px;
            'flex':'none',//px
            'order': 3,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'recommendedCourseTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',//px,
            'left': '16px',
            'top': '8px',
            //'gap': 824,//px;
            'width': 'auto', //px;
            'height': 48, //px;
            'flex':'none',//px
            'order': 0,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'upcomingSessionSectionDiv':{
            'background': '#FFFFFF',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',//px,
            'gap': 16,//px;
            'width': 'auto', //px;
            'height': 'auto', //px;
            'flex':'none',//px
            'order': 0,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'upcomingSessionTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',//px,
            'left': '16px',
            'top': '8px',
            'gap': 0,//px;
            'width': 'auto', //px;
            'height': 48, //px;
            'flex':'none',//px
            'order': 0,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'meetTeamSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 3,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'meetTeamTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content':'space-between',
            'align-items': 'flex-start',
            'padding': '0px',
            'width': 'auto', 
            'height': '48px', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'abhyasTeamDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-end',
            'padding': '12px 8px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto', 
            'overflow-x': 'scroll',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'abhyasTeamAvatarCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '16px',
            'gap': '8px',
            'width': '360px', 
            'height': 'auto', 
            'border-radius': '8px', 
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'avatarDiv':{
            'width': 198, //px;
            'height': 169, //px;
            'flex':'none',//px
            'order': 0,//px
            'flex-grow': 0,//px
        },
        'maskGroupDiv':{
            'width': 184.19, //px;
            'height': 169, //px;
            'position': 'absolute',
            'left': 0, //%
            'right': 6.98, //%
            'top': 0, //%
            'bottom': 0, //%
        },
        'heroSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '24px 10px',//px,
            'gap': '10px',//px;
            'width': 'auto', //px;
            'height': 'auto', //px;
            'flex':'none',//px
            'order': 0,//px
            'align-self': 'stretch',
            'flex-grow':0,//px
        },
        'heroSectionImageDiv':{
            'border-radius':'8px',
            'filter': 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))',
            'margin-left': '5%',
            'margin-right': '5%', 
            'width': '-webkit-fill-available', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'flex-grow':0,
            'word-break': 'break-word'
        },
        'heroSectionTitleDiv':{
            'display': 'flex',
            'text-align': 'left',
            'align-items': 'center',
            'margin-left': '2%',
            'margin-top': '2%', 
            'width': '489px', 
            'height': 'auto'
        },
        'heroSectionDescriptionDiv':{
            'display': 'flex',
            'text-align': 'left',
            'align-items': 'center',
            'margin-left': '2%',
            'margin-top': '2%', 
            'width': '402px', 
            'height': 'auto', 
        },
        'getStartedSectionDiv':{
            'display': 'flex',
            'align-items': 'center',
            'margin-left': '32px',
            'margin-top': '24px', 
            'margin-bottom': '10%',
            'width': 'auto', 
            'height': 'auto'
        },
        'upcomingCourseSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px 16px',
            'gap': '16px',
            'width': 'auto', 
            'margin-bottom':'0px',
            'height': 'auto', 
            'flex':'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'upcomingCourseTitleDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content':'space-between',
            'align-items': 'center',
            'padding': '0px',
            //'gap': '874px',
            'width': 'auto', 
            'height': '48px', 
            'flex':'none',
            'order': 0,
            'flex-grow':0,
            'align-self': 'stretch'
        },
        'upcomingCoursesDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto', 
            'overflow-x': 'scroll',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'myCoursesDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',
            'gap': '16px',
            'width': 'auto', 
            'height': 'auto', 
            'overflow-x': 'scroll',
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'recommendedCoursesDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',//px,
            'gap': 16,//px;
            'left':16,//px
            'right':16,//px
            'width': 'auto', //px;
            'height': 276, //px;
            'overflow-x': 'scroll',
            'flex':'none',//px
            'order': 1,//px
            'align-self': 'stretch',
            'flex-grow': 0,//px
        },
        'abhyasCourseCardDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': '360px', 
            'height': 'auto',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'border-radius': '8px', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
            'position': 'static',
            'z-index': 0
        },
        'courseSessionsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': '100%', 
            'height': 'auto', 
            'flex':'none',
            'order': 3,
            'flex-grow':0
        },
        'selectedFiltersDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'flex-start',
            'padding': '8px',
            //'gap': 480,//px;
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'tagCloudDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self':'stretch',
            'z-index': 0
        },
        'titlebar':{
            'display': 'inline-block',
            'width':'262',//px
            'height':'96',//px
            'left': '52',//px
            'top':'122',//px
            'right':'52',//px
            'text-align':'left'
        },
        'descriptionbar':{
            'display': 'inline-block',
            'width':'261',//px
            'height':'100',//px
            'left': '52',//px
            'top':'48',//px
            'right':'53',//px
            'bottom':'263',//px
            'text-align':'left'
        },
        'checkBoxDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '4px',
            'gap': '8px',
            'width': 'auto', 
            'height': '32px', 
            'flex':'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow':0
        },
        'checkBoxGroupTitle':{
            'display': 'flex',
            'align-items': 'flex-end',
            'width': 'auto', 
            'height': '35px', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'checkBoxGroupDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '8px',
            'gap': '4px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'filtersDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto', 
            'height': 'auto', 
            'flex':'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow':0,
        },
        'searchContentDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '0px',
            'gap': '16px',
            'width': '20%', 
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 1,
            'z-index': 0
        },
        'rectangle5Div':{
            'position':'absolute',
            'width': '329px',//px
            'height': 'auto', //px
            'left': 0,//px
            'top': 0,//px
            'bottom': 274,//px
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)'
        },
        'filterSectionDiv':{
            'box-sizing':'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'float': 'left',
            'width': '300px', 
            'height': 'auto', 
            'flex':'none',
            'order': 0,
            'flex-grow': 0,
            'z-index': 1
        },
        'searchPageDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'isolation': 'isolate',
            'width': 'auto',
            'height': 'auto', 
        },
        'homePageDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap':'12px',
            'width': 'auto',
            'height': 'auto', 
            'flex':'none',
            'order': 1,
            'flex-grow': 0,
            'z-index': 1
        },
        'inputRadioBoxLabel':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '0px',
            'gap':'8px',
            'width': '24px',
            'height': 'auto'
        },
        'inputRadioBoxInput':{
            'box-sizing': 'border-box',
            'width':'24px',
            'height':'24px',
            'border-radius': '12px',
            'border': '1px solid ',
            'flex':'none',
            'order': 0,
            'flex-grow':0,
        },
        'pageContentDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '12px',
            'position': 'absolute',
            'width': 'auto',
            'height': 'auto',
            'overflow-y': 'scroll' 
        },
        'pageFormContentDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px 0px 195px',
            'gap': '27px',
            'width': 'auto',
            'height': 'auto',
            'overflow-y': 'scroll',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 1,
        },
        'rightRailContentDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '24px 8px',
            'gap': '8px',
            'width': '400px',
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'pageFormSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '12px',
            'gap': '12px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0,
        },
        'formSectionCourseBasicInfoDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'headingBarDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'heading':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '0px',
            'gap': '16px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 0
        },
        'sectionSubTitle':{
            'display': 'flex',
            'align-items': 'center',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 1,
            'align-self': 'stretch',
            'flex-grow': 0
        },
        'instructionsStatementDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'questionStatementDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'hintformDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'FRQAdvanceformDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'FRQformDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'MCQOptionformDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'MCQformDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '100%',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'challengeTypeformDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '598px',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'basicInfoFormDiv':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '598px',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'scheduleInfoForm':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '598px',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'priceInfoForm':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '598px',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'additionalInfoForm':{
            'display': 'none',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '16px',
            'width': '598px',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'basicInfoFormInputDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': '566px',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'textBoxLabelField':{
            'display': 'flex',
            'align-items': 'flex-end',
            'width': 'auto',
            'height': '35px',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'formSectionScheduleInfoDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'dateInputFieldDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '4px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'flex-grow': 1,
        },
        'helperTextDiv':{
            'display': 'flex',
            'align-items': 'center',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 2,
            'align-self':'stretch',
            'flex-grow': 0,
        },
        'formSectionPriceAndEnrollSectionDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'formSectionBackgroundDocumentsDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '16px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'border-radius': '8px',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'fileUploaderDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto',
            'height': 'auto',
            'flex': 'none',
            'order': 0,
            'align-self': 'stretch',
            'flex-grow': 0,
        },
        'fileDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'center',
            'padding': '0px',
            'gap': '8px',
            'width': 'auto',
            'height': '48px',
            'flex': 'none',
            'order': 1,
            'flex-grow': 0,
        },
        'fileNameInputDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'8px 16px',
            'gap': '8px',
            'width':'auto',
            'height':'48px',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'flex-grow':0,
        },
        'fileInputTextAreaDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'8px 16px',
            'gap': '8px',
            'width':'100%',
            'height':'100px',
            'border-radius': '8px',
            'flex':'none',
            'order': 0,
            'flex-grow':0,
        },
        'buttonsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'0px',
            'gap': '16px',
            'width':'auto',
            'height':'48px',
            'flex':'none',
            'order': 2,
            'flex-grow':0,
        },
        'sessionbuttonsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding':'0px',
            'gap': '27px',
            'width':'auto',
            'height':'48px',
            'flex':'none',
            'order': 3,
            'flex-grow':0,
        },
        'liveSessionVideoDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'justify-content': 'center',
            'width':'auto',
            'height':'100%'
        },
        'liveSessionBodyDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'align-self': 'stretch',
            'width':'auto',
            'height':'100%'
        },
        'liveSessionTopNavDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'space-between',
            'align-items': 'center',
            'align-self': 'stretch',
            'width':'auto',
            'height':'auto',
            'padding': '0px'
        },
        'liveSessionTabBarOuterDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'width':'auto',
            'height':'auto',
            'flex' : 'none',
            'order': 0,
            'flex-grow' : 0,
            'margin-top': '16px'
        },
        'liveSessionTabBarInnerDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '0px',
            'width':'auto',
            'height':'auto',
            'flex' : 'none',
            'order': 0,
            'flex-grow' : 0,
        },
        'liveSessionTopNavButtons':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '4px 16px',
            'gap' : '16px',
            'flex' : 'none',
            'order': 1,
            'flex-grow' : 0,
            'width':'auto',
            'height':'auto'
        },
        'liveTopNavSessionButtonsDiv':{
            'display': 'flex',
            'flex-direction': 'row',
            'align-items': 'flex-start',
            'padding': '4px 4px',
            'gap' : '4px',
            'flex' : 'none',
            'order': 2,
            'flex-grow' : 0,
            'width':'auto',
            'height':'auto',
            'margin': '0'
        },
        'liveSessionTabButton':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'flex-end',
            'padding': '8px',
            'gap' : '4px',
            'flex' : 'none',
            'order': 0,
            'flex-grow' : 0,
            'width':'auto',
            'height':'auto',
            'border-style': 'solid',
            'border-width': '1px 1px 0px 1px',
            'border-radius': '4px 4px 0px 0px'
        },
        'canvasHomeDiv':{
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'align-self': 'stretch',
            'padding': '0px',
            'gap': '8px',
            'width':'auto',
            'height':'auto',
            'overflow-y': 'scroll',
            'flex': 'none',
            'order': '0',
            'flex-grow': '1'
        },
        'conversationDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'align-self': 'stretch',
            'padding': '0px',
            'width':'400px',
            'height':'100%',
            'border': '1px solid',
            'flex': 'none',
            'order': '1',
            'flex-grow': '0'
        },
        'classTitleDiv':{
            'box-sizing': 'border-box',
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'align-self': 'stretch',
            'padding': '12px',
            'width':'auto',
            'height':'auto',
            'border-width': '1px 1px 1px 1px',
            'border-style': 'solid',
            'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
            'flex': 'none',
            'order': '0',
            'flex-grow': '0'
        },
        'sessionStatusDiv':{
            //onePrimaryButtonCardDiv
            'display': 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'padding': '16px',
            'gap': '8px',
            'width': "auto",
            'height': "auto",
            'margin-left': '16px',
            'margin-right': '16px',
            'box-shadow': "0px 0px 8px rgba(0, 0, 0, 0.25)",
            'border-radius':"8px",
            'flex': 'none',
            'order': 1,
            'align-self': 'center',
            'flex-grow': 0
        },
        'frames':{
            "frame_50":{
                'display': 'flex',
                'flex-direction': 'row',
                'justify-content': 'space-between',
                'align-items': 'flex-end',
                'padding':'16px 8px',
                'gap': '8px',
                'width':'auto',
                'height':'80px',
                'flex':'none',
                'order': 2,
                'align-self': 'stretch',
                'flex-grow':0,
                'z-index': 2
            },
            'frame_35':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 1,
                'flex-grow':0
            },
            'frame_48':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-end',
                'padding':'0px',
                'gap': '8px',
                'width':'auto',
                'height':'20px',
                'flex':'none',
                'order': 2,
                'flex-grow':0
            },
            'frame_36':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '4px',
                'width':'90%',
                'height':'auto',
                'max-width': '670px',
                'flex':'none',
                'flex-wrap': 'wrap',
                'order': 0,
                'flex-grow':0
            },
            'frame_55':{
                'display': 'flex',
                'flex-direction': 'column',
                'justify-content': 'center',
                'align-items': 'center',
                'padding':'16px',
                'gap': '16px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'align-self': 'stretch',
                'flex-grow':1
            },
            'frame_18':{
                'box-sizing': 'border-box',
                'display': 'flex',
                'flex-direction': 'row',
                'justify-content': 'space-between',
                'align-items': 'flex-start',
                'padding':'8px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'border': '1px solid',
                'border-radius': '8px 8px 8px 8px',
                'flex':'none',
                'order': 0,
                'align-self': 'stretch',
                'flex-grow':0
            },
            'frame_17':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'8px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 3,
                'align-self': 'stretch',
                'flex-grow':0
            },
            'frame_54':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 2,
                'flex-grow':0
            },
            'MCQOptionBarDiv':{
                //frame_27
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'align-self': 'stretch',
                'flex-grow':0,
            },
            'MCQOptionTextFieldDiv':{
                //frame_25
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 1,
                'flex-grow':1,
            },
            //frame_24
            'footerFrameSectionDiv':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'0px 8px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 1,
                'flex-grow':0,
            },
            'inputDropDownDiv':{
                'box-sizing': 'border-box',
                'display': 'flex',
                'flex-direction': 'row',
                'justify-content': 'space-between',
                'align-items': 'flex-start',
                'padding':'8px',
                'gap': '8px',
                'width':'auto',
                'height':'auto',
                'border-radius': '8px',
                'flex':'none',
                'order': 1,
                'align-self': 'stretch',
                'flex-grow':0,
            },
            'enrollStudentNoDiv':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '16px',
                'width':'566px',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'align-self': 'stretch',
                'flex-grow':0,
            },
            'frame_14':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '12px',
                'width':'auto',
                'height':'24px',
                'flex':'none',
                'order': 0,
                'flex-grow':0,
            },
            'inputCurrencyField':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '16px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 4,
                'align-self': 'stretch',
                'flex-grow':0,
            },
            'inputScheduleField':{
                //frame_45
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '16px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'align-self': 'stretch',
                'flex-grow':0,
            },
            'myDraftsCourseTitleRowDiv':{
                //frame_34
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap': '4px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'align-self': 'stretch',
                'flex-grow':0,
                'z-index': 0
            },
            'formInputField':{
                'box-sizing': 'border-box',
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'8px 16px',
                'gap': '8px',
                'width':'auto',
                'height':'36px',
                'border-radius': '8px',
                'flex':'none',
                'order': 1,
                'align-self': 'stretch',
                'flex-grow':0,
            },
            'filterMyLearningSection':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 4,
                'flex-grow':0,
            },
            'filterTypeSection':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 3,
                'flex-grow':0,
            },
            'filterStandardSection':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 2,
                'flex-grow':0,
            },
            'filterCurriculumSection':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 1,
                'flex-grow':0,
            },
            'filterSubjectSection':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-start',
                'padding':'8px',
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'flex-grow':0,
            },
            'frame_29':{
                'width':'533',//px
                'height':'106',//px
                'left':'8',//px
                'top': '103',//px
                'right':'8',//px
            },
            'frame_28':{
                'display': 'flex',
                'flex-direction': 'row',
                'justify-content': 'flex-end',
                'width':'533',//px
                'height':'58',//px
                'left':'8',//px
                'top': '8',//px
                'right':'8',//px
            },
            'frame_27':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'box-shadow': '0px 0px 8px rgba(0, 0, 0, 0.25)',
                'padding': 0,
                'left':'262',//px
                'top': '181',//px
                'right':'263',//px
                'bottom': '180',//px
                'width':'915',//px
                'height':'629',//px
            },
            'frame_26':{
                'width':'366',//px
                'height':'629',//px
                'flex': 'none',
                'order': 0,
                'flex-grow': 0,
            },
            'frame_25':{
                'width':'549',//px
                'height':'629',//px
                'flex': 'none',
                'order': 1,
                'flex-grow': 0,
            },
            'frame_22':{
                'display': 'flex',
                'flex-direction': 'row',
                'align-items': 'flex-start',
                'padding':'0px',
                'gap':'4px', 
                'width':'auto',
                'height':'auto',
                'flex':'none',
                'order': 0,
                'flex-grow':0,
            },
            'frame_16':{
                'display': 'flex',
                'flex-direction': 'column',
                'align-items': 'flex-end',
                'padding':'0px 8px',//px
                'gap':8, //px
                'width':'328',//px
                'height':'auto',//px
                'flex':'none',//px
                'order': 3,//px
                'align-self': 'stretch',
                'flex-grow':0//px
            },
            'frame_15':{
                'display': 'flex',
                'flex-direction': 'row',
                'justify-content': 'flex-end',
                'align-items': 'center',
                'padding':'0px 8px',
                'gap': '8px',
                'width':'100%',
                'height':'auto',
                'flex':'none',
                'order': 3,
                'align-self': 'stretch',
                'flex-grow':1
            }
        }
    }
}