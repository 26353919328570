<template>
    <c-footerSectionDiv>
        <c-footerLinkGroup-Div >
            <c-footerTitleDiv textalign="left" padding="0px 16px" display="flex">
                <c-H3-27 :color="colorTheme['Secondary-Gray0700']">Courses</c-H3-27>
            </c-footerTitleDiv>
            <div style="display: flex;flex-direction: column;">
                <c-footerFrameSectionDiv v-for="(courseTitle, index) in inputCoursesList" :key="index" >
                    <c-row textalign="left">
                        <c-hypherlink_secondary height="36px" :id="'inputViewCourse_' + (courseTitle.name)" postpendIcon="chevron_right"><span style="text-decoration-line:underline"  @click="gotoBrowseSearchPageFn(courseTitle.name)">{{ courseTitle["name"] }}</span> </c-hypherlink_secondary>
                    </c-row>
                </c-footerFrameSectionDiv>
            </div>
        </c-footerLinkGroup-Div >
        <c-footerLinkGroup-Div :order="1" >
            <c-footerTitleDiv textalign="left" padding="0px 16px" display="flex">
                <c-H3-27 :color="colorTheme['Secondary-Gray0700']">Abhyas </c-H3-27>
            </c-footerTitleDiv>
            <div style="display: flex;flex-direction: column;">
                <c-footerFrameSectionDiv v-for="(abhyasSectionTitle, index) in inputAbhyasSectionlist" :key="index">
                    <c-row textalign="left">
                        <c-hypherlink_secondary height="36px" :id="'inputViewAbhyasSection_' + abhyasSectionTitle" postpendIcon="chevron_right"><span style="text-decoration-line:underline" @click="gotoPrivacyPolicyPageFn()">{{ abhyasSectionTitle }}</span> </c-hypherlink_secondary>
                    </c-row>
                </c-footerFrameSectionDiv>
            </div>
        </c-footerLinkGroup-Div>
        <c-footerLinkGroup-Div  :order="2" >
            <c-footerTitleDiv textalign="left" padding="0px 16px" display="flex">
                <c-H3-27 :color="colorTheme['Secondary-Gray0700']">Terms </c-H3-27>
            </c-footerTitleDiv>
            <div style="display: flex;flex-direction: column;">
                <c-footerFrameSectionDiv v-for="(termsSectionTitle, index) in inputTermsSectionList" :key="index">
                    <c-row textalign="left">
                        <c-hypherlink_secondary height="36px"  :id="'inputViewTermsSection_' + termsSectionTitle" postpendIcon="chevron_right"><span style="text-decoration-line:underline" @click="gotoPrivacyPolicyPageFn()">{{ termsSectionTitle }}</span> </c-hypherlink_secondary>
                    </c-row>
                </c-footerFrameSectionDiv>
            </div>
        </c-footerLinkGroup-Div>
    </c-footerSectionDiv>
</template>
<script>
import {footerSectionDiv, row, footerLinkGroupDiv, footerTitleDiv,footerFrameSectionDiv} from '../atoms/div_styles'
import {theme} from '../assets/theme.js'
import {H3_27} from '../atoms/text_styles'
import hypherlink_secondary from '../molecules/hypherlink_secondary.vue'
import axios from 'axios';
import AppConfig from '../../config.js';

export default {
    name:'footerComp',
    components:{
        'c-footerSectionDiv': footerSectionDiv,
        'c-row': row,
        //'c-col': col,
        'c-hypherlink_secondary':hypherlink_secondary,
        'c-H3-27': H3_27,
        'c-footerLinkGroup-Div':footerLinkGroupDiv,
        'c-footerTitleDiv':footerTitleDiv,
        'c-footerFrameSectionDiv':footerFrameSectionDiv
    },
    data(){
        return{
            UI_baseURL: AppConfig.UI_Base_URL,
            auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
            colorTheme:theme,
            inputCourse:"",
            inputCoursesList:[],
            inputAbhyasSectionlist:['About us','What we offer','Contact us','FAQ'],
            inputTermsSectionList:['Privacy policy','Cookie settings','Site map','Accessibility statement']
        }
    },
    async beforeMount() {
        await this.getSubjectsList()
    },
    methods: {
        gotoPrivacyPolicyPageFn(){
             //redirect to privacy policy page
            let privacyPolicyPageUrl = this.UI_baseURL + '/privacy-policy'
            console.log("privacyPolicyPageUrl ", privacyPolicyPageUrl)
            window.location.href = privacyPolicyPageUrl
        },
        gotoBrowseSearchPageFn(inputCourse){
            this.inputCourse= inputCourse
            console.log("inputCourse",this.inputCourse);
            // if(this.inputCourse === undefined){
            //     console.log("this.router",this.$router);
            //     this.$router.push({ path: '/browseSearch'})
            // }
            // if(this.inputCourse != undefined){
            //     let browseSearchURL = this.UI_baseURL + '/browseSearch?subject=' + this.inputCourse
            //     console.log("browseSearch url", browseSearchURL)
            //     window.location.href = browseSearchURL
            // }
            // redirect to coming soon page
            let maintenancePageURL = this.UI_baseURL + '/maintenance'
            console.log("maintenance page url", maintenancePageURL)
            window.location.href = maintenancePageURL
        },
        async getSubjectsList(){
            try{
                let getSubjectListResp = await axios.get(this.auth_api_Base_URL + "/v1_0/subjectsList")
                console.log("resp of getSubjectsList", getSubjectListResp);
                this.inputCoursesList = getSubjectListResp.data.subjects
            }catch(error){
                console.log("error in response of get subject list", error);
            }
        },
    },
}
</script>