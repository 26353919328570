<template>
  <div class="my-courses"  >
    <c-H4-22 :color="colorTheme['Secondary-Gray0700']">My courses</c-H4-22>
    <c-upcoming-courses v-if="coursesData.length>0" class="my-courses-list" >
      <c-abhyas-course-card style="width: 330px;"  v-for="(courseDetails, index) in coursesData" :key="index" position="relative">
          <c-description-div style="-webkit-line-clamp : 2; height : auto;" overflow="hidden" text_align="left" text_transform="none">
              <c-H4-22 :color="colorTheme['Secondary-Gray0700']">{{courseDetails.title}}</c-H4-22>
          </c-description-div>
          <c-row text_align="left" text_transform="none">
              <c-overline-11>{{courseDetails.tutor_name}}</c-overline-11>
          </c-row>
          <c-tag-cloud>
              <c-tag-row>
                  <c-tag-pill v-if="!((courseDetails.curriculum === null) || (courseDetails.curriculum === 'null') || (courseDetails.curriculum === ''))"><c-body-14>{{courseDetails.curriculum}}</c-body-14></c-tag-pill>
                  <c-tag-pill v-if="!((courseDetails.subj === null) || (courseDetails.subj === 'null') || (courseDetails.subj === ''))"><c-body-14>{{courseDetails.subj}}</c-body-14></c-tag-pill>
                  <c-tag-pill v-if="!((courseDetails.grade === null) || (courseDetails.grade === 'null') || (courseDetails.grade === ''))"><c-body-14>{{courseDetails.grade}}</c-body-14></c-tag-pill>
              </c-tag-row>
          </c-tag-cloud>
          <c-row text_transform="none" text_align="left">
              <c-body-14 :color="colorTheme['Secondary-Gray0700']">
                  <c-span-body-14 :color="colorTheme['Secondary-Gray0700']" :font_weight="7" >Start: </c-span-body-14>
                  {{ convertDateFormat(courseDetails.start_at) }}<br>
              </c-body-14>
          </c-row>
          <c-frame-16 style="width: auto;">
              <c-btn-secondary as="a" :id="'inputViewCourseDetails_' + (index + 1) " :href="UI_base_url + '/course/' + courseDetails.id">View details</c-btn-secondary>
          </c-frame-16>
      </c-abhyas-course-card>
    </c-upcoming-courses>
    <div v-else>
      <c-row textalign="left" text_transform="none">
        You don't have any courses
      </c-row>
    </div>
  </div>
</template>
  
<script>
//import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22, body_14,span_body_14,OVERLINE_11} from '../atoms/text_styles';
import { theme } from '../assets/theme';
import { descriptionDiv, tagRowDiv, tagCloudDiv, frame_16, abhyasCourseCard, rowDiv, upcomingCourses} from '../atoms/div_styles'
import buttonSecondary from '../molecules/button_secondary.vue'
import tag_pill from '../molecules/tag_pill.vue'

  export default {
    name: 'MyOrgs',
    data() {
      return {
        colorTheme:theme,
        currentView: 'MyOrgs', // Default view
        UI_base_url: AppConfig.UI_Base_URL,
        auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
        coursesData:[]
      };
    },
    props:{
      coursesListdata:{
        type: Array
      }
    },
    components:{
      'c-body-14': body_14,
      'c-description-div': descriptionDiv,
      'c-frame-16':frame_16,
      'c-row': rowDiv,
      'c-span-body-14': span_body_14,
      'c-overline-11': OVERLINE_11,
      'c-H4-22': H4_22,
      'c-abhyas-course-card': abhyasCourseCard,
      'c-tag-pill': tag_pill,
      'c-tag-cloud': tagCloudDiv,
      'c-tag-row': tagRowDiv,
      'c-btn-secondary': buttonSecondary,
      'c-upcoming-courses':upcomingCourses
    },
    async mounted(){
      console.log("coursesListdata", this.coursesListdata);
      this.coursesData = this.coursesListdata
    },
    methods: {
      /*async getUsersOrgFn(){
          try{
              let getUsersOrgResp = await axios.get(this.auth_api_Base_URL + "/v1_0/user_orgs")
              console.log("resp of getUsersOrg", getUsersOrgResp);
              this.org_details = getUsersOrgResp.data
          }catch(error){
              console.log("error in response of get getUsersOrg", error);
          }
      },*/
      gotodashboardfn(org) {
        window.location.href = this.UI_base_url + '/org/' + org.org_id
      },
      convertDateFormat(dateString) {
        // Create a Date object from the date string.
        let date = new Date(dateString);
        // Get the weekday, month, and day of the date.
        // Create an array of the weekday names.
        let weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let weekday = weekdayNames[date.getDay()]
        // Create an array of the month names.
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = monthNames[date.getMonth()]
        let day = date.getDate();
        let year = date.getFullYear();
        // Format the date string.
        let formattedDate = `${weekday}, ${month} ${day} ${year}`;
        return formattedDate;
      },
    }
  };
    
  </script>
  
  <style>
  .my-courses {
    /* Add your styles here */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    gap: 8px;
    align-items: flex-start;
    border-radius: 8px;
    background: #F0FFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .my-courses-list{
    display: flex;
    flex-wrap: wrap;
  }
  </style>
  