<template>
  <label>
    <input type="checkbox" id="inputDiv" :disabled="disabled"  :checked="checked" />
    <span id="spanDiv"></span>
    <slot>primary tag</slot>
  </label>
</template>
<script>
import { theme } from '../assets/theme';
  export default {
      name: 'c-checkbox',
      props:{
        checked:{
          default: false,
          type: Boolean
        },
        disabled:{
          default: false,
          type: Boolean
        }
      },
      components:{
      },
      data() {
        return {
          colorTheme:theme,
        }
      },
      mounted() {
      },
  };
</script>
<style lang="scss">
  :root {
  --checkbox-color: grey;
  --checkmark-color: white;
  label {
    //Todo should be config driven
    position: relative;
    display: flex;
    align-items: flex-end;
    gap: 8px;
  }
  #spanDiv {
    width: 24px;
    height: 24px;
    border: 1px solid var(--checkbox-color);
    display: inline-block;
    border-radius: 8px;
    transition: all linear 0.3s;
    &:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 6px;
      border-bottom: 4px solid #F8FFFF;
      border-right: 4px solid #F8FFFF;
      height: 18px;
      width: 11px;
      transform: rotate(45deg);
      visibility: hidden;
    }
  }
  #inputDiv {
    display: none;
    &:checked ~ #spanDiv {
      background: #00B0B3;
      border: 1px solid #00B0B3;
      &:after {
        visibility: visible;
      }
    }
  }
}
</style>