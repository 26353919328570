<template>
    <c-upcoming-course-section>
        <!--send color only if color is given in props-->
        <c-upcoming-course-title>
            <c-H4-22 :color="colorTheme['Secondary-Gray0700']" style="text-align: left;">Upcoming and ongoing courses</c-H4-22>
            <!-- <c-btn-secondary id="inputViewAllCoursesButton" as="a" :href="gotoBrowseSearchFn()">View more</c-btn-secondary> -->
        </c-upcoming-course-title>
        <c-upcoming-courses >
            <div v-if="isLoadingCoursesListData" style="display: contents;">
                <c-frame-55 >
                    <c-loader-div></c-loader-div>
                </c-frame-55>
            </div>
            <div v-else style="display: contents;">
                <c-abhyas-course-card  v-for="(courseDetails, index) in upcomingCourses" :key="index" position="relative">
                    <c-description-div style="-webkit-line-clamp : 2; height : auto;" overflow="hidden" text_align="left" text_transform="none">
                        <c-H4-22 :color="colorTheme['Secondary-Gray0700']">{{courseDetails.title}}</c-H4-22>
                    </c-description-div>
                    <c-row text_align="left" text_transform="none">
                        <c-overline-11>{{courseDetails.tutor.name}} <br> {{courseDetails.org.name}}</c-overline-11>
                    </c-row>
                    <c-tag-cloud>
                        <c-tag-row>
                            <c-tag-pill v-if="!((courseDetails.curriculum === null) || (courseDetails.curriculum === 'null') || (courseDetails.curriculum === ''))"><c-body-14>{{courseDetails.curriculum}}</c-body-14></c-tag-pill>
                            <c-tag-pill v-if="!((courseDetails.subject === null) || (courseDetails.subject === 'null') || (courseDetails.subject === ''))"><c-body-14>{{courseDetails.subject}}</c-body-14></c-tag-pill>
                            <c-tag-pill v-if="!((courseDetails.grade === null) || (courseDetails.grade === 'null') || (courseDetails.grade === ''))"><c-body-14>{{courseDetails.grade}}</c-body-14></c-tag-pill>
                        </c-tag-row>
                    </c-tag-cloud>
                    <c-row text_transform="none" text_align="left">
                        <c-body-14 :color="colorTheme['Secondary-Gray0700']">
                            <c-span-body-14 :color="colorTheme['Secondary-Gray0700']" :font_weight="7" >Start: </c-span-body-14>
                                {{ convertDateFormat(courseDetails.start_date) }}<br>
                            <c-span-body-14  :color="colorTheme['Secondary-Gray0700']" :font_weight="7" >Schedule: </c-span-body-14>
                                <c-span-body-14 v-for="(day, idx) in ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']" :key="idx" 
                                    :font_weight="(courseDetails.weekly_schedule.includes(day)) ? 7 : 4" :color="(courseDetails.weekly_schedule.includes(day)) ? colorTheme['Secondary-Gray0800'] : colorTheme['Secondary-Gray0500']">
                                    {{day[0]}} 
                                </c-span-body-14>
                                <br>
                            <c-span-body-14 :color="colorTheme['Secondary-Gray0700']" :font_weight="7" >When: </c-span-body-14>
                            {{ convertTimeToAMPM(courseDetails.default_start_time) }} ({{ (courseDetails.default_session_duration) }})<br>
                            <c-span-body-14 :color="colorTheme['Secondary-Gray0700']" :font_weight="7" >Where: </c-span-body-14>
                            {{ courseDetails.location  }}
                        </c-body-14>
                    </c-row>
                    <c-frame-16>
                        <c-btn-secondary as="a" :id="'inputViewCourseDetails_' + (index + 1) " :href="UI_baseURL + '/course/' + courseDetails.id">View details</c-btn-secondary>
                    </c-frame-16>
                </c-abhyas-course-card>
            </div>
        </c-upcoming-courses>
    </c-upcoming-course-section>
</template>
<script>
import AppConfig from '../../config';
import {loaderDiv, descriptionDiv, frame_55, tagRowDiv, tagCloudDiv, frame_16, upcomingCourses, abhyasCourseCard, rowDiv, upcomingCourseTitle, upcomingCourseSection} from '../atoms/div_styles'
import {theme} from '../assets/theme.js'
import buttonSecondary from '../molecules/button_secondary.vue'
import {span_body_14, body_14, OVERLINE_11, H4_22} from '../atoms/text_styles'
import tag_pill from '../molecules/tag_pill.vue'
export default {
    name:'upcomingCourseSection',
    components:{
        'c-description-div': descriptionDiv,
        'c-loader-div': loaderDiv,
        'c-frame-55': frame_55,
        'c-frame-16':frame_16,
        'c-row': rowDiv,
        'c-btn-secondary': buttonSecondary,
        'c-body-14': body_14,
        'c-span-body-14': span_body_14,
        'c-overline-11': OVERLINE_11,
        'c-H4-22': H4_22,
        'c-upcoming-courses':upcomingCourses,
        'c-upcoming-course-title':upcomingCourseTitle,
        'c-upcoming-course-section': upcomingCourseSection,
        'c-abhyas-course-card': abhyasCourseCard,
        'c-tag-pill': tag_pill,
        'c-tag-cloud': tagCloudDiv,
        'c-tag-row': tagRowDiv,
    },
    props:{
        upcomingCoursesListData:Array,
        isLoadingCoursesListData : Boolean
    },
    data() {
        return {
            UI_baseURL: AppConfig.UI_Base_URL,
            colorTheme:theme, // for colors of text
            upcomingCourses: []
            //courseId: "", // no need as we have no use of this variable at whole instance level, just define it in the function
            // and that will also make the function stateless, which we can reuse at other places
        }
    },
    beforeMount() {
        console.log("upcomingCoursesData", this.upcomingCoursesListData);
        this.upcomingCourses = this.upcomingCoursesListData 
    },
    methods: {
        convertTimeToAMPM(timeString) {
            console.log("timstamp is...", timeString);
            // Create a Date object from the time string.
            let date = new Date("2023-06-23 " + timeString); // have to fill a date
            console.log("date---", date);
            // Get the hour and minute of the time.
            let hour = date.getHours();
            let minute = date.getMinutes();
            //check if minute is less than 10
            if(minute<10){
                minute = '0' + minute
            }
            console.log("minute is--",minute);
            console.log('hour is----', hour);
            // Check if the hour is between 0 and 11.
            if (hour < 12) {
                // The time is in the morning.
                return `${hour}:${minute} AM`;
            }
            if (hour === 12) {
                // The time is in the morning.
                return `${hour}:${minute} PM`;
            }
             else {
                // The time is in the afternoon or evening.
                return `${hour - 12}:${minute} PM`;
            }
        },
        convertDateFormat(dateString) {
            // Create a Date object from the date string.
            let date = new Date(dateString);
            // Get the weekday, month, and day of the date.
            // Create an array of the weekday names.
            let weekdayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            let weekday = weekdayNames[date.getDay()]
            // Create an array of the month names.
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let month = monthNames[date.getMonth()]
            let day = date.getDate();
            let year = date.getFullYear();
            // Format the date string.
            let formattedDate = `${weekday}, ${month} ${day} ${year}`;
            return formattedDate;
        },
        gotoBrowseSearchFn(){
            // page navigation for browse-search page
            let viewMoreCoursesPageURL = this.UI_baseURL + '/search-course'
            //console.log("browseSearch url", viewMoreCoursesPageURL)
            return viewMoreCoursesPageURL
        },
        gotoCourseLandingFn(courseDetails){
            // page navigation for course-landing page
            console.log("courseInfo",courseDetails);
            let courseId = courseDetails.id
            let courseLandingUrl = this.UI_baseURL + '/course/' + courseId
            console.log("courseLanding url", courseLandingUrl)
            window.location.href = courseLandingUrl
        }
    },
}
</script>