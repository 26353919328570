<template>
    <c-select-div v-model="inputVal">
      <c-icon>{{prependIcon}}</c-icon>
      <slot>primary button</slot>
      <c-icon>{{postpendIcon}}</c-icon>
    </c-select-div>
</template>
  
<script>
import { selectDiv } from '../atoms/div_styles'
import {iconStyle} from '../atoms/icon_styles'
  export default {
    name: 'button-primary',
    props:{
      prependIcon: String,
      postpendIcon: String,
      value: String
    },
    components:{
        'c-select-div': selectDiv,
        'c-icon': iconStyle
    },
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  }
</script>