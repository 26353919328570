import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Component from './inputBoxComponent.vue'

export default Node.create({
  name: 'InputBoxComponent',

  // Extensions group
  group: 'block',

  // Don't allow selection
  selectable: false,

  // Add "uniqkey" attribute to our element
  // We are going to use this attribute as a
  // container for our latex uniqkey
  addAttributes() {
    return {
      uniqkey: {
        default: '',
      },
      chal_id: {
        default: '',
      }
    }
  },

  //https://tiptap.dev/guide/node-views
  // Parse all <katex> tags
  // restoring the content, Attributes are automagically restored, if you registered them through addAttributes
  parseHTML() {
    return [
      {
        tag: 'textInput'
      }
    ]
  },

  // Render our <katex> tag and merge it with our "uniqkey" attribute
  renderHTML({ HTMLAttributes }) {
    return ['textInput', mergeAttributes(HTMLAttributes)]
  },

  // Use our Vue component as node view
  addNodeView() {
    Component.props.parent = "this.editor"
    console.log('-----editor in js is', this.editor);
    return VueNodeViewRenderer(Component)
  },

  // Insert math block at the specific cursor position
  addCommands() {
    return {
      addInputBox: (attrs) => ({state, dispatch}) => {
        const { selection } = state
        const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos //position of cursor where we will add the math block
        console.log("position before inputbox is", typeof position);
        const node = this.type.create(attrs)
        const transaction = state.tr.insert(position, node);
        dispatch(transaction);
        //this.editor.commands.insertContentAt(position + 1, '<p>Hello world</p>')
      }
    }
  }
})