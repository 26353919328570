<template>
    <c-form-div :padding="4">
        <div v-if="!inFullScreenViewModeDataVar" :style="{width: editor_width}">
            <c-input-field-marker :height="editor_height">
                <c-main-vertical-input-div style="width:100%;"> <!-- overflow:auto-->
                    <c-input-entry-box-div style="width:100%;" :id="'inputEditorClientId' + uniqueCompKey">
                        <c-top-inside-toolbar-div v-if="showToolbar">
                            <c-formatting-toolbar-div v-if="iCanvasUiObj !==null && iCanvasUiObj.showFigureToolBar" style="cursor: pointer; width:100%; padding: 4px 24px 4px 24px">
                                <div style="width:100%; display: flex; justify-content: flex-end;">
                                    <c-button-icon-div>
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon :id="'close_figure_' + uniqueCompKey + inputCompFor" styledIcon="close" @click.native="figureDiginkOprUtil('cancel')"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Cancel</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div >
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon  :id="'save_figure_' + uniqueCompKey + inputCompFor" styledIcon="check" color="green" @click.native="figureDiginkOprUtil('save')"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Save</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                </div>
                            </c-formatting-toolbar-div>
                            <c-formatting-toolbar-div v-else style="cursor: pointer; width:100%; padding: 4px 24px 4px 24px; justify-content:space-between;">
                                <div style="width:auto; display: flex" v-if="inputMode && editor_mode === 'text'">
                                    <c-button-icon-div :id="'inputAddBlackColor' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddBlackColor' + inputCompFor)"><c-style-icon styledIcon="circle" @click.native="editor.commands.setColor('black')"></c-style-icon></c-button-icon-div>
                                    <!-- TODO: change hard coded color to the theme config values-->
                                    <c-button-icon-div :id="'inputAddGreenColor' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddGreenColor' + inputCompFor)"><c-style-icon styledIcon="circle" style="color:#00B0B2" @click.native="editor.commands.setColor('#00B0B2')"></c-style-icon></c-button-icon-div>
                                    <c-button-icon-div :id="'inputAddBoldStyle' + uniqueCompKey + inputCompFor"  @click.native="highlightIconFn('inputAddBoldStyle' + inputCompFor)"><c-style-icon styledIcon="format_bold" @click.native="editor.chain().focus().toggleBold().run()"></c-style-icon></c-button-icon-div>
                                    <c-button-icon-div :id="'inputAddItalicStyle' + uniqueCompKey + inputCompFor"  @click.native="highlightIconFn('inputAddItalicStyle' + inputCompFor)"><c-style-icon styledIcon="format_italic" @click.native="editor.commands.toggleItalic()"></c-style-icon></c-button-icon-div>
                                    <c-button-icon-div :id="'inputAddUnderlineStyle' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddUnderlineStyle' + inputCompFor)"><c-style-icon styledIcon="format_underlined" @click.native="editor.commands.toggleUnderline()"></c-style-icon></c-button-icon-div>
                                    <c-button-icon-div :id="'inputAddKatex' + uniqueCompKey + inputCompFor"  @click.native="highlightIconFn('inputAddKatex' + inputCompFor)"><c-style-icon styledIcon="calculate" @click.native="addKatexFn()"></c-style-icon></c-button-icon-div>
                                    <c-button-icon-div :id="'inputAddImage' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddImage' + inputCompFor)">
                                        <c-style-icon  styledIcon="photo_library" @click.native="clickIconUploadImageFn()"></c-style-icon>
                                        <input @change="imageUploadedFn" type="file" :id="'ImageUploadID_' + uniqueCompKey + inputCompFor" name="image" accept="image/gif,image/jpeg,image/jpg,image/png" data-original-title="upload photos" hidden>
                                        <!--above input filed is hidden so that only the icon is shown and when the icon is clicked we will trigger a event to click this input-->
                                    </c-button-icon-div>
                                </div>
                                <!-- <div style="width:60%; display: flex" v-if="inputMode && editor_mode === 'text'"> -->
                                    <c-button-icon-div v-if="!inputMode && editor_mode === 'text'">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon  :id="'modeChange' + uniqueCompKey + inputCompFor" styledIcon="edit" @click.native="inputMode=true"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Edit</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                <!-- </div> -->
                                <div style="width:60%; display: flex" v-if="inputMode && editor_mode === 'iCanvas'">
                                    <!-- Toolbar canvas mode buttons-->
                                    <c-button-icon-div :id="'iCanvasVisualModeControlId' + uniqueCompKey" @click.native="iCanvasModeControlClickUtil('visualMode')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="preview" :style="{color: canvasModeControlColor('visualMode')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;margin-left:1px;">
                                                <p>Visual Mode</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div :id="'iCanvasInsertModeControlId' + uniqueCompKey" @click.native="iCanvasModeControlClickUtil('insertMode')" >
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="draw" :style="{color: canvasModeControlColor('insertMode')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Insert Mode</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <!-- Add separation-->
                                    <c-button-icon-div style="align-items: center;justify-content: center; font-size: 24px;"><p>&#8741;</p></c-button-icon-div>
                                    <!-- Toolbar action buttons -->
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasTextControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('text')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="title" :style="{color: toolbarControlColor('textBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Text block</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('digink')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="brush" :style="{color: toolbarControlColor('diginkBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px; width:100px;">
                                                <p>Free hand drawing</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasEngDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('english_digink')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="history_edu" :style="{color: toolbarControlColor('englishDiginkBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>English digink block</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasMathDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('math_digink')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="calculate" :style="{color: toolbarControlColor('mathDiginkBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Math digink block</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasHindiDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('hindi_digink')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="language" :style="{color: toolbarControlColor('hindiDiginkBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Hindi digink block</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasImageControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('image')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="image" :style="{color: toolbarControlColor('imageBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Add Image</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasDiagramControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('diagram')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="account_tree_icon" :style="{color: toolbarControlColor('diagramBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Draw diagram</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasKatexControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('katex')">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="functions" :style="{color: toolbarControlColor('katexBlock')}"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Draw equation</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                </div>
                                <div style="width:auto; display: flex; justify-content: flex-end;">
                                    <c-button-icon-div  :id="'inputUndoIn' + uniqueCompKey + inputCompFor" @click.native="undoControlUtil">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="undo"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Undo</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div :id="'inputRedoIn' + uniqueCompKey + inputCompFor" @click.native="redoControlUtil">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="redo"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Redo</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div :id="'inputDelete' + uniqueCompKey + inputCompFor" @click.native="deleteControlUtil">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon styledIcon="delete"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Delete</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <!-- grab and drag handle -->
                                    <!-- <c-button-icon-div style="align-items: center;justify-content: center; font-size: 24px;"><p  :id="'inputHorizontalDragHandle' + uniqueCompKey + inputCompFor" >&#8741;</p></c-button-icon-div> -->
                                    <c-button-icon-div v-if="showMaxCanvasMode">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon :id="'inputMaximize' + uniqueCompKey + inputCompFor" styledIcon="crop_free" @click.native="toggleInputFieldViewAreaFn"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Maximize</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                    <c-button-icon-div v-if="inputMode && editor_mode === 'text'">
                                        <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                            <c-style-icon  :id="'inputSave' + uniqueCompKey + inputCompFor" styledIcon="check" color="green" @click.native="saveAndToggleViewMode()"></c-style-icon>
                                            <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                <p>Save</p>
                                            </c-tooptip-text>
                                        </c-tooltip-bottom-div>
                                    </c-button-icon-div>
                                </div>
                            </c-formatting-toolbar-div>
                        </c-top-inside-toolbar-div>
                        <!-- TODO: create styled component for this-->
                        <editor-content ref="'defaultViewModeSection_' + inputCompFor" v-if="inputMode && editor_mode === 'text'" class="content" :editor="editor" />
                        <div :id="'iCanvasRenderDivId' + uniqueCompKey" v-else-if="inputMode && editor_mode === 'iCanvas'"></div>
                        <div v-else style="padding:10px; text-align: left;" :id="'defaultViewModeSection_' + uniqueCompKey + inputCompFor" class="final-content viewContent" v-html="richTextContent" ></div>
                        <!--<c-input-field-view-div v-html="richTextContent"></c-input-field-view-div>--> <!-- not working because of v-html issue with components-->
                    </c-input-entry-box-div>
                </c-main-vertical-input-div>
            </c-input-field-marker>
        </div>
        <div v-else :id="'inputCompFullScreen_' + uniqueCompKey + inputCompFor" class="inputCompModal" >
            <div class="inputCompModal-content" :style="{width: (editor_mode === 'text') ? '100%' : 'auto'}">
                <c-input-field-marker height="100%">
                    <c-main-vertical-input-div style="width:90%">
                        <c-input-entry-box-div style="width:100%;" height="90%" alignItems="flex-start" :id="'inputEditorClientId' + uniqueCompKey">
                            <c-top-inside-toolbar-div >
                                <c-formatting-toolbar-div v-if="iCanvasUiObj !==null && iCanvasUiObj.showFigureToolBar" style="cursor: pointer; width:100%; padding: 4px 24px 4px 24px">
                                    <div style="width:100%; display: flex; justify-content: flex-end;">
                                        <c-button-icon-div>
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon :id="'close_figure_' + uniqueCompKey + inputCompFor" styledIcon="close" @click.native="figureDiginkOprUtil('cancel')"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Cancel</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div >
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon  :id="'save_figure_' + uniqueCompKey + inputCompFor" styledIcon="check" color="green" @click.native="figureDiginkOprUtil('save')"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Save</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                    </div>
                                </c-formatting-toolbar-div>
                                <c-formatting-toolbar-div v-else style="cursor: pointer; width:100%; padding: 4px 24px 4px 24px">
                                    <div style="width:60%; display: flex" v-if="inputMode && editor_mode === 'text'">
                                        <c-button-icon-div :id="'inputAddBlackColor' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddBlackColor' + inputCompFor)"><c-style-icon styledIcon="circle" @click.native="editor.commands.setColor('black')"></c-style-icon></c-button-icon-div>
                                        <!-- TODO: change hard coded color to the theme config values-->
                                        <c-button-icon-div :id="'inputAddGreenColor' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddGreenColor' + inputCompFor)"><c-style-icon styledIcon="circle" style="color:#00B0B2" @click.native="editor.commands.setColor('#00B0B2')"></c-style-icon></c-button-icon-div>
                                        <c-button-icon-div :id="'inputAddBoldStyle' + uniqueCompKey + inputCompFor"  @click.native="highlightIconFn('inputAddBoldStyle' + inputCompFor)"><c-style-icon styledIcon="format_bold" @click.native="editor.chain().focus().toggleBold().run()"></c-style-icon></c-button-icon-div>
                                        <c-button-icon-div :id="'inputAddItalicStyle' + uniqueCompKey + inputCompFor"  @click.native="highlightIconFn('inputAddItalicStyle' + inputCompFor)"><c-style-icon styledIcon="format_italic" @click.native="editor.commands.toggleItalic()"></c-style-icon></c-button-icon-div>
                                        <c-button-icon-div :id="'inputAddUnderlineStyle' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddUnderlineStyle' + inputCompFor)"><c-style-icon styledIcon="format_underlined" @click.native="editor.commands.toggleUnderline()"></c-style-icon></c-button-icon-div>
                                        <c-button-icon-div :id="'inputAddKatex' + uniqueCompKey + inputCompFor"  @click.native="highlightIconFn('inputAddKatex' + inputCompFor)"><c-style-icon styledIcon="calculate" @click.native="addKatexFn()"></c-style-icon></c-button-icon-div>
                                        <c-button-icon-div :id="'inputAddImage' + uniqueCompKey + inputCompFor" @click.native="highlightIconFn('inputAddImage' + inputCompFor)">
                                            <c-style-icon  styledIcon="photo_library" @click.native="clickIconUploadImageFn()"></c-style-icon>
                                            <input @change="imageUploadedFn" type="file" :id="'ImageUploadID_' + uniqueCompKey + inputCompFor" name="image" accept="image/gif,image/jpeg,image/jpg,image/png" data-original-title="upload photos" hidden>
                                            <!--above input filed is hidden so that only the icon is shown and when the icon is clicked we will trigger a event to click this input-->
                                        </c-button-icon-div>
                                    </div>
                                    <div style="width:60%; display: flex" v-if="inputMode && editor_mode === 'iCanvas'">
                                        <!-- Toolbar canvas mode buttons-->
                                        <c-button-icon-div :id="'iCanvasVisualModeControlId' + uniqueCompKey" @click.native="iCanvasModeControlClickUtil('visualMode')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="preview" :style="{color: canvasModeControlColor('visualMode')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Visual Mode</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div :id="'iCanvasInsertModeControlId' + uniqueCompKey" @click.native="iCanvasModeControlClickUtil('insertMode')" >
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="draw" :style="{color: canvasModeControlColor('insertMode')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Insert Mode</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <!-- Add separation-->
                                        <c-button-icon-div style="align-items: center;justify-content: center; font-size: 24px;"><p>&#8741;</p></c-button-icon-div>
                                        <!-- Toolbar action buttons -->
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasTextControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('text')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="title" :style="{color: toolbarControlColor('textBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Text block</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('digink')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="brush" :style="{color: toolbarControlColor('diginkBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Free hand drawing</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasEngDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('english_digink')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="history_edu" :style="{color: toolbarControlColor('englishDiginkBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>English digink block</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasMathDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('math_digink')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="calculate" :style="{color: toolbarControlColor('mathDiginkBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Math digink block</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasHindiDiginkControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('hindi_digink')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="language" :style="{color: toolbarControlColor('hindiDiginkBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Hindi digink block</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasImageControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('image')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="image" :style="{color: toolbarControlColor('imageBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Add Image</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasDiagramControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('diagram')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="account_tree_icon" :style="{color: toolbarControlColor('diagramBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Draw diagram</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasKatexControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('katex')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="functions" :style="{color: toolbarControlColor('katexBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Draw equation</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasDiagramControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('diagram')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="account_tree_icon" :style="{color: toolbarControlColor('diagramBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Draw diagram</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasKatexControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('katex')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="functions" :style="{color: toolbarControlColor('katexBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Draw equation</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasDiagramControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('diagram')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="account_tree_icon" :style="{color: toolbarControlColor('diagramBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Draw diagram</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="showICanvasToolbarUtil()" :id="'iCanvasKatexControlId' + uniqueCompKey" @click.native="iCanvasToolbarControlClickUtil('katex')">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="functions" :style="{color: toolbarControlColor('katexBlock')}"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Draw equation</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                    </div>
                                    <div style="width:auto; display: flex; justify-content: flex-end;">
                                        <c-button-icon-div  :id="'inputUndoIn' + uniqueCompKey + inputCompFor" @click.native="undoControlUtil">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="undo"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Undo</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div :id="'inputRedoIn' + uniqueCompKey + inputCompFor" @click.native="redoControlUtil">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="redo"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Redo</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div :id="'inputDelete' + uniqueCompKey + inputCompFor" @click.native="deleteControlUtil">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="delete"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Delete</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <!-- grab and drag handle -->
                                        <c-button-icon-div style="align-items: center;justify-content: center; font-size: 24px;"><p  :id="'inputHorizontalDragHandle' + uniqueCompKey + inputCompFor" >&#8741;</p></c-button-icon-div>
                                        <c-button-icon-div v-if="showMaxCanvasMode" :id="'inputCompFullScreen_' + 'Minimize' + uniqueCompKey + inputCompFor">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon styledIcon="crop_free" @click.native="toggleInputFieldViewAreaFn"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Default</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                        <c-button-icon-div v-if="inputMode && editor_mode === 'text'">
                                            <c-tooltip-bottom-div style="height: 100%;width:auto;">
                                                <c-style-icon  :id="'inputSave' + uniqueCompKey + inputCompFor" styledIcon="check" color="green" @click.native="saveAndToggleViewMode()"></c-style-icon>
                                                <c-tooptip-text style="display: inline-block; padding: 4px;">
                                                    <p>Save</p>
                                                </c-tooptip-text>
                                            </c-tooltip-bottom-div>
                                        </c-button-icon-div>
                                    </div>
                                </c-formatting-toolbar-div>
                            </c-top-inside-toolbar-div>
                            <editor-content v-if="inputMode && editor_mode === 'text'" class="full-screen-content" :editor="editor" />
                            <div :id="'iCanvasRenderDivId' + uniqueCompKey" v-else-if="inputMode && editor_mode === 'iCanvas'"></div>
                            <div v-else style="padding:10px;" :id="'fullScreenViewModeSection_' + uniqueCompKey + inputCompFor" class="final-content viewContent" v-html="richTextContent" ></div>
                            <!--<c-input-field-view-div v-html="richTextContent"></c-input-field-view-div>--> <!-- not working because of v-html issue with components-->
                        </c-input-entry-box-div>
                    </c-main-vertical-input-div>
                </c-input-field-marker>
            </div>
        </div>
    </c-form-div>
</template>

<script>
import axios from 'axios'
import AppConfig from '../../config.js';
// toolbarWindowControlDiv, toolbarUndoRedoDiv, topOutsideToolbarDiv,
import {formDiv, buttonIconDiv, formattingToolbarDiv, topInsideToolbarDiv, inputEntryBoxDiv, mainVerticalInputDiv, inputFieldMarkerDiv} from '../atoms/create_challenge_div_styles';
import style_icons from '../molecules/style_icons.vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import FormulaComponent from '../components/FormulaExtension.js'
import ImageComponent from '../components/ImageExtension.js'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import BulletList from '@tiptap/extension-bullet-list'
import TextAlign from '@tiptap/extension-text-align'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import katex from 'katex'
import InputBoxComponent from '../components/inputBoxComponent.js';
import {tooltipbottomDiv, tooptipText } from '../atoms/div_styles';
import {iCanvas} from '@anshul565/icanvas'
// for local dev
// import {iCanvas} from '../../../../../../../../giteawrkspc-new/jslib/code/iCanvas/iCanvas-ui.js'
export default {
    name: 'input_component',
    components:{
        // 'c-toolbar-white-bar-div': toolbarWhiteBarDiv,
        //'c-toolbar-grab-handle-div': toolbarGrabHandleDiv,
        // 'c-toolbar-window-control-div': toolbarWindowControlDiv,
        // 'c-toolbar-undo-redo-div': toolbarUndoRedoDiv,
        // 'c-top-outside-toolbar-div': topOutsideToolbarDiv,
        'c-button-icon-div': buttonIconDiv,
        'c-formatting-toolbar-div': formattingToolbarDiv,
        'c-top-inside-toolbar-div': topInsideToolbarDiv,
        'c-input-entry-box-div': inputEntryBoxDiv,
        'c-main-vertical-input-div': mainVerticalInputDiv,
        // 'c-left-outside-toolbar-div': leftOutsideToolbarDiv,
        'c-input-field-marker': inputFieldMarkerDiv,
        'c-style-icon': style_icons,
        'c-form-div': formDiv,
        'editor-content': EditorContent,
        // 'c-tooltip-top-div': tooltiptopDiv,
        'c-tooltip-bottom-div': tooltipbottomDiv,
        'c-tooptip-text': tooptipText
        //'c-input-field-view-div': inputFieldViewDiv
    },
    props:{
        savedTextContent:{ // already saved content, when user is editing the challenge
            type: String,
            default: '<p></p>'
        },
        inputCompFor: { // for question_statement, question_instructions, for MCQ_option 1, MCQ_option 2, etc.
            type: String,
            default: ''
        },
        chal_id:{
            type: String,
            default: ''
        },
        challenge_type: String,
        editor_width:{
            type: String,
            default: '600px'
        },
        editor_height:{
            type: String,
            default: null // config have 400px as default
        },
        use_icanvas_editor:{
            type: Boolean,
            default: false
        },
        default_editor_mode:{
            type: String,
            default: 'text'
        },
        roomIdProp:{
            type: String,
        },
        userRoleInSlot:{
            type: String
        },
        canvasInViewMode:{
            type: Boolean,
            default: false
        },
        uniqueCompKey:{
            type: String,
            default: ''
        },
        showToolbar: {
            type: Boolean,
            default: true
        },
        showMaxCanvasMode:{
            type: Boolean,
            default: true
        },
        hideCanvasScroolbar:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            UI_baseURL: AppConfig.UI_Base_URL,
            auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
            editor: null, // rich text editor object, will use it to v-model the object value of rich text editor
            inputMode: true, // show the render mode if its false else go to edit mode
            richTextContent: '', // will hold the html content that rich text ediotr hold, and will be used to render it in view mode
            inFullScreenViewModeDataVar: false,
            chal_type : "",
            editor_mode: "text",
            iCanvasUiObj: null,
            // api_Base_URL: AppConfig.Auth_API_Base_URL,
            rocket_chat_live_api_obj: null,
            // webSocketUrl: 'wss://qroc.rasree.com/websocket',
            ICANVAS_API_KEY: "secret-key",
            mycanvasApi: null,
            mycanvasComm: null,
            roomName: ""
        }
    },
    async mounted() {
        this.editor_mode = this.default_editor_mode
        console.log("props are", {savedTextContent: this.savedTextContent, inputCompFor: this.inputCompFor, chal_type : this.challenge_type});
        this.editor = new Editor({
            content: this.savedTextContent, // initilize the starting content, use it when load a page again which already have some content added
            extensions: [
                FormulaComponent, //math katex component
                ImageComponent.configure({
                    vueInstance: this, // Pass the Vue instance, to use this.$store for drag and drop
                }),
                InputBoxComponent, //input box component
                StarterKit,
                Color,
                Underline,
                BulletList,
                TextStyle,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                    defaultAlignment: 'left',
                }),
            ],
        })
        
        console.log("Editor is", this.editor);
        console.log("chal_type", this.challenge_type);
        //this.saveAndToggleViewMode() // as when page load we will be in view mode
        if(this.editor_mode === 'iCanvas' && (this.roomIdProp !== '' && this.roomIdProp !== undefined)){
            await this.renderICanvas()
        }
    },
    watch:{
        // Watch for changes in iCanvasObj.api.blockSet and emit updates
        'iCanvasUiObj.api.blockSet': {
        handler(newVal) {
            if (newVal !== undefined) {
                // Ensure that the new value is defined before emitting
                this.$emit('block-set-changed', this.iCanvasUiObj?.api?.blockSet); // Using optional chaining to safely access nested properties
            }
        },
        deep: true // Add deep watching to handle nested changes
        },
        'iCanvasUiObj.canvasMode': {
        handler(newVal) {
            if (newVal !== undefined) {
                // Ensure that the new value is defined before emitting
                this.$emit('canvas-mode-change', this.iCanvasUiObj?.canvasMode); // Using optional chaining to safely access nested properties
            }
        },
        deep: true // Add deep watching to handle nested changes
        },
        'iCanvasUiObj.blockType': {
        handler(newVal) {
            if (newVal !== undefined) {
                // Ensure that the new value is defined before emitting
                this.$emit('block-type-change', this.iCanvasUiObj?.blockType); // Using optional chaining to safely access nested properties
            }
        },
        deep: true // Add deep watching to handle nested changes
        }
    },
    methods: {
        /**
        * Function to fetch config for canvas
        * like socket io URL, bot name, etc.
        * 
        * @param {string} room_name - name of the iCanvas room (in socket)
        * @function getICanvasConfigUtil
        */
        async getICanvasConfigUtil(){
            // TODO: @soma netops
        },
        async toggle_editor_mode(mode){
            // if(this.editor_mode === 'text'){
            //     this.editor_mode = 'iCanvas'
            // }
            // else if (this.editor_mode === 'iCanvas'){
            //     this.editor_mode = 'text'
            // }
            this.editor_mode = mode
            await this.sleep(1)
            if(this.editor_mode === 'iCanvas'){
                await this.renderICanvas()
            }
        },
        async deleteControlUtil(){
            // delete the block
            if(this.editor_mode === 'iCanvas'){
                // if editor is iCanvas
                await this.iCanvasUiObj.deleteBlock()
            }
        },
        async undoControlUtil(){
            // delete the block
            if(this.editor_mode === 'iCanvas'){
                // if editor is iCanvas
                await this.iCanvasUiObj.undoBlockState()
            }
        },
        async redoControlUtil(){
            // delete the block
            if(this.editor_mode === 'iCanvas'){
                // if editor is iCanvas
                await this.iCanvasUiObj.redoBlockState()
            }
        },
        canvasModeControlColor(controlType){
            if(this.iCanvasUiObj === null || this.iCanvasUiObj === undefined){
                return "black"
            }
            else if(controlType === this.iCanvasUiObj.canvasMode){
                return "#00B0B2"
            }
            return "black"
        },
        toolbarControlColor(controlType){
            if(this.iCanvasUiObj === null || this.iCanvasUiObj === undefined){
                return "black"
            }
            else if(controlType === this.iCanvasUiObj.blockType){
                return "#00B0B2"
            }
            return "black"
        },
        iCanvasModeControlClickUtil(canvasMode){
            if(this.iCanvasUiObj === null || this.iCanvasUiObj === undefined){
                return
            }
            if(canvasMode === 'visualMode'){
                this.iCanvasUiObj.canvasVisualModeClickControl()
            }
            else{
                this.iCanvasUiObj.canvasInsertModeClickControl()
            }
        },
        figureDiginkOprUtil(opr){
            this.iCanvasUiObj.figureControlClickEventUtil(opr)
        },
        iCanvasToolbarControlClickUtil(toolbarControl){
            if(this.iCanvasUiObj === null || this.iCanvasUiObj === undefined){
                return
            }
            if(toolbarControl === 'text'){
                this.iCanvasUiObj.textControlClickEvent()
            }
            else if(toolbarControl === 'digink'){
                this.iCanvasUiObj.diginkControlClickEvent()
            }
            else if(toolbarControl === 'english_digink'){
                this.iCanvasUiObj.engDiginkControlClickEvent()
            }
            else if(toolbarControl === 'math_digink'){
                this.iCanvasUiObj.mathDiginkControlClickEvent()
            }
            else if(toolbarControl === 'hindi_digink'){
                this.iCanvasUiObj.hindiDiginkControlClickEvent()
            }
            else if(toolbarControl === 'image'){
                this.iCanvasUiObj.imageControlClickEvent()
            }
            else if(toolbarControl === 'diagram'){
                this.iCanvasUiObj.diagramControlClickEvent()
            }
            else if(toolbarControl === 'katex'){
                this.iCanvasUiObj.katexControlClickEvent()
            }
        },
        showICanvasToolbarUtil(){
            if(this.iCanvasUiObj === null || this.iCanvasUiObj === undefined){
                return false
            }
            if(this.iCanvasUiObj.canvasMode === 'insertMode'){
                return true
            }
            return false
        },
        async renderICanvas(){
            this.roomName = this.roomIdProp //this.$route.name + "-" + this.$route.params.id
            // TODO: Check if valid attempt id or not
            let rcRoomConfigResponse = await axios.get(this.auth_api_Base_URL + "/v2_1/rcRoomConfig/" + this.roomName + "/")
            console.log("--- create room config---", rcRoomConfigResponse)
            let toolbarHeight = 54 // 54 for toolbar height
            let extraCanvasHeight = 138
            let canvasClientHight = document.getElementById('inputEditorClientId' + this.uniqueCompKey).clientHeight - ((this.showToolbar) ? toolbarHeight: 0) // or offsetHeight toinclude padding, scrollBar and borders.
            // canvas width is hard coded in attemptChallengeContent.vue file update that too if changes here
            let canvasClientWidth = 1136 // document.getElementById('inputEditorClientId').clientWidth
            // initialize the icanvas
            let canvas_config = { // config for ui layer controls, AM_REVIEW
                mainCanvasStyle:{
                    width: canvasClientWidth, //'600px',//(screen.width),//*0.60, // TODO: @anadi rework 0.1
                    height: canvasClientHight + extraCanvasHeight, //'300px', //(screen.height),//*0.70 // TODO: @anadi rework 0.2
                },
                mappedCanvas:{
                    id: 'mappedCanvasOuterDivId' + this.uniqueCompKey, // Id of the div containing the canvas in mapped surface
                    style:{
                        height: (window.innerHeight)/2
                    },
                    canvas:{
                        id: 'mainFullScreenCanvasID' + this.uniqueCompKey,
                        style:{
                            horizontalMargins: 50 + 50, // left + right
                            verticalMargins: 40 + 40, // top + bottom
                        }
                    }
                },
                style:{
                    width: canvasClientWidth, //'600px',//(screen.width),//*0.60, // TODO: @anadi rework 0.1
                    height: canvasClientHight, //'300px', //(screen.height),//*0.70 // TODO: @anadi rework 0.2
                    // fontSize: 52,
                    bandConfig:{
                        n: 17, // Non writing band of n pixel. 1/3 of fontsize
                        fontSize: 25, // total writing band height in pixel: 52
                        t: 0.3, // top margin, % of font size
                        m: 0.4, // main band, % of font size
                        b: 0.3, // bottom margin, % of font size
                    },
                    guideLines:{ // TODO: @anadi rework 1.0 , define n, t, m, b
                        type: 'guideLine',
                        style:{
                            stroke: '#FF0000'
                        }
                    }
                },
                canvasRendererOuter:{ // control for the canvas element
                    id: 'iCanvasRendererOuterDivId' + this.uniqueCompKey,
                    style: {
                        //border: '2px solid black',
                        overflowY: 'scroll',
                        height: canvasClientHight + 'px' //'300px', //'786px'
                    }
                },
                canvasRenderer:{ // control for the canvas element
                    id: 'iCanvasRendererId' + this.uniqueCompKey,
                    style: {
                    //border: '2px solid black',
                    //overflowY: 'scroll'
                    }
                },
                suggestionRenderer:{
                    id: 'sugg-render-outer' + this.uniqueCompKey + "-",
                    style:{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '16px',
                        gap: '8px',
                        flex: 'none',
                        order: '0',
                        alignSelf: 'stretch',
                        flexGrow: '0',
                        width: '500px',
                        height: 'auto',
                        background: '#F8FFFF',
                        boxShadow: '0px 0px 8px rgba(0,0,0,0.25)',
                        borderRadius: '8px',
                        position: 'relative',
                        zIndex: '10'
                    },
                    suggIcon:{
                        id: 'sugg' + this.uniqueCompKey + '-',
                    },
                    suggRenderInner:{
                        type: 'div',
                        id: 'sugg-render-inner' + this.uniqueCompKey + '-',
                        style:{
                            textAlign: 'left',
                            gap: '5px',
                            flexDirection: 'column',
                            display: 'flex'
                        },
                        suggRenderInnerItem:{
                            type: 'div',
                            id: 'sugg-render-inner-item' + this.uniqueCompKey + '-'
                        }
                    },
                    suggAcceptControl: {
                        type: 'button',
                        style: { // add style here
                            backgroundColor: '#4CAF50', /* Green */
                            color: 'white',
                            padding: '5px 5px',
                        },
                        textContent: "Accept"
                    },
                    suggCloseControl: {
                        type: 'button',
                        style: { // add style here
                            backgroundColor: '#4CAF50', /* Green */
                            color: 'white',
                            padding: '5px 5px',
                        },
                        textContent: "Close"
                    },
                    suggRejectControl: {
                        type: 'button',
                        style: { // add style here
                            backgroundColor: '#4CAF50', /* Green */
                            color: 'white',
                            padding: '5px 5px',
                        },
                        textContent: "Reject"
                    },
                },
                toolbar:{ // control for the toolbar element, buttons for operations will be rendered here in this element
                    id: 'iCanvasToolbarId' + this.uniqueCompKey,
                    showToolbar: false,
                    style: {
                        display: 'flex',
                        textAlign: 'left',
                        gap: '5px'
                    },
                    leftMenu:{
                        id: 'iCanvasToolbarLeftMenuId' + this.uniqueCompKey,
                        style: {
                            width:'100%',
                            gap: '5px',
                            display: 'flex'
                        }
                    },
                    rightMenu:{
                        id: 'iCanvasToolbarRightMenuId' + this.uniqueCompKey,
                        style: {
                            display: 'flex',
                            width: '400px',
                            float: 'right',
                            gap: '5px',
                            justifyContent: 'flex-end'
                        },
                        undoControl:{
                            type: 'button',
                            textContent: 'U',
                            style: { // add style here
                            backgroundColor: '#4CAF50', /* Green */
                            color: 'white',
                            padding: '5px 5px',
                            },
                        },
                        redoControl:{
                            type: 'button',
                            textContent: 'R',
                            style: { // add style here
                            backgroundColor: '#4CAF50', /* Green */
                            color: 'white',
                            padding: '5px 5px',
                            },
                        },
                        deleteControl:{
                            type: 'button',
                            textContent: 'D',
                            style: { // add style here
                            backgroundColor: '#4CAF50', /* Green */
                            color: 'white',
                            padding: '5px 5px',
                            },
                        },
                    }
                },
                canvasModeControl: {// how the other control will look when selected, default is selection mode
                    type: 'button',
                    visualMode:{
                        style:{
                            backgroundColor: 'Black', /* Green */
                            color: 'white',
                            padding: '15px 32px',
                        },
                        textContent: 'Visual mode'
                    },
                    insertMode:{
                        style:{
                            backgroundColor: 'Black', /* Green */
                            color: 'white',
                            padding: '15px 32px',
                        },
                        textContent: 'Insert mode'
                    }
                },
                textControl: {
                    type: 'button',
                    id: 'text-control' + this.uniqueCompKey,
                    style: { // add style here
                        backgroundColor: '#4CAF50', /* Green */
                        color: 'white',
                        padding: '15px 32px',
                    },
                    textContent: "Add Text Block"
                },
                diginkControl: {
                    type: 'button',
                    id: 'digink-control' + this.uniqueCompKey,
                    style: { // add style here
                        backgroundColor: '#4CAF50', /* Green */
                        color: 'white',
                        padding: '15px 32px',
                    },
                    textContent: "Draw"
                },
                englishDiginkControl: {
                    type: 'button',
                    id: 'english-digink-control' + this.uniqueCompKey,
                    style: { // add style here
                        backgroundColor: '#4CAF50', /* Green */
                        color: 'white',
                        padding: '15px 32px',
                    },
                    textContent: "English"
                },
                writtingStripRenderer:{
                    id: 'writtingStripRenderer' + this.uniqueCompKey,
                    type: 'div',
                    style:{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        //padding: '16px',
                        gap: '8px',
                        flex: 'none',
                        order: '0',
                        alignSelf: 'stretch',
                        flexGrow: '0',
                        //width: '100%',
                        height: 'auto',
                        // background: '#F8FFFF',
                        // boxShadow: '0px 0px 8px rgba(0,0,0,0.25)',
                        // borderRadius: '8px',
                        position: 'absolute',
                        zIndex: '11'
                    },
                    toolbar:{
                        type: 'div',
                        style:{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            textAlign: 'left',
                            gap: '5px',
                            width: '100%',
                            // background: '#F0FFFF',
                            // border: '1px solid #93ABAB',
                            // borderRadius: '8px 8px 0px 0px',
                            padding: '4px 24px'
                        },
                        saveControl:{
                            type: 'icon',
                            style: { // add style here
                                backgroundColor: '#4CAF50', /* Green */
                                color: 'white',
                                padding: '15px 32px',
                            },
                            textContent: "S"
                        },
                        closeControl:{
                            type: 'icon',
                            style: { // add style here
                                backgroundColor: '#4CAF50', /* Green */
                                color: 'white',
                                padding: '15px 32px',
                            },
                            textContent: "X"
                        }
                    },
                    writtingStrip:{
                        type: 'canvas',
                        id: 'writtingStrip' + this.uniqueCompKey
                    }
                }
            }
            let commConfig = { // config for communication layer
                backendBaseUrl: this.auth_api_Base_URL,
                roomDetails: {
                    roomId: rcRoomConfigResponse.data.roomName,
                    webSocketUrl: rcRoomConfigResponse.data.webSocketUrl,
                    botUsername: rcRoomConfigResponse.data.botUsername
                }
            }
            let user_role = this.userRoleInSlot //$keycloak.tokenParsed.primaryRole
            console.log("-- role of use in input ---", user_role);
            this.iCanvasUiObj = new iCanvas(this.ICANVAS_API_KEY, 'iCanvasRenderDivId' + this.uniqueCompKey, canvas_config, this.$keycloak, user_role, this.canvasInViewMode, commConfig.backendBaseUrl) // instance of ui layer, AM_REVIEW
            //console.log("------- my canvas------", iCanvas)
            await this.iCanvasUiObj.setupLayers(commConfig) // setup communication and api layers
            if(this.hideCanvasScroolbar){
                let iCanvasContainerElem = document.getElementById('iCanvasRendererOuterDivId' + this.uniqueCompKey)
                // iCanvasContainerElem.style.height = height
                iCanvasContainerElem.style.scrollbarWidth = 'none'
                iCanvasContainerElem.style.MsOverflowStyle = 'none'
            }
        },
        canvasHeightToggleUtil(height){
            let iCanvasContainerElem = document.getElementById('iCanvasRendererOuterDivId' + this.uniqueCompKey)
            iCanvasContainerElem.style.height = height
        },
        async toggleInputFieldViewAreaFn(){
            // toggle between full screen and default mode of input field
            console.log("toggle height of input field");
            this.inFullScreenViewModeDataVar = !this.inFullScreenViewModeDataVar
            this.$emit('inputCompFullScreenMode', this.inFullScreenViewModeDataVar)
            // this.$root.$children[0].toggleTopNavBar(!this.inFullScreenViewModeDataVar)
            await this.sleep(1)
            if(this.inFullScreenViewModeDataVar){
                var modal = document.getElementById("inputCompFullScreen_" + this.uniqueCompKey + this.inputCompFor);
                modal.style.display = "block";//change display from none to block so that we can have a fullscreen overlay
            }
            if(this.use_icanvas_editor){
                console.log("toggle full screen mode")
                if(this.editor_mode === 'iCanvas'){
                    await this.renderICanvas()
                }
            }
            else{
                await this.preview()
            }
            //let dataToEmit={}
            //dataToEmit['savedTextContent'] = this.editor.getHTML()
            //dataToEmit['inFullScreenViewMode'] = !this.inFullScreenViewMode
            //dataToEmit['inputCompFor'] = this.inputCompFor
            //this.$emit('emitToggleCompViewAreaEvent', dataToEmit) //emit this event so that parent component(create challenge) can catch it and change the view
        },
        highlightIconFn(stylename){
            console.log("stylename", stylename);
            let toggleColor = '1px solid red';
            let elemID = document.getElementById(stylename);
            if(elemID.style.border != toggleColor){
                elemID.style.border = toggleColor
                console.log("elemID", elemID.style.border);
            }
            else{
                elemID.style.border = 'none'
            }
        },
        addKatexFn(){
            let ts = Date.now()
            let uniq_key = "$formula_key_" + ts + "$"
            if(uniq_key in this.$store.state.files_manifest){
                console.log('error, key already exist');
                return
            }
            this.$store.commit('addKeyInFilesManifest', {
                key: uniq_key, type: "katex", value: '', opr: "add"
            })
            this.editor.chain().focus().addKatex({uniqkey: uniq_key, chal_id: this.chal_id}).run()
        },
        addInputBoxFn(){
            let ts = Date.now()
            let uniq_key = "$formula_key_" + ts + "$"
            if(uniq_key in this.$store.state.files_manifest){
                console.log('error, key already exist');
                return
            }
            this.$store.commit('addKeyInFilesManifest', {
                key: uniq_key, type: "inputBox", value: '', opr: "add"
            })
            this.editor.chain().focus().addInputBox({uniqkey: uniq_key, chal_id: this.chal_id}).run()
        },
        imageUploadedFn(e){
            //create url for the uploaded image
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            //console.log("file is", URL.createObjectURL(files[0]));
            //let createdImgUrl = URL.createObjectURL(files[0])
            //this.editor.chain().focus().setImage({ src: createdImgUrl }).run()

            // TODO: req api here to save the uploaded image and return us the url which we will save in the uniqkey attr
            /*
            let storeEditorRespBody = {}
            storeEditorRespBody["editorType"] = "image"
            storeEditorRespBody["imageFile"] = files[0]
            let storeApiResp = await axios.post(this.auth_api_Base_URL + "/v1_0/chalEditors/add/" + this.chal_id, storeEditorRespBody)
            console.log("storeApiResp is", storeApiResp)
            createdImgUrl = storeApiResp.resp["contentLink"]
            */
            let uniq_key = "$" + files[0].name + "_" + Date.now() + "$"
            if(uniq_key in this.$store.state.files_manifest){
                console.log('error, key already exist');
                return
            }
            this.$store.commit('addKeyInFilesManifest', {
                key: uniq_key, type: "image", value: files[0], opr: "add"
            })
            this.editor.chain().focus().addImage({uniqkey: uniq_key, chal_id: this.chal_id}).run()
            //this.editor.commands.updateAttributes('c-image', {url: createdImgUrl});
        },
        clickIconUploadImageFn(){
            // function will be called when the image icon will be cliccked and this function will then click on the hidden input field
            document.getElementById('ImageUploadID_' + this.uniqueCompKey + this.inputCompFor).click()
            console.log('value of inputCompFor is', this.inputCompFor);
        },
        async saveAndToggleViewMode(){
            // toggle between edit mode and view mode of input comp
            this.richTextContent = this.editor.getHTML() // store the HTML content in the data variable
            this.inputMode = false // as in view mode
            await this.sleep(1) // add some sleep time to let the DOM render the elements
            await this.preview() // call it to preview the content in view mode
            //this.toggleInputFieldViewAreaFn()
            //Emit the input values/content to the parent component, which will call api and store it in DB
            let dataToEmit={}
            dataToEmit['savedTextContent'] = this.editor.getHTML()
            dataToEmit['inputCompFor'] = this.inputCompFor
            this.$emit('emitSaveInputContentEvent', dataToEmit)
        },
        sleep(ms) {
            // add some sleep using this function , can be used when we want to wait some time to let page load the html
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async getEditorKeyContentFn(storedUniqKey){
        // request api if value of key is not in the stored manifest
        let storeApiResp = await axios.get(this.auth_api_Base_URL + "/v2_1/chalEditors/" + this.chal_id + "/?chal_editor=" + storedUniqKey)
        console.log("storeApiResp is", storeApiResp)
        return storeApiResp.data.actualContent
        // get rawFormula value from here, if apiResp return error means key not stored yet so new key and hence rawFormula will be just a empty string.
        },
        async preview() {
            // function to convert the rich text to renderable form
            let previewDiv = "" //document.getElementById('viewModeSection_' + this.inputCompFor) //document.querySelector('.final-content'); // get the elements from DOM where we want to render it
            if(this.inFullScreenViewModeDataVar){
                previewDiv = document.getElementById('fullScreenViewModeSection_' + this.uniqueCompKey + this.inputCompFor)
            }
            else{
                previewDiv = document.getElementById('defaultViewModeSection_' + this.uniqueCompKey + this.inputCompFor)
            }
            //console.log("preview div is", previewDiv);
            if(previewDiv){
                setTimeout(() => { // either add sleep or use setTimeout, the reason is DOM take time to update the richTextContent html so the queryselector was empty if no sleep is added
                    previewDiv.querySelectorAll('katex').forEach(async(el) => {
                        console.log('katex is', el);
                        //https://katex.org/docs/api.html
                        let katex_uniq_key = el.getAttribute('uniqkey')
                        let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                        if(this.$store.state.files_manifest[katex_uniq_key].value === null){
                            console.log("wait here");
                            valueToRender = await this.getEditorKeyContentFn(katex_uniq_key)
                        }
                        else{
                            valueToRender = this.$store.state.files_manifest[katex_uniq_key].value
                        }
                        katex.render(valueToRender, el, { //replace the katex element to renderable form using katex API
                            throwOnError: false,
                            strict: false,
                            displayMode: true,
                            maxSize: 300
                        });
                    })
                    previewDiv.querySelectorAll('textInput').forEach(async(el) => {
                        console.log('textInput is', el);
                        //https://katex.org/docs/api.html
                        let textInput_uniq_key = el.getAttribute('uniqkey')
                        let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                        console.log("valueToRender", valueToRender);
                        if(this.$store.state.files_manifest[textInput_uniq_key].value === null){
                            console.log("wait here");
                            valueToRender = await this.getEditorKeyContentFn(textInput_uniq_key)
                        }
                        else{
                            valueToRender = (this.$store.state.files_manifest[textInput_uniq_key].value)
                        }
                        katex.render(valueToRender, el, { //replace the katex element to renderable form using katex API
                            throwOnError: false,
                            strict: false,
                            displayMode: true,
                            maxSize: 300
                        });
                    })
                    previewDiv.querySelectorAll('c-image').forEach(el => { // render images using img tag and src as the created urls
                        if (!el.hasChildNodes()) {
                            //check if child already exist because if user click on preview again muliplte times then the appendChild function
                            //written below will add same amount of image child
                            console.log("image tag is", el);
                            let node = document.createElement("img");
                            let img_uniq_key = el.getAttribute('uniqkey')
                            let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                            console.log("---value", this.$store.state.files_manifest[img_uniq_key]);
                            if(this.$store.state.files_manifest[img_uniq_key].value === null){
                                valueToRender = this.auth_api_Base_URL + "/v2_1/chalEditors/" + this.chal_id + "/?chal_editor=" + img_uniq_key//await this.getEditorKeyContentFn(img_uniq_key, 'image')
                            }
                            else{
                                valueToRender = URL.createObjectURL(this.$store.state.files_manifest[img_uniq_key].value)
                            }
                            let createdImgUrl = valueToRender
                            node.setAttribute('src', createdImgUrl)
                            node.style.width = '500px'
                            node.style.height = '500px'
                            el.appendChild(node)
                        }
                    })
                }, 1)
            }
        },
    },
}
</script>
<style>
.inputCompModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  /*padding-top: 100px;*/ /* Location of the box */
  left: 0;
  top: 66px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.inputCompModal-content {
    background-color: #F8FFFF;
    border-radius: 8px;
    display: block;
    /* width: 100%; */
    height: -webkit-fill-available;
    position: fixed;
    padding: 16px;
}
.full-screen-content{
    width:100%;
    height : 90%;
    overflow-y : scroll;
    text-align: left;
}
.full-screen-content::-webkit-scrollbar {
        width: 0;  
        background: transparent;
        height: 0;
    }
.content {
      width: 100%;
      height: 350px;
      overflow: scroll;
      padding: 4px;
      text-align: left;
}
.content::-webkit-scrollbar {
        width: 0;  
        background: transparent;
        height: 0;
    }
.viewContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 350px;
    overflow: scroll;
    border: 1px solid ;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}
.viewContent::-webkit-scrollbar {
        width: 0;  
        background: transparent;
        height: 0;
    }
.ProseMirror{
    height:100%;
    width:100%;
    /*padding: 10px;*/
}

/* Add this to your global CSS file or in the component's scoped style */
.content .ProseMirror-focused {
  outline: none !important;
  border: none !important;
}

</style>
