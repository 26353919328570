import styled from "vue-styled-components";
import {theme} from '../assets/theme.js'
import config from '../assets/config.js'

const body_14_props={
    font_weight: Number, // multiple of 100
    color:String
}

export const body_14 = styled('p', body_14_props)`
    color: ${(props) => (props.color ?props.color:theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['Body-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-14']['font-style']};
    font-weight: ${(props) => (props.font_weight ? (props.font_weight)*100 : config['fonts']['lexend']['Body-14']['font-weight'])};
    font-size: ${config['fonts']['lexend']['Body-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-14']['line-height']};
`;
export const span_body_14 = styled('span', body_14_props)`
    color: ${(props) => (props.color ?props.color:theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['Body-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-14']['font-style']};
    font-weight: ${(props) => (props.font_weight ? (props.font_weight)*100 : config['fonts']['lexend']['Body-14']['font-weight'])};
    font-size: ${config['fonts']['lexend']['Body-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-14']['line-height']};
`;
const span_body_props={
    font_weight: Number, // multiple of 100
    color:String,
    line_height: Number,
    font_size: Number,
    margin_bottom: Number
}
export const span_body = styled('span', span_body_props)`
    color: ${theme['Primary-Cyan0500']};
    font-family: ${config['fonts']['lexend']['Body-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-14']['font-style']};
    margin-bottom: ${(props) => (props.margin_bottom ? props.margin_bottom:0)}px;
    font-weight: ${(props) => (props.font_weight ? (props.font_weight) : config['fonts']['lexend']['Body-14']['font-weight'])};
    font-size: ${(props) => (props.font_size ? (props.font_size) : config['fonts']['lexend']['Body-14']['font-size'])}px;
    line-height:${(props) => (props.line_height ? (props.line_height) : config['fonts']['lexend']['Body-14']['line-height'])}%;
`;

const body_UX_14_props={
    color:String
}
export const body_UX_14 = styled('p', body_UX_14_props)`
    color: ${(props) => (props.color ?props.color:theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
    font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
    font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    text-transform: ${config['fonts']['lexend']['Body-UX-14']['text-transform']};;
`;

const OVERLINE_11_props={
    color:String
}
export const OVERLINE_11 = styled('p', OVERLINE_11_props)`
    color: ${(props) => (props.color ?props.color:theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['OVERLINE-11']['font-family']};
    font-style: ${config['fonts']['lexend']['OVERLINE-11']['font-style']};
    font-weight: ${config['fonts']['lexend']['OVERLINE-11']['font-weight']};
    font-size: ${config['fonts']['lexend']['OVERLINE-11']['font-size']};
    line-height: ${config['fonts']['lexend']['OVERLINE-11']['line-height']};
`;

export const secondary_hyperlink = styled.p`
    color: ${theme['Secondary-Gray0700']};
    font-family: ${config['fonts']['lexend']['H5-18']['font-family']};
    font-style: ${config['fonts']['lexend']['H5-18']['font-style']};
    font-weight: ${config['fonts']['lexend']['H5-18']['font-weight']};
    font-size: ${config['fonts']['lexend']['H5-18']['font-size']};
    line-height: ${config['fonts']['lexend']['H5-18']['line-height']};
    text-decoration-line: underline;
`;

const H4_22_props={
    color:String
}

export const H4_22 = styled('p', H4_22_props)`
    color: ${(props) => (props.color ?props.color:theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['H4-22']['font-family']};
    font-style: ${config['fonts']['lexend']['H4-22']['font-style']};
    font-weight: ${config['fonts']['lexend']['H4-22']['font-weight']};
    font-size: ${config['fonts']['lexend']['H4-22']['font-size']};
    line-height: ${config['fonts']['lexend']['H4-22']['line-height']};
`;

const H2_34_props={
    color:String
}

export const H2_34 = styled('p',H2_34_props)`
    color: ${(props) => (props.color ? props.color : theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['H2-34']['font-family']};
    font-style: ${config['fonts']['lexend']['H2-34']['font-style']};
    font-weight: ${config['fonts']['lexend']['H2-34']['font-weight']};
    font-size: ${config['fonts']['lexend']['H2-34']['font-size']};
    line-height: ${config['fonts']['lexend']['H2-34']['line-height']};
`;
const H5_18_props={
    color:String
}

export const H5_18 = styled('p',H5_18_props)`
    color: ${theme['Secondary-Gray0800']};
    font-family: ${config['fonts']['lexend']['H5-18']['font-family']};
    font-style: ${config['fonts']['lexend']['H5-18']['font-style']};
    font-weight: ${config['fonts']['lexend']['H5-18']['font-weight']};
    font-size: ${config['fonts']['lexend']['H5-18']['font-size']};
    line-height: ${config['fonts']['lexend']['H5-18']['line-height']};
`;
const H1_43_props={
    color:String
}
export const H1_43 = styled('p', H1_43_props)`
    color: ${(props) => (props.color ? props.color:theme['Secondary-Gray0800'])};
    font-family: ${config['fonts']['lexend']['H1-43']['font-family']};
    font-style: ${config['fonts']['lexend']['H1-43']['font-style']};
    font-weight: ${config['fonts']['lexend']['H1-43']['font-weight']};
    font-size: ${config['fonts']['lexend']['H1-43']['font-size']};
    line-height: ${config['fonts']['lexend']['H1-43']['line-height']};
`;

const H3_27_props={
    color: String,
    margin_top: Number,
    margin_bottom: Number
}
export const H3_27 = styled('p', H3_27_props)`
    color: ${(props) => (props.color ? props.color:theme['Secondary-Gray0000'])};
    font-family: ${config['fonts']['lexend']['H3-27']['font-family']};
    font-style: ${config['fonts']['lexend']['H3-27']['font-style']};
    font-weight: ${config['fonts']['lexend']['H3-27']['font-weight']};
    font-size: ${config['fonts']['lexend']['H3-27']['font-size']};
    line-height: ${config['fonts']['lexend']['H3-27']['line-height']};
`;

const breadCrumbTextProps={
    color: String
}
export const breadCrumbText = styled('p', breadCrumbTextProps)`
    color: ${(props) => (props.color ?props.color:theme['Secondary-Gray0700'])};
    font-family: ${config['fonts']['lexend']['Bread-Crumb-Text']['font-family']};
    font-style: ${config['fonts']['lexend']['Bread-Crumb-Text']['font-style']};
    font-weight: ${config['fonts']['lexend']['Bread-Crumb-Text']['font-weight']};
    font-size: ${config['fonts']['lexend']['Bread-Crumb-Text']['font-size']};
    line-height: ${config['fonts']['lexend']['Bread-Crumb-Text']['line-height']};
`;
const logoTextProps={
    color: String
}
export const logoText = styled('p', logoTextProps)`
    color: ${theme['Secondary-Gray0800']};
    font-family: ${config['fonts']['lexend']['logo-text']['font-family']};
    font-style: ${config['fonts']['lexend']['logo-text']['font-style']};
    font-weight: ${config['fonts']['lexend']['logo-text']['font-weight']};
    font-size: ${config['fonts']['lexend']['logo-text']['font-size']};
    line-height: ${config['fonts']['lexend']['logo-text']['line-height']};
`;
const caption_9_Props={
    color: String
}
export const Caption_9 = styled('p', caption_9_Props)`
    color: ${theme['Secondary-Gray0800']};
    font-family: ${config['fonts']['lexend']['Caption-9']['font-family']};
    font-style: ${config['fonts']['lexend']['Caption-9']['font-style']};
    font-weight: ${config['fonts']['lexend']['Caption-9']['font-weight']};
    font-size: ${config['fonts']['lexend']['Caption-9']['font-size']};
    line-height: ${config['fonts']['lexend']['Caption-9']['line-height']};
`;
const largeTitleProps={
    color: String,
    lineHeight: String
}
export const largeTitle = styled('p', largeTitleProps)`
    color: ${(props) => (props.color ? props.color : theme['PrimaryCyan0400-Default'])};
    font-family: ${config['fonts']['lexend']['large-title']['font-family']};
    font-style: ${config['fonts']['lexend']['large-title']['font-style']};
    font-weight: ${config['fonts']['lexend']['large-title']['font-weight']};
    font-size: ${config['fonts']['lexend']['large-title']['font-size']};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : config['fonts']['lexend']['large-title']['line-height'])};
    text-transform: ${config['fonts']['lexend']['large-title']['text-transform']};
`;