import styled from "vue-styled-components";
//import {theme} from '../assets/theme.js'
import config from '../assets/config.js'

const tagProps = {
    width: Number, //multiple of default value
}

export const tagButton = styled('button', tagProps)`
    box-sizing: ${config['tags']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['tags']['display'])};
    flex-direction: ${config['tags']['flex-direction']};
    align-items: ${config['tags']['align-items']};
    padding: ${config['tags']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['tags']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' :config['tags']['width'])};
    height: ${config['tags']['height']}px;
    background: ${config['tags']['background']};
    border: ${config['tags']['border']};
    border-radius: ${config['tags']['border-radius']};
    flex: ${config['tags']['flex']};
    order: ${config['tags']['order']};
    flex-grow: ${config['tags']['flex-grow']};
`;
