<template>
    <c-meet-team-section>
        <c-meet-team-title>
            <c-H4-22 :color="colorTheme['Secondary-Gray0700']">Meet the team</c-H4-22>
            <!-- TODO: Can we remove View more from 'Meet the team' Section?-->
            <!-- <c-btn-secondary id="inputViewTeamDetailsButton" @click.native="gotoAboutTeamPage">View more</c-btn-secondary> -->
        </c-meet-team-title>
        <c-abhyas-team>
            <c-abhyas-team-avatar-card v-for="(team, index) in teamsDetails" :key="index">
                <c-avatar>
                    <c-mask-group-div  alt=""></c-mask-group-div>
                </c-avatar>
                <c-row-div style="width: 100%;" text_transform="none" display="contents">
                    <c-H4-22 >{{ team.name }}</c-H4-22>
                </c-row-div>
                <c-row-div  style="width: 100%;"  display="contents" >
                    <c-overline-11 >{{ team.role }}</c-overline-11>
                </c-row-div>
                <c-row-div  style="width: 100%; justify-content: center;" text_transform="none" textalign="left">
                    <c-body-14 :color="colorTheme['Secondary-Gray0700']"> member since {{ team.joined_year }} in rasree</c-body-14>
                </c-row-div>
            </c-abhyas-team-avatar-card>
        </c-abhyas-team>
    </c-meet-team-section>
</template>
<script>
//import abhyas_team from './abhyas-team.vue'
import {rowDiv, meetTeamSectionDiv, meetTeamTitleDiv, abhyasTeamDiv, abhyasTeamAvatarCardDiv, avatarDiv, maskGroupDiv} from '../atoms/div_styles'
import {theme} from '../assets/theme.js'
// import buttonSecondary from '../molecules/button_secondary.vue'
import {H4_22, OVERLINE_11, body_14} from '../atoms/text_styles'
import AppConfig from '../../config.js';

export default {
    name:'meetTeamSection',
    components:{
        // 'c-btn-secondary': buttonSecondary,
        'c-H4-22': H4_22,
        'c-body-14': body_14,
        'c-overline-11': OVERLINE_11,
        //'c-abhyas-team':abhyas_team,
        'c-meet-team-section' : meetTeamSectionDiv,
        'c-meet-team-title': meetTeamTitleDiv,
        'c-abhyas-team': abhyasTeamDiv,
        'c-abhyas-team-avatar-card': abhyasTeamAvatarCardDiv,
        'c-avatar': avatarDiv,
        'c-mask-group-div': maskGroupDiv,
        'c-row-div': rowDiv
    },
    props:{
        meetTheTeamListData:Array
    },
    data(){
        return{
            UI_baseURL: AppConfig.UI_Base_URL,
            colorTheme:theme,
            teamsDetails: []
        }
    },
    beforeMount() {
        console.log('request API to get the details of team members', this.meetTheTeamListData);
        this.teamsDetails = this.meetTheTeamListData 
    },
    methods: {
        // gotoAboutTeamPage(){
        //      //redirect to search browse page
        //     let aboutTeamPagePageUrl = this.UI_baseURL + '/about/team'
        //     console.log("aboutTeamPagePageUrl ", aboutTeamPagePageUrl)
        //     window.location.href = aboutTeamPagePageUrl
        // },
    },
    
}
</script>