import styled from "vue-styled-components"
import {theme} from '../assets/theme.js'
import config from '../assets/config.js'
import heroSectionImage from '../assets/images/rasree-background.webp'
import avatarImage from '../assets/images/avatar.png'
import profileImage from '../assets/images/profileImage.png'

export const navLinkOuterDiv = styled.div`
    background: ${theme['Secondary-Gray0000']};
    border-radius: 28.8px;
`;

export const navLinkInnerDiv = styled.div`
    background: ${theme['Secondary-Gray0100']};
    padding: 8px;
`;
export const inputField = styled.input`
    font-size: 1.25em;
    padding: 0.5em;
    color: black;
    border: 1px solid black;
    background-color: ${theme['Primary-white']};
    border-radius: 3px;
`;
export const selectField = styled.select`
    font-size: 1.25em;
    padding: 0.5em;
    color: black;
    border: 1px solid black;
    background-color: ${theme['Primary-white']};
    border-radius: 3px;
`;
export const optionField = styled.option`
    font-size: 1.25em;
    padding: 0.5em;
    color: black    ;
    border: 1px solid black;
    background-color: ${theme['Primary-white']};
    border-radius: 3px;
`;
export const selectDiv = styled.select`
    width: 100%;
    height:35px;
    background: #F8FFFF;
    padding-left: 15px;
    font-size: 14px;
    border: 1px solid #93ABAB;
    border-radius: 8px;
    flex-grow: ${config['divs']['inputSearchBox']['flex-grow']};
    font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
    font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
    font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    color: ${theme['Secondary-Gray0800']};
    ::placeholder {
        color: ${(props) => (props.placeholderColor ?props.placeholderColor:theme['Secondary-Gray0500'])};
        font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
        font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
        font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
        font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
        line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    }
    &:focus{
        outline: none;
        border: 1px solid ${theme['PrimaryCyan0400-Default']};
        ::placeholder {
            color: ${theme['Secondary-Gray0800']};
            font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
            font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
            font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
            font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
            line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
        }
    }
       option {
        background-color: ${theme['Secondary-Gray0000']}!important;
        border-radius: 8px 8px 0px 0px; !important;
        border: 1px solid #00B0B3;
        color: ${theme['Secondary-Gray0800']} !important;
        height: 36px;
        font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
        font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
        font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
        font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
        line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
        &::hover{
           background: ${theme['PrimaryCyan0400-Default']}!important;
        }
        }
`;
export const optionDiv = styled.option`
    color: ${theme['Secondary-Gray0800']} !important;
    border: 1px solid ${theme['PrimaryCyan0400-Default']}!important;
    border-radius:8px 8px 0px 0px;
    background-color: ${theme['Secondary-Gray0000']}!important;
    &:hover {
        background-color: ${theme['PrimaryCyan0400-Default']}!important
    }
`;


const rowDivProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    overflow: String,
    gap: Number //multiple of 8, default 0
};

export const row = styled("div", rowDivProps)`
    display: ${(props) => (props.gap ? 'flex' : 'block')};
    overflow: ${(props) => (props.overflow ? props.overflow : 'hidden')};
    flex-direction: column;
    text-tranform:none;
    width: ${(props) => ((props.width) ? (props.width)*10 + "%" : "auto")};
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }      
`;

const colDivProps = {
    width: Number, // width is multiple of 10
    padding: Number,
    textalign: String,
    float: String,
    gap: Number, //multiple of 8, default 0
    overflow: String,
    background_color: String,
};

export const col = styled("div", colDivProps)`
    display: ${(props) => (props.gap ? 'grid' : 'block')};
    overflow: ${(props) => (props.overflow ? 'scroll' : 'hidden')}
    background: ${(props) => (props.background_color ? props.background_color: 'transparent')};
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    width: ${(props) => (props.width ? props.width*10+"%" : "auto")};
    float: ${(props) => (props.float ? props.float : "")};
    padding: ${(props) => ((props.padding) ? (props.padding) : 0)}px;
`;

const cardsProps = {
    width: String, // width is multiple of 10
    padding: String, // multiple of 8
    background_color: String
};

export const cards = styled("div", cardsProps)`
    background: ${(props) => (props.background_color ? props.background_color:theme['Secondary-Gray0000'])};
    border-radius: ${config['divs']['card']['border-radius']}px;
    width: ${(props) => (props.width ? props.width*10 : 100)}%;
    float: left;
    height:${config['divs']['card']['height']}%;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    box-shadow: ${config['divs']['card']['box-shadow']};
    flex: ${config['divs']['card']['flex']};
    order: ${config['divs']['card']['order']};
    align-self: ${config['divs']['card']['align-self']};
    flex-grow: ${config['divs']['card']['flex-grow']};
`;
const loaderDivProps = {
    width: String
};

export const loaderDiv = styled("div", loaderDivProps)`
    width: ${(props) => (props.width ? props.width : config['divs']['loaderDiv']['width'])};
    height: ${(props) => (props.height ? props.height : config['divs']['loaderDiv']['height'])};
    border: ${config['divs']['loaderDiv']['border']}${theme['Secondary-Gray0200']};
    border-radius: ${config['divs']['loaderDiv']['border-radius']};
    border-top: ${config['divs']['loaderDiv']['border-top']}${theme['PrimaryCyan0400-Default']};
    -webkit-animation : ${config['divs']['loaderDiv']['-webkit-animation']};
    animation : ${config['divs']['loaderDiv']['animation']};
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;
const registrationBoxDivProps = {
    width: String, 
    padding: String,
    display: String
};

export const registrationBoxDiv = styled("div", registrationBoxDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['registrationBoxDiv']['display'])};
    flex-direction: ${config['divs']['registrationBoxDiv']['flex-direction']};
    align-items: ${config['divs']['registrationBoxDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['registrationBoxDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['registrationBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['registrationBoxDiv']['width'])};
    height: ${config['divs']['registrationBoxDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['registrationBoxDiv']['box-shadow']};
    border-radius:${config['divs']['registrationBoxDiv']['border-radius']};
    flex: ${config['divs']['registrationBoxDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['registrationBoxDiv']['order'])};
    flex-grow: ${config['divs']['registrationBoxDiv']['flex-grow']};
`;
const aboutCourseSessionDivProps = {
    width: String, 
    padding: String,
    display: String
};

export const aboutCourseSessionDiv = styled("div", aboutCourseSessionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutCourseSessionDiv']['display'])};
    flex-direction: ${config['divs']['aboutCourseSessionDiv']['flex-direction']};
    align-items: ${config['divs']['aboutCourseSessionDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['aboutCourseSessionDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['aboutCourseSessionDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutCourseSessionDiv']['width'])};
    height: ${config['divs']['aboutCourseSessionDiv']['height']};
    overflow-y:${config['divs']['aboutCourseSessionDiv']['overflow-y']};
    flex: ${config['divs']['aboutCourseSessionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['aboutCourseSessionDiv']['order'])};
    flex-grow: ${config['divs']['aboutCourseSessionDiv']['flex-grow']};
`;
const actionButtonsDivProps = {
    width: String, 
    padding: String,
    display: String
};

export const actionButtonsDiv = styled("div", actionButtonsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['actionButtonsDiv']['display'])};
    flex-direction: ${config['divs']['actionButtonsDiv']['flex-direction']};
    align-items: ${config['divs']['actionButtonsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['actionButtonsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['actionButtonsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['actionButtonsDiv']['width'])};
    height: ${config['divs']['actionButtonsDiv']['height']};
    flex: ${config['divs']['actionButtonsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['actionButtonsDiv']['order'])};
    align-self: ${config['divs']['actionButtonsDiv']['align-self']};
    flex-grow: ${config['divs']['actionButtonsDiv']['flex-grow']};
`;
const aboutCardDivProps = {
    width: Number, 
    padding: Number
}
export const aboutCardDiv = styled("div", aboutCardDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutCardDiv']['display'])};
    flex-direction: ${config['divs']['aboutCardDiv']['flex-direction']};
    align-items: ${config['divs']['aboutCardDiv']['align-items']};
    padding: ${config['divs']['aboutCardDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['aboutCardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutCardDiv']['width'])};
    height: ${config['divs']['aboutCardDiv']['height']};
    flex: ${config['divs']['aboutCardDiv']['flex']};
    order: ${config['divs']['aboutCardDiv']['order']};
    align-self: ${config['divs']['aboutCardDiv']['align-self']};
    flex-grow: ${config['divs']['aboutCardDiv']['flex-grow']};
`;
const aboutClassCardDivProps = {
    width: Number, 
    padding: Number
}
export const aboutClassCardDiv = styled("div", aboutClassCardDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutClassCardDiv']['display'])};
    flex-direction: ${config['divs']['aboutClassCardDiv']['flex-direction']};
    align-items: ${config['divs']['aboutClassCardDiv']['align-items']};
    padding: ${config['divs']['aboutClassCardDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['aboutClassCardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutClassCardDiv']['width'])};
    height: ${config['divs']['aboutClassCardDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['aboutClassCardDiv']['box-shadow']};
    border-radius:${config['divs']['aboutClassCardDiv']['border-radius']};
    flex: ${config['divs']['aboutClassCardDiv']['flex']};
    order: ${config['divs']['aboutClassCardDiv']['order']};
    align-self: ${config['divs']['aboutClassCardDiv']['align-self']};
    flex-grow: ${config['divs']['aboutClassCardDiv']['flex-grow']};
`;
const oneSecondaryButtonCardDivProps = {
    width: Number, 
    padding: Number
}
export const oneSecondaryButtonCardDiv = styled("div", oneSecondaryButtonCardDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['oneSecondaryButtonCardDiv']['display'])};
    flex-direction: ${config['divs']['oneSecondaryButtonCardDiv']['flex-direction']};
    align-items: ${config['divs']['oneSecondaryButtonCardDiv']['align-items']};
    padding: ${config['divs']['oneSecondaryButtonCardDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['oneSecondaryButtonCardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['oneSecondaryButtonCardDiv']['width'])};
    height: ${config['divs']['oneSecondaryButtonCardDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['oneSecondaryButtonCardDiv']['box-shadow']};
    border-radius:${config['divs']['oneSecondaryButtonCardDiv']['border-radius']};
    flex: ${config['divs']['oneSecondaryButtonCardDiv']['flex']};
    order: ${config['divs']['oneSecondaryButtonCardDiv']['order']};
    align-self: ${config['divs']['oneSecondaryButtonCardDiv']['align-self']};
    flex-grow: ${config['divs']['oneSecondaryButtonCardDiv']['flex-grow']};
`;
const aboutTutorCardDivProps = {
    width: Number, 
    padding: Number
}
export const aboutTutorCardDiv = styled("div", aboutTutorCardDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutTutorCardDiv']['display'])};
    flex-direction: ${config['divs']['aboutTutorCardDiv']['flex-direction']};
    align-items: ${config['divs']['aboutTutorCardDiv']['align-items']};
    padding: ${config['divs']['aboutTutorCardDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['aboutTutorCardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutTutorCardDiv']['width'])};
    height: ${config['divs']['aboutTutorCardDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['aboutTutorCardDiv']['box-shadow']};
    border-radius:${config['divs']['aboutTutorCardDiv']['border-radius']};
    flex: ${config['divs']['aboutTutorCardDiv']['flex']};
    order: ${config['divs']['aboutTutorCardDiv']['order']};
    align-self: ${config['divs']['aboutTutorCardDiv']['align-self']};
    flex-grow: ${config['divs']['aboutTutorCardDiv']['flex-grow']};
`;
const aboutTutorAndOrgDivProps = {
    width: Number, 
    padding: Number
}
export const aboutTutorAndOrgDiv = styled("div", aboutTutorAndOrgDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutTutorAndOrgDiv']['display'])};
    flex-direction: ${config['divs']['aboutTutorAndOrgDiv']['flex-direction']};
    align-items: ${config['divs']['aboutTutorAndOrgDiv']['align-items']};
    padding: ${config['divs']['aboutTutorAndOrgDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['aboutTutorAndOrgDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutTutorAndOrgDiv']['width'])};
    height: ${config['divs']['aboutTutorAndOrgDiv']['height']};
    flex: ${config['divs']['aboutTutorAndOrgDiv']['flex']};
    order: ${config['divs']['aboutTutorAndOrgDiv']['order']};
    flex-grow: ${config['divs']['aboutTutorAndOrgDiv']['flex-grow']};
`;
const tutorAndOrgDivProps = {
    width: Number, 
    padding: Number
}
export const tutorAndOrgDiv = styled("div", tutorAndOrgDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['tutorAndOrgDiv']['display'])};
    flex-direction: ${config['divs']['tutorAndOrgDiv']['flex-direction']};
    align-items: ${config['divs']['tutorAndOrgDiv']['align-items']};
    padding: ${config['divs']['tutorAndOrgDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['tutorAndOrgDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['tutorAndOrgDiv']['width'])};
    height: ${config['divs']['tutorAndOrgDiv']['height']};
    flex: ${config['divs']['tutorAndOrgDiv']['flex']};
    order: ${config['divs']['tutorAndOrgDiv']['order']};
    flex-grow: ${config['divs']['tutorAndOrgDiv']['flex-grow']};
    overflow-x: ${config['divs']['tutorAndOrgDiv']['overflow-x']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
`;
const cardCTADivProps = {
    width: Number, 
    padding: Number
}
export const cardCTADiv = styled("div", cardCTADivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['cardCTADiv']['display'])};
    flex-direction: ${config['divs']['cardCTADiv']['flex-direction']};
    align-items: ${config['divs']['cardCTADiv']['align-items']};
    padding: ${config['divs']['cardCTADiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['cardCTADiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['cardCTADiv']['width'])};
    height: ${config['divs']['cardCTADiv']['height']};
    flex: ${config['divs']['cardCTADiv']['flex']};
    order: ${config['divs']['cardCTADiv']['order']};
    align-self: ${config['divs']['cardCTADiv']['align-self']};
    flex-grow: ${config['divs']['cardCTADiv']['flex-grow']};
`;
const selectProps = {
    width: Number, //multiple of default value
}

export const selectButton = styled('select', selectProps)`
    width: ${(props) => ((props.width) ? (props.width)+'px' :config['divs']['selectDiv']['width'])};
    height: ${config['divs']['selectDiv']['height']};
    background: ${config['divs']['selectDiv']['background']};
    border: ${config['divs']['selectDiv']['border']};
    border-radius: ${config['divs']['selectDiv']['border-radius']};
    text-align: ${config['divs']['selectDiv']['text-align']};
    font-style: ${config['divs']['selectDiv']['font-style']};
    font-weight: ${config['divs']['selectDiv']['font-weight']};
    font-size: ${config['divs']['selectDiv']['font-size']};
    line-height: ${config['divs']['selectDiv']['font-height']};
`;
const optionProps = {
    width: Number, //multiple of default value
}

export const optionButton = styled('option', optionProps)`
    width: ${(props) => ((props.width) ? (props.width)+'px' :config['divs']['optionDiv']['width'])};
    height: ${config['divs']['optionDiv']['height']};
    background: ${config['divs']['optionDiv']['background']};
    border: ${config['divs']['optionDiv']['border']};
    border-radius: ${config['divs']['optionDiv']['border-radius']};
    text-align: ${config['divs']['optionDiv']['text-align']};
    font-style: ${config['divs']['optionDiv']['font-style']};
    font-weight: ${config['divs']['optionDiv']['font-weight']};
    font-size: ${config['divs']['optionDiv']['font-size']};
    line-height: ${config['divs']['optionDiv']['font-height']};
`;

const menuModalProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String
}
export const menuModal = styled("div", menuModalProps)`
    box-sizing: ${config['divs']['menuModal']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['menuModal']['display'])};
    flex-direction: ${config['divs']['menuModal']['flex-direction']};
    align-items: ${config['divs']['menuModal']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['menuModal']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['menuModal']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['menuModal']['width'])};
    height: ${config['divs']['menuModal']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0200']};
    box-shadow:${config['divs']['menuModal']['box-shadow']};
    border-radius:${config['divs']['menuModal']['border-radius']};
`;
const profileModalProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String
}
export const profileModal = styled("div", profileModalProps)`
    box-sizing: ${config['divs']['profileModal']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['profileModal']['display'])};
    flex-direction: ${config['divs']['profileModal']['flex-direction']};
    align-items: ${config['divs']['profileModal']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['profileModal']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['profileModal']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['profileModal']['width'])};
    height: ${config['divs']['profileModal']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0200']};
    box-shadow:${config['divs']['profileModal']['box-shadow']};
    border-radius:${config['divs']['profileModal']['border-radius']};
`;
export const nameDiv = styled("div", profileModalProps)`
    box-sizing: ${config['divs']['nameDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['nameDiv']['display'])};
    flex-direction: ${config['divs']['nameDiv']['flex-direction']};
    justify-content: ${config['divs']['nameDiv']['justify-content']};
    align-items: ${config['divs']['nameDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['nameDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['nameDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['nameDiv']['width'])};
    height: ${config['divs']['nameDiv']['height']};
    border-bottom: 1px solid ${theme['Secondary-Gray0600']};
    flex: ${config['divs']['nameDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['nameDiv']['order'])};
    align-self: ${config['divs']['nameDiv']['align-self']};
    flex-grow: ${config['divs']['nameDiv']['flex-grow']};
    color: ${theme['Secondary-Gray0700']};
`;

const waitingForApprovalSectionDivProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String
}
export const waitingForApprovalSectionDiv = styled("div", waitingForApprovalSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['waitingForApprovalSectionDiv']['display'])};
    flex-direction: ${config['divs']['waitingForApprovalSectionDiv']['flex-direction']};
    align-items: ${config['divs']['waitingForApprovalSectionDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['waitingForApprovalSectionDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['waitingForApprovalSectionDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['waitingForApprovalSectionDiv']['width'])};
    height: ${config['divs']['waitingForApprovalSectionDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    flex: ${config['divs']['waitingForApprovalSectionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['waitingForApprovalSectionDiv']['order'])};
    align-self: ${config['divs']['waitingForApprovalSectionDiv']['align-self']};
    flex-grow: ${config['divs']['waitingForApprovalSectionDiv']['flex-grow']};
`;
export const waitingForApprovalSectionTitleDiv = styled("div", waitingForApprovalSectionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['waitingForApprovalSectionTitleDiv']['display'])};
    flex-direction: ${config['divs']['waitingForApprovalSectionTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['waitingForApprovalSectionTitleDiv']['justify-content']};
    align-items: ${config['divs']['waitingForApprovalSectionTitleDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['waitingForApprovalSectionTitleDiv']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['waitingForApprovalSectionTitleDiv']['width'])};
    height: ${config['divs']['waitingForApprovalSectionTitleDiv']['height']};
    flex: ${config['divs']['waitingForApprovalSectionTitleDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['waitingForApprovalSectionTitleDiv']['order'])};
    align-self: ${config['divs']['waitingForApprovalSectionTitleDiv']['align-self']};
    flex-grow: ${config['divs']['waitingForApprovalSectionTitleDiv']['flex-grow']};
`;
const myDraftsDivProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String,
    background_color : String
}
export const myDraftsDiv = styled("div", myDraftsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['myDraftsDiv']['display'])};
    flex-direction: ${config['divs']['myDraftsDiv']['flex-direction']};
    align-items: ${config['divs']['myDraftsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['myDraftsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['myDraftsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['myDraftsDiv']['width'])};
    height: ${config['divs']['myDraftsDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    flex: ${config['divs']['myDraftsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['myDraftsDiv']['order'])};
    align-self: ${config['divs']['myDraftsDiv']['align-self']};
    flex-grow: ${config['divs']['myDraftsDiv']['flex-grow']};
`;
export const myDraftsTitleDiv = styled("div", myDraftsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['myDraftsTitleDiv']['display'])};
    flex-direction: ${config['divs']['myDraftsTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['myDraftsTitleDiv']['justify-content']};
    align-items: ${config['divs']['myDraftsTitleDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['myDraftsTitleDiv']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['myDraftsTitleDiv']['width'])};
    height: ${config['divs']['myDraftsTitleDiv']['height']};
    flex: ${config['divs']['myDraftsTitleDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['myDraftsTitleDiv']['order'])};
    align-self: ${config['divs']['myDraftsTitleDiv']['align-self']};
    flex-grow: ${config['divs']['myDraftsTitleDiv']['flex-grow']};
`;
export const myDraftsCourseTitleRowDiv = styled("div", myDraftsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['frames']['myDraftsCourseTitleRowDiv']['display'])};
    flex-direction: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['flex-direction']};
    justify-content: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['justify-content']};
    align-items: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['frames']['myDraftsCourseTitleRowDiv']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['myDraftsCourseTitleRowDiv']['width'])};
    height: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['height']};
    flex: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['frames']['myDraftsCourseTitleRowDiv']['order'])};
    align-self: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['align-self']};
    flex-grow: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['flex-grow']};
    z-index: ${config['divs']['frames']['myDraftsCourseTitleRowDiv']['z-index']};
`;
export const badgeCard = styled("p", myDraftsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['badgeCard']['display'])};
    flex-direction: ${config['divs']['badgeCard']['flex-direction']};
    align-items: ${config['divs']['badgeCard']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['badgeCard']['padding'])};
    gap: ${config['divs']['badgeCard']['gap']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['badgeCard']['width'])};
    height: ${config['divs']['badgeCard']['height']};
    flex: ${config['divs']['badgeCard']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['badgeCard']['order'])};
    flex-grow: ${config['divs']['badgeCard']['flex-grow']};
    z-index: ${config['divs']['badgeCard']['z-index']};
    font-family: ${config['fonts']['lexend']['OVERLINE-11']["font-family"]};
    font-style: ${config['fonts']['lexend']['OVERLINE-11']["font-style"]};
    font-weight: ${config['fonts']['lexend']['OVERLINE-11']["font-weight"]};
    font-size: ${config['fonts']['lexend']['OVERLINE-11']["font-size"]};
    line-height: ${config['fonts']['lexend']['OVERLINE-11']["line-height"]};
    text-align: ${config['divs']['badgeCard']['text-align']};
    text-transform: ${config['divs']['badgeCard']['text-transform']};
    color: ${theme['Primary-white']};
    background: ${(props) => ((props.background_color) ? (props.background_color) : theme['Secondary-Gray0700'])};
`;
const navBarDesktopProps = {
    width: String
}
export const navBarDesktop = styled("div", navBarDesktopProps)`
    display: ${config['divs']['navBarDesktop']['display']};
    flex-direction: ${config['divs']['navBarDesktop']['flex-direction']};
    align-items: ${config['divs']['navBarMobile']['align-items']};
    justify-content: ${config['divs']['navBarDesktop']['justify-content']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['navBarDesktop']['width'])};
    height: ${config['divs']['navBarDesktop']['height']};
`;
const navBarMobileProps = {
    width: String
}
export const navBarMobile = styled("div", navBarMobileProps)`
    display: ${config['divs']['navBarMobile']['display']};
    flex-direction: ${config['divs']['navBarMobile']['flex-direction']};
    align-items: ${config['divs']['navBarMobile']['align-items']};
    gap: ${config['divs']['navBarMobile']['gap']};
    justify-content: ${config['divs']['navBarMobile']['justify-content']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['navBarMobile']['width'])};
    height: ${config['divs']['navBarMobile']['height']};
`;
const topNavBarDivProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String
}
export const topNavBarDiv = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['topNavBarDiv']['display'])};
    flex-direction: ${config['divs']['topNavBarDiv']['flex-direction']};
    justify-content: ${config['divs']['topNavBarDiv']['justify-content']};
    align-items: ${config['divs']['topNavBarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['topNavBarDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['topNavBarDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['topNavBarDiv']['width'])};
    height: ${config['divs']['topNavBarDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border-bottom: 1px solid ${theme['Secondary-Gray0800']};
    box-shadow:${config['divs']['topNavBarDiv']['box-shadow']};
    position: ${config['divs']['topNavBarDiv']['position']};
    z-index: ${config['divs']['topNavBarDiv']['z-index']};
    flex: ${config['divs']['topNavBarDiv']['flex']};
    order: ${config['divs']['topNavBarDiv']['order']};
    flex-grow: ${config['divs']['topNavBarDiv']['flex-grow']};
    overflow-x: scroll
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
`;
export const logoDiv = styled("div", topNavBarDivProps)`
    display: ${config['divs']['logoDiv']['display']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['logoDiv']['width'])};
    height: ${config['divs']['logoDiv']['height']};
    flex: ${config['divs']['logoDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['logoDiv']['order'])};
    flex-grow: ${config['divs']['logoDiv']['flex-grow']};
`;
export const searchBar = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['searchBar']['display'])};
    flex-direction: ${config['divs']['searchBar']['flex-direction']};
    justify-content: ${config['divs']['searchBar']['justify-content']};
    align-items: ${config['divs']['searchBar']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['searchBar']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['searchBar']['width'])};
    height: ${config['divs']['searchBar']['height']};
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['divs']['searchBar']['border-radius']};
    flex: ${config['divs']['searchBar']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['searchBar']['order'])};
    flex-grow: ${config['divs']['searchBar']['flex-grow']};
`;
export const signInButtonsDiv = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['signInButtonsDiv']['display'])};
    flex-direction: ${config['divs']['signInButtonsDiv']['flex-direction']};
    justify-content: ${config['divs']['signInButtonsDiv']['justify-content']};
    align-items: ${config['divs']['signInButtonsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['signInButtonsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['signInButtonsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['signInButtonsDiv']['width'])};
    height: ${config['divs']['signInButtonsDiv']['height']};
    flex: ${config['divs']['signInButtonsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['signInButtonsDiv']['order'])};
    align-self: ${config['divs']['signInButtonsDiv']['align-self']};
    flex-grow: ${config['divs']['signInButtonsDiv']['flex-grow']};
`;
export const exploreButtonDiv = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['exploreButtonDiv']['display'])};
    flex-direction: ${config['divs']['exploreButtonDiv']['flex-direction']};
    justify-content: ${config['divs']['exploreButtonDiv']['justify-content']};
    align-items: ${config['divs']['exploreButtonDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['exploreButtonDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['exploreButtonDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['exploreButtonDiv']['width'])};
    height: ${config['divs']['exploreButtonDiv']['height']};
    background: ${theme['Secondary-Gray0100']};
    border-radius: ${config['divs']['exploreButtonDiv']['border-radius']};
    flex: ${config['divs']['exploreButtonDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['exploreButtonDiv']['order'])};
    flex-grow: ${config['divs']['exploreButtonDiv']['flex-grow']};
`;
export const frame_14 = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['frames']['frame_14']['display'])};
    flex-direction: ${config['divs']['frames']['frame_14']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_14']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['frames']['frame_14']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['frames']['frame_14']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['frame_14']['width'])};
    height: ${config['divs']['frames']['frame_14']['height']};
    flex: ${config['divs']['frames']['frame_14']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['frames']['frame_14']['order'])};
    flex-grow: ${config['divs']['frames']['frame_14']['flex-grow']};
`;
export const iconAndTextDiv = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['iconAndTextDiv']['display'])};
    flex-direction: ${config['divs']['iconAndTextDiv']['flex-direction']};
    align-items: ${config['divs']['iconAndTextDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['iconAndTextDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['iconAndTextDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['iconAndTextDiv']['width'])};
    height: ${config['divs']['iconAndTextDiv']['height']};
    flex: ${config['divs']['iconAndTextDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['iconAndTextDiv']['order'])};
    flex-grow: ${config['divs']['iconAndTextDiv']['flex-grow']};
`;
export const searchBoxDiv = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['searchBoxDiv']['display'])};
    flex-direction: ${config['divs']['searchBoxDiv']['flex-direction']};
    align-items: ${config['divs']['searchBoxDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['searchBoxDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['searchBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['searchBoxDiv']['width'])};
    height: ${config['divs']['searchBoxDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['divs']['searchBoxDiv']['border-radius']};
    flex: ${config['divs']['searchBoxDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['searchBoxDiv']['order'])};
    flex-grow: ${config['divs']['searchBoxDiv']['flex-grow']};
`;

const inputSearchBoxProps = {
    width: Number, 
    padding: Number, 
    gap: Number, 
    order: Number,
    display: String,
    placeholderColor: String,
}

export const inputSearchBox = styled("input", inputSearchBoxProps)`
    box-sizing: ${config['divs']['inputSearchBox']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['inputSearchBox']['display'])};
    flex-direction: ${config['divs']['inputSearchBox']['flex-direction']};
    align-items: ${config['divs']['inputSearchBox']['align-items']};
    padding: ${config['divs']['inputSearchBox']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['inputSearchBox']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' :config['divs']['inputSearchBox']['width'])};
    height: ${config['divs']['inputSearchBox']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    border-radius: ${config['divs']['inputSearchBox']['border-radius']};
    flex: ${config['divs']['inputSearchBox']['flex']};
    order: ${config['divs']['inputSearchBox']['order']};
    align-self: ${config['divs']['inputSearchBox']['align-self']};
    flex-grow: ${config['divs']['inputSearchBox']['flex-grow']};
    font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
    font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
    font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
    font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
    line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    color: ${theme['Secondary-Gray0800']};
    ::placeholder {
        color: ${(props) => (props.placeholderColor ?props.placeholderColor:theme['Secondary-Gray0500'])};
        font-family: ${config['fonts']['lexend']['Body-UX-14']['font-family']};
        font-style: ${config['fonts']['lexend']['Body-UX-14']['font-style']};
        font-weight: ${config['fonts']['lexend']['Body-UX-14']['font-weight']};
        font-size: ${config['fonts']['lexend']['Body-UX-14']['font-size']};
        line-height: ${config['fonts']['lexend']['Body-UX-14']['line-height']};
    }
    &:focus{
        outline: none;
        border: 1px solid ${theme['PrimaryCyan0400-Default']};
        ::placeholder {
            color: transparent;
        }
    }
`;

export const navBarDiv = styled("div", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['navBarDiv']['display'])};
    flex-direction: ${config['divs']['navBarDiv']['flex-direction']};
    justify-content: ${config['divs']['navBarDiv']['justify-content']};
    align-items: ${config['divs']['navBarDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['navBarDiv']['padding'])};
    isolation: ${config['divs']['navBarDiv']['isolation']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['navBarDiv']['width'])};
    height: ${config['divs']['navBarDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['divs']['navBarDiv']['border-radius']};
`;
export const profileDiv = styled("a", topNavBarDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['profileDiv']['display'])};
    flex-direction: ${config['divs']['profileDiv']['flex-direction']};
    justify-content: ${config['divs']['profileDiv']['justify-content']};
    align-items: ${config['divs']['profileDiv']['align-items']};
    text-decoration:none;
    padding: ${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['profileDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['profileDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['profileDiv']['width'])};
    height: ${config['divs']['profileDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['divs']['profileDiv']['border-radius']};
    flex: ${config['divs']['profileDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['profileDiv']['order'])};
    flex-grow: ${config['divs']['profileDiv']['flex-grow']};
`;
const challengeFrameDivProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number, // multiple of 8
    gap: Number, //multiple of 8, default 0
    order: Number,
    display: String
}
export const challengeFrameDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeFrameDiv']['display'])};
    flex-direction: ${config['divs']['challengeFrameDiv']['flex-direction']};
    align-items: ${config['divs']['challengeFrameDiv']['align-items']};
    padding: ${config['divs']['challengeFrameDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['challengeFrameDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengeFrameDiv']['width'])};
    height: ${config['divs']['challengeFrameDiv']['height']};
`;
export const viewChallengeHeaderDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['viewChallengeHeaderDiv']['display'])};
    flex-direction: ${config['divs']['viewChallengeHeaderDiv']['flex-direction']};
    align-items: ${config['divs']['viewChallengeHeaderDiv']['align-items']};
    justify-content: ${config['divs']['viewChallengeHeaderDiv']['justify-content']};
    padding: ${config['divs']['viewChallengeHeaderDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['viewChallengeHeaderDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['viewChallengeHeaderDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['viewChallengeHeaderDiv']['height'])};
    flex: ${config['divs']['viewChallengeHeaderDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['viewChallengeHeaderDiv']['order'])};
    flex-grow: ${config['divs']['editChallengeHeaderDiv']['flex-grow']};
`;
export const challengeInfoDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeInfoDiv']['display'])};
    flex-direction: ${config['divs']['challengeInfoDiv']['flex-direction']};
    align-items: ${config['divs']['challengeInfoDiv']['align-items']};
    padding: ${config['divs']['challengeInfoDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['challengeInfoDiv']['gap'])}px;
    background: ${config['divs']['challengeInfoDiv']['background']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengeInfoDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['challengeInfoDiv']['height'])};
    flex: ${config['divs']['challengeInfoDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengeInfoDiv']['order'])};
    flex-grow: ${config['divs']['challengeInfoDiv']['flex-grow']};
`;
export const challengePartContentAndAboutDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengePartContentAndAboutDiv']['display'])};
    flex-direction: ${config['divs']['challengePartContentAndAboutDiv']['flex-direction']};
    align-items: ${config['divs']['challengePartContentAndAboutDiv']['align-items']};
    padding: ${config['divs']['challengePartContentAndAboutDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['challengePartContentAndAboutDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengePartContentAndAboutDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['challengePartContentAndAboutDiv']['height'])};
    flex: ${config['divs']['challengePartContentAndAboutDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengePartContentAndAboutDiv']['order'])};
    align-self: ${config['divs']['challengePartContentAndAboutDiv']['align-self']};
    flex-grow: ${config['divs']['challengePartContentAndAboutDiv']['flex-grow']};
`;
export const chalAboutDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['chalAboutDiv']['display'])};
    flex-direction: ${config['divs']['chalAboutDiv']['flex-direction']};
    align-items: ${config['divs']['chalAboutDiv']['align-items']};
    padding: ${config['divs']['chalAboutDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['chalAboutDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['chalAboutDiv']['width'])};
    height: ${config['divs']['chalAboutDiv']['height']};
    background: ${config['divs']['chalAboutDiv']['background']};
    box-shadow: ${config['divs']['chalAboutDiv']['box-shadow']};
    border-radius:${(props) => ((props.border_radius) ? (props.border_radius) : config['divs']['chalAboutDiv']['border-radius'])};
    flex: ${config['divs']['chalAboutDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['chalAboutDiv']['order'])};
    align-self: ${config['divs']['chalAboutDiv']['align-self']};
    flex-grow: ${config['divs']['chalAboutDiv']['flex-grow']};
`;
export const tagSectionDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['tagSectionDiv']['display'])};
    flex-direction: ${config['divs']['tagSectionDiv']['flex-direction']};
    align-items: ${config['divs']['tagSectionDiv']['align-items']};
    padding: ${config['divs']['tagSectionDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['tagSectionDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['tagSectionDiv']['width'])};
    height: ${config['divs']['tagSectionDiv']['height']};
    flex: ${config['divs']['tagSectionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['tagSectionDiv']['order'])};
    align-self: ${config['divs']['tagSectionDiv']['align-self']};
    flex-grow: ${config['divs']['tagSectionDiv']['flex-grow']};
`;
export const tagSelectedDiv = styled("div", challengeFrameDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['tagSelectedDiv']['display'])};
    flex-direction: ${config['divs']['tagSelectedDiv']['flex-direction']};
    align-items: ${config['divs']['tagSelectedDiv']['align-items']};
    padding: ${config['divs']['tagSelectedDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['tagSelectedDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['tagSelectedDiv']['width'])};
    height: ${config['divs']['tagSelectedDiv']['height']};
    flex: ${config['divs']['tagSelectedDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['tagSelectedDiv']['order'])};
    flex-grow: ${config['divs']['tagSelectedDiv']['flex-grow']};
`;
export const searchBarDiv = styled("input", challengeFrameDivProps)`
    box-sizing: ${config['divs']['searchBarDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['searchBarDiv']['display'])};
    flex-direction: ${config['divs']['searchBarDiv']['flex-direction']};
    align-items: ${config['divs']['searchBarDiv']['align-items']};
    padding: ${config['divs']['searchBarDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['searchBarDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' :config['divs']['searchBarDiv']['width'])};
    height: ${config['divs']['searchBarDiv']['height']};
    background: ${config['divs']['searchBarDiv']['background']};
    border: ${config['divs']['searchBarDiv']['border']};
    border-radius: ${config['divs']['searchBarDiv']['border-radius']};
    flex: ${config['divs']['searchBarDiv']['flex']};
    order: ${config['divs']['searchBarDiv']['order']};
    align-self: ${config['divs']['searchBarDiv']['align-self']};
    flex-grow: ${config['divs']['searchBarDiv']['flex-grow']};
`;
const listProps = {
    width: String, //multiple of default value
    height: String,
    order: Number,
    display: String,
    background_color : String,
    gap : String,
    padding: String
}

export const listDiv = styled('div', listProps)`
    box-sizing: ${config['divs']['list']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['list']['display'])};
    flex-direction: ${config['divs']['list']['flex-direction']};
    align-items: ${config['divs']['list']['align-items']};
    padding: ${config['divs']['list']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['list']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' :config['divs']['list']['width'])};
    height: ${config['divs']['list']['height']};
    background: ${config['divs']['list']['background']};
    border: ${config['divs']['list']['border']};
    border-radius: ${config['divs']['list']['border-radius']};
    box-shadow: ${config['divs']['list']['box-shadow']};
`;
export const listItem = styled('button', listProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['listItem']['display'])};
    flex-direction: ${config['divs']['listItem']['flex-direction']};
    align-items: ${config['divs']['listItem']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) :config['divs']['listItem']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['listItem']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) :config['divs']['listItem']['width'])};
    height: ${(props) => ((props.height) ? (props.height) :config['divs']['listItem']['height'])};
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['divs']['listItem']['border-radius']};
    flex: ${config['divs']['listItem']['flex']};
    order: ${config['divs']['listItem']['order']};
    align-self: ${config['divs']['listItem']['align-self']};
    flex-grow: ${config['divs']['listItem']['flex-grow']};
    font-family: ${config['fonts']['lexend']['H5-18']["font-family"]};
    font-style: ${config['fonts']['lexend']['H5-18']["font-style"]};
    font-weight: ${config['fonts']['lexend']['H5-18']["font-weight"]};
    font-size: ${config['fonts']['lexend']['H5-18']["font-size"]};
    line-height: ${config['fonts']['lexend']['H5-18']["font-height"]};
    color: ${theme['Secondary-Gray0800']};
    &:hover{
        background: ${(props) => ((props.background_color) ? (props.background_color) : theme['Secondary-Gray0100'])};
        height: ${(props) => ((props.height) ? (props.height) :config['divs']['listItem']['height'])};
    }
`;
const dialogBoxDivProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number, // multiple of 8
    gap: Number //multiple of 8, default 0
}
export const dialogBox = styled("div", dialogBoxDivProps)`
    z-index: ${config['divs']['dialogBox']['z-index']};
    left: ${config['divs']['dialogBox']['left']};
    top: ${config['divs']['dialogBox']['top']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['dialogBox']['display'])};
    flex-direction: ${config['divs']['dialogBox']['flex-direction']};
    align-items: ${config['divs']['dialogBox']['align-items']};
    padding: ${config['divs']['dialogBox']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['dialogBox']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['dialogBox']['width'])};
    height: ${config['divs']['dialogBox']['height']};
    background-color: ${config['divs']['dialogBox']['background-color']};
    flex: ${config['divs']['dialogBox']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['dialogBox']['order'])};
    align-self: ${config['divs']['dialogBox']['align-self']};
    flex-grow: ${config['divs']['dialogBox']['flex-grow']};
`;
export const overlayBox = styled("div", dialogBoxDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['overlayBox']['display'])};
    padding: ${config['divs']['overlayBox']['padding']};
    margin: ${config['divs']['overlayBox']['margin']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['overlayBox']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['overlayBox']['width'])};
    height: ${config['divs']['overlayBox']['height']};
    background-color: ${config['divs']['overlayBox']['background-color']};
    border: ${(props) => ((props.border) ? (props.border) :config['divs']['overlayBox']['border'])};
`;
const sessionPageContentDivProps = {
    width: Number, 
    padding: String, 
    gap: Number,
    display: String
}
export const sessionPageContentDiv = styled("div", sessionPageContentDivProps)`
    background: ${theme['Secondary-Gray0100']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['sessionPageContentDiv']['display'])};
    flex-direction: ${config['divs']['sessionPageContentDiv']['flex-direction']};
    align-items: ${config['divs']['sessionPageContentDiv']['align-items']};
    padding: ${config['divs']['sessionPageContentDiv']['padding']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionPageContentDiv']['width'])};
    height: ${config['divs']['sessionPageContentDiv']['height']};
    overflow-y: ${config['divs']['sessionPageContentDiv']['overflow-y']};
    flex: ${config['divs']['sessionPageContentDiv']['flex']};
    order: ${config['divs']['sessionPageContentDiv']['order']};
    flex-grow: ${config['divs']['sessionPageContentDiv']['flex-grow']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
    }
`;
export const sessionDetailsDiv = styled("div", sessionPageContentDivProps)`
    background: ${theme['Secondary-Gray0100']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['sessionDetailsDiv']['display'])};
    flex-direction: ${config['divs']['sessionDetailsDiv']['flex-direction']};
    align-items: ${config['divs']['sessionDetailsDiv']['align-items']};
    padding: ${(props) => ((props.padding) ? (props.padding) :config['divs']['sessionDetailsDiv']['padding'])};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['sessionDetailsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionDetailsDiv']['width'])};
    height: ${config['divs']['sessionDetailsDiv']['height']};
    overflow-y: ${config['divs']['sessionDetailsDiv']['overflow-y']};
    flex: ${config['divs']['sessionDetailsDiv']['flex']};
    order: ${config['divs']['sessionDetailsDiv']['order']};
    flex-grow: ${config['divs']['sessionDetailsDiv']['flex-grow']};
`;
export const sessionDetailsRightRailDiv = styled("div", sessionPageContentDivProps)`
    background: ${theme['Secondary-Gray0100']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['sessionDetailsRightRailDiv']['display'])};
    flex-direction: ${config['divs']['sessionDetailsRightRailDiv']['flex-direction']};
    align-items: ${config['divs']['sessionDetailsRightRailDiv']['align-items']};
    padding: ${config['divs']['sessionDetailsRightRailDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['sessionDetailsRightRailDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionDetailsRightRailDiv']['width'])};
    height: ${config['divs']['sessionDetailsRightRailDiv']['height']};
    align-self: ${config['divs']['sessionDetailsRightRailDiv']['align-self']};
    flex: ${config['divs']['sessionDetailsRightRailDiv']['flex']};
    order: ${config['divs']['sessionDetailsRightRailDiv']['order']};
    flex-grow: ${config['divs']['sessionDetailsRightRailDiv']['flex-grow']};
`;
const aboutSessionDivProps = {
    width: Number, 
    padding: Number
}
export const aboutSessionDiv = styled("div", aboutSessionDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutSessionDiv']['display'])};
    flex-direction: ${config['divs']['aboutSessionDiv']['flex-direction']};
    align-items: ${config['divs']['aboutSessionDiv']['align-items']};
    padding: ${config['divs']['aboutSessionDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['aboutSessionDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutSessionDiv']['width'])};
    height: ${config['divs']['aboutSessionDiv']['height']};
    flex: ${config['divs']['aboutSessionDiv']['flex']};
    order: ${config['divs']['aboutSessionDiv']['order']};
    align-self: ${config['divs']['aboutSessionDiv']['align-self']};
    flex-grow: ${config['divs']['aboutSessionDiv']['flex-grow']};
`;
export const aboutSlotCardDiv = styled("div", sessionPageContentDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['aboutSlotCardDiv']['display'])};
    flex-direction: ${config['divs']['aboutSlotCardDiv']['flex-direction']};
    align-items: ${config['divs']['aboutSlotCardDiv']['align-items']};
    padding: ${config['divs']['aboutSlotCardDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['aboutSlotCardDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['aboutSlotCardDiv']['width'])};
    height: ${config['divs']['aboutSlotCardDiv']['height']};
    overflow-y: ${config['divs']['aboutSlotCardDiv']['overflow-y']};
    flex: ${config['divs']['aboutSlotCardDiv']['flex']};
    order: ${config['divs']['aboutSlotCardDiv']['order']};
    flex-grow: ${config['divs']['aboutSlotCardDiv']['flex-grow']};
`;
const courseSessionsDivProps = {
    width: String,
}
export const courseSessionsDiv = styled("div", courseSessionsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['courseSessionsDiv']['display'])};
    flex-direction: ${config['divs']['courseSessionsDiv']['flex-direction']};
    align-items: ${config['divs']['courseSessionsDiv']['align-items']};
    padding: ${config['divs']['courseSessionsDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['courseSessionsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['courseSessionsDiv']['width'])};
    height: ${config['divs']['courseSessionsDiv']['height']};
    flex: ${config['divs']['courseSessionsDiv']['flex']};
    order: ${config['divs']['courseSessionsDiv']['order']};
    flex-grow: ${config['divs']['courseSessionsDiv']['flex-grow']};
`;
const courseSessionsListDivProps = {
    width: String,
}
export const courseSessionsListDiv = styled("div", courseSessionsListDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['courseSessionsListDiv']['display'])};
    flex-direction: ${config['divs']['courseSessionsListDiv']['flex-direction']};
    align-items: ${config['divs']['courseSessionsListDiv']['align-items']};
    padding: ${config['divs']['courseSessionsListDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['courseSessionsListDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['courseSessionsListDiv']['width'])};
    height: ${config['divs']['courseSessionsListDiv']['height']};
    flex: ${config['divs']['courseSessionsListDiv']['flex']};
    order: ${config['divs']['courseSessionsListDiv']['order']};
    align-self: ${config['divs']['courseSessionsListDiv']['align-self']};
    flex-grow: ${config['divs']['courseSessionsListDiv']['flex-grow']};
`;
const courseListDivProps = {
    width: String,
}
export const courseListDiv = styled("div", courseListDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['courseListDiv']['display'])};
    flex-direction: ${config['divs']['courseListDiv']['flex-direction']};
    align-items: ${config['divs']['courseListDiv']['align-items']};
    padding: ${config['divs']['courseListDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['courseListDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['courseListDiv']['width'])};
    height: ${config['divs']['courseListDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    flex: ${config['divs']['courseListDiv']['flex']};
    order: ${config['divs']['courseListDiv']['order']};
    align-self: ${config['divs']['courseListDiv']['align-self']};
    flex-grow: ${config['divs']['courseListDiv']['flex-grow']};
`;
const preReadingDivProps = {
    width: String,
    gap: Number
}
export const preReadingDiv = styled("div", preReadingDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['preReadingDiv']['display'])};
    flex-direction: ${config['divs']['preReadingDiv']['flex-direction']};
    align-items: ${config['divs']['preReadingDiv']['align-items']};
    padding: ${config['divs']['preReadingDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['preReadingDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['preReadingDiv']['width'])};
    height: ${config['divs']['preReadingDiv']['height']};
    flex: ${config['divs']['preReadingDiv']['flex']};
    order: ${config['divs']['preReadingDiv']['order']};
    flex-grow: ${config['divs']['preReadingDiv']['flex-grow']};
    align-self: ${config['divs']['preReadingDiv']['align-self']};
`;
const preReadingCardsDivProps = {
    width: String,
    gap: Number
}
export const preReadingCardsDiv = styled("div", preReadingCardsDivProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['preReadingCardsDiv']['display'])};
    flex-direction: ${config['divs']['preReadingCardsDiv']['flex-direction']};
    align-items: ${config['divs']['preReadingCardsDiv']['align-items']};
    padding: ${config['divs']['preReadingCardsDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['preReadingCardsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['preReadingCardsDiv']['width'])};
    height: ${config['divs']['preReadingCardsDiv']['height']};
    flex: ${config['divs']['preReadingCardsDiv']['flex']};
    order: ${config['divs']['preReadingCardsDiv']['order']};
    flex-grow: ${config['divs']['preReadingCardsDiv']['flex-grow']};
    align-self: ${config['divs']['preReadingCardsDiv']['align-self']};
    overflow-x: ${config['divs']['preReadingCardsDiv']['overflow-x']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
`;
const courseCardProps = {
    width : String,
    gap: Number
}
export const courseCard = styled("div", courseCardProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['courseCard']['display'])};
    flex-direction: ${config['divs']['courseCard']['flex-direction']};
    align-items: ${config['divs']['courseCard']['align-items']};
    padding: ${config['divs']['courseCard']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap)+'px' :config['divs']['courseCard']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['courseCard']['width'])};
    height: ${config['divs']['courseCard']['height']};
    background: ${theme['Secondary-Gray0000']};
    border-radius: ${config['divs']['courseCard']['border-radius']};
    box-shadow: ${config['divs']['courseCard']['box-shadow']};
    flex: ${config['divs']['courseCard']['flex']};
    order: ${config['divs']['courseCard']['order']};
    flex-grow: ${config['divs']['courseCard']['flex-grow']};
`;

const editorSectionProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number, // multiple of 8
    gap: Number //multiple of 8, default 0
}
export const editorSectionDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['editorSectionDiv']['display'])};
    flex-direction: ${config['divs']['editorSectionDiv']['flex-direction']};
    align-items: ${config['divs']['editorSectionDiv']['align-items']};
    padding: ${config['divs']['editorSectionDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['editorSectionDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['editorSectionDiv']['width'])};
    height: ${config['divs']['editorSectionDiv']['height']};
`;
export const editorHeaderDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['editorHeaderDiv']['display'])};
    flex-direction: ${config['divs']['editorHeaderDiv']['flex-direction']};
    align-items: ${config['divs']['editorHeaderDiv']['align-items']};
    padding: ${config['divs']['editorHeaderDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['editorHeaderDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['editorHeaderDiv']['width'])};
    height: ${config['divs']['editorHeaderDiv']['height']};
    flex: ${config['divs']['editorHeaderDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['editorHeaderDiv']['order'])};
    align-self: ${config['divs']['editorHeaderDiv']['align-self']};
    flex-grow: ${config['divs']['editorHeaderDiv']['flex-grow']};
`;
export const viewChalDetailsDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['viewChalDetailsDiv']['display'])};
    flex-direction: ${config['divs']['viewChalDetailsDiv']['flex-direction']};
    align-items: ${config['divs']['viewChalDetailsDiv']['align-items']};
    padding: ${config['divs']['viewChalDetailsDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['viewChalDetailsDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['viewChalDetailsDiv']['width'])};
    height: ${config['divs']['viewChalDetailsDiv']['height']};
    flex: ${config['divs']['viewChalDetailsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['viewChalDetailsDiv']['order'])};
    align-self: ${config['divs']['viewChalDetailsDiv']['align-self']};
    flex-grow: ${config['divs']['viewChalDetailsDiv']['flex-grow']};
`;
export const editorBodySectionDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['editorBodySectionDiv']['display'])};
    flex-direction: ${config['divs']['editorBodySectionDiv']['flex-direction']};
    align-items: ${config['divs']['editorBodySectionDiv']['align-items']};
    padding: ${config['divs']['editorBodySectionDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['editorBodySectionDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['editorBodySectionDiv']['width'])};
    height: ${config['divs']['editorBodySectionDiv']['height']};
    flex: ${config['divs']['editorBodySectionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['editorBodySectionDiv']['order'])};
    align-self: ${config['divs']['editorBodySectionDiv']['align-self']};
    flex-grow: ${config['divs']['editorBodySectionDiv']['flex-grow']};
`;
export const inputAndPreviewSectionDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['inputAndPreviewSectionDiv']['display'])};
    flex-direction: ${config['divs']['inputAndPreviewSectionDiv']['flex-direction']};
    align-items: ${config['divs']['inputAndPreviewSectionDiv']['align-items']};
    padding: ${config['divs']['inputAndPreviewSectionDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['inputAndPreviewSectionDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['inputAndPreviewSectionDiv']['width'])};
    height: ${config['divs']['inputAndPreviewSectionDiv']['height']};
    background: ${config['divs']['inputAndPreviewSectionDiv']['background']};
    flex: ${config['divs']['inputAndPreviewSectionDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['inputAndPreviewSectionDiv']['order'])};
    align-self: ${config['divs']['inputAndPreviewSectionDiv']['align-self']};
    flex-grow: ${config['divs']['inputAndPreviewSectionDiv']['flex-grow']};
`;
export const inputFieldDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['inputFieldDiv']['display'])};
    flex-direction: ${config['divs']['inputFieldDiv']['flex-direction']};
    align-items: ${config['divs']['inputFieldDiv']['align-items']};
    padding: ${config['divs']['inputFieldDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['inputFieldDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['inputFieldDiv']['width'])};
    height: ${config['divs']['inputFieldDiv']['height']};
    flex: ${config['divs']['inputFieldDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['inputFieldDiv']['order'])};
    align-self: ${config['divs']['inputFieldDiv']['align-self']};
    flex-grow: ${config['divs']['inputFieldDiv']['flex-grow']};
`;
export const inputPartFieldDiv = styled("div", editorSectionProps)`
    box-sizing: ${config['divs']['inputPartFieldDiv']['box-sizing']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['inputPartFieldDiv']['display'])};
    flex-direction: ${config['divs']['inputPartFieldDiv']['flex-direction']};
    align-items: ${config['divs']['inputPartFieldDiv']['align-items']};
    padding: ${config['divs']['inputPartFieldDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['inputPartFieldDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['inputPartFieldDiv']['width'])};
    height: ${config['divs']['inputPartFieldDiv']['height']};
    background: ${config['divs']['inputPartFieldDiv']['background']};
    border: ${config['divs']['inputPartFieldDiv']['border']};
    border-radius:${(props) => ((props.border_radius) ? (props.border_radius) : config['divs']['inputPartFieldDiv']['border-radius'])};
    flex: ${config['divs']['inputPartFieldDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['inputPartFieldDiv']['order'])};
    align-self: ${config['divs']['inputPartFieldDiv']['align-self']};
    flex-grow: ${config['divs']['inputPartFieldDiv']['flex-grow']};
`;
export const previewFieldDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['previewFieldDiv']['display'])};
    flex-direction: ${config['divs']['previewFieldDiv']['flex-direction']};
    align-items: ${config['divs']['previewFieldDiv']['align-items']};
    padding: ${config['divs']['previewFieldDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['previewFieldDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['previewFieldDiv']['width'])};
    height: ${config['divs']['previewFieldDiv']['height']};
    background: ${config['divs']['previewFieldDiv']['background']};
    box-shadow: ${config['divs']['previewFieldDiv']['box-shadow']};
    flex: ${config['divs']['previewFieldDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['previewFieldDiv']['order'])};
    align-self: ${config['divs']['previewFieldDiv']['align-self']};
    flex-grow: ${config['divs']['previewFieldDiv']['flex-grow']};
`;
export const commandPaletteDiv = styled("div", editorSectionProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['commandPaletteDiv']['display'])};
    flex-direction: ${config['divs']['commandPaletteDiv']['flex-direction']};
    align-items: ${config['divs']['commandPaletteDiv']['align-items']};
    padding: ${config['divs']['commandPaletteDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['commandPaletteDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['commandPaletteDiv']['width'])};
    height: ${config['divs']['commandPaletteDiv']['height']};
    flex: ${config['divs']['commandPaletteDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['commandPaletteDiv']['order'])};
    align-self: ${config['divs']['commandPaletteDiv']['align-self']};
    flex-grow: ${config['divs']['commandPaletteDiv']['flex-grow']};
`;

const fixedWidthDivProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number, // multiple of 8
    textalign: String,
    float: String,
    gap: Number //multiple of 8, default 0
};

export const fixedWidthDiv = styled("div", fixedWidthDivProps)`
    display: ${(props) => (props.gap ? 'grid' : 'block')};
    background: transparent;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    width: ${(props) => (props.width ? props.width*100 : 100)}px;
    float: ${(props) => (props.float ? props.float : "center")};
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
`;
const breadCrumbsProps = {
    width: Number,
    display: String
};
export const breadCrumbs = styled("ul", breadCrumbsProps)`
    list-style-type: ${config['divs']['breadCrumbs']['list-style-type']};
    padding-left: ${config['divs']['breadCrumbs']['padding']};
    li {
        float:left;
    }
    li {
        a{
            color: ${theme['Secondary-Gray0700']}; 
            text-decoration: ${config['divs']['anchorTag']['text-decoration']};
            padding: ${config['divs']['anchorTag']['padding']};
            background: ${theme['Secondary-Gray0200']}; 
            position: ${config['divs']['anchorTag']['position']};
            display: ${config['divs']['anchorTag']['display']};
            float: ${config['divs']['anchorTag']['float']};
            &::after {
                content: " "; 
                display: block; 
                width: 0; 
                height: 0;
                border-top: 14px solid transparent;           /* Go big on the size, and let overflow hide */
                border-bottom: 14px solid transparent;
                border-left: 12px solid ${theme['Secondary-Gray0200']}; 
                position: absolute;
                top: 100%;
                margin-top: -28px; 
                left: 100%;
                z-index: 2; 
            }  
            &::before {
                content: " "; 
                display: block; 
                width: 0; 
                height: 0;
                border-top: 14px solid transparent;           /* Go big on the size, and let overflow hide */
                border-bottom: 14px solid transparent;
                border-left: 12px solid ${theme['Primary-white']};
                position: absolute;
                top: 100%;
                margin-top: -28px;
                margin-left: 1px; 
                left: 100%;
                z-index: 2; 
            }
        }
    }
`;
export const breadCrumbsList = styled("li", breadCrumbsProps)`
    display: ${config['divs']['breadCrumbsList']['display']};
    margin: ${config['divs']['breadCrumbsList']['margin']};
    color: ${config['divs']['breadCrumbsList']['color']};
    font-size: ${config['divs']['breadCrumbsList']['font-size']};
    height: ${config['divs']['breadCrumbsList']['height']};
`;
export const breadCrumbText = styled("div", breadCrumbsProps)`
    display: ${(props) => ((props.display) ? (props.display):config['divs']['breadCrumbText']['display'])};
    flex-direction: ${config['divs']['breadCrumbText']['flex-direction']};
    align-items: ${config['divs']['breadCrumbText']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['breadCrumbText']['padding'])};
    gap: ${config['divs']['breadCrumbText']['gap']}
    background: ${theme['Secondary-Gray0200']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['breadCrumbText']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' :config['divs']['breadCrumbText']['height'])};
    flex:${config['divs']['breadCrumbText']['flex']}
    order:${config['divs']['breadCrumbText']['order']}
    flex-grow:${config['divs']['breadCrumbText']['flex-grow']}
    z-index:${config['divs']['breadCrumbText']['z-index']}
`;
const rowProps = {
    width: Number, // width is multiple of 10
    height: String,
    overflow: String,
    text_align: String,
    padding: Number,
    gap:Number,
    text_transform: String,
    display: String,
    align_items: String,
    background: String,
    wordBreak: String
};

export const rowDiv = styled("div", rowProps)`
    display: ${(props) => ((props.display) ? (props.display):config['divs']['rowDiv']['display'])};
    align-items: ${(props) => ((props.align_items) ? (props.align_items):config['divs']['rowDiv']['align-items'])};
    text-align: ${(props) => ((props.text_align) ? (props.text_align) : config['divs']['rowDiv']['text-align'])};
    text-transform: ${(props) => ((props.text_transform) ? (props.text_transform): config['divs']['rowDiv']['text-transform'])};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['rowDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['rowDiv']['gap'])}
    flex:${config['divs']['rowDiv']['flex']}
    order:${config['divs']['rowDiv']['order']}
    flex-grow:${config['divs']['rowDiv']['flex-grow']}
    word-break:${(props) => ((props.wordBreak) ? (props.wordBreak) : config['divs']['rowDiv']['word-break'])}
    overflow:${(props) => ((props.overflow) ? (props.overflow) : config['divs']['rowDiv']['width'])};
    width: ${(props) => ((props.width) ? (props.width) + 'px' : config['divs']['rowDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height) + 'px' :config['divs']['rowDiv']['height'])};
    background: ${(props) => ((props.background) ? (props.background) : '')};
`;
const descriptionDivProps = {
    width: String,
    height: Number,
    overflow: String,
    text_align: String,
    padding: Number,
    gap:Number,
    text_transform: String,
    display: String,
    align_items: String
}
export const descriptionDiv = styled("div", descriptionDivProps)`
    display: ${(props) => ((props.display) ? (props.display):config['divs']['descriptionDiv']['display'])};
    -webkit-line-clamp:${config['divs']['descriptionDiv']['-webkit-line-clamp']}
    -webkit-box-orient:${config['divs']['descriptionDiv']['-webkit-box-orient']}
    align-items: ${(props) => ((props.align_items) ? (props.align_items):config['divs']['descriptionDiv']['align-items'])};
    text-align: ${(props) => ((props.text_align) ? (props.text_align) : config['divs']['descriptionDiv']['text-align'])};
    text-transform: ${(props) => ((props.text_transform) ? (props.text_transform): config['divs']['descriptionDiv']['text-transform'])};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['descriptionDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['descriptionDiv']['gap'])}
    flex:${config['divs']['descriptionDiv']['flex']}
    order:${config['divs']['descriptionDiv']['order']}
    flex-grow:${config['divs']['descriptionDiv']['flex-grow']}
    overflow:${(props) => ((props.overflow) ? (props.overflow) : config['divs']['descriptionDiv']['width'])};
    width: ${(props) => ((props.width) ? (props.width) + 'px' : config['divs']['descriptionDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height) + 'px' :config['divs']['descriptionDiv']['height'])};
`
export const challengeDiv = styled("div", rowProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeDiv']['display'])};
    flex-direction: ${ config['divs']['challengeDiv']['flex-direction']};
    align-items: ${config['divs']['challengeDiv']['align-items']};
    padding: ${config['divs']['challengeDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) :config['divs']['challengeDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengeDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['challengeDiv']['height'])};
`;
export const editChallengeHeaderDiv = styled("div", rowProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['editChallengeHeaderDiv']['display'])};
    flex-direction: ${config['divs']['editChallengeHeaderDiv']['flex-direction']};
    align-items: ${config['divs']['editChallengeHeaderDiv']['align-items']};
    justify-content: ${config['divs']['editChallengeHeaderDiv']['justify-content']};
    padding: ${config['divs']['editChallengeHeaderDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['editChallengeHeaderDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['editChallengeHeaderDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['editChallengeHeaderDiv']['height'])};
    flex: ${config['divs']['editChallengeHeaderDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['editChallengeHeaderDiv']['order'])};
    flex-grow: ${config['divs']['editChallengeHeaderDiv']['flex-grow']};
`;
export const editChallengeTopic = styled("div", rowProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['editChallengeTopic']['display'])};
    flex-direction: ${config['divs']['editChallengeTopic']['flex-direction']};
    align-items: ${config['divs']['editChallengeTopic']['align-items']};
    padding: ${config['divs']['editChallengeTopic']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['editChallengeTopic']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['editChallengeTopic']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['editChallengeTopic']['height'])};
    flex: ${config['divs']['editChallengeTopic']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['editChallengeTopic']['order'])};
    flex-grow: ${config['divs']['editChallengeTopic']['flex-grow']};
`;
export const challengeOperationsDiv = styled("div", rowProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengeOperationsDiv']['display'])};
    flex-direction: ${config['divs']['challengeOperationsDiv']['flex-direction']};
    align-items: ${config['divs']['challengeOperationsDiv']['align-items']};
    padding: ${config['divs']['challengeOperationsDiv']['padding']};
    gap: ${(props) => ((props.gap) ? (props.gap) + 'px' :config['divs']['challengeOperationsDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengeOperationsDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['challengeOperationsDiv']['height'])};
    flex: ${config['divs']['challengeOperationsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengeOperationsDiv']['order'])};
    flex-grow: ${config['divs']['challengeOperationsDiv']['flex-grow']};
`;
export const challengePartsDiv = styled("div", rowProps)`
    background: ${config['divs']['challengePartsDiv']['background']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengePartsDiv']['display'])};
    flex-direction: ${config['divs']['challengePartsDiv']['flex-direction']};
    align-items: ${config['divs']['challengePartsDiv']['align-items']};
    padding: ${config['divs']['challengePartsDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['challengePartsDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengePartsDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['challengePartsDiv']['height'])};
    flex: ${config['divs']['challengePartsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengePartsDiv']['order'])};
    flex-grow: ${config['divs']['challengePartsDiv']['flex-grow']};
`;
export const challengePartsContentAndSettingDiv = styled("div", rowProps)`
    background: ${config['divs']['challengePartsContentAndSettingDiv']['background']};
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['challengePartsContentAndSettingDiv']['display'])};
    flex-direction: ${config['divs']['challengePartsContentAndSettingDiv']['flex-direction']};
    align-items: ${config['divs']['challengePartsContentAndSettingDiv']['align-items']};
    padding: ${config['divs']['challengePartsContentAndSettingDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['challengePartsContentAndSettingDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['challengePartsContentAndSettingDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['challengePartsContentAndSettingDiv']['height'])};
    flex: ${config['divs']['challengePartsContentAndSettingDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['challengePartsContentAndSettingDiv']['order'])};
    align-self: ${config['divs']['challengePartsContentAndSettingDiv']['align-self']};
    flex-grow: ${config['divs']['challengePartsContentAndSettingDiv']['flex-grow']};
`;
export const chalPartsDiv = styled("div", rowProps)`
    display: ${(props) => ((props.display) ? (props.display) :config['divs']['chalPartsDiv']['display'])};
    flex-direction: ${config['divs']['chalPartsDiv']['flex-direction']};
    align-items: ${config['divs']['chalPartsDiv']['align-items']};
    padding: ${config['divs']['chalPartsDiv']['padding']};
    gap: ${(props) => ((props.order) ? (props.order) :config['divs']['chalPartsDiv']['gap'])}px;
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['chalPartsDiv']['width'])};
    height: ${(props) => ((props.height) ? (props.height)+'px' : config['divs']['chalPartsDiv']['height'])};
    flex: ${config['divs']['chalPartsDiv']['flex']};
    order: ${(props) => ((props.order) ? (props.order) :config['divs']['chalPartsDiv']['order'])};
    align-self: ${config['divs']['chalPartsDiv']['align-self']};
    flex-grow: ${config['divs']['chalPartsDiv']['flex-grow']};
`;
export const chalContentDiv = styled("div", rowProps)`
    display: ${config['divs']['chalContentDiv']['display']};
    flex-direction: ${config['divs']['chalContentDiv']['flex-direction']};
    align-items: ${config['divs']['chalContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['chalContentDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['chalContentDiv']['gap'])}px
    text-align:${config['divs']['chalContentDiv']['text-align']}
    flex:${config['divs']['chalContentDiv']['flex']}
    order:${config['divs']['chalContentDiv']['order']}
    flex-grow:${config['divs']['chalContentDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['chalContentDiv']['width'])};
    height: ${config['divs']['chalContentDiv']['height']};
    background: ${(props) => ((props.background) ? (props.background) : config['divs']['chalContentDiv']['background'])};
    box-shadow: ${config['divs']['chalContentDiv']['box-shadow']};
    border-radius: ${config['divs']['chalContentDiv']['border-radius']};
`;
export const operationsDiv = styled("div", rowProps)`
    display: ${config['divs']['operationsDiv']['display']};
    flex-direction: ${config['divs']['operationsDiv']['flex-direction']};
    justify-content: ${config['divs']['operationsDiv']['justify-content']};
    align-items: ${config['divs']['operationsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['operationsDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['operationsDiv']['gap'])}px
    flex:${config['divs']['operationsDiv']['flex']}
    order:${config['divs']['operationsDiv']['order']}
    flex-grow:${config['divs']['operationsDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['operationsDiv']['width'])};
    height: ${config['divs']['operationsDiv']['height']};
`;
export const selectPartDiv = styled("div", rowProps)`
    display: ${config['divs']['selectPartDiv']['display']};
    flex-direction: ${config['divs']['selectPartDiv']['flex-direction']};
    align-items: ${config['divs']['selectPartDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['selectPartDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['selectPartDiv']['gap'])}px
    flex:${config['divs']['selectPartDiv']['flex']}
    order:${config['divs']['selectPartDiv']['order']}
    flex-grow:${config['divs']['selectPartDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['selectPartDiv']['width'])};
    height: ${config['divs']['selectPartDiv']['height']};
`;
const ellipseIconStyleProps = {
    width: Number, // width is multiple of 10
    padding: String, // multiple of 8
    margin_left: Number,
    margin_top: Number
};
export const ellipseIconStyle = styled("div", ellipseIconStyleProps)`
    background: ${theme['Primary-Cyan0400']};
    border-radius: ${config['icons']['ellipse']['border-radius']}%;
    margin-top: ${(props) => ((props.margin_top) ? (props.margin_top) : config['icons']['ellipse']['top'])};
    margin-left: ${(props) => ((props.margin_left) ? (props.margin_left) : config['icons']['ellipse']['left'])};
    border: ${config['icons']['ellipse']['border']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['icons']['ellipse']['width'])};
    height:${config['icons']['ellipse']['height']};
    padding: ${(props) => ((props.padding) ? (props.padding)+'px' : config['icons']['ellipse']['padding'])};
    box-shadow: ${config['icons']['ellipse']['box-shadow']};
    flex: ${config['icons']['ellipse']['flex']};
    order: ${config['icons']['ellipse']['order']};
    flex-grow: ${config['icons']['ellipse']['flex-grow']};
`;
const avatarIconStyleProps = {
    width: String, // width is multiple of 10
    padding: String, // multiple of 8
    margin_left: Number,
    margin_top: Number
};
export const avatarIconStyle = styled("div", avatarIconStyleProps)`
    background-image: url(${avatarImage});
    background-size:199px 199px ;
    border-radius: ${config['icons']['ellipse']['border-radius']}%;
    margin-left: 69px;
    margin-top: 16px;
    margin-right: 83px;
    width: ${(props) => (props.width ? (props.width) : 200)}px;
    height:${config['icons']['ellipse']['height']}px;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    box-shadow: ${config['icons']['ellipse']['box-shadow']};
    flex: ${config['icons']['ellipse']['flex']};
    order: ${config['icons']['ellipse']['order']};
    flex-grow: ${config['icons']['ellipse']['flex-grow']};
`;
const meetTeamSectionDivProps = {
    width: Number, 
    textalign: String,
    padding: Number,
    gap: Number,
    background_color: String
};
export const meetTeamSectionDiv = styled("div", meetTeamSectionDivProps)`
    display: ${config['divs']['meetTeamSectionDiv']['display']};
    flex-direction: ${config['divs']['meetTeamSectionDiv']['flex-direction']};
    align-items: ${config['divs']['meetTeamSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['meetTeamSectionDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['meetTeamSectionDiv']['gap'])};
    flex:${config['divs']['meetTeamSectionDiv']['flex']};
    order:${config['divs']['meetTeamSectionDiv']['order']};
    align-self:${config['divs']['meetTeamSectionDiv']['align-self']};
    flex-grow:${config['divs']['meetTeamSectionDiv']['flex-grow']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['meetTeamSectionDiv']['width'])};
    height: ${config['divs']['meetTeamSectionDiv']['height']};
`;
export const meetTeamTitleDiv = styled("div", meetTeamSectionDivProps)`
    display: ${config['divs']['meetTeamTitleDiv']['display']};
    flex-direction: ${config['divs']['meetTeamTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['meetTeamTitleDiv']['justify-content']};
    align-items: ${config['divs']['meetTeamTitleDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['meetTeamTitleDiv']['padding'])}
    flex:${config['divs']['meetTeamTitleDiv']['flex']}
    order:${config['divs']['meetTeamTitleDiv']['order']}
    align-self:${config['divs']['meetTeamTitleDiv']['align-self']}
    flex-grow:${config['divs']['meetTeamTitleDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['meetTeamTitleDiv']['width'])};
    height: ${config['divs']['meetTeamTitleDiv']['height']};
`;
export const abhyasTeamDiv = styled("div", meetTeamSectionDivProps)`
    display: ${config['divs']['abhyasTeamDiv']['display']};
    flex-direction: ${config['divs']['abhyasTeamDiv']['flex-direction']};
    align-items: ${config['divs']['abhyasTeamDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['abhyasTeamDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['abhyasTeamDiv']['gap'])}
    flex:${config['divs']['abhyasTeamDiv']['flex']}
    order:${config['divs']['abhyasTeamDiv']['order']}
    align-self:${config['divs']['abhyasTeamDiv']['align-self']}
    flex-grow:${config['divs']['abhyasTeamDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['abhyasTeamDiv']['width'])};
    height: ${config['divs']['abhyasTeamDiv']['height']};
    overflow-x:${config['divs']['abhyasTeamDiv']['overflow-x']}
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
`;
export const abhyasTeamAvatarCardDiv = styled("div", meetTeamSectionDivProps)`
    display: ${config['divs']['abhyasTeamAvatarCardDiv']['display']};
    flex-direction: ${config['divs']['abhyasTeamAvatarCardDiv']['flex-direction']};
    align-items: ${config['divs']['abhyasTeamAvatarCardDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['abhyasTeamAvatarCardDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['abhyasTeamAvatarCardDiv']['gap'])};
    flex:${config['divs']['abhyasTeamAvatarCardDiv']['flex']}
    order:${config['divs']['abhyasTeamAvatarCardDiv']['order']}
    flex-grow:${config['divs']['abhyasTeamAvatarCardDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['abhyasTeamAvatarCardDiv']['width'])};
    height: ${config['divs']['abhyasTeamAvatarCardDiv']['height']};
    background: ${(props) => ((props.background_color) ? (props.background_color) : theme['Secondary-Gray0000'])};
    box-shadow: ${config['divs']['abhyasTeamAvatarCardDiv']['box-shadow']};
    border-radius: ${config['divs']['abhyasTeamAvatarCardDiv']['border-radius']};
`;
const avatarDivProps = {
    width: Number,
    position: String
};
export const avatarDiv = styled("div", avatarDivProps)`
    flex:${config['divs']['avatarDiv']['flex']}
    order:${config['divs']['avatarDiv']['order']}
    flex-grow:${config['divs']['avatarDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['avatarDiv']['width'])}px;
    height: ${config['divs']['avatarDiv']['height']}px;
`;
export const maskGroupDiv = styled("img", avatarDivProps)`
    background: url(${profileImage});
    background-size:183px 169px ;
    border-radius:50%;
    background-repeat: no-repeat;
    border:1px solid ${theme['Secondary-Gray0400']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['maskGroupDiv']['width'])}px;
    height: ${config['divs']['maskGroupDiv']['height']}px;
`;

const heroSectionDivProps = {
    width: Number, 
    padding: Number,
    gap: Number, 
    background_color: String
};
export const heroSectionDiv = styled("div", heroSectionDivProps)`
    background: ${(props) => ((props.background_color) ? (props.background_color):theme['Secondary-Gray0800'])};
    display: ${config['divs']['heroSectionDiv']['display']};
    flex-direction: ${config['divs']['heroSectionDiv']['flex-direction']};
    align-items: ${config['divs']['heroSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['heroSectionDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['heroSectionDiv']['gap'])};
    flex:${config['divs']['heroSectionDiv']['flex']};
    order:${config['divs']['heroSectionDiv']['order']};
    flex-grow:${config['divs']['heroSectionDiv']['flex-grow']};
    align-self:${config['divs']['heroSectionDiv']['align-self']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['heroSectionDiv']['width'])};
    height: ${config['divs']['heroSectionDiv']['height']};
`;
export const heroSectionImageDiv = styled("div", heroSectionDivProps)`
    background: url(${heroSectionImage});
    background-size: cover;
    background-position: center;
    border-radius: ${config['divs']['heroSectionImageDiv']['border-radius']};
    filter: ${config['divs']['heroSectionImageDiv']['filter']};
    margin-left: ${config['divs']['heroSectionImageDiv']['margin-left']};
    margin-right: ${config['divs']['heroSectionImageDiv']['margin-right']};
    flex:${config['divs']['heroSectionImageDiv']['flex']};
    order:${config['divs']['heroSectionImageDiv']['order']};
    flex-grow:${config['divs']['heroSectionImageDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['heroSectionImageDiv']['width'])};
    height: ${config['divs']['heroSectionImageDiv']['height']};
    word-break: ${config['divs']['heroSectionImageDiv']['word-break']};
    @media (max-width: 770px) {
        // Your media styles
        background : ${theme['Secondary-Gray0000']};
    }
`;
export const heroSectionTitleDiv = styled("div", heroSectionDivProps)`
    display: ${config['divs']['heroSectionTitleDiv']['display']};
    align-items: ${config['divs']['heroSectionTitleDiv']['align-items']};
    text-align: ${config['divs']['heroSectionTitleDiv']['text-align']};
    margin-left: ${config['divs']['heroSectionTitleDiv']['margin-left']};
    margin-top: ${config['divs']['heroSectionTitleDiv']['margin-top']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['heroSectionTitleDiv']['width'])};
    height: ${config['divs']['heroSectionTitleDiv']['height']};
    @media (max-width: 600px) {
        // Your media styles
        width: 90%
    }
`;
export const heroSectionDescriptionDiv = styled("div", heroSectionDivProps)`
    display: ${config['divs']['heroSectionDescriptionDiv']['display']};
    align-items: ${config['divs']['heroSectionDescriptionDiv']['align-items']};
    text-align: ${config['divs']['heroSectionDescriptionDiv']['text-align']};
    margin-left: ${config['divs']['heroSectionDescriptionDiv']['margin-left']}
    margin-top: ${config['divs']['heroSectionDescriptionDiv']['margin-top']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['heroSectionDescriptionDiv']['width'])};
    height: ${config['divs']['heroSectionDescriptionDiv']['height']};
    @media (max-width: 600px) {
        // Your media styles
        width: 80%
    }
`;
export const getStartedSectionDiv = styled("div", heroSectionDivProps)`
    display: ${config['divs']['getStartedSectionDiv']['display']};
    align-items: ${config['divs']['getStartedSectionDiv']['align-items']};
    text-align: ${config['divs']['getStartedSectionDiv']['text-align']};
    margin-left: ${config['divs']['getStartedSectionDiv']['margin-left']}
    margin-top: ${config['divs']['getStartedSectionDiv']['margin-top']}
    margin-bottom: ${config['divs']['getStartedSectionDiv']['margin-bottom']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['getStartedSectionDiv']['width'])};
    height: ${config['divs']['getStartedSectionDiv']['height']};
`;
export const HeroSectionImage = styled.div`
  background-image: url(${heroSectionImage});
    background-size:1113px 377px ;
    margin-left: 163px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 163px;
    width:1113px;
    height:373px;
    
`;

const heroSectionTitleProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    gap: Number, //multiple of 8, default 0
    margin_left: Number,
    margin_top: Number
};
export const heroSectionTitle = styled("div", heroSectionTitleProps)`
    display: ${(props) => (props.gap ? 'flex' : 'block')};
    overflow: hidden;
    margin-left:${(props) => ((props.margin_left) ? (props.margin_left) : "32")}px
    margin-top:${(props) => ((props.margin_top) ? (props.margin_top) : "32")}px
    width: ${(props) => ((props.width) ? (props.width) : "auto")}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }      
`;
const heroSectionProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    gap: Number, //multiple of 8, default 0
    display: String,
    background_color: String
};

export const HeroSectionDiv = styled("div", heroSectionProps)`
    display: ${(props) => (props.display ? 'flex' : 'block')};
    overflow: hidden;
    background: ${(props) => (props.background_color ? props.background_color : 'transparent')};
    width: ${(props) => ((props.width) ? (props.width) : "auto")}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }      
`;
/*const howItWorksProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    gap: Number, //multiple of 8, default 0
    display: String,
    background_color: String
};

export const howItWorksDiv = styled("div", howItWorksProps)`
    display: ${(props) => (props.display ? 'flex' : 'block')};
    overflow: hidden;
    background: ${(props) => (props.background_color ? props.background_color : 'transparent')};
    width: ${(props) => ((props.width) ? (props.width) : "auto")}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }      
`;*/
const upcomingCourseTitleProps = {
    width: Number, 
    textalign: String,
    padding: Number,
    gap: Number, 
    background_color: String,
    margin_bottom: Number

};
export const upcomingCourseTitle = styled("div", upcomingCourseTitleProps)`
    display: ${config['divs']['upcomingCourseTitleDiv']['display']};
    flex-direction: ${config['divs']['upcomingCourseTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['upcomingCourseTitleDiv']['justify-content']};
    align-items: ${config['divs']['upcomingCourseTitleDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['upcomingCourseTitleDiv']['padding'])};
    flex:${config['divs']['upcomingCourseTitleDiv']['flex']}
    order:${config['divs']['upcomingCourseTitleDiv']['order']}
    align-self:${config['divs']['upcomingCourseTitleDiv']['align-self']}
    flex-grow:${config['divs']['upcomingCourseTitleDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['upcomingCourseTitleDiv']['width'])};
    height: ${config['divs']['upcomingCourseTitleDiv']['height']};
`;
export const upcomingCourses = styled("div", upcomingCourseTitleProps)`
    display: ${config['divs']['upcomingCoursesDiv']['display']};
    flex-direction: ${config['divs']['upcomingCoursesDiv']['flex-direction']};
    align-items: ${config['divs']['upcomingCoursesDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['upcomingCoursesDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['upcomingCoursesDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['upcomingCoursesDiv']['width'])};
    margin-bottom: ${(props) => ((props.margin_bottom) ? (props.margin_bottom)+'px' : config['divs']['upcomingCoursesDiv']['margin-bottom'])};
    height:${config['divs']['upcomingCoursesDiv']['height']};
    overflow-x:${config['divs']['upcomingCoursesDiv']['overflow-x']};
    flex:${config['divs']['upcomingCoursesDiv']['flex']}
    order:${config['divs']['upcomingCoursesDiv']['order']}
    align-self:${config['divs']['upcomingCoursesDiv']['align-self']}
    flex-grow:${config['divs']['upcomingCoursesDiv']['flex-grow']}
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */

`;
export const myCourses = styled("div", upcomingCourseTitleProps)`
    display: ${config['divs']['myCoursesDiv']['display']};
    flex-direction: ${config['divs']['myCoursesDiv']['flex-direction']};
    align-items: ${config['divs']['myCoursesDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['myCoursesDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['myCoursesDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['myCoursesDiv']['width'])};
    height:${config['divs']['myCoursesDiv']['height']}
    overflow-x:${config['divs']['myCoursesDiv']['overflow-x']}
    flex:${config['divs']['myCoursesDiv']['flex']}
    order:${config['divs']['myCoursesDiv']['order']}
    align-self:${config['divs']['myCoursesDiv']['align-self']}
    flex-grow:${config['divs']['myCoursesDiv']['flex-grow']}
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    
`;
export const recommendedCourses = styled("div", upcomingCourseTitleProps)`
    display: ${config['divs']['recommendedCoursesDiv']['display']};
    flex-direction: ${config['divs']['recommendedCoursesDiv']['flex-direction']};
    align-items: ${config['divs']['recommendedCoursesDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['recommendedCoursesDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['recommendedCoursesDiv']['gap'])}px
    flex:${config['divs']['recommendedCoursesDiv']['flex']}
    overflow-x:${config['divs']['recommendedCoursesDiv']['overflow-x']}
    order:${config['divs']['recommendedCoursesDiv']['order']}
    align-self:${config['divs']['recommendedCoursesDiv']['align-self']}
    flex-grow:${config['divs']['recommendedCoursesDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['recommendedCoursesDiv']['width'])};
    overflow-x: ${config['divs']['recommendedCoursesDiv']['overflow-x']};
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
        height: 0;
        display:none;  /* for Chrome, Safari, and Opera */
    }
    scrollbar-width: none;  /* for Firefox */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
`;

const abhyasCourseCardProps = {
    width: Number, 
    textalign: String,
    padding: Number,
    gap: Number, 
    background: String,
    position: String
};

// TODO: Its not abhyas Card - rename it
export const abhyasCourseCard = styled("div", abhyasCourseCardProps)`
    display: ${config['divs']['abhyasCourseCardDiv']['display']};
    flex-direction: ${config['divs']['abhyasCourseCardDiv']['flex-direction']};
    align-items: ${config['divs']['abhyasCourseCardDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['abhyasCourseCardDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['abhyasCourseCardDiv']['gap'])};
    flex:${config['divs']['abhyasCourseCardDiv']['flex']}
    order:${config['divs']['abhyasCourseCardDiv']['order']}
    align-self:${config['divs']['abhyasCourseCardDiv']['align-self']}
    flex-grow:${config['divs']['abhyasCourseCardDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) + 'px' : config['divs']['abhyasCourseCardDiv']['width'])};
    height: ${config['divs']['abhyasCourseCardDiv']['height']};
    background: ${(props) => ((props.background) ? (props.background) : theme['Secondary-Gray0000'])};
    box-shadow: ${config['divs']['abhyasCourseCardDiv']['box-shadow']};
    border-radius: ${config['divs']['abhyasCourseCardDiv']['border-radius']};
    position: ${(props) => ((props.position) ? (props.position) : config['divs']['abhyasCourseCardDiv']['position'])};
    z-index: ${config['divs']['abhyasCourseCardDiv']['z-index']};
`;

const meetTheTeamTitleProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    gap: Number, //multiple of 8, default 0
    background_color: String
};
export const meetTheTeamTitle = styled("div", meetTheTeamTitleProps)`
    display: ${(props) => (props.gap ? 'flex' : 'block')};
    overflow: hidden;
    margin-left:16px;
    margin-top:8px
    height:48px;
    background: ${(props) => (props.background_color ? props.background_color : 'transparent')};
    width: ${(props) => ((props.width) ? (props.width) : "auto")}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    padding: ${(props) => ((props.padding) ? (props.padding)*8 : 0)}px;
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }      
`;
const howItWorkSectionDivProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    gap: Number, //multiple of 8, default 0
    background_color: String
};
export const howItWorkSectionDiv = styled("div", howItWorkSectionDivProps)`
    background: ${(props) => ((props.background_color) ? (props.background_color):theme['Secondary-Gray0800'])};
    display: ${config['divs']['howItWorkSectionDiv']['display']};
    flex-direction: ${config['divs']['howItWorkSectionDiv']['flex-direction']};
    align-items: ${config['divs']['howItWorkSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['howItWorkSectionDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['howItWorkSectionDiv']['gap'])};
    flex:${config['divs']['howItWorkSectionDiv']['flex']}
    order:${config['divs']['howItWorkSectionDiv']['order']}
    flex-grow:${config['divs']['howItWorkSectionDiv']['flex-grow']}
    align-self:${config['divs']['howItWorkSectionDiv']['align-self']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['howItWorkSectionDiv']['width'])};
    height: ${config['divs']['howItWorkSectionDiv']['height']};
`;
export const howItWorksDiv = styled("div", howItWorkSectionDivProps)`
    background: ${(props) => ((props.background_color) ? (props.background_color):theme['Secondary-Gray0000'])};
    display: ${config['divs']['howItWorksDiv']['display']};
    flex-direction: ${config['divs']['howItWorksDiv']['flex-direction']};
    align-items: ${config['divs']['howItWorksDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['howItWorksDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['howItWorksDiv']['gap'])};
    margin-left: ${config['divs']['howItWorksDiv']['margin-left']};
    margin-right: ${config['divs']['howItWorksDiv']['margin-right']};
    flex:${config['divs']['howItWorksDiv']['flex']}
    order:${config['divs']['howItWorksDiv']['order']}
    flex-grow:${config['divs']['howItWorksDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['howItWorksDiv']['width'])};
    height: ${config['divs']['howItWorksDiv']['height']};
    border-radius: ${config['divs']['howItWorksDiv']['border-radius']};
    box-shadow: ${config['divs']['howItWorksDiv']['box-shadow']};
`;
export const howItWorksTitleDiv = styled("div", howItWorkSectionDivProps)`
    display: ${config['divs']['howItWorksTitleDiv']['display']};
    justify-content: ${config['divs']['howItWorksTitleDiv']['justify-content']};
    align-items: ${config['divs']['howItWorksTitleDiv']['align-items']};
    text-align: ${config['divs']['howItWorksTitleDiv']['text-align']};
    flex:${config['divs']['howItWorksTitleDiv']['flex']}
    order:${config['divs']['howItWorksTitleDiv']['order']}
    align-self:${config['divs']['howItWorksTitleDiv']['align-self']}
    flex-grow:${config['divs']['howItWorksTitleDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['howItWorksTitleDiv']['width'])};
    height: ${config['divs']['howItWorksTitleDiv']['height']};
`;
export const howItWorksStepsDiv = styled("div", howItWorkSectionDivProps)`
    display: ${config['divs']['howItWorksStepsDiv']['display']};
    flex-direction: ${config['divs']['howItWorksStepsDiv']['flex-direction']};
    justify-content: ${config['divs']['howItWorksStepsDiv']['justify-content']};
    align-items: ${config['divs']['howItWorksStepsDiv']['align-items']};
    padding: ${config['divs']['howItWorksStepsDiv']['padding']};
    gap: ${config['divs']['howItWorksStepsDiv']['gap']};
    flex:${config['divs']['howItWorksStepsDiv']['flex']};
    order:${config['divs']['howItWorksStepsDiv']['order']};
    align-self:${config['divs']['howItWorksStepsDiv']['align-self']}
    flex-grow:${config['divs']['howItWorksStepsDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['howItWorksStepsDiv']['width'])};
    height: ${config['divs']['howItWorksStepsDiv']['height']};
    @media (max-width : 760px){
        svg{
            height : 150px;
        }
    }
`;
/*export const howItWorkStepsDiv = styled.div`
    display: flex;
    background: ${theme['Secondary-Gray0000']};
    margin-left: 12px;
    margin-top: 28px;
    margin-bottom: 28px;
    margin-right: 12px;
    width:1088px;
    height:224px;     
`;*/

const footerLinkProps = {
    width: Number,
    padding: Number, 
    textalign: String,
    float: String,
    gap: Number,
    overflow: String,
    background_color: String,
    display: String,
    height: Number,
    order: Number
};

export const footerLinkGroupDiv = styled("div", footerLinkProps)`
    display: ${config['divs']['footerLinkGroupDiv']['display']};
    flex-direction: ${config['divs']['footerLinkGroupDiv']['flex-direction']};
    align-items: ${config['divs']['footerLinkGroupDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['footerLinkGroupDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['footerLinkGroupDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) + 'px' : config['divs']['footerLinkGroupDiv']['width'])};
    height: ${config['divs']['footerLinkGroupDiv']['height']};
    flex:${config['divs']['footerLinkGroupDiv']['flex']}
    order:${(props) => ((props.order) ? (props.order) :config['divs']['footerLinkGroupDiv']['order'])}
    flex-grow:${config['divs']['footerLinkGroupDiv']['flex-grow']}
`;

const footerTitleProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: String,
    gap: Number, //multiple of 8, default 0
    display: String
};

export const footerTitleDiv = styled("div", footerTitleProps)`
    display: ${(props) => (props.display ? 'flex' : 'block')};
    overflow: hidden;
    width: ${(props) => (props.width ? (props.width) : "auto")}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : 'center')};
    gap: ${(props) => (props.gap ? props.gap*8 : 0)}px;
    padding: ${(props) => ((props.padding) ? (props.padding) : '0px')};
    &::after {
        content: "";
        display: table;
        clear: both;
        background: transparent;
    }      
`;
const footerSectionProps = {
    width: Number, // width is multiple of 10
    textalign: String,
    padding: Number,
    gap: Number, //multiple of 8, default 0
    display: String,
    background_color: String
};

export const footerSectionDiv = styled("div", footerSectionProps)`
    box-sizing: ${config['divs']['footerSectionDiv']['box-sizing']};
    display: ${config['divs']['footerSectionDiv']['display']};
    flex-direction: ${config['divs']['footerSectionDiv']['flex-direction']};
    justify-content: ${config['divs']['footerSectionDiv']['justify-content']};
    align-items: ${config['divs']['footerSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['footerSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['footerSectionDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['footerSectionDiv']['width'])};
    height:${config['divs']['footerSectionDiv']['height']};
    background: ${(props) => (props.background_color ? props.background_color: theme['Secondary-Gray0200'])};
    border: 1px solid ${theme['Secondary-Gray0400']}
    flex:${config['divs']['footerSectionDiv']['flex']}
    order:${config['divs']['footerSectionDiv']['order']}
    flex-grow:${config['divs']['footerSectionDiv']['flex-grow']}
    align-self:${config['divs']['footerSectionDiv']['align-self']}
    flex-wrap : ${config['divs']['footerSectionDiv']['flex-wrap']}
    @media (min-width: 481px) and (max-width: 960px){
        justify-content : center;
        gap: 0px
    }
    @media (max-width : 480px){
        justify-content : flex-start;
        gap: 24px
    }
    
`;
export const footerFrameSectionDiv = styled("div", footerSectionProps)`
    display: ${config['divs']['frames']['footerFrameSectionDiv']['display']};
    flex-direction: ${config['divs']['frames']['footerFrameSectionDiv']['flex-direction']};
    align-items: ${config['divs']['frames']['footerFrameSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['frames']['footerFrameSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['frames']['footerFrameSectionDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) + 'px' : config['divs']['frames']['footerFrameSectionDiv']['width'])};
    height:${config['divs']['frames']['footerFrameSectionDiv']['height']};
    flex:${config['divs']['frames']['footerFrameSectionDiv']['flex']}
    order:${config['divs']['frames']['footerFrameSectionDiv']['order']}
    align-self:${config['divs']['frames']['footerFrameSectionDiv']['align-self']}
`;
const frame_27Props = {
    width: String, // width is multiple of 10
    padding: String, // multiple of 8
    background_color: String,
};
export const frame_36 = styled("div", frame_27Props)`
    display: ${config['divs']['frames']['frame_36']['display']};
    flex-direction: ${config['divs']['frames']['frame_36']['flex-direction']};
    justify-content: ${config['divs']['frames']['frame_36']['justify-content']};
    align-items: ${config['divs']['frames']['frame_36']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['frames']['frame_36']['padding'])}px;
    flex:${config['divs']['frames']['frame_36']['flex']}
    order:${config['divs']['frames']['frame_36']['order']}
    flex-grow:${config['divs']['frames']['frame_36']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_36']['width'])}px;
    height: ${config['divs']['frames']['frame_36']['height']}px;
`;

export const frame_27 = styled("div", frame_27Props)`
    background: ${(props) => (props.background_color ? props.background_color:theme['Secondary-Gray0000'])};
    width: ${(props) => (props.width ? props.width : config['divs']['frames']['frame_27']['width'])}px;
    height: ${(props) => (props.height ? props.height : config['divs']['frames']['frame_27']['height'])}px;
    padding: ${(props) => ((props.padding) ? (props.padding) : config['divs']['frames']['frame_27']['padding'])}px;
    box-shadow: ${config['divs']['frames']['frame_27']['box-shadow']};
    margin-left: ${config['divs']['frames']['frame_27']['left']}px;
    margin-top: ${config['divs']['frames']['frame_27']['top']}px;
    margin-right: ${config['divs']['frames']['frame_27']['right']}px;
    margin-bottom: ${config['divs']['frames']['frame_27']['bottom']}px;
    border:1px solid red;
    display: ${config['divs']['frames']['frame_27']['display']};
    flex-direction: ${config['divs']['frames']['frame_27']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_27']['align-items']}
`;
const frame_26Props = {
    width: String, // width is multiple of 10
    padding: String, // multiple of 8
    background_color: String,
};
export const frame_26 = styled("div", frame_26Props)`
    background: ${(props) => (props.background_color ? props.background_color:theme['Secondary-Gray0800'])};
    width: ${(props) => (props.width ? props.width : config['divs']['frames']['frame_26']['width'])}px;
    height: ${(props) => (props.height ? props.height : config['divs']['frames']['frame_26']['height'])}px;
    border:1px solid red;
    flex: ${config['divs']['frames']['frame_26']['flex']};
    order: ${config['divs']['frames']['frame_26']['order']}
    flex-grow: ${config['divs']['frames']['frame_26']['flex-grow']};
`;
export const frame_22 = styled("div", frame_26Props)`
    display: ${config['divs']['frames']['frame_22']['display']};
    flex-direction: ${config['divs']['frames']['frame_22']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_22']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['frame_22']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['frames']['frame_22']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['frame_22']['width'])};
    height: ${config['divs']['frames']['frame_22']['height']};
    flex:${config['divs']['frames']['frame_22']['flex']}
    order:${config['divs']['frames']['frame_22']['order']}
    flex-grow:${config['divs']['frames']['frame_22']['flex-grow']}
`;
export const frame_16 = styled("div", frame_26Props)`
    display: ${config['divs']['frames']['frame_16']['display']};
    flex-direction: ${config['divs']['frames']['frame_16']['flex-direction']};
    align-items: ${config['divs']['frames']['frame_16']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['frames']['frame_16']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['frames']['frame_16']['gap'])}px
    flex:${config['divs']['frames']['frame_16']['flex']}
    order:${config['divs']['frames']['frame_16']['order']}
    flex-grow:${config['divs']['frames']['frame_16']['flex-grow']}
    align-self:${config['divs']['frames']['frame_16']['align-self']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_16']['width'])}px;
    height: ${config['divs']['frames']['frame_16']['height']}px;
`;
export const frame_15 = styled("div", frame_26Props)`
    display: ${config['divs']['frames']['frame_15']['display']};
    flex-direction: ${config['divs']['frames']['frame_15']['flex-direction']};
    justify-content: ${config['divs']['frames']['frame_15']['justify-content']};
    align-items: ${config['divs']['frames']['frame_15']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['frames']['frame_15']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['frames']['frame_15']['gap'])};
    flex:${config['divs']['frames']['frame_15']['flex']}
    order:${config['divs']['frames']['frame_15']['order']}
    flex-grow:${config['divs']['frames']['frame_15']['flex-grow']}
    align-self:${config['divs']['frames']['frame_15']['align-self']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_15']['width'])};
    height: ${config['divs']['frames']['frame_15']['height']};
`;
export const frame_25 = styled("div", frame_26Props)`
    background: ${(props) => (props.background_color ? props.background_color:theme['Secondary-Gray0200'])};
    width: ${(props) => (props.width ? props.width : config['divs']['frames']['frame_25']['width'])}px;
    height: ${(props) => (props.height ? props.height : config['divs']['frames']['frame_25']['height'])}px;
    border:1px solid red;
    flex: ${config['divs']['frames']['frame_25']['flex']};
    order: ${config['divs']['frames']['frame_25']['order']}
    flex-grow: ${config['divs']['frames']['frame_25']['flex-grow']};
`;
const titleBarProps = {
    width: Number,
    height: Number,
    margin_left: Number,
    margin_top: Number,
    margin_right: Number,
    display: String,
    background_color:String,
    textalign:String
};
export const titleBar = styled("div", titleBarProps)`
    background: ${(props) => (props.background_color ? props.background_color:'transperant')};
    display: ${(props) => (props.display ? props.display : config['divs']['titlebar']['display'])};
    overflow: hidden;
    margin-left:${(props) => ((props.margin_left) ? (props.margin_left) : config['divs']['titlebar']['left'])}px
    margin-top:${(props) => ((props.margin_top) ? (props.margin_top) : config['divs']['titlebar']['top'])}px
    margin-right:${(props) => ((props.margin_right) ? (props.margin_right) : config['divs']['titlebar']['right'])}px
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['titlebar']['width'])}px;
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['titlebar']['height'])}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : config['divs']['titlebar']['text-align'])};
`;
const descriptionBarProps = {
    width: Number,
    height: Number,
    margin_left: Number,
    margin_top: Number,
    margin_right: Number,
    margin_bottom: Number,
    display: String,
    background_color:String,
    textalign:String
};
export const descriptionBar = styled("div", descriptionBarProps)`
    background: ${(props) => (props.background_color ? props.background_color:'transperant')};
    display: ${(props) => (props.display ? props.display : config['divs']['descriptionbar']['display'])};
    overflow: hidden;
    margin-left:${(props) => ((props.margin_left) ? (props.margin_left) : config['divs']['descriptionbar']['left'])}px
    margin-top:${(props) => ((props.margin_top) ? (props.margin_top) : config['divs']['descriptionbar']['top'])}px
    margin-right:${(props) => ((props.margin_right) ? (props.margin_right) : config['divs']['descriptionbar']['right'])}px
    margin-bottom:${(props) => ((props.margin_bottom) ? (props.margin_bottom) : config['divs']['descriptionbar']['bottom'])}px
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['descriptionbar']['width'])}px;
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['descriptionbar']['height'])}px;
    text-align: ${(props) => ((props.textalign) ? (props.textalign) : config['divs']['descriptionbar']['text-align'])};
`;
const frame_28_Props = {
    width: Number,
    height: Number,
    margin_left: Number,
    margin_top: Number,
    margin_right: Number
};
export const frame_28 = styled("div", frame_28_Props)`
    display: ${config['divs']['frames']['frame_28']['display']};
    flex-direction: ${config['divs']['frames']['frame_28']['flex-direction']};
    justify-content: ${config['divs']['frames']['frame_28']['justify-content']};
    align-items: ${config['divs']['frames']['frame_28']['align-items']};
    margin-left:${(props) => ((props.margin_left) ? (props.margin_left) : config['divs']['frames']['frame_28']['left'])}px
    margin-top:${(props) => ((props.margin_top) ? (props.margin_top) : config['divs']['frames']['frame_28']['top'])}px
    margin-right:${(props) => ((props.margin_right) ? (props.margin_right) : config['divs']['frames']['frame_28']['right'])}px
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_28']['width'])}px;
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['frames']['frame_28']['height'])}px;
`;
export const frame_29 = styled("div", frame_28_Props)`
    margin-left:${(props) => ((props.margin_left) ? (props.margin_left) : config['divs']['frames']['frame_29']['left'])}px
    margin-top:${(props) => ((props.margin_top) ? (props.margin_top) : config['divs']['frames']['frame_29']['top'])}px
    margin-right:${(props) => ((props.margin_right) ? (props.margin_right) : config['divs']['frames']['frame_29']['right'])}px
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_29']['width'])}px;
    height: ${(props) => ((props.height) ? (props.height) : config['divs']['frames']['frame_29']['height'])}px;
`;
const rectangle5DivProps = {
    width: Number, // width is multiple of 100 in px
    height: Number
};

export const rectangle5Div = styled("div", rectangle5DivProps)`
    position: ${config['divs']['rectangle5Div']['position']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['rectangle5Div']['width'])};
    height: ${config['divs']['rectangle5Div']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow: ${config['divs']['rectangle5Div']['box-shadow']};
`;
const filterSectionDivProps = {
    width: Number, // width is in px
    height: Number,
    gap: Number,
    padding: Number
};

export const filterSectionDiv = styled("div", filterSectionDivProps)`
    box-sizing: ${config['divs']['filterSectionDiv']['box-sizing']};
    display: ${config['divs']['filterSectionDiv']['display']};
    flex-direction: ${config['divs']['filterSectionDiv']['flex-direction']};
    align-items: ${config['divs']['filterSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['filterSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['filterSectionDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['filterSectionDiv']['width'])};
    height: ${config['divs']['filterSectionDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0500']};
    flex:${config['divs']['filterSectionDiv']['flex']};
    order:${config['divs']['filterSectionDiv']['order']};
    flex-grow:${config['divs']['filterSectionDiv']['flex-grow']};
    z-index:${config['divs']['filterSectionDiv']['z-index']};
`;
const filtersDivProps = {
    width: Number, //in px
};
export const filtersDiv = styled("div", filtersDivProps)`
    display: ${config['divs']['filtersDiv']['display']};
    flex-direction: ${config['divs']['filtersDiv']['flex-direction']};
    align-items: ${config['divs']['filtersDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['filtersDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['filtersDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['filtersDiv']['width'])};
    height:${config['divs']['filtersDiv']['height']};
    flex:${config['divs']['filtersDiv']['flex']};
    order:${config['divs']['filtersDiv']['order']};
    align-self:${config['divs']['filtersDiv']['align-self']};
    flex-grow:${config['divs']['filtersDiv']['flex-grow']};
`;
const filterSubjectSectionProps = {
    width: Number,
    padding: Number,
    height: Number,
    order: Number
};
export const filterSubjectSection = styled("div", filterSubjectSectionProps)`
    display: ${config['divs']['frames']['filterSubjectSection']['display']};
    flex-direction: ${config['divs']['frames']['filterSubjectSection']['flex-direction']};
    align-items: ${config['divs']['frames']['filterSubjectSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['filterSubjectSection']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['filterSubjectSection']['width'])};
    height: ${config['divs']['frames']['filterSubjectSection']['height']};
    flex:${config['divs']['frames']['filterSubjectSection']['flex']};
    order:${config['divs']['frames']['filterSubjectSection']['order']};
    flex-grow:${config['divs']['frames']['filterSubjectSection']['flex-grow']};
`;
export const filterCurriculumSection = styled("div", filterSubjectSectionProps)`
    display: ${config['divs']['frames']['filterCurriculumSection']['display']};
    flex-direction: ${config['divs']['frames']['filterCurriculumSection']['flex-direction']};
    align-items: ${config['divs']['frames']['filterCurriculumSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['filterCurriculumSection']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['filterCurriculumSection']['width'])};
    height: ${config['divs']['frames']['filterCurriculumSection']['height']};
    flex:${config['divs']['frames']['filterCurriculumSection']['flex']};
    order:${config['divs']['frames']['filterCurriculumSection']['order']};
    flex-grow:${config['divs']['frames']['filterCurriculumSection']['flex-grow']};
`;
export const filterStandardSection = styled("div", filterSubjectSectionProps)`
    display: ${config['divs']['frames']['filterStandardSection']['display']};
    flex-direction: ${config['divs']['frames']['filterStandardSection']['flex-direction']};
    align-items: ${config['divs']['frames']['filterStandardSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['filterStandardSection']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['filterStandardSection']['width'])};
    height: ${config['divs']['frames']['filterStandardSection']['height']};
    flex:${config['divs']['frames']['filterStandardSection']['flex']};
    order:${config['divs']['frames']['filterStandardSection']['order']};
    flex-grow:${config['divs']['frames']['filterStandardSection']['flex-grow']};
`;
export const filterTypeSection = styled("div", filterSubjectSectionProps)`
    display: ${config['divs']['frames']['filterTypeSection']['display']};
    flex-direction: ${config['divs']['frames']['filterTypeSection']['flex-direction']};
    align-items: ${config['divs']['frames']['filterTypeSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['filterTypeSection']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['filterTypeSection']['width'])};
    height: ${config['divs']['frames']['filterTypeSection']['height']};
    flex:${config['divs']['frames']['filterTypeSection']['flex']};
    order:${config['divs']['frames']['filterTypeSection']['order']};
    flex-grow:${config['divs']['frames']['filterTypeSection']['flex-grow']};
`;
export const filterMyLearningSection = styled("div", filterSubjectSectionProps)`
    display: ${config['divs']['frames']['filterMyLearningSection']['display']};
    flex-direction: ${config['divs']['frames']['filterMyLearningSection']['flex-direction']};
    align-items: ${config['divs']['frames']['filterMyLearningSection']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['frames']['filterMyLearningSection']['padding'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['frames']['filterMyLearningSection']['width'])};
    height: ${config['divs']['frames']['filterMyLearningSection']['height']};
    flex:${config['divs']['frames']['filterMyLearningSection']['flex']};
    order:${config['divs']['frames']['filterMyLearningSection']['order']};
    flex-grow:${config['divs']['frames']['filterMyLearningSection']['flex-grow']};
`;
const checkBoxGroupDivProps = {
    width: Number,
    padding: Number,
    gap: Number,
    order : String
};
export const checkBoxGroupDiv = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxGroupDiv']['display']};
    flex-direction: ${config['divs']['checkBoxGroupDiv']['flex-direction']};
    align-items: ${config['divs']['checkBoxGroupDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['checkBoxGroupDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['checkBoxGroupDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupDiv']['width'])};
    height: ${config['divs']['checkBoxGroupDiv']['height']};
    flex:${config['divs']['checkBoxGroupDiv']['flex']};
    order:${config['divs']['checkBoxGroupDiv']['order']};
    align-self:${config['divs']['checkBoxGroupDiv']['align-self']};
    flex-grow:${config['divs']['checkBoxGroupDiv']['flex-grow']};
`;
export const checkBoxGroupTitle = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxGroupTitle']['display']};
    align-items: ${config['divs']['checkBoxGroupTitle']['align-items']};
    flex:${config['divs']['checkBoxGroupTitle']['flex']};
    order:${config['divs']['checkBoxGroupTitle']['order']};
    align-self:${config['divs']['checkBoxGroupTitle']['align-self']};
    flex-grow:${config['divs']['checkBoxGroupTitle']['flex-grow']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxGroupTitle']['width'])};
    height: ${config['divs']['checkBoxGroupTitle']['height']};
    color: ${theme['Secondary-Gray0800']};
`;
export const checkBoxDiv = styled("div", checkBoxGroupDivProps)`
    display: ${config['divs']['checkBoxDiv']['display']};
    flex-direction: ${config['divs']['checkBoxDiv']['flex-direction']};
    align-items: ${config['divs']['checkBoxDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['checkBoxDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['checkBoxDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['checkBoxDiv']['width'])};
    height: ${config['divs']['checkBoxDiv']['height']};
    flex:${config['divs']['checkBoxDiv']['flex']};
    order:${(props) => ((props.order) ? (props.order) : config['divs']['checkBoxDiv']['order'])};
    align-self:${config['divs']['checkBoxDiv']['align-self']};
    flex-grow:${config['divs']['checkBoxDiv']['flex-grow']};
`;
const checkBoxDiv_props = {
    width: Number,
    padding: Number,
};
export const unselectedCheckBox = styled("input", checkBoxDiv_props)`
    box-sizing: ${config['divs']['checkBox']['unselectedCheckBox']['box-sizing']};
    width: ${(props) => ((props.width) ? (props.width)+ 'px' : config['divs']['checkBox']['unselectedCheckBox']['width'])};
    height: ${config['divs']['checkBox']['unselectedCheckBox']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0600']};
    border-radius:${ config['divs']['checkBox']['unselectedCheckBox']['border-radius']}
    flex:${config['divs']['checkBox']['unselectedCheckBox']['flex']}
    order:${config['divs']['checkBox']['unselectedCheckBox']['order']}
    flex-grow:${config['divs']['checkBox']['unselectedCheckBox']['flex-grow']}
`;
export const selectedCheckBox = styled("div", checkBoxDiv_props)`
    box-sizing: ${config['divs']['checkBox']['selectedCheckBox']['box-sizing']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['checkBox']['selectedCheckBox']['width'])};
    height: ${config['divs']['checkBox']['selectedCheckBox']['height']};
    background: ${config['divs']['checkBox']['selectedCheckBox']['background']};
    border: ${config['divs']['checkBox']['selectedCheckBox']['border']};
    border-radius:${(props) => ((props.padding) ? (props.padding) : config['divs']['checkBox']['selectedCheckBox']['border-radius'])}
    flex:${config['divs']['checkBox']['selectedCheckBox']['flex']}
    order:${config['divs']['checkBox']['selectedCheckBox']['order']}
    flex-grow:${config['divs']['checkBox']['selectedCheckBox']['flex-grow']}
    z-index: ${config['divs']['checkBox']['selectedCheckBox']['z-index']}
    color: ${config['divs']['checkBox']['selectedCheckBox']['color']};
`;
export const disabledCheckBox = styled("div", checkBoxDiv_props)`
    box-sizing: ${config['divs']['checkBox']['disabledCheckBox']['box-sizing']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['checkBox']['disabledCheckBox']['width'])};
    height: ${config['divs']['checkBox']['disabledCheckBox']['height']};
    background: ${config['divs']['checkBox']['disabledCheckBox']['background']};
    border: ${config['divs']['checkBox']['disabledCheckBox']['border']};
    border-radius:${(props) => ((props.padding) ? (props.padding) : config['divs']['checkBox']['disabledCheckBox']['border-radius'])}px
    flex:${config['divs']['checkBox']['disabledCheckBox']['flex']}
    order:${config['divs']['checkBox']['disabledCheckBox']['order']}
    flex-grow:${config['divs']['checkBox']['disabledCheckBox']['flex-grow']}
`;
export const intermediateCheckBox = styled("div", checkBoxDiv_props)`
    box-sizing: ${config['divs']['checkBox']['intermediateCheckBox']['box-sizing']};
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['checkBox']['intermediateCheckBox']['width'])};
    height: ${config['divs']['checkBox']['intermediateCheckBox']['height']};
    background: ${config['divs']['checkBox']['intermediateCheckBox']['background']};
    border: ${config['divs']['checkBox']['intermediateCheckBox']['border']};
    border-radius:${(props) => ((props.padding) ? (props.padding) : config['divs']['checkBox']['intermediateCheckBox']['border-radius'])}px
    flex:${config['divs']['checkBox']['intermediateCheckBox']['flex']}
    order:${config['divs']['checkBox']['intermediateCheckBox']['order']}
    flex-grow:${config['divs']['checkBox']['intermediateCheckBox']['flex-grow']}
    z-index: ${config['divs']['checkBox']['intermediateCheckBox']['z-index']}
`;
const box_div_props = {
    width: Number,
    borderRadius: Number
};
export const boxDiv = styled("input", box_div_props)`
    box-sizing: ${config['divs']['boxDiv']['display']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['boxDiv']['width'])};
    height: ${config['divs']['boxDiv']['height']};
    background: ${theme['Secondary-Gray0000']};
    border: 1px solid ${theme['Secondary-Gray0600']};
    border-radius: ${(props) => ((props.borderRadius) ? (props.borderRadius)+'px' : config['divs']['boxDiv']['border-radius'])};
    flex:${config['divs']['boxDiv']['flex']}
    order:${config['divs']['boxDiv']['order']}
    z-index:${config['divs']['boxDiv']['z-index']}
    &:focus{
        background-color: ${theme['Secondary-Gray0100']};
        color: ${theme['Primary-Cyan0400']};
    }

`;
const searchPageProps = {
    width: Number, // width is multiple of 100 in px
    height: Number, //multiple of 8, default 0
    position: String,
    background: String,
    margin_left: Number,
    margin_top: Number

};

export const searchPageDiv = styled("div", searchPageProps)`
    display: ${config['divs']['searchPageDiv']['display']};
    flex-direction: ${config['divs']['searchPageDiv']['flex-direction']};
    align-items: ${config['divs']['searchPageDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['searchPageDiv']['padding'])};
    isolation:${config['divs']['searchPageDiv']['isolation']};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['searchPageDiv']['width'])};
    height: ${config['divs']['searchPageDiv']['height']};
`;
export const home_Page_Div = styled("div", searchPageProps)`
    display: ${config['divs']['searchPageDiv']['display']};
    flex-direction: ${config['divs']['searchPageDiv']['flex-direction']};
    align-items: ${config['divs']['searchPageDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['searchPageDiv']['padding'])}px
    isolation:${config['divs']['searchPageDiv']['isolation']}
    margin-left:${(props) => ((props.margin_left) ? (props.margin_left) : config['divs']['searchPageDiv']['left'])}px;
    height: ${config['divs']['searchPageDiv']['height']}px;
`;

const searchContentDivProps = {
    width: Number

};

export const searchContentDiv = styled("div", searchContentDivProps)`
    display: ${config['divs']['searchContentDiv']['display']};
    flex-direction: ${config['divs']['searchContentDiv']['flex-direction']};
    align-items: ${config['divs']['searchContentDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['searchContentDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['searchContentDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+ 'px' : config['divs']['searchContentDiv']['width'])};
    height: ${config['divs']['searchContentDiv']['height']};
    flex:${config['divs']['searchContentDiv']['flex']}
    order:${config['divs']['searchContentDiv']['order']}
    flex-grow:${config['divs']['searchContentDiv']['flex-grow']}
    z-index:${config['divs']['searchContentDiv']['z-index']}
`;
const sessionSummaryCardProps = {
    width: Number, // width is multiple of 100 in px
    padding: Number,
    gap: Number,
    order: Number, 
    background: String,

};

export const sessionSummaryCard = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['sessionSummaryCard']['display']};
    flex-direction: ${config['divs']['sessionSummaryCard']['flex-direction']};
    align-items: ${config['divs']['sessionSummaryCard']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['sessionSummaryCard']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['sessionSummaryCard']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionSummaryCard']['width'])};
    height: ${config['divs']['sessionSummaryCard']['height']};
    background: ${theme['Secondary-Gray0000']};
    box-shadow:${config['divs']['sessionSummaryCard']['box-shadow']};
    border-radius:${config['divs']['sessionSummaryCard']['border-radius']};
    flex:${config['divs']['sessionSummaryCard']['flex']}
    order:${config['divs']['sessionSummaryCard']['order']}
    flex-grow:${config['divs']['sessionSummaryCard']['flex-grow']}
    align-self: ${config['divs']['sessionSummaryCard']['align-self']}
`;
export const topSessionDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['topSessionDiv']['display']};
    flex-direction: ${config['divs']['topSessionDiv']['flex-direction']};
    align-items: ${config['divs']['topSessionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['topSessionDiv']['padding'])}px
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['topSessionDiv']['gap'])}px
    flex:${config['divs']['topSessionDiv']['flex']}
    order:${config['divs']['topSessionDiv']['order']}
    align-self:${config['divs']['topSessionDiv']['align-self']}
    flex-grow:${config['divs']['topSessionDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['topSessionDiv']['width'])};
`;
export const sessions = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['sessionsDiv']['display']};
    flex-direction: ${config['divs']['sessionsDiv']['flex-direction']};
    align-items: ${config['divs']['sessionsDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['sessionsDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['sessionsDiv']['gap'])};
    flex:${config['divs']['sessionsDiv']['flex']}
    order:${config['divs']['sessionsDiv']['order']}
    align-self:${config['divs']['sessionsDiv']['align-self']}
    flex-grow:${config['divs']['sessionsDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionsDiv']['width'])};
    height: ${config['divs']['sessionsDiv']['height']};
`;
export const tagCloudDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['tagCloudDiv']['display']};
    flex-direction: ${config['divs']['tagCloudDiv']['flex-direction']};
    align-items: ${config['divs']['tagCloudDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['tagCloudDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['tagCloudDiv']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['tagCloudDiv']['width'])};
    height: ${config['divs']['tagCloudDiv']['height']};
    flex:${config['divs']['tagCloudDiv']['flex']}
    order:${config['divs']['tagCloudDiv']['order']}
    flex-grow:${config['divs']['tagCloudDiv']['flex-grow']}
`;
export const tagRowDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['tagRowDiv']['display']};
    flex-wrap: ${config['divs']['tagRowDiv']['flex-wrap']};
    flex-direction: ${config['divs']['tagRowDiv']['flex-direction']};
    align-items: ${config['divs']['tagRowDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['tagRowDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['tagRowDiv']['gap'])};
    flex:${config['divs']['tagRowDiv']['flex']}
    order:${config['divs']['tagRowDiv']['order']}
    flex-grow:${config['divs']['tagRowDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['tagRowDiv']['width'])};
    height: ${config['divs']['tagRowDiv']['height']};
`;

export const selectedFiltersDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['selectedFiltersDiv']['display']};
    flex-direction: ${config['divs']['selectedFiltersDiv']['flex-direction']};
    justify-content: ${config['divs']['selectedFiltersDiv']['justify-content']};
    align-items: ${config['divs']['selectedFiltersDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['selectedFiltersDiv']['padding'])}
    width: ${(props) => ((props.width) ? (props.width)+ 'px' : config['divs']['selectedFiltersDiv']['width'])};
    height: ${config['divs']['selectedFiltersDiv']['height']};
    flex:${config['divs']['selectedFiltersDiv']['flex']}
    order:${config['divs']['selectedFiltersDiv']['order']}
    align-self:${config['divs']['selectedFiltersDiv']['align-self']}
    flex-grow:${config['divs']['selectedFiltersDiv']['flex-grow']}
`;
export const sessionSummaryCardLeftDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['sessionSummaryCardLeft']['display']};
    flex-direction: ${config['divs']['sessionSummaryCardLeft']['flex-direction']};
    align-items: ${config['divs']['sessionSummaryCardLeft']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['sessionSummaryCardLeft']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['sessionSummaryCardLeft']['gap'])};
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['sessionSummaryCardLeft']['width'])};
    height: ${config['divs']['sessionSummaryCardLeft']['height']};
    flex:${config['divs']['sessionSummaryCardLeft']['flex']}
    order:${config['divs']['sessionSummaryCardLeft']['order']}
    flex-grow:${config['divs']['sessionSummaryCardLeft']['flex-grow']}
`;
export const sessionSummaryCardRightDiv = styled("div", sessionSummaryCardProps)`
    display: ${config['divs']['sessionSummaryCardRight']['display']};
    flex-direction: ${config['divs']['sessionSummaryCardRight']['flex-direction']};
    align-items: ${config['divs']['sessionSummaryCardRight']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['sessionSummaryCardRight']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['sessionSummaryCardRight']['gap'])};
    width: ${(props) => ((props.width) ? (props.width) + 'px' : config['divs']['sessionSummaryCardRight']['width'])};
    height: ${config['divs']['sessionSummaryCardRight']['height']};
    flex:${config['divs']['sessionSummaryCardRight']['flex']}
    order:${config['divs']['sessionSummaryCardRight']['order']}
    flex-grow:${config['divs']['sessionSummaryCardRight']['flex-grow']}
`;
export const upcomingCourseSection = styled("div", sessionSummaryCardProps)`
    background: ${(props) => ((props.background) ? (props.background) :theme['Secondary-Gray0000'])};
    display: ${config['divs']['upcomingCourseSectionDiv']['display']};
    flex-direction: ${config['divs']['upcomingCourseSectionDiv']['flex-direction']};
    align-items: ${config['divs']['upcomingCourseSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['upcomingCourseSectionDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['upcomingCourseSectionDiv']['gap'])}
    flex:${config['divs']['upcomingCourseSectionDiv']['flex']}
    order:${(props) => ((props.orde) ? (props.order) :config['divs']['upcomingCourseSectionDiv']['order'])}
    align-self:${config['divs']['upcomingCourseSectionDiv']['align-self']}
    flex-grow:${config['divs']['upcomingCourseSectionDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['upcomingCourseSectionDiv']['width'])};
    height: ${config['divs']['upcomingCourseSectionDiv']['height']};
`;
export const browseCourseSection = styled("div", sessionSummaryCardProps)`
    background: ${(props) => ((props.background) ? (props.background) :theme['Secondary-Gray0300'])};
    display: ${config['divs']['upcomingCourseSectionDiv']['display']};
    flex-direction: ${config['divs']['upcomingCourseSectionDiv']['flex-direction']};
    align-items: ${config['divs']['upcomingCourseSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) + 'px' : config['divs']['upcomingCourseSectionDiv']['padding'])};
    gap:${(props) => ((props.gap) ? (props.gap) + 'px' : config['divs']['upcomingCourseSectionDiv']['gap'])}
    flex:${config['divs']['upcomingCourseSectionDiv']['flex']}
    order:${(props) => ((props.order) ? (props.order) :config['divs']['upcomingCourseSectionDiv']['order'])}
    align-self:${config['divs']['upcomingCourseSectionDiv']['align-self']}
    flex-grow:${config['divs']['upcomingCourseSectionDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['upcomingCourseSectionDiv']['width'])};
    height: ${config['divs']['upcomingCourseSectionDiv']['height']};
`;
export const upcomingSessionSection = styled("div", sessionSummaryCardProps)`
    background: ${(props) => (props.background ? props.background: theme['Secondary-Gray0000'])};
    display: ${config['divs']['upcomingSessionSectionDiv']['display']};
    flex-direction: ${config['divs']['upcomingSessionSectionDiv']['flex-direction']};
    align-items: ${config['divs']['upcomingSessionSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['upcomingSessionSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['upcomingSessionSectionDiv']['gap'])}px
    flex:${config['divs']['upcomingSessionSectionDiv']['flex']}
    order:${config['divs']['upcomingSessionSectionDiv']['order']}
    align-self:${config['divs']['upcomingSessionSectionDiv']['align-self']}
    flex-grow:${config['divs']['upcomingSessionSectionDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['upcomingSessionSectionDiv']['width'])};
    height: ${config['divs']['upcomingSessionSectionDiv']['height']};
`;
export const myCourseSection = styled("div", sessionSummaryCardProps)`
    background: ${(props) => (props.background_color ? props.background_color: theme['Secondary-Gray0000'])};
    display: ${config['divs']['myCourseSectionDiv']['display']};
    flex-direction: ${config['divs']['myCourseSectionDiv']['flex-direction']};
    align-items: ${config['divs']['myCourseSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['myCourseSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['myCourseSectionDiv']['gap'])}px
    flex:${config['divs']['myCourseSectionDiv']['flex']}
    order:${config['divs']['myCourseSectionDiv']['order']}
    align-self:${config['divs']['myCourseSectionDiv']['align-self']}
    flex-grow:${config['divs']['myCourseSectionDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['myCourseSectionDiv']['width'])};
    height: ${config['divs']['myCourseSectionDiv']['height']};
`;
export const recommendedCourseSection = styled("div", sessionSummaryCardProps)`
    background: ${theme['Secondary-Gray0000']};
    display: ${config['divs']['recommendedCourseSectionDiv']['display']};
    flex-direction: ${config['divs']['recommendedCourseSectionDiv']['flex-direction']};
    align-items: ${config['divs']['recommendedCourseSectionDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['recommendedCourseSectionDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['recommendedCourseSectionDiv']['gap'])}px
    flex:${config['divs']['recommendedCourseSectionDiv']['flex']}
    order:${config['divs']['recommendedCourseSectionDiv']['order']}
    align-self:${config['divs']['recommendedCourseSectionDiv']['align-self']}
    flex-grow:${config['divs']['recommendedCourseSectionDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['recommendedCourseSectionDiv']['width'])};
    height: ${config['divs']['recommendedCourseSectionDiv']['height']};
`;
const upcomingSessionTitleDivProps = {
    width: Number, // width is multiple of 100 in px
    height: Number, //multiple of 8, default 0
    margin_left: Number,
    margin_top: Number

};
export const upcomingSessionTitleDiv = styled("div", upcomingSessionTitleDivProps)`
    display: ${config['divs']['upcomingSessionTitleDiv']['display']};
    flex-direction: ${config['divs']['upcomingSessionTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['upcomingSessionTitleDiv']['justify-content']};
    align-items: ${config['divs']['upcomingSessionTitleDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['upcomingSessionTitleDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['upcomingSessionTitleDiv']['gap'])}px
    flex:${config['divs']['upcomingSessionTitleDiv']['flex']}
    order:${config['divs']['upcomingSessionTitleDiv']['order']}
    align-self:${config['divs']['upcomingSessionTitleDiv']['align-self']}
    flex-grow:${config['divs']['upcomingSessionTitleDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['upcomingSessionTitleDiv']['width'])};
    height: ${config['divs']['upcomingSessionTitleDiv']['height']}px;
`;
export const mySessionTitle = styled("div", upcomingSessionTitleDivProps)`
    display: ${config['divs']['mySessionTitleDiv']['display']};
    flex-direction: ${config['divs']['mySessionTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['mySessionTitleDiv']['justify-content']};
    align-items: ${config['divs']['mySessionTitleDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['mySessionTitleDiv']['padding'])}px
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['mySessionTitleDiv']['gap'])}px
    flex:${config['divs']['mySessionTitleDiv']['flex']}
    order:${config['divs']['mySessionTitleDiv']['order']}
    align-self:${config['divs']['mySessionTitleDiv']['align-self']}
    flex-grow:${config['divs']['mySessionTitleDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['mySessionTitleDiv']['width'])};
    height: ${config['divs']['mySessionTitleDiv']['height']}px;
`;
export const recommendedCourseTitle = styled("div", upcomingSessionTitleDivProps)`
    display: ${config['divs']['recommendedCourseTitleDiv']['display']};
    flex-direction: ${config['divs']['recommendedCourseTitleDiv']['flex-direction']};
    justify-content: ${config['divs']['recommendedCourseTitleDiv']['justify-content']};
    align-items: ${config['divs']['recommendedCourseTitleDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['recommendedCourseTitleDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['recommendedCourseTitleDiv']['gap'])}px
    flex:${config['divs']['recommendedCourseTitleDiv']['flex']}
    order:${config['divs']['recommendedCourseTitleDiv']['order']}
    align-self:${config['divs']['recommendedCourseTitleDiv']['align-self']}
    flex-grow:${config['divs']['recommendedCourseTitleDiv']['flex-grow']}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['recommendedCourseTitleDiv']['width'])};
    height: ${config['divs']['recommendedCourseTitleDiv']['height']}px;
`;
const homePageProps = {
    width: Number,
    background_color: String,
    padding: Number,
    gap: Number
};
export const homePageDiv = styled("div", homePageProps)`
background: ${(props) => (props.background_color ? props.background_color: theme['Secondary-Gray0000'])};
    display: ${config['divs']['homePageDiv']['display']};
    flex-direction: ${config['divs']['homePageDiv']['flex-direction']};
    align-items: ${config['divs']['homePageDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['homePageDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['homePageDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['homePageDiv']['width'])};
    height: ${config['divs']['homePageDiv']['height']};
    flex:${config['divs']['homePageDiv']['flex']}
    order:${(props) => ((props.order) ? (props.order) :config['divs']['homePageDiv']['order'])}
    flex-grow:${config['divs']['homePageDiv']['flex-grow']}
`;
const frame_55_props = {
    width: Number, 
    padding: Number,
    gap: Number,
    order: Number,
    justifyContent : String
}
export const frame_55 = styled("div", frame_55_props)`
    display: ${config['divs']['frames']['frame_55']['display']};
    flex-direction: ${config['divs']['frames']['frame_55']['flex-direction']};
    justify-content: ${(props) => ((props.justifyContent) ? (props.justifyContent) : config['divs']['frames']['frame_55']['justify-content'])};
    align-items: ${config['divs']['frames']['frame_55']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding) : config['divs']['frames']['frame_55']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap) : config['divs']['frames']['frame_55']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['frames']['frame_55']['width'])};
    height: ${config['divs']['frames']['frame_55']['height']};
    flex: ${config['divs']['frames']['frame_55']['flex']};
    order: ${config['divs']['frames']['frame_55']['order']};
    align-self: ${config['divs']['frames']['frame_55']['align-self']};
    flex-grow: ${config['divs']['frames']['frame_55']['flex-grow']};
`;
const inputRadioBoxProps = {
    width: Number,
    padding: Number,
    gap: Number
};
export const inputRadioBoxLabel = styled("label", inputRadioBoxProps)`
    display: ${config['divs']['inputRadioBoxLabel']['display']};
    flex-direction: ${config['divs']['inputRadioBoxLabel']['flex-direction']};
    align-items: ${config['divs']['inputRadioBoxLabel']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding)+'px' : config['divs']['inputRadioBoxLabel']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap)+'px' : config['divs']['inputRadioBoxLabel']['gap'])}
    width: ${(props) => ((props.width) ? (props.width)+'px' : config['divs']['inputRadioBoxLabel']['width'])};
    height: ${config['divs']['inputRadioBoxLabel']['height']};
`;

const inputRadioBoxInputProps = {
    //checked: Boolean
};

export const inputRadioBoxInput = styled("input", inputRadioBoxInputProps)`
    &[type="radio"]{
    position: absolute
    width:24px;
    height:24px;
    &:checked:before{
        content:"";
        position:absolute;
        width: 24px;
        height: 24px;
        background:#00B0B3;
        border: 1px solid #586666;
        border-radius: 12px;
        left: 0;
        top: 0;
      }
  
  }
`;
const tooltipDivProps = {
};

export const tooltipleftDiv = styled("div", tooltipDivProps)`
    display: ${config['divs']['tooltipDiv']['display']};
    position: ${config['divs']['tooltipDiv']['position']};
    span {
        visibility: ${config['divs']['tooltiptext']['visibility']};
        width: ${config['divs']['tooltiptext']['width']};
        background-color: ${config['divs']['tooltiptext']['background-color']};
        color: ${config['divs']['tooltiptext']['color']};
        text-align:${config['divs']['tooltiptext']['text-align']}
        border-radius:${config['divs']['tooltiptext']['border-radius']}
        padding:${config['divs']['tooltiptext']['padding']}
        position:${config['divs']['tooltiptext']['position']}
        z-index:${config['divs']['tooltiptext']['z-index']}
        right:${config['divs']['tooltiptext']['right']}
        top:${config['divs']['tooltiptext']['top']}
        opacity:${config['divs']['tooltiptext']['opacity']}
        transition:${config['divs']['tooltiptext']['transition']}
    }
    &:hover {
        span{
            visibility: visible;
            opacity:1;
        }
    }
`;
export const tooltiprightDiv = styled("div", tooltipDivProps)`
    display: ${config['divs']['tooltipDiv']['display']};
    position: ${config['divs']['tooltipDiv']['position']};
    span {
        visibility: ${config['divs']['tooltiptext']['visibility']};
        width: ${config['divs']['tooltiptext']['width']};
        background-color: ${config['divs']['tooltiptext']['background-color']};
        color: ${config['divs']['tooltiptext']['color']};
        text-align:${config['divs']['tooltiptext']['text-align']}
        border-radius:${config['divs']['tooltiptext']['border-radius']}
        padding:${config['divs']['tooltiptext']['padding']}
        position:${config['divs']['tooltiptext']['position']}
        z-index:${config['divs']['tooltiptext']['z-index']}
        left:105%
        top:${config['divs']['tooltiptext']['top']}
        opacity:${config['divs']['tooltiptext']['opacity']}
        transition:${config['divs']['tooltiptext']['transition']}
    }
    &:hover {
        span{
            visibility: visible;
            opacity:1;
        }
    }
`;
export const tooltiptopDiv = styled("div", tooltipDivProps)`
    display: ${config['divs']['tooltipDiv']['display']};
    position: ${config['divs']['tooltipDiv']['position']};
    span {
        visibility: ${config['divs']['tooltiptext']['visibility']};
        width: ${config['divs']['tooltiptext']['width']};
        background-color: ${config['divs']['tooltiptext']['background-color']};
        color: ${config['divs']['tooltiptext']['color']};
        text-align:${config['divs']['tooltiptext']['text-align']}
        border-radius:${config['divs']['tooltiptext']['border-radius']}
        padding:${config['divs']['tooltiptext']['padding']}
        position:${config['divs']['tooltiptext']['position']}
        z-index:${config['divs']['tooltiptext']['z-index']}
        left:${config['divs']['tooltiptext']['left']}
        bottom:${config['divs']['tooltiptext']['bottom']}
        margin-left:${config['divs']['tooltiptext']['margin-left']}
        opacity:${config['divs']['tooltiptext']['opacity']}
        transition:${config['divs']['tooltiptext']['transition']}
    }
    &:hover {
        span{
            visibility: visible;
            opacity:1;
        }
    }
`;

export const tooltipbottomDiv = styled("div", tooltipDivProps)`
    display: ${config['divs']['tooltipDiv']['display']};
    position: ${config['divs']['tooltipDiv']['position']};
    span {
        visibility: ${config['divs']['tooltiptext']['visibility']};
        width: ${config['divs']['tooltiptext']['width']};
        background-color: ${config['divs']['tooltiptext']['background-color']};
        color: ${config['divs']['tooltiptext']['color']};
        text-align:${config['divs']['tooltiptext']['text-align']}
        border-radius:${config['divs']['tooltiptext']['border-radius']}
        padding:${config['divs']['tooltiptext']['padding']}
        position:${config['divs']['tooltiptext']['position']}
        z-index:${config['divs']['tooltiptext']['z-index']}
        left: 50%
        top:100%
        margin-left:${config['divs']['tooltiptext']['margin-left']}
        opacity:${config['divs']['tooltiptext']['opacity']}
        transition:${config['divs']['tooltiptext']['transition']}
    }
    &:hover {
        span{
            visibility: visible;
            opacity:1;
        }
    }
`;

// TODO: fix this in below, width: ${config['divs']['tooltiptext']['width']};
export const tooptipText = styled("span", tooltipDivProps)`
    visibility: ${config['divs']['tooltiptext']['visibility']};
    width: auto !important;
    white-space: nowrap;
    background-color: ${config['divs']['tooltiptext']['background-color']};
    color: ${config['divs']['tooltiptext']['color']};
    text-align:${config['divs']['tooltiptext']['text-align']}
    border-radius:${config['divs']['tooltiptext']['border-radius']}
    padding:${config['divs']['tooltiptext']['padding']}
`;

const snackbarDivProps = {
};


export const snackbarDiv = styled("div", snackbarDivProps)`
    visibility: ${config['divs']['snackbarDiv']['visibility']};
    min-width: ${config['divs']['snackbarDiv']['min-width']};
    margin-left: ${config['divs']['snackbarDiv']['margin-left']};
    background-color: ${config['divs']['snackbarDiv']['background-color']};
    color: ${config['divs']['snackbarDiv']['color']};
    text-align:${config['divs']['snackbarDiv']['text-align']}
    border-radius:${config['divs']['snackbarDiv']['border-radius']}
    padding:${config['divs']['snackbarDiv']['padding']}
    position:${config['divs']['tooltiptext']['position']}
    z-index:${config['divs']['tooltiptext']['z-index']}
    left:${config['divs']['tooltiptext']['left']}
    bottom:${config['divs']['tooltiptext']['bottom']}
    font-size:${config['divs']['snackbarDiv']['font-size']}
    transition:${config['divs']['snackbarDiv']['transition']}
    &:active {
        visibility: visible;
        opacity:1;
    }
`;

const inputCompareDivProps = {
    width: String,
    padding: String,
    gap: String,
    flexDirection:String
};
export const inputCompareDiv = styled("div", inputCompareDivProps)`
    display: ${config['divs']['inputCompareDiv']['display']};
    flex-direction: ${(props) => ((props.flexDirection) ? (props.flexDirection) : config['divs']['inputCompareDiv']['flex-direction'])};
    align-items: ${config['divs']['inputCompareDiv']['align-items']};
    padding:${(props) => ((props.padding) ? (props.padding): config['divs']['inputCompareDiv']['padding'])}
    gap:${(props) => ((props.gap) ? (props.gap): config['divs']['inputCompareDiv']['gap'])}
    width: ${(props) => ((props.width) ? (props.width) : config['divs']['inputCompareDiv']['width'])};
    height: ${config['divs']['inputCompareDiv']['height']};
`;