import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
  files_manifest: {}
}
const mutations = {
    updateFilesManifest (state, {files_manifest}) {
      state.files_manifest = files_manifest
    },
    updateKeyValue (state, {key, value}) {
      state.files_manifest[key].value = value
    },
    updateKeyOpr (state, {key, opr}) {
      state.files_manifest[key].opr = opr
    },
    addKeyInFilesManifest (state, {key, type, value, opr}) {
      state.files_manifest[key] = {
          type: type,
          value: value,
          opr: opr
      }
    },
  }
export default new Vuex.Store({
  state,
  mutations
})