<template>
  <btn-secondary :as="as" :width="width" :display="display" :height="height" :order="order" :padding="padding">
    <c-icon>{{prependIcon}}</c-icon>
    <slot>secondary button</slot>
    <c-icon>{{postpendIcon}}</c-icon>
  </btn-secondary>
</template>

<script>
import {SecondaryButton} from '../atoms/button_styles'
import {iconStyle} from '../atoms/icon_styles'
export default {
    name: 'button-secondary',
    props:{
      prependIcon: String,
      postpendIcon: String,
      width: {
        type: Number
      },
      padding: {
        type: String
      },
      as:{ //here 'as' word used to change html tag/element type
        type: String
      },
      display:{
        type: String,
      },
      height: {
        type: Number
      },
      order: {
        type: Number
      }
    },
    components:{
        'btn-secondary': SecondaryButton,
        'c-icon': iconStyle
    }
}
</script>