<template>
    <div class="my-challenges"  >
        <c-H4-22 :color="colorTheme['Secondary-Gray0700']">Private space challenges</c-H4-22>
        <div v-if="loadingChallenges" style="display: contents;">
            <c-frame-55 >
                <c-loader-div></c-loader-div>
            </c-frame-55>
        </div>
        <c-upcoming-courses v-if="(challengesData.length>0)" class="my-challenges-list" >
            <c-abhyas-course-card style="width: 330px;justify-content: space-between;"  v-for="(challengeDetails, index) in challengesData" :key="index" position="relative">
                <div style="width: 100%;display: flex;flex-direction: column;gap: 8px;">
                    <c-description-div v-if="!(challengeDetails.chal_types === 'Free response question')" style="-webkit-line-clamp : 2; max-height: 100px;" overflow="hidden" text_align="left" text_transform="none">
                        <!-- eslint-disable -->
                        <c-H4-22 :color="colorTheme['Secondary-Gray0700']" :id="'chalQuestionStatement_' + (index + 1)"  v-html="challengeDetails.question_statement.textContent"></c-H4-22>
                    </c-description-div>
                    <div v-if="challengeDetails.chal_types === 'Free response question'" style="display:flex;overflow-x:scroll;scrollbar-width: none;-ms-overflow-style: none;width: 100%;">
                        <c-input-component :userRoleInSlot="'protege'" :canvasInViewMode="true" :uniqueCompKey="'frq_' + challengeDetails.id" :roomIdProp="'chal_qs_' + challengeDetails.id" :use_icanvas_editor="false" :default_editor_mode="'iCanvas'" :editor_width="'auto'" :editor_height="'100px'" :chal_id="challengeDetails.id" :showToolbar="false" :key="reRenderQuestionStatementInputComp"></c-input-component>
                    </div>
                    <c-row text_align="left" text_transform="none">
                        <c-overline-11>{{challengeDetails.chal_types}}</c-overline-11>
                    </c-row>
                </div>
                <c-frame-16 style="width: auto;">
                    <c-btn-secondary as="a" :id="'inputViewChallengeDetails_' + (index + 1) " :href="UI_base_url + '/challenge/' + challengeDetails.id">View details</c-btn-secondary>
                </c-frame-16>
            </c-abhyas-course-card>
            <c-frame-55 v-if=" challengesData.length === 0" >
                <c-row text_align="left" align_items="flex-start" text_transform="none">
                    <c-body-ux-14>You do not have any challenges.</c-body-ux-14>
                </c-row>
            </c-frame-55>
        </c-upcoming-courses>
    </div>
</template>
    
<script>
import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22,OVERLINE_11, body_UX_14} from '../atoms/text_styles';
import { theme } from '../assets/theme';
import { descriptionDiv, frame_16, abhyasCourseCard, rowDiv,loaderDiv,frame_55, upcomingCourses} from '../atoms/div_styles'
import buttonSecondary from '../molecules/button_secondary.vue'
import input_component from '../organisms/input_component.vue'
import katex from 'katex'
// import tag_pill from '../molecules/tag_pill.vue'
  
    export default {
        name: 'MyChallenges',
        data() {
            return {
                colorTheme:theme,
                currentView: 'MyChallenges', // Default view
                UI_base_url: AppConfig.UI_Base_URL,
                auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
                challengesData:[],
                loadingChallenges: '',
                reRenderQuestionStatementInputComp: true
            };
        },
        props:{
            challengesListdata:{
                type: Array
            },
            isLoadingChallenges:{
                type: Boolean
            }
        },
        components:{
            // 'c-body-14': body_14,
            'c-description-div': descriptionDiv,
            'c-frame-16':frame_16,
            'c-row': rowDiv,
            // 'c-span-body-14': span_body_14,
            'c-overline-11': OVERLINE_11,
            'c-H4-22': H4_22,
            'c-abhyas-course-card': abhyasCourseCard,
            // 'c-tag-pill': tag_pill,
            // 'c-tag-cloud': tagCloudDiv,
            // 'c-tag-row': tagRowDiv,
            'c-btn-secondary': buttonSecondary,
            'c-upcoming-courses':upcomingCourses,
            'c-loader-div': loaderDiv,
            'c-frame-55': frame_55,
            'c-body-ux-14': body_UX_14,
            'c-input-component' : input_component
        },
        async mounted(){
            console.log("challengesListdata", this.challengesListdata);
            this.challengesData = this.challengesListdata
            this.loadingChallenges = this.isLoadingChallenges
            if(this.challengesData.length > 0){
                let updatedFilesManifest = {}
                for(let idx=0; idx<this.challengesData.length; idx++){
                    for(let key in this.challengesData[idx]['files_manifest']){
                        // Update the value and opr for each key
                        this.challengesData[idx]['files_manifest'][key]['value'] = null
                        this.challengesData[idx]['files_manifest'][key]['opr'] = ''
                    }
                    // Accumulate the updated files_manifest for all challengesData
                    updatedFilesManifest = {
                        ...updatedFilesManifest,
                        ...this.challengesData[idx]['files_manifest']
                    }
                }
                // Commit the accumulated files_manifest to the store once
                this.$store.commit('updateFilesManifest', { files_manifest: updatedFilesManifest })
            }
            if(this.challengesData.length > 0){
                for(let idx=0; idx<this.challengesData.length; idx++){
                    if(!(this.challengesData[idx].chal_types === 'Free response question')){
                        await this.sleep(1);
                        await this.preview('chalQuestionStatement_' + (idx+1), this.challengesData[idx]['id'])
                    }
                }
            }
        },
        methods: {
            async getEditorKeyContentFn(storedUniqKey, chalId){
                // request api if value of key is not in the stored manifest
                let storeApiResp = await axios.get(this.auth_api_Base_URL + "/v2_1/chalEditors/" + chalId + "/?chal_editor=" + storedUniqKey)
                console.log("storeApiResp is", storeApiResp)
                return storeApiResp.data.actualContent
                // get rawFormula value from here, if apiResp return error means key not stored yet so new key and hence rawFormula will be just a empty string.
            },
            sleep(ms) {
                // add some sleep using this function , can be used when we want to wait some time to let page load the html
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async preview(eleId, chal_id) {
                // function to convert the rich text to renderable form
                let previewDiv = document.getElementById(eleId)
                console.log("preview div is", previewDiv, eleId, chal_id);
                console.log("=====>", this.$store.state);
                
                setTimeout(() => { // either add sleep or use setTimeout, the reason is DOM take time to update the richTextContent html so the queryselector was empty if no sleep is added
                    previewDiv.querySelectorAll('katex').forEach(async(el) => {
                        console.log('katex is', el);
                        //https://katex.org/docs/api.html
                        let katex_uniq_key = el.getAttribute('uniqkey')
                        let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                        if(this.$store.state.files_manifest[katex_uniq_key].value === null){
                            valueToRender = await this.getEditorKeyContentFn(katex_uniq_key, chal_id)
                        }
                        else{
                            valueToRender = this.$store.state.files_manifest[katex_uniq_key].value
                        }
                        katex.render(valueToRender, el, { //replace the katex element to renderable form using katex API
                            throwOnError: false,
                            strict: false,
                            displayMode: true,
                            maxSize: 300
                        });
                    })
                    previewDiv.querySelectorAll('c-image').forEach(el => { // render images using img tag and src as the created urls
                        if (!el.hasChildNodes()) {
                            //check if child already exist because if user click on preview again muliplte times then the appendChild function
                            //written below will add same amount of image child
                            console.log("image tag is", el);
                            let node = document.createElement("img");
                            let img_uniq_key = el.getAttribute('uniqkey')
                            let valueToRender = '' // TODO: req api in case value is null as that means value is stored in backend
                            if(this.$store.state.files_manifest[img_uniq_key].value === null){
                                valueToRender = this.auth_api_Base_URL + "/v2_1/chalEditors/" + chal_id + "/?chal_editor=" + img_uniq_key//await this.getEditorKeyContentFn(img_uniq_key, 'image')
                            }
                            else{
                                valueToRender = this.$store.state.files_manifest[img_uniq_key].value
                            }
                            let createdImgUrl = valueToRender //URL.createObjectURL(this.$store.state.files_manifest[el.getAttribute('uniqkey')].value)
                            node.setAttribute('src', createdImgUrl)
                            node.style.width = '500px'
                            node.style.height = '500px'
                            el.appendChild(node)
                        }
                    })
                }, 1)
            }
        }
    };
      
</script>
    
<style>
    .my-challenges {
      /* Add your styles here */
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 4px;
      gap: 8px;
      align-items: flex-start;
      border-radius: 8px;
      background: #F0FFFF;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    }
    .my-challenges-list{
      display: flex;
      flex-wrap: wrap;
    }
</style>
    