<template>
    <div class="my-wards">
        <c-H4-22 :color="colorTheme['Secondary-Gray0700']">My wards</c-H4-22>
        <!-- Add your ward-specific content here -->
        <div v-if="wardsData.length > 0" class="ward-content-div">
            <div v-for="ward in wardsData" :key="ward" class="ward-list-div">
                <c-H5-18 class="ward-card"> 
                    <a style="color: blue !important" :href="UI_base_url + '/user/' + ward.ward_id">
                        <c-body-14 style="color: blue !important;">&nbsp;  {{ ward.ward_user_name }}</c-body-14>
                    </a>
                    <a style="color: blue !important" :href="UI_base_url + '/calendar/' + ward.ward_id + '?userRole=Ward' ">
                        <c-body-14 style="color: black !important;">&nbsp; more info</c-body-14>
                    </a>

                </c-H5-18>
            </div>
        </div>
        <div v-if="wardsData.length === 0"  style="display: flex;gap: 16px;">
            <c-body-14 style="margin-top: 8px;text-align: left;display: flex;">You don't have any wards yet, you can invite by 
                <a style="color: blue !important" :href="UI_base_url + '/manage/ward'">
                    <c-body-14 style="color: blue !important;">&nbsp; Click here</c-body-14>
                </a>
            </c-body-14>
        </div>
    </div>
</template>
  
<script>
//import axios from 'axios';
import AppConfig from '../../config.js';
import { H4_22, H5_18, body_14} from '../atoms/text_styles';
import { theme } from '../assets/theme';
//import buttonSecondary from '../molecules/button_secondary.vue'
export default {
    name: 'MyWards',
    data() {
        return {
            colorTheme:theme,
            currentView: 'MyWards', // Default view
            UI_base_url: AppConfig.UI_Base_URL,
            auth_api_Base_URL: AppConfig.Auth_API_Base_URL,
            wardsData:[],
            user_id : null
        }
    },
    props:{
        wardsListdata:{
        type: Array
      }
    },
    components:{
      'c-H4-22': H4_22,
      'c-H5-18': H5_18,
      'c-body-14': body_14,
      //'c-btn-secondary': buttonSecondary
    },
    async beforeMount(){
        console.log("wardsListdata", this.wardsListdata);
        this.wardsData = this.wardsListdata
    },
    methods: {
        gotodashboardfn(org) {
            window.location.href = this.UI_base_url + '/org/' + org.org_id
        },
        //gotoInviteWardfn() {
        //    window.location.href = this.UI_base_url + '/manage/ward/'
        //},
        gotoCalendarFn(ward_id){
            this.user_id = ward_id
            let calendarPageUrl = this.UI_base_url + '/calendar/' + this.user_id + "?userRole=Ward"
            console.log("calendarPage url", calendarPageUrl)
            window.location.href = calendarPageUrl
        },
    }
};

  </script>
  
  <style>
  .my-wards {
    /* Add your styles here */
    border: 1px solid #d0d0d0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px;
    background: #f0ffff;
    border-radius: 8px;
    align-items: flex-start;
  }
  .ward-list-div{
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Abhyas-Color-Base-Secondary-Gray0000, #F8FFFF);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .ward-content-div {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .ward-card {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  </style>
  